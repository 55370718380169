import axios from 'axios'
import dayjs from "dayjs";
import {API} from "aws-amplify";

export const AppUtil = {
    handle_transaction_date_change: (event, option) => {
        let selDate = '';
        if (event.isValid !== undefined) {
            if (event.toDate().getMonth() >= 9) {
                if (option === 'onlyYearMonth') {
                    selDate = event.toDate().getFullYear() + '-' + (event.toDate().getMonth() + 1) + '-01';
                } else {
                    if (event.toDate().getDate() > 9) {
                        selDate = event.toDate().getFullYear() + '-' + (event.toDate().getMonth() + 1) + '-' + event.toDate().getDate();
                    } else {
                        selDate = event.toDate().getFullYear() + '-' + (event.toDate().getMonth() + 1) + '-0' + event.toDate().getDate();
                    } // + 'T00:00.00.000Z';
                }
            } else if (event.toDate().getMonth() < 9) {
                if (option === 'onlyYearMonth') {
                    selDate = event.toDate().getFullYear() + '-0' + (event.toDate().getMonth() + 1) + '-01';
                } else {
                    if (event.toDate().getDate() > 9) {
                        selDate = event.toDate().getFullYear() + '-0' + (event.toDate().getMonth() + 1) + '-' + event.toDate().getDate();
                    } else {
                        selDate = event.toDate().getFullYear() + '-0' + (event.toDate().getMonth() + 1) + '-0' + event.toDate().getDate();
                    }
                }
            }
        } else {
            selDate = event;
        }
        return selDate;
    },
    getFileInfo: async (url) => {
        const response = await axios.get(url);
        const mimeType = response.headers['content-type'];
        if (mimeType.startsWith('image/')) {
            return {fileType: 'image', url: url, type: mimeType};
        } else if (mimeType === 'application/pdf') {
            return {fileType: 'pdf', url: url, type: mimeType};
        } else {
            return {fileType: null, url: url, type: mimeType}
        }
    },
    generateRandomPassword: () => {
        const length = 8;
        const characters = {
            symbols: '!@#$%^&*()_+{}[]|:;<>,.?/~`-=', // Add more if desired
            lowercase: 'abcdefghijklmnopqrstuvwxyz',
            uppercase: 'ABCDEFGHIJKLMNOPQRSTUVWXYZ',
            numbers: '0123456789',
        };

        const allCharacters = Object.values(characters).join('');
        let password = '';

        password += characters.symbols[Math.floor(Math.random() * characters.symbols.length)];
        password += characters.lowercase[Math.floor(Math.random() * characters.lowercase.length)];
        password += characters.uppercase[Math.floor(Math.random() * characters.uppercase.length)];
        password += characters.numbers[Math.floor(Math.random() * characters.numbers.length)];

        for (let i = 4; i < length; i++) {
            password += allCharacters[Math.floor(Math.random() * allCharacters.length)];
        }

        password = password.split('').sort(() => Math.random() - 0.5).join('');
        return password;
    },
    getChangedProperties: (prev, current) => {
        const changes = {};
        for (const key in current) {
            if (current[key] !== prev[key]) {
                changes[key] = {
                    prev: prev[key] === undefined ? null : prev[key], // Handle missing keys
                    current: current[key],
                };
            }
        }
        return changes;
    }
}

export const CurrencyLocales = {
    USD: 'en-US', // US Dollar
    EUR: 'de-DE', // Euro
    JPY: 'ja-JP', // Japanese Yen
    GBP: 'en-GB', // British Pound Sterling
    AUD: 'en-AU', // Australian Dollar
    CAD: 'en-CA', // Canadian Dollar
    CHF: 'de-CH', // Swiss Franc
    CNY: 'zh-CN', // Chinese Yuan
    SEK: 'sv-SE', // Swedish Krona
    NZD: 'en-NZ', // New Zealand Dollar
    MXN: 'es-MX', // Mexican Peso
    SGD: 'en-SG', // Singapore Dollar
    HKD: 'zh-HK', // Hong Kong Dollar
    NOK: 'nb-NO', // Norwegian Krone
    KRW: 'ko-KR', // South Korean Won
    TRY: 'tr-TR', // Turkish Lira
    RUB: 'ru-RU', // Russian Ruble
    INR: 'hi-IN', // Indian Rupee
    BRL: 'pt-BR', // Brazilian Real
    ZAR: 'en-ZA', // South African Rand
    AED: 'ar-AE', // United Arab Emirates Dirham
    ARS: 'es-AR', // Argentine Peso
    CLP: 'es-CL', // Chilean Peso
    COP: 'es-CO', // Colombian Peso
    DKK: 'da-DK', // Danish Krone
    EGP: 'ar-EG', // Egyptian Pound
    IDR: 'id-ID', // Indonesian Rupiah
    ILS: 'he-IL', // Israeli New Shekel
    MYR: 'ms-MY', // Malaysian Ringgit
    NGN: 'en-NG', // Nigerian Naira
    PHP: 'en-PH', // Philippine Peso
    PKR: 'ur-PK', // Pakistani Rupee
    PLN: 'pl-PL', // Polish Zloty
    SAR: 'ar-SA', // Saudi Riyal
    THB: 'th-TH', // Thai Baht
    TWD: 'zh-TW', // New Taiwan Dollar
    UAH: 'uk-UA', // Ukrainian Hryvnia
    VND: 'vi-VN'  // Vietnamese Dong
};

export const Log = {
    info: async ({ id = null, data = null, logType = null, logAction = null, logDescription = null, beneficiaryID = null, transferID = null}) => {

        const res = await axios.get('https://ipinfo.io/json?token=153278dac2fa60')
            .then(res => res.data).catch(() => null);
        if (res != null) {
            await API.post("commons", "/insert", {
                body: {
                    context: 'logging',
                    data: {
                        city: res.city,
                        country: res.country,
                        ip: res.ip,
                        region: res.region,
                        location: res.loc,
                        timezone: res.timezone,
                        recordCreated: dayjs().format('YYYY-MM-DD HH:mm:ss'),
                        logType: logType,
                        logAction: logAction,
                        clientId: id,
                        beneficiaryID: beneficiaryID,
                        transferID: transferID,
                        logDescription: logDescription,
                        data: JSON.stringify(data)
                    }
                }
            });
        }
    }
}