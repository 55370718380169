import React, { Fragment } from "react";
import { connect } from "react-redux";
import {
  update_map_data,
  updateNewTransferCreation,
  updateNewTransferCreationBeneficiary,
  clearNewTransfer
} from "../../redux/actions/transfers_dashboard";
import {Row, Col, Descriptions, Input, Form} from "antd";
import "antd/dist/antd.css";
import { createNewBeneficiary } from "../../redux/actions/beneficiaries";

import withStyles from "@material-ui/core/styles/withStyles";
import dashboardStyle from "../../views/Pages/DashboardPageStyle";
import customCheckboxRadioSwitch from "assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch.jsx";
import CustomTextField from "../../components/CustomInput/CustomTextField";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import CloseIcon from "@material-ui/icons/Close";
import IconButton from "@material-ui/core/IconButton";
import Button from "components/CustomButtons/Button.jsx";
import addIcon from "../../assets/img/addIcon.png";
import { FormattedMessage } from "react-intl";
import CustomReactSelect from "../Forms/CustomReactSelect/CustomReactSelect";
import { API } from "aws-amplify";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import DocumentUpload from "components/DocumentUpload/DocumentUpload.jsx";
import document_image from "assets/img/document_image.jpg";
import sample_invoice from "assets/img/sample_invoice.png";
import { cardTitle } from "../../assets/jss/material-dashboard-pro-react";
import { withRouter } from "react-router-dom";

import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Slide from "@material-ui/core/Slide";
import CommoneCurrentComponent from "./Common";
import cardPng from "../../assets/img/payment_24px.png";
import { Checkbox, Chip, Divider, FormControlLabel } from "@material-ui/core";
import Check from "@material-ui/icons/Check";
import Clear from "@material-ui/icons/Clear";
import queryString from "query-string";

import Table from "components/Table/Table.jsx";

import { Checkbox as AntCheckbox } from 'antd';

import { Alert } from 'antd';
import FileUploader from "../FileUploader/FileUploader";
import {Log} from "../../AppUtil";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const axios = require("axios");

const min_transfer_amount = 0;
const style = {
  ...dashboardStyle,
  ...customCheckboxRadioSwitch,
  cardTitle,
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px",
    "& small": {
      fontSize: "80%",
      fontWeight: "400"
    }
  },
  infoText: {
    fontWeight: "300",
    margin: "10px 0 30px",
    textAlign: "center"
  },
  inputAdornmentIcon: {
    color: "#555"
  },
  inputAdornment: {
    position: "relative"
  },
  formControl: {
    minWidth: 120,
    padding: "0 !important"
  },
  selectControl: {
    backgroundColor: "#00BDAB !important",
    color: "white",
    padding: "5%",
    width: "100% !important"
  },
  optionStyle: {
    backgroundColor: "#00BDAB !important"
  },

  column: {
    paddingRight: "0 !important",
    paddingBottom: "0 !important"
  },
  columnRight: {
    paddingLeft: "0 !important"
  },
  boxShadow: {
    filter: "drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))",
    boxSizing: "border-box"
  },
  input: {
    margin: 0,
    padding: 0,
    border: "none",
    direction: "rtl",
    paddingRight: "4%",
    fontWeight: "700",
    fontFamily: "Roboto",
    fontSize: "24px"
  },
  divv: {
    filter: "drop-shadow(2px 4px 4px rgba(0, 0, 0, 0.25))",
    boxSizing: "border-box"
  },
  pp: {
    float: "right",
    paddingRight: "9px",
    padding: "0",
    margin: "0",
    fontFamily: "Roboto",
    fontWeight: "400",
    fontSize: "12px",
    paddingTop: "1px"
  },
  modal2: {
    borderRadius: "6px",
    marginTop: "100px !important",
    overflow: "visible",
    maxHeight: "unset",
    position: "relative",
    height: "fit-content"
  },
  iso: {
    fontSize: "13px !important"
  },
  isoSpan: {
    width: "33% !important"
  },
  textArea: {
    backgroundColor: "white",
    width: "100%"
  },
  inputReadOnly: {
    paddingTop: "4px",
    zIndex: "-1000",
    paddingRight: "4%",
    fontFamily: "Roboto",
    fontWeight: "400",
    fontSize: "13px",
    margin: 0,
    padding: 0,
    paddingBottom: "0",
    border: "none",
    direction: "rtl"
  },
  transferRate: {
    fontFamily: "Roboto",
    fontWeight: "700",
    fontSize: "16px",
    lineHeight: "24px",
    letterSpacing: "2px",
    paddingLeft: "4%",
    color: "#131313",
    paddingTop: "3%",
    textTransform: "uppercase"
  },
  selectContainer: {
    padding: "4%",
    background: "#FFFFFF",
    border: "1px solid #0BACF4",
    boxSizing: "border-box",
    borderRadius: "6px"
  },
  paymentMethod: {
    fontFamily: "Roboto",
    fontWeight: "400",
    fontSize: "12px",
    lineHeight: "17.04px",
    float: "right",
    textAlign: "right",
    opacity: "0.7",
    color: "#131313",
    paddingTop: "4%",
    paddingRight: "3%"
  },
  cardImg: {
    textAlign: "center",
    paddingLeft: "7% !important"
  },
  transactionOptions: {
    border: "none",
    width: "100%",
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "16px",
    lineHeight: "24px",
    letterSpacing: "2px",
    textAlign: "right",
    letterSpacing: "0.15em",
    textTransform: "uppercase",
    color: "#0BACF4",
    backgroundColor: "white"
  },
  modalTitle2: {
    fontFamily: "Roboto",
    fontWeight: "400",
    fontSize: "48px"
  },
  benficiaryTitle: {
    fontFamily: "Roboto",
    fontWeight: "400",
    fontSize: "24px",
    marginBottom: "0 !important",
    paddingBottom: "0 !important"
  },
  beneficiaryDivider: {
    border: "0px solid rgba(0, 0, 0, 0.12)",
    marginTop: "1px !important",
    paddingTop: "0 !important",
    marginBottom: "5% !important"
  },
  createBeneficary: {
    fontFamily: "Roboto",
    cursor: "pointer",
    fontWeight: "700",
    fontSize: "14px",
    letterSpacing: "2px",
    color: "#0BACF4"
  },
  createBeneficary2: {
    fontFamily: "Roboto",
    fontWeight: "700",
    cursor: "pointer",
    fontSize: "14px",
    letterSpacing: "2px",
    color: "#0BACF4",
    textAlign: "right",
    color: "#131313"
  },
  primaryB: {
    fontFamily: "Roboto",
    width: "100%",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "16px",
    lineHeight: "24px",
    textAlign: "center",
    letterSpacing: "0.15em",
    textTransform: "uppercase",
    color: "#FFFFFF",
    background: "#7C4DFF",
    boxShadow:
      "0px 4px 4px rgba(0, 0, 0, 0.25), -10px -10px 30px rgba(124, 77, 255, 0.3)"
  },
  primaryC: {
    width: "100%",
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "16px",
    lineHeight: "24px",
    textAlign: "center",
    letterSpacing: "0.15em",
    textTransform: "uppercase",
    backgroundColor: "transparent"
  }
};
class DashboardTransferStep1 extends React.Component {

  uploadRef = React.createRef();
  constructor(props) {
    super(props);
    this.state = {
      thirdParty: false,
      componentLoading: false,
      beneficiaries_list: [],
      purpose_list: [],
      currencies_list_priority: [],
      map_data: {},
      show_iban: false,
      show_bsb: false,
      show_sort_code: false,
      show_swift_code: false,
      show_aba: false,
      show_account_number: false,
      payment_method: null,
      country_list: [],
      currencies_list: [],
      clientTransfers: [
        {
          beneficiary_id: "",
          amount_to: "",
          purpose_of_payment_detail: "",
          beneficiary_id_state: "",
          amount_to_state: "",
          purpose_of_payment_detail_state: "",
          detail_1: "",
          detail_2: "",
          detail_3: "",
          detail_1_state: "",
          detail_2_state: "",
          detail_3_state: "",
          document: "",
          purpose_of_payment_other: "",
          purpose_of_payment_other_state: ""
        }
      ],
      ledgerFrom: false,
      ledgerTo: false,
      newLedgerFrom: false,
      newLedgerTo: false,
      send_to_holding_acc_checkbox: false,
      showDocumentUpload: false,
      holdingAccountBalances: {},
      timeoutId: null,
      displayLedgerWarning: false,
      fileIds: []
    };

    this.abortController = new AbortController();
  }

  initializeDefaultCurries = () => {
    const query_strings = queryString.parse(this.props.location.search);
    const currency = parseInt(query_strings.fromCurrency);
    const fromCurrency = parseInt(query_strings.fromCurrency);
    const fromLedger = parseInt(query_strings.fromLedger);
    const toCurrency = parseInt(query_strings.toCurrency);
    const toLedger = parseInt(query_strings.toLedger);
    const purposeOfPayment = parseInt(query_strings.purposeOfPayment);
    

    if (!isNaN(fromLedger)) {
      this.props.updateNewTransferCreation("ledgerFrom", fromLedger);
      // this.props.updateNewTransferCreation("send_to_holding_acc_checkbox", true);
      this.setState({
        fromLedger: fromLedger
      });
    }
    if (!isNaN(fromCurrency)) {
      this.props.updateNewTransferCreation("currency_from_id", fromCurrency);
      this.props.updateNewTransferCreation("currency_from_state", "success");
      this.props.updateNewTransferCreation("amount_lock", "from_amount");
      API.get("currencies", `/currencies/get/${fromCurrency}`)
      .then(response => {
        this.props.updateNewTransferCreation("currency_from", response);
        this.props.updateNewTransferCreation(
          "currency_from_name",
          response.iso_alpha_3
        );
      })
      .catch(error => {
        console.log(error);
      });
      this.updateMapData(
        "from_currency",
        true,
        true,
        {value: fromCurrency},
      );
    }

    if (!isNaN(toLedger)) {
      this.props.updateNewTransferCreation("ledgerTo", toLedger);
      this.props.updateNewTransferCreation("send_to_holding_acc_checkbox", true);
      this.setState({
        toLedger: toLedger
      });
    }
    if (!isNaN(toCurrency)) {
      this.props.updateNewTransferCreation("currency_to_id", toCurrency);
      this.props.updateNewTransferCreation("currency_to_state", "success");
      // this.props.updateNewTransferCreation("amount_lock", "from_amount");
      API.get("currencies", `/currencies/get/${toCurrency}`)
      .then(response => {
        this.props.updateNewTransferCreation("currency_to", response);
        this.props.updateNewTransferCreation(
          "currency_to_name",
          response.iso_alpha_3
        );
      })
      .catch(error => {
        console.log(error);
      });
      this.updateMapData(
        "to_currency",
        true,
        true,
        {value: toCurrency},
      );
    }
    
    // Handle query string for purpose of payment
    if (!isNaN(purposeOfPayment)) {

      let prefill_purpose_of_payment_id = purposeOfPayment;
      let index;

      for (index in this.props.app_state.current_client.purpose_of_payment_list) {
        if (
          this.props.app_state.current_client.purpose_of_payment_list[index].id === prefill_purpose_of_payment_id
        ) {
          let default_beneficiary_array = this.props.transfers_dashboard.new_transfer_data.multiple_beneficiary_id || [{}];
          let purpose_of_payment_obj = this.props.app_state.current_client.purpose_of_payment_list[index];
          
          this.setState({ prefill_purpose_of_payment: true });
          default_beneficiary_array[0].purpose_of_payment_detail = purpose_of_payment_obj.id;
          default_beneficiary_array[0].purpose_of_payment_detail_nickname = purpose_of_payment_obj.description;
          default_beneficiary_array[0].purpose_of_payment_detail_state = "success";
          
          if (purposeOfPayment == 87) {
            this.setState({ prefill_payment_detail: true });
            default_beneficiary_array[0].detail_1 = "Transfer to own account";
            default_beneficiary_array[0].detail_1_state = "success";
          }
      
            this.props.updateNewTransferCreationBeneficiary(
            default_beneficiary_array
          );
        }
      }

    }
    


    // this.props.updateNewTransferCreation("amount_from", "1000");
    // this.handleAmount("to_amount");
    // this.defaultCurrencies(
    //   "from_currency",
    //   this.props.transfers_dashboard.new_transfer_data.currency_from_id,
    //   this.props.transfers_dashboard.new_transfer_data.currency_to_id,
    //   isNaN(currency) ? 1 : currency
    // );
    // this.defaultCurrencies(
    //   "to_currency",
    //   this.props.transfers_dashboard.new_transfer_data.currency_from_id,
    //   this.props.transfers_dashboard.new_transfer_data.currency_to_id,
    //   5
    // );
    this.setState({});
  };

  getLedgerBalances = () => {
    let chip_list = [];
    API.get("holding_accounts", `/get/all/v2/${this.props.app_state.current_client.id}`)
      .then(response => {
        console.log(response)
        this.setState({
          holdingAccountBalances: response.holding_account_list
        });
      })
      .catch(error => {
        console.log(error);
      });
  };

  async componentDidMount() {
    this.props.setDrawer(this.getInstructions);
    await this.initialiseState()
  }

  async componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.prefill !== this.props.prefill) {
      this.componentDidMount();
    }
    if (prevProps.app_state.current_client.id !== this.props.app_state.current_client.id) {
      await this.initialiseState()
    }
  }

  componentWillUnmount() {}

  initialiseState = async () => {
    this.setState({componentLoading: true})
    await API.get("currencies", `/currencies/get-list-priority`)
    .then(response => {
      this.setState({
        currencies_list_priority: response
      });
    })
    await API.get("beneficiaries", `/beneficiaries/list/${this.props.app_state.current_client.id}`)
    .then(response => {
      const filteredResponse = response.filter((item) => { return item.active === 1 })
      this.setState(
        {
          beneficiaries_list: filteredResponse,
          prefill_from_currency: false,
          prefill_to_currency: false,
          prefill_from_amount: false,
          prefill_to_amount: false,
          prefill_beneficiary: false,
          prefill_purpose_of_payment: false,
          prefill_payment_detail: false
        },
        () => {
          this.preFillFields();
          this.set_payment_radio("bank");
          this.initializeDefaultCurries();
          this.getLedgerBalances();
        }
      );
    })
    .catch(error => {
      console.log(error);
    });
    await API.get("countries", `/countries/list_not_deleted`)
      .then(response => {
        this.setState({
          country_list: response
        });
      })
      .catch(error => {
        console.log(error);
      });

    await API.get("currencies", `/currencies/get-all`)
      .then(response => {
        this.setState({
          currencies_list: response
        });
      })
      .catch(error => {
        console.log(error);
      });

    this.setState({
      payment_method: "bank"
    });
    this.setState({componentLoading: false})
  }

  preFillFields() {
    if (!this.props.prefill) return null;
    const prefill_strings_array = this.props.prefill.split("-");
    // set default "from currency"
    if (prefill_strings_array[0] !== "" && !!prefill_strings_array[0]) {
      let prefill_from_currency_name = prefill_strings_array[0];
      // let default_currency_from_id = ""
      let index;
      // only set default value if the prefilled name is under the currency list
      for (index in this.state.currencies_list_priority) {
        if (
          this.state.currencies_list_priority[index].iso_alpha_3 ===
          prefill_from_currency_name
        ) {
          let default_currency_from_id = this.state.currencies_list_priority[
            index
          ].id;
          let currency_from_object = { value: default_currency_from_id }; // label is not important in this object so it's omitted
          // Disable DDL
          this.setState({ prefill_from_currency: true });
          // update map
          this.updateMapData(
            "from_currency",
            this.props.transfers_dashboard.new_transfer_data.currency_from_id,
            this.props.transfers_dashboard.new_transfer_data.currency_to_id,
            currency_from_object
          );
          // set props and state as well as handle amount
          this.setState(
            {
              ["from_currency"]: default_currency_from_id
            },
            () => {
              this.handleAmount();
            }
          );
          this.props.updateNewTransferCreation(
            "currency_from_id",
            default_currency_from_id
          );
          this.props.updateNewTransferCreation(
            "currency_from_state",
            "success"
          );
          API.get("currencies", `/currencies/get/${default_currency_from_id}`)
            .then(response => {
              this.props.updateNewTransferCreation("currency_from", response);
              this.props.updateNewTransferCreation(
                "currency_from_name",
                response.iso_alpha_3
              );
            })
            .catch(error => {
              console.log(error);
            });
        }
      }
    }

    // set default "to currency"
    if (prefill_strings_array[1] !== "" && !!prefill_strings_array[1]) {
      let prefill_to_currency_name = prefill_strings_array[1];
      let index;
      // only set default value if the prefilled name is under the currency list
      for (index in this.state.currencies_list_priority) {
        if (
          this.state.currencies_list_priority[index].iso_alpha_3 ===
          prefill_to_currency_name
        ) {
          let default_currency_to_id = this.state.currencies_list_priority[
            index
          ].id;
          let currency_to_object = { value: default_currency_to_id }; // label is not important in this object so it's omitted
          // Disable DDL
          this.setState({ prefill_to_currency: true });
          // update map
          this.updateMapData(
            "to_currency",
            this.props.transfers_dashboard.new_transfer_data.currency_from_id,
            this.props.transfers_dashboard.new_transfer_data.currency_to_id,
            currency_to_object
          );

          //set show values using tom module
          let currency_dataset = this.state.currencies_list_priority;
          for (let i = 0; i < currency_dataset.length; i++) {
            if (currency_dataset[i].id === default_currency_to_id) {
              //iban check
              if (currency_dataset[i].iban === 1) {
                this.setState({
                  show_iban: true
                });
              } else {
                this.setState({
                  show_iban: false
                });
              }
              //bsb check
              if (currency_dataset[i].bsb === 1) {
                this.setState({
                  show_bsb: true
                });
              } else {
                this.setState({
                  show_bsb: false
                });
              }
              //sort_code check
              if (currency_dataset[i].sort_code === 1) {
                this.setState({
                  show_sort_code: true
                });
              } else {
                this.setState({
                  show_sort_code: false
                });
              }
              //swift_code check
              if (currency_dataset[i].swift_code === 1) {
                this.setState({
                  show_swift_code: true
                });
              } else {
                this.setState({
                  show_swift_code: false
                });
              }
              //aba_routing check
              if (currency_dataset[i].aba_routing === 1) {
                this.setState({
                  show_aba: true
                });
              } else {
                this.setState({
                  show_aba: false
                });
              }
              //account number check
              if (currency_dataset[i].account_number === 1) {
                this.setState({
                  show_account_number: true
                });
              } else {
                this.setState({
                  show_account_number: false
                });
              }
            }
          }
          // set props and state as well as handle amount
          this.setState(
            {
              ["to_currency"]: default_currency_to_id,
              ["account_currency"]: default_currency_to_id
            },
            () => {
              this.handleAmount();
            }
          );
          this.props.updateNewTransferCreation(
            "currency_to_id",
            default_currency_to_id
          );
          this.props.updateNewTransferCreation("currency_to_state", "success");
          API.get("currencies", `/currencies/get/${default_currency_to_id}`)
            .then(response => {
              this.props.updateNewTransferCreation("currency_to", response);
              this.props.updateNewTransferCreation(
                "currency_to_name",
                response.iso_alpha_3
              );
            })
            .catch(error => {
              console.log(error);
            });
        }
      }
    }

    // set default "from amount"
    if (prefill_strings_array[2] !== "" && !!prefill_strings_array[2]) {
      let default_from_amount = Number(prefill_strings_array[2]);
      // Check if the amount is larger than the minimum amount
      if (this.isLargerThanNumber(default_from_amount, min_transfer_amount)) {
        this.props.updateNewTransferCreation("amount_from_state", "success");
      } else {
        this.props.updateNewTransferCreation("amount_from_state", "error");
      }

      this.props.updateNewTransferCreation("amount_lock", "from_amount");
      this.props.updateNewTransferCreation("amount_from", default_from_amount);

      // Disable CustomInput and then handle "to amount"
      this.setState(
        {
          prefill_from_amount: true
        },
        () => {
          this.handleAmount("to_amount");
        }
      );
    }

    // set default "to amount"
    if (prefill_strings_array[3] !== "" && !!prefill_strings_array[3]) {
      let default_to_amount = Number(prefill_strings_array[3]);
      // Check if the amount is larger than the minimum amount
      if (this.isLargerThanNumber(default_to_amount, min_transfer_amount)) {
        this.props.updateNewTransferCreation("amount_to_state", "success");
      } else {
        this.props.updateNewTransferCreation("amount_to_state", "error");
      }

      this.props.updateNewTransferCreation("amount_lock", "to_amount");
      this.props.updateNewTransferCreation("amount_to", default_to_amount);

      // Disable CustomInput and then handle "from amount"
      this.setState(
        {
          prefill_to_amount: true
        },
        () => {
          this.handleAmount("from_amount");
        }
      );
    }

    // set default "beneficiary id"
    if (prefill_strings_array[4] !== "" && !!prefill_strings_array[4]) {
      let prefill_beneficiary_id = Number(prefill_strings_array[4]);
      let index;
      // only set default value if the prefilled id is under the beneficiaries list
      for (index in this.state.beneficiaries_list) {
        if (
          this.state.beneficiaries_list[index].id === prefill_beneficiary_id
        ) {
          let default_beneficiary_array = this.props.transfers_dashboard
            .new_transfer_data.multiple_beneficiary_id || [{}];
          // Disable DDL
          this.setState({ prefill_beneficiary: true });
          API.get(
            "beneficiaries",
            `/beneficiaries/getb/${prefill_beneficiary_id}`
          )
            .then(beneficiaries_detail => {
              default_beneficiary_array[0].beneficiary_id = prefill_beneficiary_id;
              default_beneficiary_array[0].beneficiary_id_state = "success";
              default_beneficiary_array[0].beneficiary =
                beneficiaries_detail[0];
              this.props.updateNewTransferCreationBeneficiary(
                default_beneficiary_array
              );
            })
            .catch(error => {
              console.log(error);
            });
        }
      }
    }

    // set default "purpose of payment id"
    if (prefill_strings_array[5] !== "" && !!prefill_strings_array[5]) {
      let prefill_purpose_of_payment_id = Number(prefill_strings_array[5]);
      let index;
      // only set default value if the prefilled id is under the purpose of payment list
      for (index in this.props.app_state.current_client
        .purpose_of_payment_list) {
        if (
          this.props.app_state.current_client.purpose_of_payment_list[index]
            .id === prefill_purpose_of_payment_id
        ) {
          let default_beneficiary_array = this.props.transfers_dashboard
            .new_transfer_data.multiple_beneficiary_id || [{}];
          let purpose_of_payment_obj = this.props.app_state.current_client
            .purpose_of_payment_list[index];
          // Disable DDL
          this.setState({ prefill_purpose_of_payment: true });
          default_beneficiary_array[0].purpose_of_payment_detail =
            purpose_of_payment_obj.id;
          default_beneficiary_array[0].purpose_of_payment_detail_nickname =
            purpose_of_payment_obj.description;
          default_beneficiary_array[0].purpose_of_payment_detail_state =
            "success";
          this.props.updateNewTransferCreationBeneficiary(
            default_beneficiary_array
          );
        }
      }
    }

    // set default "payment detail"
    if (prefill_strings_array[6] !== "" && !!prefill_strings_array[6]) {
      let prefill_payment_detail_string = prefill_strings_array[6];
      let default_beneficiary_array = this.props.transfers_dashboard
        .new_transfer_data.multiple_beneficiary_id || [{}];
      // Disable CustomInput
      this.setState({ prefill_payment_detail: true });
      default_beneficiary_array[0].detail_1 = prefill_payment_detail_string;
      default_beneficiary_array[0].detail_1_state = "success";
      this.props.updateNewTransferCreationBeneficiary(
        default_beneficiary_array
      );
    }


  }

  clearBeneficiaryDetails() {
    let beneficiary_array = this.props.transfers_dashboard.new_transfer_data
      .multiple_beneficiary_id || [{}];
    beneficiary_array[0].beneficiary = {};
    beneficiary_array[0].beneficiary_id = "";
    beneficiary_array[0].beneficiary_id_state = "";
    this.props.updateNewTransferCreationBeneficiary(beneficiary_array);
  }

  // function that verifies if a string has a given length or not
  verifyLength(value, length) {
    if (value.length >= length) {
      return true;
    }
    return false;
  }

  // function that verifies if a value is larger than a number or not
  isLargerThanNumber(value, number) {
    if (value !== "" && value >= number) {
      return true;
    }
    return false;
  }

  change(event, stateName, type, stateNameEqualTo) {
    console.log('change: ', stateName, event.target.value)
    console.log('ledgerFrom: ', this.props.transfers_dashboard.new_transfer_data.ledgerFrom)
    let ledgerOverride = false;
    console.log('ledgerOverride: ', ledgerOverride)
    let eventTargetValue = event.target.value;
    console.log('eventTargetValue: ', eventTargetValue)

    for (var idx in this.state.holdingAccountBalances) {
      let record = this.state.holdingAccountBalances[idx];
      if (
        record.currency_id === this.props.transfers_dashboard.new_transfer_data.currency_from_id
        && record.balance
      ) {
        console.log('ledger balance: ', record.balance)
        if ((record.balance < event.target.value) && (this.props.transfers_dashboard.new_transfer_data.ledgerFrom)) {
          this.setState({displayLedgerWarning: true})
          eventTargetValue = record.balance.toFixed(2)
        } else {
          this.setState({displayLedgerWarning: false})
          eventTargetValue = event.target.value
        }

      }
    }
    console.log('eventTargetValue: ', eventTargetValue)
    console.log('---')

    
    switch (type) {
      case "length":
        if (this.verifyLength(eventTargetValue, stateNameEqualTo)) {
          this.setState({ [stateName + "_state"]: "success" });
        } else {
          this.setState({ [stateName + "_state"]: "error" });
        }
        break;
      case "isLargerThanNumber":
        if (this.isLargerThanNumber(eventTargetValue, stateNameEqualTo)) {
          if (stateName === "from_amount") {
            this.props.updateNewTransferCreation(
              "amount_from_state",
              "success"
            );
          }
          if (stateName === "to_amount") {
            this.props.updateNewTransferCreation("amount_to_state", "success");
          }
        } else {
          if (stateName === "from_amount") {
            this.props.updateNewTransferCreation("amount_from_state", "error");
          }
          if (stateName === "to_amount") {
            this.props.updateNewTransferCreation("amount_to_state", "error");
          }
        }
        break;
      default:
        break;
    }

    // Calculate "to amount"
    if (stateName === "from_amount") {
      this.props.updateNewTransferCreation("amount_lock", "from_amount");
      this.props.updateNewTransferCreation("amount_from", eventTargetValue);
      this.handleAmount("to_amount");
    }

    // Calculate "from amount"
    if (stateName === "to_amount") {
      this.props.updateNewTransferCreation("amount_lock", "to_amount");
      this.props.updateNewTransferCreation("amount_to", eventTargetValue);
      this.handleAmount("from_amount");
    }

    if (stateName === "purpose_of_payment_other") {
      let beneficiary_array = this.props.transfers_dashboard.new_transfer_data.multiple_beneficiary_id || [{}];
      beneficiary_array[0].purpose_of_payment_other = eventTargetValue;
      if (eventTargetValue !== "") {
        beneficiary_array[0].purpose_of_payment_other_state = "success";
      } else {
        beneficiary_array[0].purpose_of_payment_other_state = "error";
      }
      this.props.updateNewTransferCreationBeneficiary(beneficiary_array);
    }

    if (stateName === "detail_1") {
      let beneficiary_array = this.props.transfers_dashboard.new_transfer_data.multiple_beneficiary_id || [{}];
      beneficiary_array[0].detail_1 = eventTargetValue;
      if (eventTargetValue !== "") {
        beneficiary_array[0].detail_1_state = "success";
      } else {
        beneficiary_array[0].detail_1_state = "";
      }
      this.props.updateNewTransferCreationBeneficiary(beneficiary_array);
    }
  }

  handleAmount(amount_name) {
    let from_currency_Iso = "AUD";
    let to_currency_Iso = "USD";

    this.state.currencies_list_priority.map((item, index) => {
      if (
        item.id ==
        this.props.transfers_dashboard.new_transfer_data.currency_from_id
      ) {
        from_currency_Iso = item.iso_alpha_3;
      }

      if (
        item.id ==
        this.props.transfers_dashboard.new_transfer_data.currency_to_id
      ) {
        to_currency_Iso = item.iso_alpha_3;
      }
    });

    let url = `https://apilayer.net/api/live?access_key=a4eb7fd0501842eb4d4712cc459cae5f`;
    axios
      .get(url, {
        params: {
          currencies: to_currency_Iso,
          source: from_currency_Iso,
          format: "1"
        }
      })
      .then(response => {
        let key = from_currency_Iso + to_currency_Iso;
        let rate_string = response.data.quotes[key];

        let rate = Number.parseFloat(rate_string);
        let rate_adjust =
          Number.parseFloat(this.props.app_state.current_client.default_rate) +
          Number.parseFloat(this.props.app_state.current_client.default_rate_division) +
          Number.parseFloat(this.props.app_state.current_client.default_rate_entity) +
          Number.parseFloat(this.props.app_state.current_client.default_rate_team) +
          Number.parseFloat(this.props.app_state.current_client.default_rate_staff);
        let adjusted_rate = rate - rate * rate_adjust;


        // // Add Credit Card Rate Adjustment
        // if (
        //   this.props.transfers_dashboard.new_transfer_data.payment_method ===
        //   "worldpay"
        // ) {
        //   adjusted_rate = adjusted_rate - adjusted_rate * 0.02;
        // }

        if (
          this.props.transfers_dashboard.new_transfer_data.currency_from_id ==
          this.props.transfers_dashboard.new_transfer_data.currency_to_id
        ) {
          // console.log("same currency")
          // adjusted_rate = rate;
          adjusted_rate = 1;
        }

        if (
          this.props.transfers_dashboard.new_transfer_data.currency_from_state === "success" &&
          this.props.transfers_dashboard.new_transfer_data.currency_to_state === "success"
        ) {
          // handle to_amount
          if (
            amount_name === "to_amount" && 
            this.props.transfers_dashboard.new_transfer_data.amount_from_state === "success"
          ) {
            let amount_from = Number.parseFloat(this.props.transfers_dashboard.new_transfer_data.amount_from);
            let amount_to = amount_from * adjusted_rate;

            this.props.updateNewTransferCreation(
              "amount_to",
              amount_to.toFixed(2)
            );
            if (amount_to >= min_transfer_amount) {
              this.props.updateNewTransferCreation(
                "amount_to_state",
                "success"
              );
            } else {
              this.props.updateNewTransferCreation("amount_to_state", "error");
            }
          }

          // handle from_amount
          if (
            amount_name === "from_amount" &&
            this.props.transfers_dashboard.new_transfer_data.amount_to_state ===
              "success"
          ) {
            let amount_to = Number.parseFloat(
              this.props.transfers_dashboard.new_transfer_data.amount_to
            );
            let amount_from = amount_to / adjusted_rate;

            this.props.updateNewTransferCreation(
              "amount_from",
              amount_from.toFixed(2)
            );
            if (amount_from >= min_transfer_amount) {
              this.props.updateNewTransferCreation(
                "amount_from_state",
                "success"
              );
            } else {
              this.props.updateNewTransferCreation(
                "amount_from_state",
                "error"
              );
            }
          }
        }
        this.props.updateNewTransferCreation("client_rate", adjusted_rate);


        let feeAmount, feeCurrencyIso, transferAmount;
        this.setState({displayFeeWarning: false})

        // Only apply fees if the transfer is not from a ledger
        if (!this.props.transfers_dashboard.new_transfer_data.ledgerFrom) {
        for (var i = 0; i < this.props.app_state.current_team_fees.length; i++) {
          if (this.props.app_state.current_team_fees[i].currency_id === this.props.transfers_dashboard.new_transfer_data.currency_from_id) {
            if (this.props.transfers_dashboard.new_transfer_data.amount_from < this.props.app_state.current_team_fees[i].transferAmount) {
              if (this.props.transfers_dashboard.new_transfer_data.amount_from < transferAmount) {
                // pass
              } else {
                feeAmount = this.props.app_state.current_team_fees[i].feeAmount;
                feeCurrencyIso = this.props.app_state.current_team_fees[i].currencies_short_name;
                transferAmount = this.props.app_state.current_team_fees[i].transferAmount;
                this.props.updateNewTransferCreation("feeCurrencyId", this.props.transfers_dashboard.new_transfer_data.currency_from_id);
                this.props.updateNewTransferCreation("feeCurrencyIso", feeCurrencyIso);
                this.props.updateNewTransferCreation("feeAmount", feeAmount);
                this.props.updateNewTransferCreation("feeDescription", this.props.app_state.current_team_fees[i].feeDescription);
                this.setState({
                  displayFeeWarning: true,
                  fee_feeAmount: feeAmount,
                  fee_feeCurrencyIso: feeCurrencyIso,
                  fee_transferAmount: transferAmount
                })
              }
            }
          }
        }
        }
        // if amount is above the fee amount, set fee to 0
        if (!feeAmount) {
          this.props.updateNewTransferCreation("feeCurrencyId", null);
          this.props.updateNewTransferCreation("feeCurrencyIso", null);
          this.props.updateNewTransferCreation("feeAmount", null);
          this.props.updateNewTransferCreation("feeDescription", null);
          this.setState({
            displayFeeWarning: false,
            fee_feeAmount: null,
            fee_feeCurrencyIso: null,
            fee_transferAmount: transferAmount
          })
        }

            if (this.state.timeoutId != null) {
                clearTimeout(this.state.timeoutId);
            }
            const timeoutId =  setTimeout(() => {
                const from_currency = this.props.transfers_dashboard.new_transfer_data.currency_from_name;
                const to_currency = this.props.transfers_dashboard.new_transfer_data.currency_to_name;
                const from_amount = this.props.transfers_dashboard.new_transfer_data.amount_from;
                const to_amount = this.props.transfers_dashboard.new_transfer_data.amount_to;
                if (from_currency && to_currency && from_amount && to_amount) {
                    this.abortController.abort();
                    const requestBody = {
                        clientId: this.props.app_state.current_client.id,
                        from_currency: from_currency,
                        to_currency: to_currency,
                        rate: parseFloat(this.props.transfers_dashboard.new_transfer_data.client_rate),
                        from_amount: from_amount,
                        to_amount: to_amount
                    }
                    API.post("email", "/client/client-transfer-notification", {body: requestBody}).then(response => {
                        console.log("email sent");
                    }).finally(() => this.setState({isFetching: false}));
                }
                // Send email
            }, 5000);
        this.setState({timeoutId: timeoutId});
        // }
      })
      .catch(error => {
        console.log(error);
      });
  }

  defaultCurrencies = (
    name,
    previous_from_currency,
    previous_to_currency,
    option_value
  ) => {
    this.setState(
      {
        [name]: option_value
      },
      () => {
        if (name === "from_currency" || name === "to_currency") {
          switch (
            this.props.transfers_dashboard.new_transfer_data.amount_lock
          ) {
            case "from_amount":
              this.handleAmount("to_amount");
              break;
            case "to_amount":
              this.handleAmount("from_amount");
              break;
            default:
              this.handleAmount();
          }
        }
      }
    );
    if (name === "from_currency" || name === "to_currency") {
      this.updateDefaultMapData(
        name,
        previous_from_currency,
        previous_to_currency,
        option_value
      );
      if (name === "from_currency") {
        this.props.updateNewTransferCreation("currency_from_id", option_value);
        this.props.updateNewTransferCreation("currency_from_state", "success");
        API.get("currencies", `/currencies/get/${option_value}`)
          .then(response => {
            this.props.updateNewTransferCreation("currency_from", response);
            this.props.updateNewTransferCreation(
              "currency_from_name",
              response.iso_alpha_3
            );
          })
          .catch(error => {
            console.log(error);
          });
      }
      if (name === "to_currency") {
        this.props.updateNewTransferCreation("currency_to_id", option_value);
        this.props.updateNewTransferCreation("currency_to_state", "success");

        //set a resumable_beneficiary_list
        // API.get("beneficiaries", `/beneficiaries/get`)
        API.get("beneficiaries", `/beneficiaries/list/${this.props.app_state.current_client.id}`)
          .then(response => {
            // console.log(response)
            const filteredResponse = response.filter((item) => { return item.active === 1 })
            let resume_beneficiaries_list = filteredResponse;
            if (resume_beneficiaries_list.length > 0) {
              //remove items from beneficiary-list by not related this to_currency items
              for (let i = resume_beneficiaries_list.length - 1; i > -1; i--) {
                if (
                  resume_beneficiaries_list[i].account_currency !== option_value
                ) {
                  //response.remove(i);
                  resume_beneficiaries_list.splice(i, 1); // Removes the (i)th element of response
                }
              }
            }
            this.setState(
              {
                beneficiaries_list: resume_beneficiaries_list,
                account_currency: option_value
              },
              () => {}
            );
          })
          .catch(error => {
            console.log(error);
          });

        API.get("currencies", `/currencies/get/${option_value}`)
          .then(response => {
            this.props.updateNewTransferCreation("currency_to", response);
            this.props.updateNewTransferCreation(
              "currency_to_name",
              response.iso_alpha_3
            );
            const clientRate = parseFloat(
              this.props.transfers_dashboard.new_transfer_data.client_rate
            );
            const from =
              clientRate *
              parseFloat(
                this.props.transfers_dashboard.new_transfer_data.amount_from
              );
            this.props.updateNewTransferCreation("amount_to", from.toFixed(2));
            this.props.updateNewTransferCreation(
              "amount_from_state",
              "success"
            );
            this.props.updateNewTransferCreation("amount_to_state", "success");
          })
          .catch(error => {
            console.log(error);
          });
      }
    }
  };

  handleCustomReactSelectChange = (
    name,
    previous_from_currency,
    previous_to_currency
  ) => value => {
    var option_value;
    var option_label;
    var beneficiary_array = this.props.transfers_dashboard.new_transfer_data
      .multiple_beneficiary_id || [{}];
      this.uploadRef.current.clearList();
    if (value === null) {
      option_value = null;
      option_label = null;
    } else {
      option_value = value.value;
      option_label = value.label;
    }

    this.setState({ fileIds: []});

    if (name === "bank_country") {
      let country_dataset = this.state.country_list;
      for (let i = 0; i < country_dataset.length; i++) {
        if (country_dataset[i].id === option_value) {
          console.table(country_dataset[i]);
          this.setState({
            [name + "_full_name"]: country_dataset[i].full_name,
            [name + "_iso_alpha_2"]: country_dataset[i].iso_alpha_2
          });
          //bsb check
          if (country_dataset[i].use_bsb === 1) {
            this.setState({
              show_bsb: true
            });
          } else {
            this.setState({
              show_bsb: false
            });
          }

          //aba_routing check
          if (country_dataset[i].use_aba === 1) {
            this.setState({
              show_aba: true
            });
          } else {
            this.setState({
              show_aba: false
            });
          }

          //swift check
          if (country_dataset[i].use_swift === 1) {
            this.setState({
              show_swift_code: true
            });
          } else {
            this.setState({
              show_swift_code: false
            });
          }

          //iban check
          if (country_dataset[i].use_iban === 1) {
            this.setState({
              show_iban: true
            });
          } else {
            this.setState({
              show_iban: false
            });
          }

          //sort_code check
          if (country_dataset[i].use_sort_code === 1) {
            this.setState({
              show_sort_code: true
            });
          } else {
            this.setState({
              show_sort_code: false
            });
          }

          //account_number check
          if (country_dataset[i].use_account_number === 1) {
            this.setState({
              show_account_number: true
            });
          } else {
            this.setState({
              show_account_number: false
            });
          }

          // transit_code
          if (country_dataset[i].use_transit_code === 1) {
            this.setState({
              show_transit_code: true
            });
          } else {
            this.setState({
              show_transit_code: false
            });
          }

          // bank_code
          if (country_dataset[i].use_bank_code === 1) {
            this.setState({
              show_bank_code: true
            });
          } else {
            this.setState({
              show_bank_code: false
            });
          }
        }
      }
    }
    this.setState(
      {
        [name]: option_value
      },
      () => {
        if (name === "from_currency") {
          this.props.updateNewTransferCreation("amount_from", 0);
          this.props.updateNewTransferCreation("ledgerFrom", null);
        }
        if (name === "from_currency" || name === "to_currency") {
          switch (
            this.props.transfers_dashboard.new_transfer_data.amount_lock
          ) {
            case "from_amount":
              this.handleAmount("to_amount");
              break;
            case "to_amount":
              this.handleAmount("from_amount");
              break;
            default:
              this.handleAmount();
          }
        }
      }
    );

    if (name === "from_currency" || name === "to_currency") {
      this.updateMapData(
        name,
        previous_from_currency,
        previous_to_currency,
        value
      );
      if (name === "from_currency") {
        this.props.updateNewTransferCreation("currency_from_id", option_value);
        this.props.updateNewTransferCreation("currency_from_state", "success");
        API.get("currencies", `/currencies/get/${option_value}`)
          .then(response => {
            this.props.updateNewTransferCreation("currency_from", response);
            this.props.updateNewTransferCreation(
              "currency_from_name",
              response.iso_alpha_3
            );
          })
          .catch(error => {
            console.log(error);
          });
      }
      if (name === "to_currency") {
        this.props.updateNewTransferCreation("currency_to_id", option_value);
        this.props.updateNewTransferCreation("currency_to_state", "success");
        this.props.updateNewTransferCreationBeneficiary([{ detail_1: '' }]);
        //set a resumable_beneficiary_list
        // API.get("beneficiaries", `/beneficiaries/get`)
        API.get("beneficiaries", `/beneficiaries/list/${this.props.app_state.current_client.id}`)
          .then(response => {
            console.log(response)
            const filteredResponse = response.filter((item) => { return item.active === 1 })
            let resume_beneficiaries_list = filteredResponse;
            if (resume_beneficiaries_list.length > 0) {
              //remove items from beneficiary-list by not related this to_currency items
              for (let i = resume_beneficiaries_list.length - 1; i > -1; i--) {
                if (
                  resume_beneficiaries_list[i].account_currency !== option_value
                ) {
                  //response.remove(i);
                  resume_beneficiaries_list.splice(i, 1); // Removes the (i)th element of response
                }
              }
            }
            this.setState(
              {
                beneficiaries_list: resume_beneficiaries_list,
                account_currency: option_value
              },
              () => {
                // console.log("Finish beneficiaries API")
                // this.queryStrings()
              }
            );
          })
          .catch(error => {
            console.log(error);
          });

        API.get("currencies", `/currencies/get/${option_value}`)
          .then(response => {
            this.props.updateNewTransferCreation("currency_to", response);
            this.props.updateNewTransferCreation(
              "currency_to_name",
              response.iso_alpha_3
            );
          })
          .catch(error => {
            console.log(error);
          });

          API.get("holding_accounts", `/get/client-currency/${this.props.app_state.current_client.id}/${option_value}`)
          .then(response => {
            console.log(response)
            // this.props.updateNewTransferCreation("currency_to", response);
            // this.props.updateNewTransferCreation(
            //   "currency_to_name",
            //   response.iso_alpha_3
            // );
          })
          .catch(error => {
            console.log(error);
          });


      }
    }
    if (name === "beneficiary_id") {
      beneficiary_array[0].beneficiary_id = option_value;
      beneficiary_array[0].beneficiary_id_state = "success";
      let beneficiaries_detail = [];
      API.get("beneficiaries", `/beneficiaries/getb/${option_value}`)
        .then(resp => {
          // console.log(resp)
          beneficiaries_detail = resp;
          beneficiary_array[0].beneficiary = beneficiaries_detail[0];
          this.props.updateNewTransferCreationBeneficiary(beneficiary_array);


        // handle update of 'to currency' when beneficiary is selected
        const beneficiaryAccountCurrency = beneficiaries_detail[0].account_currency
          this.props.updateNewTransferCreation("currency_to_id", beneficiaryAccountCurrency);
          this.props.updateNewTransferCreation("currency_to_state", "success");

          //set a resumable_beneficiary_list
          // API.get("beneficiaries", `/beneficiaries/get`)
          API.get("beneficiaries", `/beneficiaries/list/${this.props.app_state.current_client.id}`)
            .then(response => {
              // console.log(response)
              const filteredResponse = response.filter((item) => { return item.active === 1 })
              let resume_beneficiaries_list = filteredResponse;
              if (resume_beneficiaries_list.length > 0) {
                //remove items from beneficiary-list by not related this to_currency items
                for (let i = resume_beneficiaries_list.length - 1; i > -1; i--) {
                  if (
                    resume_beneficiaries_list[i].account_currency !== beneficiaryAccountCurrency
                  ) {
                    //response.remove(i);
                    resume_beneficiaries_list.splice(i, 1); // Removes the (i)th element of response
                  }
                }
              }
              this.setState(
                {
                  beneficiaries_list: resume_beneficiaries_list,
                  account_currency: beneficiaryAccountCurrency
                },
                () => {
                  // console.log("Finish beneficiaries API")
                  // this.queryStrings()
                }
              );
            })
            .catch(error => {
              console.log(error);
            });
  
          API.get("currencies", `/currencies/get/${beneficiaryAccountCurrency}`)
            .then(response => {
              this.props.updateNewTransferCreation("currency_to", response);
              this.props.updateNewTransferCreation(
                "currency_to_name",
                response.iso_alpha_3
              );
            })
            .catch(error => {
              console.log(error);
            });
  
            API.get("holding_accounts", `/get/client-currency/${this.props.app_state.current_client.id}/${beneficiaryAccountCurrency}`)
            .then(response => {
              console.log(response)
              // this.props.updateNewTransferCreation("currency_to", response);
              // this.props.updateNewTransferCreation(
              //   "currency_to_name",
              //   response.iso_alpha_3
              // );
            })
            .catch(error => {
              console.log(error);
            });




        })
        .catch(error => {
          console.log(error);
        });

  


    }
    if (name === "purpose_of_payment_detail") {
      beneficiary_array[0].purpose_of_payment_detail = option_value;
      beneficiary_array[0].purpose_of_payment_detail_nickname = option_label;
      beneficiary_array[0].purpose_of_payment_detail_state = "success";
      this.props.updateNewTransferCreationBeneficiary(beneficiary_array);
      // let document_upload_show = false;
      // for (let key in this.props.app_state.current_client
      //   .purpose_of_payment_list) {
      //   if (
      //     this.props.app_state.current_client.purpose_of_payment_list[key].id ==
      //     option_value
      //   ) {
      //     if (
      //       this.props.app_state.current_client.purpose_of_payment_list[key]
      //         .document_upload == 1
      //     ) {
      //       document_upload_show = true;
      //     }
      //   }
      // }
      // this.setState({
      //   showDocumentUpload: document_upload_show
      // });
    }
  };

  handleCheckboxToggle = name => event => {
    let beneficiary_array = this.props.transfers_dashboard.new_transfer_data
      .multiple_beneficiary_id || [{}];
    beneficiary_array[0].checked_beneficiary_details = event.target.checked;
    this.props.updateNewTransferCreationBeneficiary(beneficiary_array);
    if (event.target.checked === true) {
      this.clearBeneficiaryDetails();
    }
  };

  updateMapData(
    currency_name,
    previous_from_currency,
    previous_to_currency,
    currency_object
  ) {
    var map_values = this.state.map_data;
    const from_currency_map_value = 0;
    const to_currency_map_value = 1;

    // update the properties for visulization
    if (currency_name === "from_currency") {
      // delele previous currency property in map_values
      if (previous_from_currency !== previous_to_currency) {
        this.deleleCurrency(
          map_values,
          previous_from_currency,
          previous_to_currency
        );
      }
      // add new currency property in map_values
      this.addCurrency(
        map_values,
        currency_object.value,
        from_currency_map_value
      );
    }
    if (currency_name === "to_currency") {
      // delele previous currency property in map_values
      if (previous_to_currency !== previous_from_currency) {
        this.deleleCurrency(
          map_values,
          previous_to_currency,
          previous_from_currency
        );
      }
      // add new currency property in map_values
      this.addCurrency(
        map_values,
        currency_object.value,
        to_currency_map_value
      );
    }
    this.setState({ map_data: map_values });
    this.props.update_map_data(map_values);
  }

  updateDefaultMapData(
    currency_name,
    previous_from_currency,
    previous_to_currency,
    value
  ) {
    var map_values = this.state.map_data;
    const from_currency_map_value = 0;
    const to_currency_map_value = 1;

    // update the properties for visulization
    if (currency_name === "from_currency") {
      // delele previous currency property in map_values
      if (previous_from_currency !== previous_to_currency) {
        this.deleleCurrency(
          map_values,
          previous_from_currency,
          previous_to_currency
        );
      }
      // add new currency property in map_values
      this.addCurrency(map_values, value, from_currency_map_value);
    }
    if (currency_name === "to_currency") {
      // delele previous currency property in map_values
      if (previous_to_currency !== previous_from_currency) {
        this.deleleCurrency(
          map_values,
          previous_to_currency,
          previous_from_currency
        );
      }
      // add new currency property in map_values
      this.addCurrency(map_values, value, to_currency_map_value);
    }
    this.setState({ map_data: map_values });
    this.props.update_map_data(map_values);
  }

  deleleCurrency(map_values, currency_deleted, the_other_currency) {
    switch (currency_deleted) {
      case 1: // AUD
        delete map_values.AU; // Australia
        break;
      case 5: // USD
        delete map_values.US; // United States
        break;
      case 8: // GBP
        // if the other currency is not Euro, delete GB
        if (the_other_currency !== 9) {
          delete map_values.GB; // United Kingdom
        }
        break;
      case 9: // Eur. For Europe: italy, spain, france, portugal, germany
        // if the other currency is not GB, delete GB
        if (the_other_currency !== 8) {
          delete map_values.GB; // United Kingdom
        }
        delete map_values.IT; // Italy
        delete map_values.ES; // Spain
        delete map_values.FR; // France
        delete map_values.PT; // Portugal
        delete map_values.DE; // Germany
        break;
      case 6: // ATS
        delete map_values.AT; // 	Austria
        break;
      case 16:
        delete map_values.JP;
        break;
      case 17:
        delete map_values.NZ;
        break;
      case 18:
        delete map_values.CA;
        break;
      case 19:
        delete map_values.HK;
        break;
      case 20:
        delete map_values.SG;
        break;
      case 21:
        delete map_values.CH;
        break;
      case 22:
        delete map_values.TH;
        break;
      case 23:
        delete map_values.DK;
        break;
      case 24:
        delete map_values.NO;
        break;
      case 25:
        delete map_values.SE;
        break;
      case 30:
        delete map_values.NP;
        break;
      case 31:
        delete map_values.VN;
        break;
      default:
    }
  }

  addCurrency(map_values, currency, currency_map_value) {
    switch (currency) {
      case 1:
        map_values.AU = currency_map_value;
        break;
      case 5:
        map_values.US = currency_map_value;
        break;
      case 8:
        map_values.GB = currency_map_value;
        break;
      case 9:
        map_values.GB = currency_map_value;
        map_values.IT = currency_map_value;
        map_values.ES = currency_map_value;
        map_values.FR = currency_map_value;
        map_values.PT = currency_map_value;
        map_values.DE = currency_map_value;
        break;
      case 6:
        map_values.AT = currency_map_value;
        break;
      case 16:
        map_values.JP = currency_map_value;
        break;
      case 17:
        map_values.NZ = currency_map_value;
        break;
      case 18:
        map_values.CA = currency_map_value;
        break;
      case 19:
        map_values.HK = currency_map_value;
        break;
      case 20:
        map_values.SG = currency_map_value;
        break;
      case 21:
        map_values.CH = currency_map_value;
        break;
      case 22:
        map_values.TH = currency_map_value;
        break;
      case 23:
        map_values.DK = currency_map_value;
        break;
      case 24:
        map_values.NO = currency_map_value;
        break;
      case 25:
        map_values.SE = currency_map_value;
        break;
      case 30:
        map_values.NP = currency_map_value;
        break;
      case 31:
        map_values.VN = currency_map_value;
        break;
      default:
    }
  }

  secondsToTime = secs => {
    let hours = Math.floor(secs / (60 * 60));

    let divisor_for_minutes = secs % (60 * 60);
    let minutes = Math.floor(divisor_for_minutes / 60);

    let divisor_for_seconds = divisor_for_minutes % 60;
    let seconds = Math.ceil(divisor_for_seconds);

    let obj = {
      h: hours,
      m: minutes,
      s: seconds
    };
    return obj;
  };

  startTimer = () => {
    if (this.timer === 0 && this.state.seconds > 0) {
      this.timer = setInterval(this.countDown, 1000);
    }
  };

  countDown = () => {
    let seconds = this.state.seconds - 1;
    this.setState({
      time: this.secondsToTime(seconds),
      seconds: seconds
    });
    this.props.updateNewTransferCreation("current_rate_ttl", seconds);

    if (seconds === 0) {
      this.setState({
        seconds: 30
      });
      this.props.updateNewTransferCreation("current_rate_ttl", 30);
      // this.fetchNewRate();
    }
  };

  onupdate = (file_id, index) => {
    let beneficiary_array = this.props.transfers_dashboard.new_transfer_data
      .multiple_beneficiary_id || [{}];
    const fileIds = this.state.fileIds;
    fileIds.push(file_id);
    this.setState({ fileIds: fileIds}, () => {
      beneficiary_array[0].document = fileIds;
      this.props.updateNewTransferCreationBeneficiary(beneficiary_array);

    })
  };

  onRemove = file => {
    let beneficiary_array = this.props.transfers_dashboard.new_transfer_data
      .multiple_beneficiary_id || [{}];
    let fileIds = this.state.fileIds.filter(i => i === file.uid);
    this.setState({fileIds: fileIds}, () => {
      beneficiary_array[0].document = fileIds;
      this.props.updateNewTransferCreationBeneficiary(beneficiary_array);
    })
  };

  render_beneficiary_details_section = () => {
    const { classes } = this.props;
    // if (
    //   this.props.transfers_dashboard.new_transfer_data.currency_from_state !==
    //     "success" ||
    //   this.props.transfers_dashboard.new_transfer_data.currency_to_state !==
    //     "success"
    // )
    //   return null;

    // For CustomReactSelect. Generate select options for dropdown list.
    // console.log(this.state.beneficiaries_list)
    const beneficiary_list_select_options = this.state.beneficiaries_list
      .sort((a, b) => (a.nickname || '').localeCompare(b.nickname))
      .map(item => ({
        value: item.id,
        // label: `${item.nickname} ${item.client_id ? '' : ' [Team Beneficiary]'} ${item.holdingAccountId ? '[*]' : ''}`
        label: <div style={{lineHeight: 1, backgroundColor: "#FFF", width: "100%"}}>
          {/* <span>{item.currency_iso_alpha_3}</span>{` `} */}
          <span style={{fontSize: "20px"}}><strong>{item.nickname}</strong>&nbsp;&nbsp;</span>
          {item.currency_iso_alpha_3 && 
          <span class={`currency-flag currency-flag-${item.currency_iso_alpha_3.toString().toLowerCase()}`}> {`    `}</span>
          }{` `}
          {item.client_id ? '' : ' **'} 
          {item.holdingAccountId ? '[*]' : ''}
          {/* <br/>
          {item.bsb_code ? <small><strong>BSB: </strong>{item.bsb_code}&nbsp;&nbsp;</small> : ''}
          {item.swift_code ? <small><strong>SWIFT: </strong>{item.swift_code}<br/></small> : ''}
          {item.account_number ? <small><strong>Account Number: </strong>{item.account_number}  </small> : ''}
          {item.iban ? <small><strong>IBAN: </strong>{item.iban} </small> : ''}
          <hr/> */}
        </div>
      }));

    const purpose_of_payment_select_options = this.props.app_state.current_client.purpose_of_payment_list.map(
      item => ({
        value: item.id,
        label: item.description
      })
    );

    var is_image = false;
    if (
      this.props.transfers_dashboard.new_transfer_data
        .multiple_beneficiary_id[0].document
    ) {
      is_image = true;
    }

    let payment_detail_label =
      this.props.transfers_dashboard &&
      this.props.transfers_dashboard.new_transfer_data &&
      this.props.transfers_dashboard.new_transfer_data
        .multiple_beneficiary_id &&
      this.props.transfers_dashboard.new_transfer_data
        .multiple_beneficiary_id[0].beneficiary_id === 402
        ? "Ninja Trader Account Number"
        : "Payment Detail / Invoice #";
    let show_invoice;
    if (
      this.props.transfers_dashboard &&
      this.props.transfers_dashboard.new_transfer_data &&
      this.props.transfers_dashboard.new_transfer_data
        .multiple_beneficiary_id &&
      this.props.transfers_dashboard.new_transfer_data
        .multiple_beneficiary_id[0].beneficiary_id === 289
    ) {
      show_invoice = (
        <div style={{ textAlign: "center", paddingTop: 25 }}>
          <img style={{ width: 300 }} src={sample_invoice} />
        </div>
      );
    }

    // Create Ledger Balance Chips
    let toCurrencyLedgerChip;
    // console.log(this.state.send_to_holding_acc_checkbox)
    // console.log(this.props.transfers_dashboard.new_transfer_data.send_to_holding_acc_checkbox)
    toCurrencyLedgerChip = (
      <>
        <AntCheckbox
          name="ledgerTo" 
          checked={this.props.transfers_dashboard.new_transfer_data.send_to_holding_acc_checkbox} 
          disabled={!this.props.transfers_dashboard.new_transfer_data.currency_to_id} 
          onChange={this.onClickToLedger}>
          {this.props.transfers_dashboard.new_transfer_data.send_to_holding_acc_checkbox ? (
            <span>
              You are sending this transfer to your{" "}
              {this.props.transfers_dashboard.new_transfer_data.currency_to ? this.props.transfers_dashboard.new_transfer_data.currency_to.short_name : ""} Currency Balance
            </span>
          ) : (
            <span>
              Tick this box to transfer to your {this.props.transfers_dashboard.new_transfer_data.currency_to ? this.props.transfers_dashboard.new_transfer_data.currency_to.short_name : ""}{" "}
              Currency Balance.
            </span>
          )}
          {!this.props.transfers_dashboard.new_transfer_data.currency_to_id && (
            <em><br/>(Please select a currency first)</em>
          )}
        </AntCheckbox>
      </>
    );

    // for (var idx in this.state.holdingAccountBalances) {
    //   let record = this.state.holdingAccountBalances[idx];
    //   if (
    //     record.currency_id ===
    //     this.props.transfers_dashboard.new_transfer_data.currency_to_id
    //   ) {
    //     toCurrencyLedgerChip = (
    //       <div>
    //         <Checkbox
    //           tabIndex={-2}
    //           disabled={false}
    //           onClick={e =>
    //             this.updateLedgerAmount("ledgerTo", e.target.checked)
    //           }
    //           checked={
    //             this.state.send_to_holding_acc_checkbox ||
    //             this.props.transfers_dashboard.new_transfer_data
    //               .send_to_holding_acc_checkbox
    //           }
    //           checkedIcon={<Check className={classes.checkedIcon} />}
    //           icon={<Check className={classes.uncheckedIcon} />}
    //           classes={{
    //             checked: classes.checked,
    //             root: classes.checkRoot
    //           }}
    //         />
    //         {this.props.transfers_dashboard.new_transfer_data
    //           .send_to_holding_acc_checkbox ? (
    //           <span>
    //             You are sending this transfer to your{" "}
    //             {record.currencies_short_name} holding account
    //           </span>
    //         ) : (
    //           <span>
    //             OR tick box to transfer to your {record.currencies_short_name}{" "}
    //             Holding Account.
    //           </span>
    //         )}
    //         {/* Current balance&nbsp;
    //             {new Intl.NumberFormat("en-GB", {
    //               style: "currency",
    //               currency: record.currencies_short_name
    //             }).format(record.balance)} */}
    //       </div>
    //     );
    //   }
    // }

    // Test for beneficiary being selected
    let beneficiarySelect =<span>Click here to select beneficiary</span>;
    if (this.props.transfers_dashboard.new_transfer_data.multiple_beneficiary_id[0].beneficiary_id_state == 'error') {
      beneficiarySelect = <span style={{color: 'red'}}>Please click here to select beneficiary</span>
    }

    return (
      <React.Fragment>
        {!this.props.transfers_dashboard.new_transfer_data.send_to_holding_acc_checkbox && (
        <GridItem xs={12} sm={6}>
          <p className={classes.transferRate}>
            {this.props.transfers_dashboard.new_transfer_data.currency_to && 
            <span style={{
                  width: "35px",
                  height: "20px"
                  }}
              className={`currency-flag currency-flag-${this.props.transfers_dashboard.new_transfer_data.currency_to ? this.props.transfers_dashboard.new_transfer_data.currency_to.iso_alpha_3.toLowerCase() : ""}`}
            />
            }
            {` `}
            {this.props.transfers_dashboard.new_transfer_data.currency_to ? 
              this.props.transfers_dashboard.new_transfer_data.currency_to.short_name : "All"}
            {` `}
            Beneficiaries
          </p>
          {/* <p className={classes.benficiaryTitle}>Beneficiary</p> */}
          {/* <Divider className={classes.beneficiaryDivider} /> */}
        </GridItem>
        )}
        {!this.props.transfers_dashboard.new_transfer_data.send_to_holding_acc_checkbox && (
        <GridItem xs={12} sm={6}>
          {this.props.transfers_dashboard.new_transfer_data.currency_to_id && (
            <p onClick={() => {
                  this.initialiseState();
              this.props.clearNewTransfer()
              }} 
              className={classes.createBeneficary}>
              CLICK TO SHOW ALL BENEFICIARIES
            </p>
          )}
        </GridItem>
        )}
        <GridItem xs={12} sm={12}>
          {this.props.transfers_dashboard.new_transfer_data
            .multiple_beneficiary_id[0].checked_beneficiary_details !== true &&
            !this.props.transfers_dashboard.new_transfer_data
              .send_to_holding_acc_checkbox && (
              <CustomReactSelect
                isDisabled={
                  this.props.transfers_dashboard.new_transfer_data
                    .send_to_holding_acc_checkbox
                }
                error={
                  this.props.transfers_dashboard.new_transfer_data
                    .multiple_beneficiary_id[0].beneficiary_id_state === "error"
                }
                disableUnderlined={true}
                label={beneficiarySelect}
                rootStyle={{
                  background: "#FFFFFF",
                  backgroundColor: "#FFF",
                  border: "1px solid rgba(0, 0, 0, 0.12)",
                  padding: "1%",
                  paddingLeft: "2%",
                  // paddingBottom: "64px",
                }}
                defaultInputValue="Click here to select beneficiary"
                inputStyle={{
                  color: "#131313",
                  backgroundColor: "#FFF",
                  lineHeight: "24px",
                  fontSize: "16px",
                  fontFamily: "Roboto",
                  fontWeight: "400",
                  // marginLeft: 16,
                  // marginTop: 36,
                }}
                containerStyle={{
                  overflow: "scroll"
                }}
                options={beneficiary_list_select_options}
                value={
                  this.props.transfers_dashboard.new_transfer_data
                    .multiple_beneficiary_id[0].beneficiary_id
                }
                onChange={this.handleCustomReactSelectChange("beneficiary_id")}
                isClearable={false}
              />
            )}
          <GridItem xs={12} sm={12}>
            <br/>
            {this.props.app_state.current_team.brand_ledger &&
              toCurrencyLedgerChip}
          </GridItem>
        
          <GridContainer>
            <GridItem xs={12} sm={12}>
            {!this.state.prefill_beneficiary
                ? this.createNewBeneficiaryUi()
                : null}
              <br />
              {!this.state.prefill_beneficiary ? this.createUI() : null}
              <br />
            </GridItem>
            </GridContainer>

          <GridContainer>
              {this.props.transfers_dashboard.new_transfer_data.multiple_beneficiary_id.length > 0 &&
               this.props.transfers_dashboard.new_transfer_data.multiple_beneficiary_id[0].beneficiary && (
            <GridItem xs={16} sm={7}>
              {/* {show_invoice} */}
                <>
                <div style={{padding: 8, border: "1px solid rgba(0, 0, 0, 0.12)"}}>


                <GridContainer style={{marginTop: 8}}>
        <GridItem xs={12}>
          <p className={classes.createBeneficary}>YOU ARE PAYING</p>
        </GridItem>
        <GridItem>
          <Table
            style={{color: '333'}}
            size="small"
            tableData={[
              [
                <span style={{fontWeight: 800, color: '333'}}>
                  BENEFICIARY
                </span>,
                <span style={{fontWeight: 600, color: '333'}}>
                  {this.props.transfers_dashboard.new_transfer_data.multiple_beneficiary_id[0].beneficiary.nickname}
                </span>
              ],
              [
                <span style={{fontWeight: 800, color: '333'}}>
                  ADDRESS
                </span>,
                <span style={{fontWeight: 400, color: '333'}}>
                  {this.props.transfers_dashboard.new_transfer_data.multiple_beneficiary_id[0].beneficiary.ben_address_line_1}{` `} 
                  {this.props.transfers_dashboard.new_transfer_data.multiple_beneficiary_id[0].beneficiary.ben_adddress_line_2}{` `} 
                  {this.props.transfers_dashboard.new_transfer_data.multiple_beneficiary_id[0].beneficiary.ben_address_suburb}{` `} 
                  {this.props.transfers_dashboard.new_transfer_data.multiple_beneficiary_id[0].beneficiary.ben_address_state}{` `} 
                  {this.props.transfers_dashboard.new_transfer_data.multiple_beneficiary_id[0].beneficiary.ben_address_postcode}{` `} 
                  {this.props.transfers_dashboard.new_transfer_data.multiple_beneficiary_id[0].beneficiary.ben_country}{` `}
                </span>
              ],
              [
                <span style={{fontWeight: 800, color: '333'}}>
                  BANK
                </span>,
                <span style={{fontWeight: 400, color: '333'}}>
                  {this.props.transfers_dashboard.new_transfer_data.multiple_beneficiary_id[0].beneficiary.bank_legal_name}
                </span>
              ],
              this.props.transfers_dashboard.new_transfer_data.multiple_beneficiary_id[0].beneficiary.swift_code ? 
              [
                <span style={{fontWeight: 800, color: '333'}}>
                  SWIFT
                </span>,
                <span style={{fontWeight: 400, color: '333'}}>
                  {this.props.transfers_dashboard.new_transfer_data.multiple_beneficiary_id[0].beneficiary.swift_code}
                </span>
              ] : [],
              this.props.transfers_dashboard.new_transfer_data.multiple_beneficiary_id[0].beneficiary.bsb_code ? 
              [
                <span style={{fontWeight: 800, color: '333'}}>
                  BSB
                </span>,
                <span style={{fontWeight: 400, color: '333'}}>
                  {this.props.transfers_dashboard.new_transfer_data.multiple_beneficiary_id[0].beneficiary.bsb_code}
                </span>
              ] : [],
              this.props.transfers_dashboard.new_transfer_data.multiple_beneficiary_id[0].beneficiary.sort_code ? 
              [
                <span style={{fontWeight: 800, color: '333'}}>
                  SORT CODE
                </span>,
                <span style={{fontWeight: 400, color: '333'}}>
                  {this.props.transfers_dashboard.new_transfer_data.multiple_beneficiary_id[0].beneficiary.sort_code}
                </span>
              ] : [],
              this.props.transfers_dashboard.new_transfer_data.multiple_beneficiary_id[0].beneficiary.account_number ? 
              [
                <span style={{fontWeight: 800, color: '333'}}>
                  ACCOUNT
                </span>,
                <span style={{fontWeight: 400, color: '333'}}>
                  {this.props.transfers_dashboard.new_transfer_data.multiple_beneficiary_id[0].beneficiary.account_number}
                </span>
              ] : [],
              this.props.transfers_dashboard.new_transfer_data.multiple_beneficiary_id[0].beneficiary.iban ? 
              [
                <span style={{fontWeight: 800, color: '333'}}>
                  IBAN
                </span>,
                <span style={{fontWeight: 400, color: '333'}}>
                  {this.props.transfers_dashboard.new_transfer_data.multiple_beneficiary_id[0].beneficiary.iban}
                </span>
              ] : [],
            ]}
          />
        </GridItem>
        </GridContainer>


                  {/* <p className={classes.createBeneficary}>
                    YOU ARE PAYING  
                  </p> */}
                  {/* <strong>{this.props.transfers_dashboard.new_transfer_data.multiple_beneficiary_id[0].beneficiary.nickname}</strong>
                  <br/>
                  {this.props.transfers_dashboard.new_transfer_data.multiple_beneficiary_id[0].beneficiary.ben_address_line_1}{` `} 
                  {this.props.transfers_dashboard.new_transfer_data.multiple_beneficiary_id[0].beneficiary.ben_adddress_line_2}{` `} 
                  {this.props.transfers_dashboard.new_transfer_data.multiple_beneficiary_id[0].beneficiary.ben_address_suburb}{` `} 
                  {this.props.transfers_dashboard.new_transfer_data.multiple_beneficiary_id[0].beneficiary.ben_address_state}{` `} 
                  {this.props.transfers_dashboard.new_transfer_data.multiple_beneficiary_id[0].beneficiary.ben_address_postcode}{` `} 
                  {this.props.transfers_dashboard.new_transfer_data.multiple_beneficiary_id[0].beneficiary.ben_country}{` `}
                  <br/>
                  <br/>
                  <strong>BANK: </strong>
                    {this.props.transfers_dashboard.new_transfer_data.multiple_beneficiary_id[0].beneficiary.bank_legal_name}
                    <br/> */}
                  {/* {this.props.transfers_dashboard.new_transfer_data.multiple_beneficiary_id[0].beneficiary.bank_adddress_line_1}{` `}  */}
                  {/* {this.props.transfers_dashboard.new_transfer_data.multiple_beneficiary_id[0].beneficiary.bank_adddress_line_2}{` `}  */}
                  {/* {this.props.transfers_dashboard.new_transfer_data.multiple_beneficiary_id[0].beneficiary.bank_address_suburb}{` `}  */}
                  {/* {this.props.transfers_dashboard.new_transfer_data.multiple_beneficiary_id[0].beneficiary.bank_address_state}{` `}  */}
                  {/* {this.props.transfers_dashboard.new_transfer_data.multiple_beneficiary_id[0].beneficiary.bank_address_postcode}{` `}  */}
                  {/* <br/> */}
                  {/* {this.props.transfers_dashboard.new_transfer_data.multiple_beneficiary_id[0].beneficiary.swift_code && (<>
                    <strong>SWIFT: </strong>
                    {this.props.transfers_dashboard.new_transfer_data.multiple_beneficiary_id[0].beneficiary.swift_code}
                    <br/>
                  </>)} */}
                  {/* {this.props.transfers_dashboard.new_transfer_data.multiple_beneficiary_id[0].beneficiary.bsb_code && (<>
                    <strong>BSB: </strong>
                    {this.props.transfers_dashboard.new_transfer_data.multiple_beneficiary_id[0].beneficiary.bsb_code}
                    <br/>
                  </>)} */}
                  {/* {this.props.transfers_dashboard.new_transfer_data.multiple_beneficiary_id[0].beneficiary.sort_code && (<>
                    <strong>Sort Code: </strong>
                    {this.props.transfers_dashboard.new_transfer_data.multiple_beneficiary_id[0].beneficiary.sort_code}
                    <br/>
                  </>)} */}
                  {/* {this.props.transfers_dashboard.new_transfer_data.multiple_beneficiary_id[0].beneficiary.account_number && (<>
                    <strong>Account: </strong>
                    {this.props.transfers_dashboard.new_transfer_data.multiple_beneficiary_id[0].beneficiary.account_number}
                    <br/>
                  </>)} */}
                  {/* {this.props.transfers_dashboard.new_transfer_data.multiple_beneficiary_id[0].beneficiary.iban && (<>
                    <strong>IBAN: </strong>
                    {this.props.transfers_dashboard.new_transfer_data.multiple_beneficiary_id[0].beneficiary.iban}
                    <br/>
                  </>)} */}
                </div>
                </>
            </GridItem>
              )}

            <GridItem xs={8} sm={5}>
              <CustomReactSelect
                error={
                  this.props.transfers_dashboard.new_transfer_data
                    .multiple_beneficiary_id[0]
                    .purpose_of_payment_detail_state === "error"
                }
                label="Purpose of payment"
                options={purpose_of_payment_select_options}
                value={
                  this.props.transfers_dashboard.new_transfer_data
                    .multiple_beneficiary_id[0].purpose_of_payment_detail
                }
                onChange={this.handleCustomReactSelectChange(
                  "purpose_of_payment_detail"
                )}
                isClearable={false}
                isDisabled={this.state.prefill_purpose_of_payment}
              />
              {this.props.transfers_dashboard.new_transfer_data
                .multiple_beneficiary_id[0].purpose_of_payment_detail === 1 && (
                <CustomInput
                  style={{ paddingTop: 25 }}
                  success={
                    this.props.transfers_dashboard.new_transfer_data
                      .multiple_beneficiary_id[0]
                      .purpose_of_payment_other_state === "success"
                  }
                  error={
                    this.props.transfers_dashboard.new_transfer_data
                      .multiple_beneficiary_id[0]
                      .purpose_of_payment_other_state === "error"
                  }
                  labelText={<span>Specify purpose of payment</span>}
                  id="purpose_of_payment_other"
                  name="purpose_of_payment_other"
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    value: this.props.transfers_dashboard.new_transfer_data
                      .multiple_beneficiary_id[0].purpose_of_payment_other,
                    onChange: event =>
                      this.change(event, "purpose_of_payment_other")
                  }}
                />
              )}
              <CustomInput
                success={
                  this.props.transfers_dashboard.new_transfer_data
                    .multiple_beneficiary_id[0].detail_1_state === "success"
                }
                error={
                  this.props.transfers_dashboard.new_transfer_data
                    .multiple_beneficiary_id[0].detail_1_state === "error"
                }
                labelText={<span>{payment_detail_label}</span>}
                id="detail_1"
                name="detail_1"
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  value: this.props.transfers_dashboard.new_transfer_data
                    .multiple_beneficiary_id[0].detail_1,
                  onChange: event => this.change(event, "detail_1"),
                  disabled: this.state.prefill_payment_detail
                }}
              />
            </GridItem>

          </GridContainer>
          {/* <hr/> */}
          <GridContainer>
            <GridItem xs={12} sm={12}>
                {/* {this.renderDocumentUploadUi()} */}
                {/* {this.state.showDocumentUpload && ( */}
              <div style={{paddingTop: 25}}>
                {this.props.app_state.current_team.feture_allowThirdPartyPayment === 1 ?
                  <span style={{ marginBottom: 10 }}>
                    <Checkbox onChange={(e) => this.setState({ thirdParty: e.target.checked })}></Checkbox> Payment will be made by third party
                    {
                      this.state.thirdParty ? <>
                        <Form.Item labelAlign={'left'} labelCol={{ span: 5 }} name={'payor_name'} label={'Payor'} >
                          <Input style={{ width: 200 }} onChange={(e) => {
                            this.props.updateNewTransferCreation('payor_name', e.target.value);
                          }} />
                        </Form.Item>
                        <Form.Item labelAlign={'left'} labelCol={{ span: 5 }} name={'payor_relationship'} label={'Payor Relationship'}>
                          <Input style={{ width: 200 }} onChange={(e) => {
                            this.props.updateNewTransferCreation('payor_relationship', e.target.value);
                          }} />
                        </Form.Item>
                      </> : <></>
                    }
                  </span> : <></>}

                {/* <React.Fragment> */}
                {/*  <DocumentUpload*/}
                {/*    circle*/}
                {/*    description="Invoice"*/}
                {/*    change={false}*/}
                {/*    noPlaceholder={true}*/}
                {/*    // placeholder={document_image}*/}
                {/*    updateFunction={this.onupdate}*/}
                {/*    onRemove={this.onRemove}*/}
                {/*    index={0}*/}
                {/*  />*/}
                <div className={'pdf-preview-modal'} style={{ marginTop: 10 }}>
                  {/*<FileUploader uploaderType={'PDF'}*/}
                  {/*              onDelete={(file) => {*/}
                  {/*                this.onRemove(file);*/}
                  {/*                return Promise.resolve();*/}
                  {/*              }}*/}
                  {/*              thumbType={'picture-card'}*/}
                  {/*              ref={this.uploadRef}*/}
                  {/*              onUploadSuccess={file => {*/}
                  {/*                this.onupdate(file.name);*/}
                  {/*              }}></FileUploader>*/}
                  <FileUploader uploaderType={'DRAGDROP'}
                                onDelete={(file) => {
                                  this.onRemove(file);
                                  return Promise.resolve();
                                }}
                                thumbType={'picture-card'}
                                ref={this.uploadRef}
                                onUploadSuccess={file => {
                                  this.onupdate(file.name);
                                }}></FileUploader>
                </div>
                {/*<p>*/}
                {/*  Please upload an invoice or supporting document.*/}
                {/*</p>*/}
                {/* </React.Fragment> */}
              </div>
              {/* )} */}
            </GridItem>
          </GridContainer>
        </GridItem>
      </React.Fragment>
    );
  };

  handleSelectChange = event => {
    this.setState({[event.target.name]: event.target.value});
    this.props.updateNewTransferCreation(event.target.name, event.target.value);
    if (event.target.name === "beneficiary_id") {
      this.getBeneficiaryDetails(event.target.value);
    }
  };

  set_payment_radio = value => {
    this.setState({ payment_method: value });
    this.props.updateNewTransferCreation("payment_method", value);
    this.handleAmount("from_amount");
  };

  isValidateBeneficiary() {
    let flag = false;
    if (
      this.state.bank_legal_name == "" ||
      typeof this.state.bank_legal_name === "undefined"
    ) {
      this.setState({ bank_legal_name_state: "error" });
      flag = false
    } else {
      this.setState({ bank_legal_name_state: "success" });
      flag = true
    }

    if (
      this.state.ben_legal_name == "" ||
      typeof this.state.ben_legal_name === "undefined"
    ) {
      this.setState({ ben_legal_name_state: "error" });
      flag = false;
    } else {
      this.setState({ ben_legal_name_state: "success" });
      flag = true;
    }

    if (
      this.state.account_number == "" ||
      typeof this.state.account_number === "undefined"
    ) {
      this.setState({ account_number_state: "error" });
      flag = false;
    } else {
      this.setState({ account_number_state: "success" });
      flag = true;
    }
    if (
      this.state.bank_country == "" ||
      typeof this.state.bank_country === "undefined"
    ) {
      this.setState({ bank_country_state: "error" });
      flag = false;
    } else {
      this.setState({ bank_country_state: "success" });
      flag = true;
    }

    if (
      this.state.ben_legal_name == "" ||
      typeof this.state.ben_legal_name === "undefined"
    ) {
      this.setState({ ben_legal_name_state: "error" });
      flag = false;
    } else {
      this.setState({ ben_legal_name_state: "success" });
      flag = true;
    }

    if (
      this.state.account_currency == "" ||
      typeof this.state.account_currency === "undefined"
    ) {
      this.setState({ account_currency_state: "error" });
      flag = false;
    } else {
      this.setState({ account_currency_state: "success" });
      flag = true;
    }

    if (
      this.state.ben_legal_name == "" ||
      typeof this.state.ben_legal_name === "undefined" ||
      this.state.account_currency == "" ||
      typeof this.state.account_currency === "undefined" ||
      this.state.bank_country == "" ||
      typeof this.state.bank_country === "undefined"
    ) {
      flag = false;
    } else {
      flag = true;
    }

    return flag
  }

  createBeneficiaryIntoDatabase = async data => {
    const createNewBeneficiaryResult = await this.props.createNewBeneficiary(data);
    // console.log(createNewBeneficiaryResult);

    await API.get("beneficiaries", `/beneficiaries/list/${this.props.app_state.current_client.id}`)
      .then(response => {
        // console.log(response)
        const filteredResponse = response.filter((item) => { return item.active === 1 })

        for (let i = filteredResponse.length - 1; i > -1; i--) {
          if (filteredResponse[i].account_currency !== this.state.to_currency) {
            filteredResponse.splice(i, 1); // Removes the (i)th element of response
          }
        }
        this.setState({
          beneficiaries_list: filteredResponse
        });
      })
      .catch(error => {
        console.log(error);
      });

    this.setState({
      ben_legal_name: "",
      ben_email_main: "",
      bank_country: "",
      ben_address_line_1: "",
      ben_address_line_2: "",
      ben_address_suburb: "",
      ben_address_state: "",
      ben_address_postcode: "",
      ben_address_country: "",

      bank_legal_name: "",
      iban: "",
      account_currency: "",
      bsb_code: "",
      swift_code: "",
      account_number: "",
      aba_routing_number: "",
      sort_code: "",
      bank_code: "",
      transit_code: ""
    });

    return;
  };

  handleAddNewBeneficiary = async () => {
    if (this.isValidateBeneficiary()) {
      const {
        bank_country,
        ben_legal_name,
        ben_email_main,
        ben_address_line_1,
        ben_address_line_2,
        ben_address_suburb,
        ben_address_state,
        ben_address_postcode,
        ben_address_country,
        bank_legal_name,
        iban,
        account_currency,
        bsb_code,
        swift_code,
        account_number,
        aba_routing_number,
        sort_code,
        bank_code,
        transit_code
      } = this.state;

      try {

        const payload = {
          client_id: this.props.app_state.current_client.id,
          nickname: ben_legal_name,
          ben_legal_name: ben_legal_name,
          ben_email_main: ben_email_main,
          ben_address_line_1: ben_address_line_1,
          ben_address_line_2: ben_address_line_2,
          ben_address_suburb: ben_address_suburb,
          ben_address_state: ben_address_state,
          ben_address_postcode: ben_address_postcode,
          ben_address_country: ben_address_country == "" ? undefined: ben_address_country,
          bank_legal_name: bank_legal_name,
          bank_address_country: bank_country,
          iban: iban,
          account_currency: account_currency,
          bsb_code: bsb_code,
          swift_code: swift_code,
          account_number: account_number,
          aba_routing_number: aba_routing_number,
          sort_code: sort_code,
          bank_code: bank_code,
          transit_code: transit_code
        }
        await this.createBeneficiaryIntoDatabase(payload);

        const [beneficiary] = await API.post("commons", "/fetch", {
          body: {
            context: 'beneficiaries',
            fields: ['*'],
            condition: { nickname: payload.nickname, client_id: this.props.app_state.current_client.id }
          }
        });

        if (beneficiary != null) {
          await Log.info({
            id: this.props.app_state.current_client.id,
            data: payload,
            logType: 'BENEFICIARY',
            logAction: 'CREATE',
            beneficiaryID: beneficiary.id,
            logDescription: 'Client created a beneficiary on client portal'
          });
        }

        this.setState(
          {
            addBeneficiaryModalOpen: false
          },
          () => {
            // API.get("beneficiaries", `/beneficiaries/get`)
            API.get("beneficiaries", `/beneficiaries/list/${this.props.app_state.current_client.id}`)
              .then(response => {
                // let resume_beneficiaries_list = response;
                if (response.length > 0) {
                  for (let i = response.length - 1; i > -1; i--) {
                    if (response[i].account_currency !== account_currency) {
                      response.splice(i, 1); // Removes the (i)th element of response
                    }
                  }
                }
                this.setState({
                  beneficiaries_list: response
                });
              })
              .catch(error => {
                console.log(error);
              });
          }
        );
      } catch (e) {
        this.setState({ isLoading: false });
      }
    }
  };

  addBeneficiaryModelOpen = () => {
    this.setState({
      addBeneficiaryModalOpen: true
    });
  };

  addBeneficiaryModelClose = () => {
    this.setState({
      addBeneficiaryModalOpen: false
    });
  };

  newBeneficiaryChange(event, stateName, type, stateNameEqualTo) {
    switch (type) {
      case "email":
        if (this.verifyEmail(event.target.value)) {
          this.setState({ [stateName + "_state"]: "success" });
        } else {
          this.setState({ [stateName + "_state"]: "error" });
        }
        break;
      case "length":
        if (this.verifyLength(event.target.value, stateNameEqualTo)) {
          this.setState({ [stateName + "_state"]: "success" });
        } else {
          this.setState({ [stateName + "_state"]: "error" });
        }
        break;
      case "password":
        if (this.verifyLength(event.target.value, 1)) {
          this.setState({ [stateName + "_state"]: "success" });
        } else {
          this.setState({ [stateName + " _state"]: "error" });
        }
        break;
      case "equalTo":
        if (this.compare(event.target.value, this.state[stateNameEqualTo])) {
          this.setState({ [stateName + "_state"]: "success" });
        } else {
          this.setState({ [stateName + "_state"]: "error" });
        }
        break;
      default:
        break;
    }
    this.setState({ [stateName]: event.target.value });
  }

  createUI() {
    const { classes } = this.props;

    if (this.props.transfers_dashboard.new_transfer_data.send_to_holding_acc_checkbox) {
      return null;
    }
    if (this.props.transfers_dashboard.new_transfer_data.multiple_beneficiary_id.length > 0 &&
      this.props.transfers_dashboard.new_transfer_data.multiple_beneficiary_id[0].beneficiary) {
        return null;
    }

    return (
      <GridContainer>
        <GridItem
          xs={1}
          sm={1}
          style={{ textAlign: "right", cursor: "pointer" }}
          onClick={() => this.addBeneficiaryModelOpen()}
        >
          <img src={addIcon} alt="" />
        </GridItem>
        <GridItem
          xs={5}
          sm={5}
          className={classes.columnRight}
          onClick={() => {
            if (
              !this.props.transfers_dashboard.new_transfer_data
                .send_to_holding_acc_checkbox
            ) {
              this.addBeneficiaryModelOpen();
            }
          }}
        >
          <span className={classes.createBeneficary}>
            CREATE NEW BENEFICIARY
          </span>
        </GridItem>
        
      </GridContainer>
    );
  }

  renderDocumentUploadUi = () => {
    const { classes } = this.props;
    // if (!this.state.showDocumentUpload) return null
    return (
      <GridContainer>
        <GridItem
          xs={1}
          sm={1}
          style={{ textAlign: "right", cursor: "pointer" }}
          onClick={() => {
            const currentState = this.state.showDocumentUpload;
            this.setState({
              showDocumentUpload: !currentState
            });
          }}
        >
          <img src={addIcon} alt="" />
        </GridItem>
        <GridItem
          xs={5}
          sm={5}
          className={classes.columnRight}
          onClick={() => {
            const currentState = this.state.showDocumentUpload;
            this.setState({
              showDocumentUpload: !currentState
            });
          }}
        >
          <span className={classes.createBeneficary}>UPLOAD DOCUMENT</span>
        </GridItem>
      </GridContainer>
    )
  }

  getInstructions = () => {
    return (
      <React.Fragment>
        <GridContainer>
          {this.state.bank_country_full_name && (
            <GridItem xs={12} sm={12}>
              <p>
                When transfering to &nbsp;
                <img
                  src={
                    this.state.bank_country_iso_alpha_2 !== ""
                      ? require(`../../assets/img/flags/${
                          this.state.bank_country_iso_alpha_2
                        }.png`)
                      : ""
                  }
                />{" "}
                &nbsp;
                <strong>{this.state.bank_country_full_name}</strong>, we require
              </p>
              <ul>
                <li>Bank name</li>
                {this.state.show_iban && <li>IBAN</li>}
                {this.state.show_swift_code && <li>SWIFT Code</li>}
                {this.state.show_aba && <li>ABA Routing Number</li>}
                {this.state.show_sort_code && <li>Sort Code</li>}
                {this.state.show_bsb && <li>BSB</li>}
                {this.state.show_transit_code && (
                  <li>Transit &amp; Institution No</li>
                )}
                {this.state.show_bank_code && <li>Bank Code</li>}
                {this.state.show_account_number && <li>Account Number</li>}
              </ul>
            </GridItem>
          )}
        </GridContainer>
      </React.Fragment>
    );
  };

  renderFeeWarning = () => {
    if (this.state.displayFeeWarning) {
      return (
        <Alert message={"Please Note"}
        description={
          <>
          Transfers under {this.state.fee_feeCurrencyIso} {this.state.fee_transferAmount} will incur a fee of {this.state.fee_feeCurrencyIso} {this.state.fee_feeAmount} 
          </>
        } type="warning" showIcon closable />
    )
  }
}

  createNewBeneficiaryUi = () => {
    const { classes } = this.props;
    const sortedCountry = this.state.country_list.sort((a,b) => a.full_name.localeCompare(b.full_name));
    const country_list_select_options = sortedCountry.map(item => ({
      value: item.id,
      alt: item.full_name,
      label: (
        <React.Fragment>
          <Row>
            <Col>
              <span
                style={{
                  width: "35px",
                  height: "20px"
                }}
              />
              <img
                src={
                  item.iso_alpha_2 !== ""
                    ? require(`../../assets/img/flags/${item.iso_alpha_2}.png`)
                    : ""
                }
              />
            </Col>
            <Col>
              &nbsp;
              <span>{item.full_name}</span>
            </Col>
          </Row>
        </React.Fragment>
      )
    }));

    const select_currency_select_options = this.state.currencies_list_priority.map(
      item => ({
        value: item.id,
        label: (
          <React.Fragment>
            <Row>
              <Col>
                <span
                  style={{
                    width: "35px",
                    height: "20px"
                  }}
                  className={`currency-flag currency-flag-${item.iso_alpha_3.toLowerCase()}`}
                />
              </Col>
              <Col>
                &nbsp;
                <span>{item.iso_alpha_3}</span>
              </Col>
            </Row>
          </React.Fragment>
        )
      })
    );
    return (
      <React.Fragment>
        <Dialog
          classes={{
            root: classes.center,
            paper: classes.modal
          }}
          maxWidth="lg"
          open={this.state.addBeneficiaryModalOpen}
          transition={Transition}
          keepMounted
          onClose={() => this.addBeneficiaryModelClose()}
          aria-labelledby="modal-slide-title"
          aria-describedby="modal-slide-description"
        >
          <DialogTitle
            id="classic-modal-slide-title"
            disableTypography
            style={{ backgroundColor: "#E0E0E0" }}
            onClose={() => this.addBeneficiaryModelClose()}
            className={classes.modalHeader}
          >
            <GridContainer justify="flex-start">
              <GridItem xs={10} md={10}>
                <h4 className={classes.modalTitle2}>Add New Beneficiary</h4>
              </GridItem>
              <GridItem xs={2} md={2} style={{ textAlign: "right" }}>
                <IconButton
                  edge="start"
                  color="inherit"
                  onClick={this.addBeneficiaryModelClose}
                  aria-label="close"
                >
                  <CloseIcon />
                </IconButton>
              </GridItem>
            </GridContainer>
          </DialogTitle>
          <DialogContent
            style={{ backgroundColor: "#E0E0E0" }}
            id="modal-slide-description"
            className={classes.modalBody}
          >
            <GridContainer justify="flex-start">
              <GridItem xs={12} md={6}>
                <CustomReactSelect
                  label="Currency"
                  options={select_currency_select_options}
                  value={this.state.account_currency}
                  onChange={this.handleCustomReactSelectChange(
                    "account_currency"
                  )}
                  style={{
                    dropdownIndicator: base => ({
                      ...base,
                      color: "gray" // Custom colour
                    })
                  }}
                  isClearable={false}
                />
                <br />
                <CustomReactSelect
                  label="Bank Country"
                  options={country_list_select_options}
                  value={this.state.bank_country}
                  style={{
                    dropdownIndicator: base => ({
                      ...base,
                      color: "gray" // Custom colour
                    })
                  }}
                  onChange={this.handleCustomReactSelectChange("bank_country")}
                  isClearable={false}
                  error={this.state.bank_country_state === "error"}
                />
              </GridItem>
              <GridItem xs={12} md={6}>
                {this.getInstructions()}
              </GridItem>
            </GridContainer>
            <br />
            <br />
            <br />
            <GridContainer justify="flex-start">
              <GridItem xs={6}>
                <p className={classes.benficiaryTitle}>Beneficiary details</p>
                <Divider className={classes.beneficiaryDivider} />
                <GridContainer>
                  <GridItem xs={12} md={12}>
                    <CustomTextField
                      success={this.state.ben_legal_name_state === "success"}
                      error={this.state.ben_legal_name_state === "error"}
                      labelText={<span>Beneficiary (Account) Name</span>}
                      id="first_name"
                      formControlProps={{
                        fullWidth: true,
                        style: { marginLeft: "0", borderRadius: "0px" }
                      }}
                      inputProps={{
                        error: this.state.ben_legal_name_state === "error",
                        value: this.state.ben_legal_name,
                        variant: "outlined",
                        size: "small",
                        label: <span>Name</span>,
                        style: { backgroundColor: "white" },
                        onChange: event =>
                          this.newBeneficiaryChange(
                            event,
                            "ben_legal_name",
                            "length",
                            3
                          )
                      }}
                    />
                    <CustomTextField
                      success={
                        this.state.ben_address_line_1_state === "success"
                      }
                      error={this.state.ben_address_line_1_state === "error"}
                      labelText={<span>Address Line 1</span>}
                      id="ben_address_line_1"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        value: this.state.ben_address_line_1,
                        variant: "outlined",
                        style: { backgroundColor: "white" },
                        size: "small",
                        label: <span>Address</span>,
                        onChange: event =>
                          this.newBeneficiaryChange(
                            event,
                            "ben_address_line_1",
                            "length",
                            3
                          )
                      }}
                    />
                    <CustomTextField
                      success={
                        this.state.ben_address_suburb_state === "success"
                      }
                      error={this.state.ben_address_suburb_state === "error"}
                      labelText={<span>Suburb / Region</span>}
                      id="ben_address_suburb"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        value: this.state.ben_address_suburb,
                        variant: "outlined",
                        style: { backgroundColor: "white" },
                        size: "small",
                        label: <span>Suburb / Region</span>,
                        onChange: event =>
                          this.newBeneficiaryChange(
                            event,
                            "ben_address_suburb",
                            "length",
                            3
                          )
                      }}
                    />
                    <CustomTextField
                      success={
                        this.state.ben_address_postcode_state === "success"
                      }
                      error={this.state.ben_address_postcode_state === "error"}
                      labelText={<span>Postcode</span>}
                      id="ben_address_postcode"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        value: this.state.ben_address_postcode,
                        variant: "outlined",
                        style: { backgroundColor: "white" },
                        size: "small",
                        label: <span>Postcode</span>,
                        onChange: event =>
                          this.newBeneficiaryChange(
                            event,
                            "ben_address_postcode",
                            "length",
                            3
                          )
                      }}
                    />
                    <CustomTextField
                      success={this.state.ben_email_main_state === "success"}
                      error={this.state.ben_email_main_state === "error"}
                      labelText={<span>Beneficiary Email [optional]</span>}
                      // helpText="May be used to provide copy of payout confirmation to beneficiary.  Confirmation is required from you on each transfer."
                      id="ben_email_main"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        value: this.state.ben_email_main,
                        variant: "outlined",
                        style: { backgroundColor: "white" },
                        size: "small",
                        label: <span>Beneficiary Email (optional)</span>,
                        onChange: event =>
                          this.newBeneficiaryChange(
                            event,
                            "ben_email_main",
                            "length",
                            3
                          )
                      }}
                    />
                  </GridItem>
                </GridContainer>
              </GridItem>
              <GridItem xs={6}>
                <p className={classes.benficiaryTitle}>Payment Details</p>
                <Divider className={classes.beneficiaryDivider} />
                <GridContainer justify="flex-start">
                  <GridItem xs={12}>
                    <CustomTextField
                      success={this.state.bank_legal_name_state === "success"}
                      error={this.state.bank_legal_name_state === "error"}
                      labelText={<span>Bank Name</span>}
                      style={{ borderRadius: "0px" }}
                      id="bank_legal_name"
                      formControlProps={{
                        fullWidth: true,
                        style: { borderRadius: "0px" }
                      }}
                      inputProps={{
                        error: this.state.bank_legal_name_state === "error",
                        value: this.state.bank_legal_name,
                        variant: "outlined",
                        style: {
                          backgroundColor: "white",
                          borderRadius: "0px"
                        },
                        size: "small",
                        label: <span>Bank Name</span>,
                        onChange: event =>
                          this.newBeneficiaryChange(
                            event,
                            "bank_legal_name",
                            "length",
                            3
                          )
                      }}
                    />
                    {this.state.show_iban && (
                      <CustomInput
                        success={this.state.iban === "success"}
                        error={this.state.iban === "error"}
                        labelText={<span>IBAN</span>}
                        id="iban"
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          value: this.state.iban,
                          onChange: event =>
                            this.newBeneficiaryChange(
                              event,
                              "iban",
                              "length",
                              3
                            )
                        }}
                      />
                    )}
                    {this.state.show_swift_code && (
                      <CustomTextField
                        success={this.state.swift_code_state === "success"}
                        error={this.state.swift_code_state === "error"}
                        labelText={<span>Swift Code</span>}
                        id="swift_code"
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          value: this.state.swift_code,
                          variant: "outlined",
                          style: { backgroundColor: "white" },
                          size: "small",
                          label: <span>Swift Code</span>,
                          onChange: event =>
                            this.newBeneficiaryChange(
                              event,
                              "swift_code",
                              "length",
                              3
                            )
                        }}
                      />
                    )}
                    {this.state.show_aba && (
                      <CustomTextField
                        success={
                          this.state.aba_routing_number_state === "success"
                        }
                        error={this.state.aba_routing_number_state === "error"}
                        labelText={<span>ABA Routing Number</span>}
                        id="aba_routing_number"
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          variant: "outlined",
                          style: { backgroundColor: "white" },
                          size: "small",
                          label: <span>ABA Routing Number</span>,
                          value: this.state.aba_routing_number,
                          onChange: event =>
                            this.newBeneficiaryChange(
                              event,
                              "aba_routing_number",
                              "length",
                              3
                            )
                        }}
                      />
                    )}

                    {this.state.show_sort_code && (
                      <CustomTextField
                        success={this.state.sort_code_state === "success"}
                        error={this.state.sort_code_state === "error"}
                        labelText={<span>Sort Code</span>}
                        id="sort_code"
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          value: this.state.sort_code,
                          variant: "outlined",
                          style: { backgroundColor: "white" },
                          size: "small",
                          label: <span>Sort Code</span>,
                          onChange: event =>
                            this.newBeneficiaryChange(
                              event,
                              "sort_code",
                              "length",
                              3
                            )
                        }}
                      />
                    )}
                    {this.state.show_bsb && (
                      <CustomTextField
                        success={this.state.bsb_code_state === "success"}
                        error={this.state.bsb_code_state === "error"}
                        labelText={<span>BSB</span>}
                        id="bsb_code"
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          value: this.state.bsb_code,
                          variant: "outlined",
                          style: { backgroundColor: "white" },
                          size: "small",
                          label: <span>BSB</span>,
                          onChange: event =>
                            this.newBeneficiaryChange(
                              event,
                              "bsb_code",
                              "length",
                              3
                            )
                        }}
                      />
                    )}
                    {this.state.show_transit_code && (
                      <CustomTextField
                        success={this.state.transit_code_state === "success"}
                        error={this.state.transit_code_state === "error"}
                        labelText={<span>Transit &amp; Institution No</span>}
                        id="transit_code"
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          value: this.state.transit_code,
                          variant: "outlined",
                          style: { backgroundColor: "white" },
                          size: "small",
                          label: <span>Transit &amp; Institution No</span>,
                          onChange: event =>
                            this.newBeneficiaryChange(
                              event,
                              "transit_code",
                              "length",
                              3
                            )
                        }}
                      />
                    )}
                    {this.state.show_bank_code && (
                      <CustomTextField
                        success={this.state.bank_code_state === "success"}
                        error={this.state.bank_code_state === "error"}
                        labelText={<span>Bank Code</span>}
                        id="bank_code"
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          value: this.state.bank_code,
                          variant: "outlined",
                          style: { backgroundColor: "white" },
                          size: "small",
                          label: <span>Bank Code</span>,
                          onChange: event =>
                            this.newBeneficiaryChange(
                              event,
                              "bank_code",
                              "length",
                              3
                            )
                        }}
                      />
                    )}

                    {this.state.show_account_number && (
                      <CustomTextField
                        success={this.state.account_number_state === "success"}
                        error={this.state.account_number_state === "error"}
                        style={{ borderRadius: "0px" }}
                        labelText={<span>Account Number </span>}
                        id="account_number"
                        formControlProps={{
                          fullWidth: true,
                          style: { borderRadius: "0px" }
                        }}
                        inputProps={{
                          value: this.state.account_number,
                          error: this.state.account_number_state === "error",
                          variant: "outlined",
                          style: {
                            backgroundColor: "white",
                            borderRadius: "0px"
                          },
                          size: "small",
                          label: <span>Account Number</span>,
                          onChange: event =>
                            this.newBeneficiaryChange(
                              event,
                              "account_number",
                              "length",
                              3
                            )
                        }}
                      />
                    )}
                  </GridItem>
                </GridContainer>
              </GridItem>
            </GridContainer>
            <br />
            <br />
          </DialogContent>

          <DialogActions
            className={classes.modalFooter + " " + classes.modalFooterCenter}
            style={{
              backgroundColor: "#E0E0E0",
              display: "block",
              padding: "0px 30%"
            }}
          >
            <Button onClick={this.handleAddNewBeneficiary} className={classes.primaryB}>
              Add Beneficiary
            </Button>
            <Button
              className={classes.primaryC}
              onClick={() => this.addBeneficiaryModelClose()}
            >
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
      </React.Fragment>
    );
  };
  set_payment_radio_second = e => {
    e.preventDefault();
    this.setState({ payment_method: e.target.value });
    this.props.updateNewTransferCreation("payment_method", e.target.value);
    this.handleAmount("from_amount");
  };

  updateLedgerAmount = async (stateName, value) => {
    console.log(stateName, value);
    this.setState({
      [stateName]: value
    });
    this.props.updateNewTransferCreation(stateName, value);

    if (stateName == "ledgerTo") {
      if (value) {
        const clientId = this.props.app_state.current_client.id;
        const currencyId = this.props.transfers_dashboard.new_transfer_data
          .currency_to_id;
        const response = await API.get(
          "holding_accounts",
          `/get/client-currency/${clientId}/${currencyId}`
        );
        console.log(response);
        this.setState({
          send_to_holding_acc_checkbox: value,
          [stateName]: response.holding_account_list[0].id
        });
        this.props.updateNewTransferCreation(
          "ledgerTo",
          response.holding_account_list[0].id
        );
        this.props.updateNewTransferCreation(
          "send_to_holding_acc_checkbox",
          value
        );
      } else {
        this.setState({
          send_to_holding_acc_checkbox: value,
          ledgerTo: value
        });
        this.props.updateNewTransferCreation("ledgerTo", value);
        this.props.updateNewTransferCreation(
          "send_to_holding_acc_checkbox",
          value
        );
      }
    }
  };

  onClickToLedger = async (e) => {
    const target = e.target.name
    const value = e.target.checked
    console.log(target, value)
    if (target == "ledgerTo") {
      if (value) {
        this.props.updateNewTransferCreationBeneficiary([{ detail_1: '' }]);
        const clientId = this.props.app_state.current_client.id;
        const currencyId = this.props.transfers_dashboard.new_transfer_data.currency_to_id;
        this.props.updateNewTransferCreation(
          "send_to_holding_acc_checkbox",
          value
        );
        const response = await API.get(
          "holding_accounts",
          `/get/client-currency/${clientId}/${currencyId}`
        );
        // console.log(response);
        this.props.updateNewTransferCreation(
          "ledgerTo",
          response.holding_account_list[0].id
        );
      } else {
        this.props.updateNewTransferCreation("ledgerTo", value);
        this.props.updateNewTransferCreation(
          "send_to_holding_acc_checkbox",
          value
        );
      }
    }
  }

  onClickFromLedger = async (e) => {
    const target = e.target.name
    const value = e.target.checked
    // console.log(target, value)
    if (target == "ledgerFrom") {
      if (value) {
        const clientId = this.props.app_state.current_client.id;
        const currencyId = this.props.transfers_dashboard.new_transfer_data.currency_from_id;
        const holdingAccountList = await API.get(
          "holding_accounts",
          `/get/client-currency/${clientId}/${currencyId}`
        );
        console.log(holdingAccountList);
        this.props.updateNewTransferCreation(
          "ledgerFrom",
          holdingAccountList.holding_account_list[0].id
        );

        this.props.updateNewTransferCreation("amount_from", holdingAccountList.holding_account_list[0].balance.toFixed(2));
        this.handleAmount("to_amount");
        
      } else {
        this.props.updateNewTransferCreation("ledgerFrom", value);
      }
    }
  }

  checkSelect = () => {
    const query_strings = queryString.parse(this.props.location.search);
    const fromLedger = parseInt(query_strings.fromLedger);

    if (!isNaN(fromLedger)) {
      return true;
    } else {
      return false;
    }
  };

  render() {
    const { classes } = this.props;
    // For CustomReactSelect. Generate select options for dropdown list.

    if (this.state.componentLoading) return <span>Loading.  Please wait...</span>

    const select_currency_from_select_options = [];
    const select_currency_select_options = this.state.currencies_list_priority.map(
      item => {
        if (item.allowFrom) {
          select_currency_from_select_options.push({
            value: item.id,
            label: (
              <React.Fragment>
                <Row>
                  <Col>
                    <img
                      src={`https://wise.com/public-resources/assets/flags/rectangle/${item.iso_alpha_3.toLowerCase()}.png`}
                      width='32px' height='16px' style={{verticalAlign: 'middle', marginBottom: '4px', marginRight: 12, border: '1px solid #ccc'}} />
                  </Col>
                  <Col>
                    <strong>
                      <span>{item.iso_alpha_3}</span>
                    </strong>
                  </Col>
                </Row>
              </React.Fragment>
            )
          });
        }
        return {
          value: item.id,
          label: (
            <React.Fragment>
              <Row>
                <Col>
                  <img
                    src={`https://wise.com/public-resources/assets/flags/rectangle/${item.iso_alpha_3.toLowerCase()}.png`}
                    width='32px' height='16px' style={{verticalAlign: 'middle', marginBottom: '4px', marginRight: 12, border: '1px solid #ccc'}} />
                </Col>
                <Col>
                  &nbsp;
                  <span>{item.iso_alpha_3}</span>
                </Col>
              </Row>
            </React.Fragment>
          )
        };
      }
    );

    // Create Ledger Balance Chips
    // let fromCurrencyLedgerChip = (
    //   <Chip icon={<React.Fragment> Ledger Balance: 0.00</React.Fragment>} />
    // );
    let fromCurrencyLedgerChip = null;

    const query_strings = queryString.parse(this.props.location.search);
    const fromLedger = parseInt(query_strings.fromLedger);
    const checkledger = record => {
      // if (
      //   !isNaN(fromLedger) &&
      //   this.props.transfers_dashboard.new_transfer_data.amount_from >
      //     record.balance
      // ) {
      //   if (!this.props.firstStepDisabled) {
      //     this.props.toggleButton(true);
      //   }
      //   return "You have entered and amount higher than the available";
      // } else {
      //   if (this.props.firstStepDisabled) {
      //     this.props.toggleButton(false);
      //   }
      //   return "";
      // }
      if (this.props.transfers_dashboard.new_transfer_data.ledgerFrom) {
        if (parseFloat(this.props.transfers_dashboard.new_transfer_data.amount_from) > record.balance) {
          // console.log(record.balance)
          // this.props.updateNewTransferCreation("amount_from", Number(record.balance).toFixed(2));
          return "Warning: You have entered an amount higher than your available balance.";
        }
      }
    };

    for (var idx in this.state.holdingAccountBalances) {
      // console.log(this.state.holdingAccountBalances[idx])
      let record = this.state.holdingAccountBalances[idx];
      if (
        record.currency_id === this.props.transfers_dashboard.new_transfer_data.currency_from_id
        && record.balance
      ) {
        fromCurrencyLedgerChip = (
          <Fragment>
            <p
              style={{
                textAlign: "right",
                textTransform: "capitalize",
                fontWeight: "bold",
                color: "red"
              }}
            >
              {/* {checkledger(record)} */}
              {this.state.displayLedgerWarning && "Amount adjusted to your available balance"}
            </p>
            <Chip
              label={
                <div>
                  <AntCheckbox
                    name="ledgerFrom" 
                    checked={this.props.transfers_dashboard.new_transfer_data.ledgerFrom} 
                    disabled={false} 
                    onChange={this.onClickFromLedger}>
                      Tick to transfer FROM your current {record.currencies_short_name} balance:&nbsp;
                      <strong>
                      {new Intl.NumberFormat("en-GB", {
                        style: "currency",
                        currency: record.currencies_short_name
                      }).format(record.balance)}
                      </strong>
                  </AntCheckbox>
                </div>
              }
            />
          </Fragment>
        );
      } 
    }

    //Dynamic Strings
    let transferRateCondition = "";
    let trasnferRate = 0;
    if (!isNaN(this.props.transfers_dashboard.new_transfer_data.client_rate)) {
      transferRateCondition = "dashboard.transferRate";
      trasnferRate = this.props.transfers_dashboard.new_transfer_data
        .client_rate;
    } else {
      transferRateCondition = "dashboard.blank";
      trasnferRate = "";
    }

    const newTransferData = this.props.transfers_dashboard.new_transfer_data;
    if (
      newTransferData.multiple_beneficiary_id &&
      newTransferData.multiple_beneficiary_id.length > 0
    ) {
      if (
        newTransferData.multiple_beneficiary_id[0].beneficiary &&
        newTransferData.multiple_beneficiary_id[0].beneficiary.holdingAccountId
      ) {
        if (!newTransferData.ledgerTo) {
          this.props.updateNewTransferCreation(
            "ledgerTo",
            newTransferData.multiple_beneficiary_id[0].beneficiary
              .holdingAccountId
          );
        }
      }
    }

    return (
      <React.Fragment>
        <p>
          <FormattedMessage id="dashboard.welcome" defaultMessage={` `} />
        </p>
        <p>
          <FormattedMessage
            id="dashboard.welcomeMessage"
            defaultMessage={` `}
          />
        </p>
        <br />
        <GridContainer>
          <CommoneCurrentComponent
            selectError={
              this.props.transfers_dashboard.new_transfer_data
                .currency_from_state === "error"
            }
            selectLabel={
              <FormattedMessage
                id="dashboard.fromCurrency"
                defaultMessage={`From currency `}
              />
            }
            defaultValue={1000}
            color="blue"
            selectOptions={select_currency_from_select_options}
            selectValue={
              this.props.transfers_dashboard.new_transfer_data.currency_from_id
            }
            isClearable={false}
            isDisabled={this.checkSelect()}
            isSearchable={false}
            onSelectChange={this.handleCustomReactSelectChange(
              "from_currency",
              this.props.transfers_dashboard.new_transfer_data.currency_from_id,
              this.props.transfers_dashboard.new_transfer_data.currency_to_id
            )}
            inputValue="You send"
            inputValue2={
              this.props.transfers_dashboard.new_transfer_data.amount_from
            }
            onInputChange={event => {
              this.change(
                event,
                "from_amount",
                "isLargerThanNumber",
                min_transfer_amount
              );
            }}
          />
        </GridContainer>
        {this.renderFeeWarning()}
        {this.props.app_state.current_team.brand_ledger && fromCurrencyLedgerChip}
        <br />
        {this.props.transfers_dashboard.new_transfer_data.currency_from &&
          this.props.transfers_dashboard.new_transfer_data.currency_to && (
            <GridContainer>
              <GridItem xs={8} sm={8} md={7}>
                <p className={classes.transferRate}>
                  transfer rate:&nbsp;
                  {trasnferRate != "" && parseFloat(trasnferRate).toFixed(4)}
                </p>
              </GridItem>
            </GridContainer>
          )}
        <br />
        <GridContainer>
          <CommoneCurrentComponent
            selectError={
              this.props.transfers_dashboard.new_transfer_data
                .currency_to_state === "error"
            }
            color="green"
            selectLabel={
              <FormattedMessage
                id="dashboard.toCurrency"
                defaultMessage={`To currency `}
              />
            }
            selectOptions={select_currency_select_options}
            selectValue={
              this.props.transfers_dashboard.new_transfer_data.currency_to_id
            }
            isClearable={false}
            isDisabled={this.state.prefill_to_currency}
            isSearchable={false}
            inputValue2={
              this.props.transfers_dashboard.new_transfer_data.amount_to
            }
            onSelectChange={this.handleCustomReactSelectChange(
              "to_currency",
              this.props.transfers_dashboard.new_transfer_data.currency_from_id,
              this.props.transfers_dashboard.new_transfer_data.currency_to_id
            )}
            inputValue="Beneficiary gets"
            onInputChange={event => {
              this.change(
                event,
                "to_amount",
                "isLargerThanNumber",
                min_transfer_amount
              );
            }}
          />
        </GridContainer>

        <br />
        <GridContainer justify="center">
          {this.render_beneficiary_details_section()}
        </GridContainer>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    app_state: state.app_state,
    language: state.language,
    transfers_dashboard: state.transfers_dashboard
  };
};

const mapDispatchToProps = dispatch => {
  return {
    update_map_data: data => {
      dispatch(update_map_data(data));
    },
    updateNewTransferCreation: (key, value) => {
      dispatch(updateNewTransferCreation(key, value));
    },
    updateNewTransferCreationBeneficiary: array => {
      dispatch(updateNewTransferCreationBeneficiary(array));
    },
    createNewBeneficiary: data => {
      dispatch(createNewBeneficiary(data));
    },
    clearNewTransfer: data => {
      dispatch(clearNewTransfer());
    }
  };
};

const DashboardTransferStep1Container = connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(style)(DashboardTransferStep1));

export default withRouter(DashboardTransferStep1Container);