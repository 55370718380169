import React from "react";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import {withRouter} from "react-router-dom";
import {AiOutlineSearch} from 'react-icons/ai';
import {connect} from "react-redux";
import {Button as AntButton, Form, Input, message, Modal, Tooltip} from "antd";
import {FilePdfFilled} from "@ant-design/icons";
import {fetchTransferList, fetchTransferListV2, setTransferSelectId} from "../../redux/actions/transfers";

// @material-ui/icons
import Dvr from "@material-ui/icons/Dvr";
import Button from "components/CustomButtons/Button.jsx";

/**
 * STYLING
 */
import {cardTitle} from "assets/jss/material-dashboard-pro-react.jsx";

import {FormattedNumber} from "react-intl";
import "react-dates/initialize";
import 'react-circular-progressbar/dist/styles.css';

import {DateRangePicker} from "react-dates";

import Badge from "../Badge/Badge";
import ListTable from "../ListTable/ListTable";
import {Spin, Table} from "antd";
import {buildStyles, CircularProgressbar} from "react-circular-progressbar";
import GridItem from "../Grid/GridItem";
import StepLabel from "@material-ui/core/StepLabel";
import Step from "@material-ui/core/Step";
import Card from "../Card/Card";
import CardBody from "../Card/CardBody";
import GridContainer from "../Grid/GridContainer";
import Stepper from "@material-ui/core/Stepper";
import {TransferAPI} from "./TransferUtil";
import {BeneficiaryAPI} from "../Beneficiaries/BeneficiaryUtil";
import FormItem from "antd/es/form/FormItem";
import Clearfix from "../Clearfix/Clearfix";
import {fetchCountriesNotDeletedList} from "../../redux/actions/countries";
import {MailOutlined} from "@material-ui/icons";
import {API} from "aws-amplify";

var moment = require("moment");

const styles = {
  cardIconTitle: {
    ...cardTitle,
    marginTop: '15px',
    marginBottom: '0px'
  },
  id: {color: '#0BACF4'},
  currencyContainer: {
    display: 'flex',
    alignItems: 'center',
    width: '100%'
  },
  currency: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: 'fit-content',
    '& > .currency-flag': {
      marginRight: '0.5rem',
      width: 52,
      height: 26
    }
  },
  currencyLabel: {
    textAlign: 'right',
    '& > p': {
      marginBottom: -5,
      fontSize: '0.6rem'
    }
  },
  progress: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    float: 'left',
    '& > span': {
      // marginLeft: 12,
      fontSize: 12,
      textTransform: 'uppercase'
    }
  },
  progressBar: {width: 50},
  trail: {
    // Trail color
    stroke: '#d6d6d6',
    // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
    strokeLinecap: 'butt',
    // Rotate the trail
    transform: 'rotate(0.25turn)',
    transformOrigin: 'left left'
  },
  path: {
    // Path color
    // stroke: `rgba(62, 152, 199, ${percentage / 100})`,
    // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
    strokeLinecap: 'butt',
    // Customize transition animation
    transition: 'stroke-dashoffset 0.5s ease 0s',
    // Rotate the path
    transform: 'rotate(0.25turn)',
    transformOrigin: 'left left'
  }
};
const CurrencyElement = ({
  classes, currency, value
}) => (
  <div className={classes.currency}>
    <div className={`currency-flag currency-flag-${currency ? currency.toLowerCase() : ''}`} />
    <div className={classes.currencyLabel}>
      <p>{currency}</p>
      <FormattedNumber minimumFractionDigits={2} maximumFractionDigits={2} value={value} />
    </div>
  </div>
);

/**
 * CLASS - TransferList
 */
class TransferList extends React.Component {

  sendReceiptForm = React.createRef();
  constructor(props) {
    super(props);
    this.state = {
      entity_list: [],
      startDate: moment().subtract(6, "month"),
      endDate: moment().add(1, "month"),
      transferList: [],
      transferListLength: 0,
      expandedRow: [],
      copyTransferAmount: 0,
      modalTransferId: null,
      isModalOpen: false
    };
  }

  componentDidMount() {
    this.props.fetchTransferListV2(this.props.app_state.current_client.id)
    this.props.fetchCountryNotDeleted();
    this.buildTableData()
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.transfers.transfer_list !== this.props.transfers.transfer_list) {
      this.buildTableData()
    }
  }

  handle_date_change = (startDate, endDate) => {
    // console.log(startDate, endDate);
    this.setState({ startDate, endDate });
    this.buildTableData();
  };

  buildTableData() {
    // eslint-disable-next-line react/prop-types
    let transfer_list_data = this.props.transfers.transfer_list;
    // console.log(transfer_list_data);

    // eslint-disable-next-line react/prop-types
    if (this.props.past) {
      transfer_list_data = transfer_list_data.filter(transfer => {
        return transfer.status === 8 || transfer.status === 10;
      });
    }

    // eslint-disable-next-line react/prop-types
    if (this.props.current) {
      transfer_list_data = transfer_list_data.filter(transfer => {
        return transfer.status < 7 || transfer.status === 9;
      });
    }

    // let date_range_transfer_list = this.props.transfers.transfer_list;
    if (this.state.startDate && this.state.endDate) {
      transfer_list_data = transfer_list_data.filter(transfer => {
        return (
          this.state.startDate.isBefore(
            moment(transfer.transaction_datetime).add(1, "day")
          ) && this.state.endDate.isAfter(moment(transfer.transaction_datetime))
        );
      });
    }

    var transfer_list_length = transfer_list_data.length;
    let newTransferList = [];
    if (transfer_list_length > 0) {
      // return this.props.transfers.transfer_list.map((prop, key) => {
      for (let i = 0; i < transfer_list_length; i++) {
        // console.log(transfer_list_data[i])
        newTransferList.push({
          id: transfer_list_data[i].id,
          // client_fullname: prop.client_nickname,
          transaction_date: transfer_list_data[i].transaction_datetime,
          isMultiPay: transfer_list_data[i].isMultiPay,
          status_id: transfer_list_data[i].status,
          memo: transfer_list_data[i].memo,
          nickname: transfer_list_data[i].nickname,
          settlement_date: transfer_list_data[i].settlement_date,
          beneficiary: transfer_list_data[i].isMultiPay ? 
            'MultiPay' : transfer_list_data[i].beneficiaries_nickname ? 
              transfer_list_data[i].beneficiaries_nickname : 
              'Currency Balance',
          currency_from: `${transfer_list_data[i].currency_from_iso_alpha_3}`,
          amount_from: `${transfer_list_data[i].amount_from}`,
          currency_to: `${transfer_list_data[i].currency_to_iso_alpha_3}`,
          amount_to: `${transfer_list_data[i].amount_to}`,
          client_rate: transfer_list_data[i].client_rate.toFixed(5),
          transfer_status: transfer_list_data[i].transfer_status,
          arrow: '⮕',
          actions: (
            <div className="actions-right">
              <Button
                justIcon
                round
                simple
                onClick={() => {
                  // eslint-disable-next-line react/prop-types
                  if (transfer_list_data[i].status == 8) {
                    this.props.history.push(`/transfers/receipt/transfer/${transfer_list_data[i].id}`);
                  } else {
                    this.props.history.push(`/transfers/edit/${transfer_list_data[i].id}`);
                  }
                }}
                color="warning"
                className="edit"
              >
                <Dvr />
              </Button>{" "}
            </div>
          )
        });
        // newTransferList = newTransferList.sort((a, b) => b.id - a.id);
      }
      // newTransferList = transfer_list_data.map((prop, key) => {
      //   return {
      //     id: prop.id,
      //     // client_fullname: prop.client_nickname,
      //     transaction_date: prop.transaction_datetime,
      //     status_id: prop.status,
      //     memo: prop.memo,
      //     nickname: prop.nickname,
      //     settlement_date: prop.settlement_date,
      //     beneficiary: prop.beneficiaries_nickname,
      //     currency_from: `${prop.currency_from_iso_alpha_3}`,
      //     amount_from: `${prop.amount_from}`,
      //     currency_to: `${prop.currency_to_iso_alpha_3}`,
      //     amount_to: `${prop.amount_to}`,
      //     client_rate: prop.client_rate.toFixed(5),
      //     transfer_status: prop.transfer_status,
      //     actions: (
      //       <div className="actions-right">
      //         <Button
      //           justIcon
      //           round
      //           simple
      //           onClick={() => {
      //             // eslint-disable-next-line react/prop-types
      //             this.props.history.push(`/transfers/edit/${prop.id}`);
      //           }}
      //           color="warning"
      //           className="edit"
      //         >
      //           <Dvr />
      //         </Button>{" "}
      //       </div>
      //     )
      //   };
      // });
    }
    // console.log(newTransferList)
    this.setState({ 
      transferList: newTransferList,
      transferListLength: transfer_list_length
     });
  }

  getSteps() {
    return [
      "Submitted",
      "Transfer acknowledged",
      "Funds received from client",
      "Payment made to Beneficiary",
      "Completed"
    ];
  }
  getSteps1() {
    return ["Submitted", "Not Proceeding"];
  }

  getFilter = (filter, onChange) => {
    return (
      <React.Fragment>
        <div
          style={{
            display: 'flex',
            alignItems: 'center'
          }}
        >
          <AiOutlineSearch />
          <input onChange={event => onChange(event.target.value)} value={filter ? filter.value : ''} />
        </div>
      </React.Fragment>
    );
  };

  getColor = (transferStatus) => {
    return transferStatus === "Draft"
        ? "warning"
        : transferStatus === "Submitted"
            ? "info"
            : transferStatus === "Acknowledged"
                ? "success"
                : transferStatus === "Processing"
                    ? "success"
                    : transferStatus === "Awaiting Funds"
                        ? "danger"
                        : transferStatus === "Funds Received"
                            ? "danger"
                            : transferStatus === "Payment Made"
                                ? "success"
                                : transferStatus === "Completed"
                                    ? "primary"
                                    : transferStatus === "On Hold"
                                        ? "danger"
                                        : transferStatus === "Not Proceeding"
                                            ? "primary"
                                            : "success";
  }

  getCircularValue = (value) => {
    return value === 'In Process'
        ? 30
        : value === 'Completed'
            ? 100
            : value === 'Acknowledged'
                ? 20
                : value === 'Funds Received'
                    ? 70
                    : value === 'Not Proceeding'
                        ? 100
                        : value === 'Processing'
                            ? 40
                            : 50
  }

  getCircularText = (text) => {
    return text === 'In Process'
        ? 30
        : text === 'Completed'
            ? 100
            : text === 'Acknowledged'
                ? 20
                : text === 'Funds Received'
                    ? 70
                    : text === 'Not Proceeding'
                        ? 100
                        : text === 'Processing'
                            ? 40
                            : 50

  }

  onRowExpand = (event, record) => {
    if (event === true) {
      if (this.state.expandedRow[`row_${record.id}`] == null || this.state.expandedRow[`row_${record.id}`].status === 'ERROR') {
        this.fetchTransferDetail(record);
      }
    }
  }

  generatePdfPayout = (id) => {
    const win = window.open(`https://api.neutronapi.com/pdfservice/api/fww/receipt/${id}`, '_blank');
    win.focus();
  }

  generatePdfSettlementInstructions = (id) => {
    const win = window.open(`https://api.neutronapi.com/pdfservice/api/fww/remittance/${id}`, '_blank');
    win.focus();
  }

  fetchTransferDetail = (record) => {
    const identifier = `row_${record.id}`;
    this.setState({expandedRow: {...this.state.expandedRow, [identifier]: {status: 'LOADING'}}});
    TransferAPI.fetchTransferDetails(record.id).then(parentResponse => {
      if (parentResponse.payouts && parentResponse.payouts.length > 0) {
        BeneficiaryAPI.fetchBeneficiaryById(parentResponse.payouts[0].beneficiary_id).then(childResponse => {
          let data = {}
          if (childResponse) {
            data = {
              ...this.state.expandedRow, [identifier]: {
                from_currency: parentResponse.fullList.currency_from_iso_alpha_3,
                to_currency: parentResponse.fullList.currency_to_iso_alpha_3,
                ben_id: childResponse,
                purpose_of_payment: parentResponse.payouts[0].purpose_of_payment_detail,
                status: parentResponse.payouts.length > 1 ? 'MULTIPAY' : 'LOADED',
                ben_name: childResponse.ben_legal_name || 'Currency Balance',
                ben_currency: childResponse.iso_alpha_3,
                ben_address_line_1: childResponse.ben_address_line_1 || '-',
                ben_address_line_2: childResponse.ben_address_line_2 || '-',
                ben_suburb: childResponse.ben_address_suburb || '-',
                ben_state: childResponse.ben_address_state || '-',
                // ben_country: childResponse.ben_address_country != null
                //     ? this.props.countries.find(item => item.id === childResponse.ben_address_country).full_name
                //     : '-',
                ben_country: childResponse.ben_address_country != null
                    ? this.props.countries.find(item => item.id === childResponse.ben_address_country).full_name
                    : '-',
                ben_postcode: childResponse.ben_address_postcode,
              }
            };
          } else {
            data = {
              ...this.state.expandedRow, [identifier]: {
                status: 'NOBENEFICIARY'
              }
            };
          }
          console.log(data);
          this.setState({ expandedRow: data });
        }).catch(err => {
          console.log(err);
          this.setState({expandedRow: {...this.state.expandedRow, [identifier]: {status: 'ERROR'}}});
        })
      }
    }).catch(err => {
      console.log(err);
      this.setState({expandedRow: {...this.state.expandedRow, [identifier]: {status: 'ERROR'}}});
    });
  }


  emailPayoutConfirmation = async (values) => {
    const loading = message.loading("Sending email. Please wait...", 0);
    const [transfer, payouts] = await Promise.all([
      API.post("commons", "/fetch", {
        body: {
          context: 'transfers',
          fields: ['*'],
          condition: {id: this.state.modalTransferId}
        }
      }),
      API.post("commons", "/fetch", {
        body: {
          context: 'transfer_payouts',
          fields: ['*'],
          condition: {transfer_id: this.state.modalTransferId}
        }
      })
    ]);
    const payload = {
      ...transfer[0],
      customEmail: values.email,
      payouts_list: payouts,
      transferID: transfer[0].id,
      staffOnly: true
    }
    API.post("email18", "/client/send-payout-advice-general", {
      body: payload
    }).then(() => {
      message.success("Email sent successfully");
    }).catch(err => {
        message.error("Failed to send email");
    }).finally(() => {
        loading();
        this.setState({isModalOpen: false, modalTransferId: null});
    })
  }


  render() {
    const {classes} = this.props;
    // console.log(this.props.past)
    // console.log(this.props.current)
    const steps = this.getSteps();
    const steps1 = this.getSteps1();

    // eslint-disable-next-line react/prop-types
    var transfer_status = this.props.transfers.transfer_list.map(
      (prop, key) => {
        return prop.transfer_status;
    });
    var transfer_status_unique = [...new Set(transfer_status)];
    var transfer_status_optionlist = transfer_status_unique.map((prop, key) => ({text: prop, value: prop}));

    // console.log(this.props);
    return (
      <React.Fragment>
        <DateRangePicker
          isOutsideRange={() => false}
          startDate={this.state.startDate} // momentPropTypes.momentObj or null,
          startDateId="your_unique_start_date_id" // PropTypes.string.isRequired,
          endDate={this.state.endDate} // momentPropTypes.momentObj or null,
          endDateId="your_unique_end_date_id" // PropTypes.string.isRequired,
          displayFormat="DD/MM/YYYY"
          onDatesChange={({ startDate, endDate }) =>
            this.handle_date_change(startDate, endDate)
          } // PropTypes.func.isRequired,
          focusedInput={this.state.focusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
          onFocusChange={focusedInput => this.setState({ focusedInput })} // PropTypes.func.isRequired,
        />
        <Table
            ref={r => {
              if(this.props.setReactTable) {
                this.props.setReactTable(this.state.transferList)
              }
            }}
            size={'middle'}
            scroll={{x: 'max-content'}}
          dataSource={this.state.transferList}
          rowKey={'id'}
          expandable={{
            onExpand: (event, record) => {this.onRowExpand(event, record)},
            expandedRowRender: (record) => {
              let ActivTab = 0;
              if (record.status_id === 2) ActivTab = 0;
              if (record.status_id === 3) ActivTab = 1;
              if (record.status_id === 4) ActivTab = 1;
              if (record.status_id === 5) ActivTab = 1;
              if (record.status_id === 6) ActivTab = 2;
              if (record.status_id === 7) ActivTab = 3;
              if (record.status_id === 8) ActivTab = 4;

              if (record.status_id === 10) ActivTab = 1;
              const data = this.state.expandedRow[`row_${record.id}`];
              if (data.status === 'NOBENEFICIARY') {
                return (
                  <Card>
                    <CardBody>
                      <GridContainer>
                        <GridItem xs={12} sm={12}>
                          {/*<h6>{record.transfer_status_name}</h6>*/}
                          {record.status_id !== 10 &&
                              <Stepper activeStep={ActivTab}>
                                {steps.map((label, index) => {
                                  const stepProps = {};
                                  const labelProps = {};

                                  return (
                                      <Step key={label} {...stepProps}>
                                        <StepLabel {...labelProps}>{label}</StepLabel>
                                      </Step>
                                  );
                                })}
                              </Stepper>
                          }
                          {record.status_id === 10 &&
                              <Stepper activeStep={ActivTab}>
                                {steps1.map((label, index) => {
                                  const stepProps = {};
                                  const labelProps = {};

                                  return (
                                      <Step key={label} {...stepProps}>
                                        <StepLabel {...labelProps}>{label}</StepLabel>
                                      </Step>
                                  );
                                })}
                              </Stepper>
                          }

                        </GridItem>
                      </GridContainer>
                      <div style={{maxWidth: 700}}>
                        <table style={{maxWidth: 500, float: "left"}}>
                          <tr>
                            <th style={{textAlign: 'left'}}>Beneficiary Name</th>
                            <td>Payment will be made to currency balance</td>
                          </tr>
                        </table>
                        <Clearfix />
                      </div>
                    </CardBody>
                  </Card>
                )
              } 
              if (data.status === 'MULTIPAY') {
                return (
                  <Card>
                    <CardBody>
                      <GridContainer>
                        <GridItem xs={12} sm={12}>
                          {/*<h6>{record.transfer_status_name}</h6>*/}
                          {record.status_id !== 10 &&
                              <Stepper activeStep={ActivTab}>
                                {steps.map((label, index) => {
                                  const stepProps = {};
                                  const labelProps = {};

                                  return (
                                      <Step key={label} {...stepProps}>
                                        <StepLabel {...labelProps}>{label}</StepLabel>
                                      </Step>
                                  );
                                })}
                              </Stepper>
                          }
                          {record.status_id === 10 &&
                              <Stepper activeStep={ActivTab}>
                                {steps1.map((label, index) => {
                                  const stepProps = {};
                                  const labelProps = {};

                                  return (
                                      <Step key={label} {...stepProps}>
                                        <StepLabel {...labelProps}>{label}</StepLabel>
                                      </Step>
                                  );
                                })}
                              </Stepper>
                          }

                        </GridItem>
                      </GridContainer>
                      <div style={{maxWidth: 700}}>
                        <table style={{maxWidth: 700, float: "left"}}>
                          <tr>
                            <th style={{textAlign: 'left'}}>Beneficiary</th>
                            <td>
                              This transfer is a MultiPay. <br/>
                              Please open it to view payout details.
                            </td>
                          </tr>
                        </table>
                        <Clearfix />
                      </div>
                    </CardBody>
                  </Card>
                )
              } 
              return (
                  <Card>
                    <CardBody>
                      {data != null ? (
                          data.status === 'LOADED'
                              ? <>
                                <GridContainer>
                                  <GridItem xs={12} sm={12}>
                                    {/*<h6>{record.transfer_status_name}</h6>*/}
                                    {record.status_id !== 10 &&
                                        <Stepper activeStep={ActivTab}>
                                          {steps.map((label, index) => {
                                            const stepProps = {};
                                            const labelProps = {};

                                            return (
                                                <Step key={label} {...stepProps}>
                                                  <StepLabel {...labelProps}>{label}</StepLabel>
                                                </Step>
                                            );
                                          })}
                                        </Stepper>
                                    }
                                    {record.status_id === 10 &&
                                        <Stepper activeStep={ActivTab}>
                                          {steps1.map((label, index) => {
                                            const stepProps = {};
                                            const labelProps = {};

                                            return (
                                                <Step key={label} {...stepProps}>
                                                  <StepLabel {...labelProps}>{label}</StepLabel>
                                                </Step>
                                            );
                                          })}
                                        </Stepper>
                                    }

                                  </GridItem>
                                  {/* <GridItem xs={12} sm={3}>
                                    <h6>Actions</h6>
                                    <Button
                                        style={{ width: 200 }} color="primary" size="sm"
                                        onClick={() => {
                                          this.props.history.push(`/transfers/edit/${record.id}`);
                                        }}
                                        className="edit">
                                      View Transfer Details</Button>
                                    <br />
                                  </GridItem> */}
                                </GridContainer>
                                <div style={{maxWidth: 700}}>
                                  <table style={{maxWidth: 500, float: "left"}}>
                                    <tr>
                                      <th style={{textAlign: 'left'}}>Beneficiary Name</th>
                                      <td>{data.ben_name}</td>
                                    </tr>
                                    <tr>
                                      <th style={{textAlign: 'left'}}>Currency</th>
                                      <td>{data.ben_currency}</td>
                                    </tr>
                                    <tr>
                                      <th style={{textAlign: 'left'}}>Address Line 1</th>
                                      <td>{data.ben_address_line_1}</td>
                                    </tr>
                                    <tr>
                                      <th style={{textAlign: 'left'}}>Address Line 2</th>
                                      <td>{data.ben_address_line_2}</td>
                                    </tr>
                                    <tr>
                                      <th style={{textAlign: 'left'}}>Suburb</th>
                                      <td>{data.ben_suburb}</td>
                                    </tr>
                                    <tr>
                                      <th style={{textAlign: 'left'}}>State</th>
                                      <td>{data.ben_state}</td>
                                    </tr>
                                    <tr>
                                      <th style={{textAlign: 'left'}}>Post Code</th>
                                      <td>{data.ben_postcode}</td>
                                    </tr>
                                    <tr>
                                      <th style={{textAlign: 'left'}}>Country</th>
                                      <td>{data.ben_country}</td>
                                    </tr>
                                  </table>
                                  <div style={{float: "right"}}>
                                    <FormItem>
                                      <Input placeholder={'Enter Amount'} onChange={(e) => this.setState({copyTransferAmount: e.target.value})}/>
                                    </FormItem>
                                    <AntButton type={'primary'}
                                               onClick={
                                                        () => {
                                                            if (this.state.copyTransferAmount != null) {
                                                              this.props.history.push(`/shortcut_transfer/${data.from_currency}-${data.to_currency}--${this.state.copyTransferAmount}-${data.ben_id.id}-${data.purpose_of_payment}---`)
                                                            } else {
                                                              message.info("Please enter amount");
                                                            }
                                                        }
                                              }><span>Copy to New Transfer <i className={'fa fa-caret-right'}></i></span></AntButton>
                                  </div>
                                  <Clearfix />
                                </div>
                              </>
                              : (
                                  data.status === "LOADING"
                                      ? <><Spin/></>
                                      : <AntButton type={'link'} danger={true}
                                                   onClick={() => this.fetchTransferDetail(record)}><strong>Try
                                        again...</strong></AntButton>
                              )
                      ) : <Spin/>}
                    </CardBody>
                  </Card>
              );
            }
          }}
            columns={[
              {
                title: "ID",
                dataIndex: "id",
                defaultSortOrder: 'descend',
                sorter: (a, b) => a.id - b.id,
                ...this.props.getColumnSearchProps({
                  dataIndex: 'id',
                  render: (text, record) => <Badge color={this.getColor(record.transfer_status)}>ID: {record.id}</Badge>
                })

              },
              {
                title: "Transaction",
                dataIndex: "transaction_date",
                ...this.props.getColumnSearchProps({
                  filterInputType: 'DATE',
                  render: (text, record) => <>{moment(text).format("DD/MM/YYYY")} </>,
                })
              },
              {
                title: "Settlement",
                dataIndex: "settlement_date",
                ...this.props.getColumnSearchProps({
                  dataIndex: 'settlement_date',
                  filterInputType: 'DATE',
                  render: (text, record) => <>{moment(text).format("DD/MM/YYYY")} </>,
                })
              },
              {
                title: "Beneficiary",
                dataIndex: "beneficiary",
                ...this.props.getColumnSearchProps({
                  render: (text) => text
                })
              },
              {
                title: 'From',
                dataIndex: 'amount_from',
                ...this.props.getColumnSearchProps({
                  dataIndex: 'from',
                  render: (text, record) => {
                    return <CurrencyElement currency={record.currency_from} value={record.amount_from} classes={classes} />
                  }
              })
            },
            {
              title: '',
              dataIndex: 'arrow'
            },
            {
              title: 'To',
              dataIndex: 'amount_to',
              ...this.props.getColumnSearchProps({
                dataIndex: 'amount_to',
                render: (text, record) => <CurrencyElement currency={record.currency_to} value={record.amount_to} classes={classes} />
              })
            },
            {
              title: "Rate",
              dataIndex: "client_rate"
            },
            {
                title: 'PDFs',
                key: 'isMultiPay',
                dataIndex: 'isMultiPay',
                render: (text, record) => {
                  return this.props.past
                      ? <div style={{ display: 'flex', alignItems: "center" }}>
                        <Tooltip title={'PDF Payout Confirmation'}><AntButton
                            type={'link'}
                            // danger={true}
                            size={'small'}
                            icon={<FilePdfFilled />}
                            onClick={() => this.generatePdfPayout(record.id)}></AntButton></Tooltip>
                        <Tooltip title={'Email Payout Confirmation'}><AntButton
                            type={'link'}
                            // disabled={true}
                            // danger={true}
                            size={'small'}
                            icon={<MailOutlined />}
                            onClick={() => {
                              this.setState({ isModalOpen: true, modalTransferId: record.id });
                            }}></AntButton></Tooltip>
                      </div>
                      : <Tooltip title={'PDF Settlement Instructions'}><AntButton
                          type={'link'}
                          // danger={true}
                          size={'small'}
                          icon={<FilePdfFilled />}
                          onClick={() => this.generatePdfSettlementInstructions(record.id)}></AntButton></Tooltip>
                },
            },
            {
              title: 'Status ',
              dataIndex: 'transfer_status',
              filters: transfer_status_optionlist,
              onFilter: (value, record) => record.transfer_status && record.transfer_status.startsWith(value),
              ...this.props.getColumnSearchProps({
                dataIndex: 'transfer_status',
                filterInputType: 'SELECT',
                render: (text, record) => <div className={classes.progress}>
                  <CircularProgressbar
                      className={`${classes.progressBar}`}
                      style={{...classes.trail, ...classes.path}}
                      value={this.getCircularValue(text)}
                      text={this.getCircularText(text) + '%'}
                      styles={buildStyles({
                        textColor: '#000',
                        textSize: '20px',
                        transformOrigin: 'left center',
                        trail: {
                          stroke: '#d6d6d6',
                          strokeLinecap: 'butt',
                          transform: 'rotate(0.25turn)',
                          transformOrigin: 'left center'
                        },
                        pathColor:
                            text === 'In Process'
                                ? this.green
                                : text === 'Completed'
                                    ? this.green
                                    : text === 'Acknowledged'
                                        ? this.green
                                        : text === 'Funds Received'
                                            ? this.green
                                            : text === 'Not Proceeding'
                                                ? this.red
                                                : text === 'Processing'
                                                    ? this.green
                                                    : this.orange
                      })}
                  ></CircularProgressbar>
                  <span>
                    &nbsp; &nbsp;
                    {text}
                  </span>
                </div>
              })
            },
            {
              title: "Actions",
              dataIndex: "actions"
            }
          ]}
        />
        <Modal title={'Email Payout Confirmation'}
               visible={this.state.isModalOpen}
               onOk={() => this.sendReceiptForm.current.submit()}
               onCancel={() => {
                  this.sendReceiptForm.current.resetFields();
                  this.setState({isModalOpen: false, modalTransferId: null});
               }}>
          <Form name={'sendReceiptForm'} ref={this.sendReceiptForm} onFinish={this.emailPayoutConfirmation}>
              <Form.Item label={'Enter Email'} name={'email'} rules={[{required: true, message: 'Email is required'}, {type: 'email', message: 'Must be a valid email'}]}>
                  <Input />
              </Form.Item>
          </Form>
        </Modal>
      </React.Fragment>
    );
  }
}

// export default withRouter(withStyles(styles)(TransferList));

const mapStateToProps = state => {
  return {
    app_state: state.app_state,
    transfers: state.transfers,
    countries: state.countries,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    fetchTransferList: () => {
      dispatch(fetchTransferList());
    },
    fetchTransferListV2: (clientId) => {
      dispatch(fetchTransferListV2(clientId));
    },
    setTransferSelectId: id => {
      dispatch(setTransferSelectId(id));
    },
    fetchCountryNotDeleted: () => {
      dispatch(fetchCountriesNotDeletedList());
    }
  };
};

const TransferListContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withStyles(styles)(ListTable(TransferList))));

export default TransferListContainer;
