import {FormattedNumber} from "react-intl";
import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";

const style = theme => ({
    currency: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: 'fit-content',
        '& > .currency-flag': {
            marginRight: '0.5rem', width: 52, height: 26
        }
    }, currencyLabel: {
        textAlign: 'left', '& > p': {
            marginBottom: -5, fontSize: '0.6rem'
        }
    }
});
const CurrencyElement = ({classes, currency, value}) => {
    return <div className={classes.currency}>
        <div
            className={`currency-flag currency-flag-${currency ? currency.toLowerCase() : ''}`}
        />
        <div className={classes.currencyLabel}>
            <p>{currency}</p>
            <FormattedNumber
                minimumFractionDigits={2}
                maximumFractionDigits={2}
                value={value}
            />
        </div>
    </div>
};

export default (withStyles(style)(CurrencyElement));