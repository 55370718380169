import React, { useEffect, useState } from "react";
import { Button, Card, Col, Divider, Form, Modal, Radio, Row, Select, Table, Typography } from 'antd';
import { connect } from "react-redux";
import Title from "antd/es/typography/Title";
import FxPieChart, { FxPieDefaultProps } from "../../Charts/FxPieChart";
import { API } from "aws-amplify";
import TypographyCurrency from "../../../components/CurrencySelect/TypographyCurrency";
import { useForm } from "antd/es/form/Form";
import dayjs from "dayjs";
import { useHistory } from "react-router-dom";
import { ExposureMonitorUtils, ExposureMonitorUtils as exposureMonitorUtils } from "./exposureMonitorUtils";
import FileUploader from "../../../components/FileUploader/FileUploader";
import ListTable from "../../../components/ListTable/ListTable";
import { FormattedNumber } from "react-intl";
import withStyles from "@material-ui/core/styles/withStyles";
import { CurrencyLocales } from "../../../AppUtil";


const style = theme => ({
  chartistTooltip: {
    position: 'absolute',
    display: 'none',
    padding: '5px',
    background: 'rgba(0, 0, 0, 0.8)',
    color: 'white',
    borderRadius: '3px',
    pointerEvents: 'none',
    zIndex: '100',
  }, currency: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: 'fit-content',
    '& > .currency-flag': {
      marginRight: '0.5rem', width: 52, height: 26,
    },
  }, currencyLabel: {
    textAlign: 'left', '& > p': {
      marginBottom: -5, fontSize: '0.6rem',
    },
  }
})

export const CurrencyElement = ({classes, currency, value}) => (<div className={classes.currency}>
  <div
    className={`currency-flag currency-flag-${currency ? currency.toLowerCase() : ''}`}
  />
  <div className={classes.currencyLabel}>
    <p>{currency}</p>
    <FormattedNumber
      minimumFractionDigits={2}
      maximumFractionDigits={2}
      value={value}
    />
  </div>
</div>);

const ExposureMonitorActual = ({getColumnSearchProps, app_state, classes}) => {

  const [exposureSummaryForm] = useForm();
  const history = useHistory();

  const [componentState, setComponentState] = useState({
    data: [],
    dataState: 'ACTUAL',
    currencyList: [],
    currency_one: 5,
    currency_two: 9,
    currency_three: 8,
    beneficiaryList: [],
    account_list: [],
    currencyBeneficiaryList: [],
    transferType: 'PAYOUTS'
  });

  const initPrefs = () => {
    if (!app_state.current_client) return true;
    API.post("commons", "/fetch", {
      body: {
        context: 'beneficiaries', fields: ['*'], condition: {client_id: app_state.current_client.id, deleted: 0}
      }
    }).then(res => {
      const data = res.filter(i => i.account_currency === componentState.fxCurrency);
      setComponentState(prev => ({...prev, beneficiaryList: res, currencyBeneficiaryList: data}));
    }).catch(err => {
      console.log("Unable to fetch beneficiaries", err);
    });
  }


  const prepareMappedData = (datum) => {
    let data = [];
    if (datum.payouts && datum.payouts.length > 0) {
      const d = datum.payouts.map(item => ({
        id: item.id,
        bid: item.beneficiary_id,
        identifier: 'cashplan_records',
        type: 'PAYOUTS',
        amount_from: null,
        amount: item.payout,
        currencyID: item.currencyID,
        date: item.scheduled,
        rate: null,
        draft: item.draft || false,
        attachments: (!(item.attachments == null || item.attachments === "")) ? JSON.parse(item.attachments) : []
      }));
      data = [...data, ...d];
    }
    if (datum.transfers && datum.transfers.length > 0) {
      const d = datum.transfers.map(item => ({
        id: item.id,
        identifier: 'transfers',
        bid: item.beneficiary_id,
        type: 'TRANSFERS',
        amount: item.amount_to,
        amount_from: item.amount_from,
        currencyID: item.currency_to_id,
        currencyFromId: item.currency_from_id,
        date: item.settlement_date,
        rate: item.client_rate,
        draft: item.draft || false,
        attachments: (!(item.attachments == null || item.attachments === "")) ? JSON.parse(item.attachments) : []
      }));
      data = [...data, ...d];
    }
    if (datum.forwards && datum.forwards.length > 0) {
      const d = datum.forwards.map(item => ({
        id: item.id,
        identifier: 'forwards',
        bid: null,
        type: 'FORWARDS',
        amount: item.amount_to,
        amount_from: item.amount_from,
        currencyID: item.currency_to_id,
        currencyFromId: item.currency_from_id,
        date: item.settlement_date,
        rate: item.client_rate,
        draft: false,
        attachments: (!(item.attachments == null || item.attachments === "")) ? JSON.parse(item.attachments) : []
      }));
      data = [...data, ...d];
    }
    if (datum.draftForwards && datum.draftForwards.length > 0) {
      const d = datum.draftForwards.map(item => ({
        id: item.id,
        identifier: 'forwards',
        bid: null,
        type: 'FORWARDS',
        amount: item.amountTo,
        amount_from: null,
        currencyID: item.currencyToID,
        currencyFromId: item.currencyFromID,
        date: item.settlementDate,
        rate: null,
        draft: true
      }));
      data = [...data, ...d];
    }

    data = data.filter(item => !!dayjs(item.date).isAfter(dayjs()));

    return data;
  }

  const initData = () => {
    if (!app_state.current_client) return true;
    Promise.all([API.post("commons", "/fetch", {
      body: {
        context: 'cashplan_records',
        fields: ['*'],
        condition: {deleted: 0, receipt: null, clientID: app_state.current_client.id}
      }
    }), API.post("commons", "/fetch", {
      body: {
        context: 'forwards', fields: ['*'], condition: {
          deleted: false, client_id: app_state.current_client.id
        }
      }
    }), API.post("commons", "/fetch", {
      body: {
        context: 'draft_forward', fields: ['*'], condition: {
          deleted: false, clientID: app_state.current_client.id
        }
      }
    }), API.post("commons", "/fetch", {
      body: {
        context: 'transfers', fields: ['*'], condition: {
          deleted: false, client_id: app_state.current_client.id
        }
      }
    })]).then(([payouts, forwards, draftForwards, transfers]) => prepareMappedData({
      payouts,
      forwards,
      draftForwards,
      transfers
    })).then(res => {
      setComponentState(prev => ({
        ...prev,
        data: res,
      }));
    });
  }


  const buildData = (currencyId) => {
    let data = componentState.data.filter(item => item.currencyID === currencyId && !!dayjs(item.date).isAfter(dayjs()));
    if (data.length > 0) {

      data = data.filter(item => !item.draft);

      if (data.length === 0) {
        return {total: 0, data: [], descriptions: [], overCovered: <></>}
      }
      let covered = data.filter(item => item.type !== 'PAYOUTS').reduce((acc, item) => acc + item.amount, 0);


      const payout = data.filter(item => item.type === 'PAYOUTS').reduce((acc, item) => acc + item.amount, 0);
      let uncovered = payout - covered;
      const total = uncovered + covered;

      let balance = 0;
      if (componentState.account_list.length > 0) {
        const acc = componentState.account_list.find(i => i.currencies_short_name === getCurrency(currencyId));
        covered = acc ? (acc.balance + covered) : covered;
        balance = acc ? acc.balance : 0;
        uncovered = uncovered - (acc ? acc.balance : 0)
      }

      const pie = [
        {label: 'Uncovered', value: uncovered, color: '#f4c63d'},
        {label: 'Covered', value: covered, color: '#00bcd4'},
      ];

      const descriptions = ExposureMonitorUtils.prepareDescriptions(data, 'ACTUAL', renderValue, balance, componentState);

      const holding_account = componentState.account_list.find(i => i.currencyId === currencyId);
      console.log('currencyId', currencyId)
      console.log('currency:', getCurrency(currencyId))
      const payoutAmount = new Intl.NumberFormat(CurrencyLocales[getCurrency(currencyId)], {
        currency: getCurrency(currencyId),
        style: 'currency',
        useGrouping: true,
        currencyDisplay: 'narrowSymbol',
        maximumFractionDigits: 2,
        minimumFractionDigits: 2
      }).format(payout);
      const coveredAmount = new Intl.NumberFormat(CurrencyLocales[getCurrency(currencyId)], {
        currency: getCurrency(currencyId),
        style: 'currency',
        useGrouping: true,
        currencyDisplay: 'narrowSymbol',
        maximumFractionDigits: 2,
        minimumFractionDigits: 2
      }).format(covered);
      const overallAmount = new Intl.NumberFormat(CurrencyLocales[getCurrency(currencyId)], {
        currency: getCurrency(currencyId),
        style: 'currency',
        useGrouping: true,
        currencyDisplay: 'narrowSymbol',
        maximumFractionDigits: 2,
        minimumFractionDigits: 2
      }).format(0 - uncovered);

      const overCovered = uncovered < 0 ? <>
        <strong>Your coverage is in excess of payments entered.</strong><br /><br />
        <FxPieChart data={[{
          label: 'Overcovered',
          value: Math.abs(uncovered),
          percentage: 100,
          color: '#00bcd4',
          decimalPlaces: 0
        }]}
                    isCurrency={true}></FxPieChart>
        <div style={{display: 'flex', flexDirection: 'column'}}>
          <Typography.Text>Your Cover is in excess of payments recorded.</Typography.Text>
          <Typography.Text>Your payments entered are {payoutAmount}</Typography.Text>
          <Typography.Text>Your coverage is {coveredAmount}</Typography.Text>
          <Typography.Text>This means you are over covered by {overallAmount}.</Typography.Text>
        </div>
      </> : null

      const rawData = {
        payments: payout,
        covered,
        balance,
        uncovered
      }

      return {
        descriptions: descriptions,
        total: renderValue(total),
        data: pie,
        overCovered,
        rawData
      };
    }
    return {total: 0, data: [], descriptions: [], overCovered: <></>}
  }

  const fetchCurrencies = () => {
    API.post("commons", "/fetch", {
      body: {
        context: 'currencies',
        fields: ['*'],
        condition: {deleted: false}
      }
    }).then(res => {
      setComponentState(prev => ({...prev, currencyList: res}));
    })
  }

  const onCurrencyChange = (key, val) => {
    setComponentState(prev => ({...prev, [key]: val}));
  }

  const renderValue = (value) => {
    return new Intl.NumberFormat('en-GB', {
      currency: 'AUD',
      style: 'currency',
      useGrouping: true,
      currencyDisplay: 'narrowSymbol',
      maximumFractionDigits: 2,
      minimumFractionDigits: 2
    }).format(value);
  }

  const getCurrency = (currencyId) => {
    const currency = componentState.currencyList.find(item => item.id === currencyId);
    if (currency != null) {
      return currency.iso_alpha_3;
    }
  }

  const buildColumns = () => {
    return exposureMonitorUtils.buildPayoutColumns(getColumnSearchProps, componentState, app_state, null, classes, initData, null, onViewDocumentsBtnClick, history);
  }

  const onViewDocumentsBtnClick = (attachments) => {
    Modal.confirm({
      title: 'Attachments',
      width: 700,
      content: <Row gutter={[16, 16]}>
        <Divider />
        {attachments.map(i => <>
          <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
            <FileUploader previewOnly={true}
                          thumbType={'picture-card'}
                          uploaderType={'PDF'}
                          fetchFileList={() => {
                            const file = {
                              name: i,
                              uid: i,
                              status: 'done'
                            }
                            return Promise.resolve([file]);
                          }}></FileUploader>
          </Col>
        </>)}
      </Row>,
      cancelButtonProps: {hidden: true},
      maskClosable: true
    })
  }

  const buildPayoutData = () => {
    return componentState.data.filter(i => i.type === 'PAYOUTS' && !i.draft);
  }

  const fetchHoldingAccountBalance = async () => {
    if (!app_state.current_client) return []
    const currentClient = app_state.current_client.id
    const response = await API.get('holding_accounts', `/get/all/v2/${currentClient}`, {});
    const account_list = response.holding_account_list.filter(i => i.balance > 0).map(record => ({
      currencies_short_name: record.currencies_short_name,
      balance: record.balance,
      currencyId: record.currency_id,
      url: `/holding-account?account=${record.id}`
    }));
    setComponentState(prev => ({...prev, account_list: account_list}));
  }

  const buildForwardColumns = () => {
    return exposureMonitorUtils.buildCoveredColumns(getColumnSearchProps, componentState, app_state, null, classes, initData, null, onViewDocumentsBtnClick, history);
  }

  const buildForwardData = () => {
    return componentState.data.filter(i => i.type === 'FORWARDS' && !i.draft);
  }

  const renderNoData = (currency) => {
    const holding_account = componentState.account_list.find(i => i.currencyId === currency);
    if (holding_account) {
      const amount = new Intl.NumberFormat(CurrencyLocales[holding_account.currencies_short_name], {
        currency: holding_account.currencies_short_name,
        style: 'currency',
        useGrouping: true,
        currencyDisplay: 'narrowSymbol',
        maximumFractionDigits: 2,
        minimumFractionDigits: 2
      }).format(holding_account.balance);
      return <div style={{display: 'flex', flexDirection: 'column'}}>

        <FxPieChart
          data={[{
            label: 'Overcovered',
            value: Math.abs(holding_account.balance),
            percentage: 100,
            color: '#00bcd4',
            decimalPlaces: 0
          }]}
          isCurrency={true}></FxPieChart>
        <Typography.Text>Your Cover is in excess of payments recorded.</Typography.Text>
        <Typography.Text>No payments have been entered as yet.</Typography.Text>
        <Typography.Text>Your coverage is {amount}</Typography.Text>
        <Typography.Text>This means you are over covered by {amount}</Typography.Text>
      </div>
    }
    return <Typography.Text>You have not entered any payments or cover information for this currency.</Typography.Text>
  }

  useEffect(() => {
    fetchCurrencies();
  }, [])

  useEffect(() => {
    initPrefs();
    initData();
    fetchHoldingAccountBalance();
  }, [app_state.current_client, componentState.currency_one, componentState.currency_two, componentState.currency_three]);


  return <>
    <Card title={<h5>Client Overall Multi-Currency Summary</h5>} style={{marginTop: 10, marginBottom: 10}}>
      <div style={{display: 'flex', justifyContent: 'end', marginBottom: 10}}>
        <div style={{display: 'flex', justifyContent: 'end', alignItems: 'center'}}>
          <Typography.Text style={{marginBottom: '25px'}}>Ledger Balances: </Typography.Text>
          {componentState.account_list.map(i => {
            return <Form.Item style={{display: 'inline-block', marginRight: 5}}>
              <Radio.Button>
                <TypographyCurrency iso_alpha_3={i.currencies_short_name}><strong>
                  {new Intl.NumberFormat(CurrencyLocales[i.currencies_short_name], {
                    currency: i.currencies_short_name,
                    style: 'currency',
                    useGrouping: true,
                    currencyDisplay: 'narrowSymbol',
                    maximumFractionDigits: 2,
                    minimumFractionDigits: 2
                  }).format(i.balance)}
                </strong></TypographyCurrency>
              </Radio.Button>
            </Form.Item>
          })}
        </div>
      </div>
      {
        <Form
          initialValues={{
            currency_one: componentState.currency_one,
            currency_two: componentState.currency_two,
            currency_three: componentState.currency_three,
          }}
          form={exposureSummaryForm} name={'exposure-summary-form'}>
          <Row gutter={[16, 16]}>
            <Col xs={24} sm={24} md={24} lg={8} xl={8} xxl={8}>
              <Card size={'small'} style={{height: '99%'}}>
                <div style={{display: 'flex', justifyContent: 'space-between'}}>
                  <Title level={4}><TypographyCurrency
                    iso_alpha_3={getCurrency(componentState.currency_one)}>{getCurrency(componentState.currency_one)} Exposure
                    Summary</TypographyCurrency> </Title>
                  <Button type={'primary'}
                          onClick={() => history.push(`/exposure-monitor/exposure-monitor-detail-actual?currency=${componentState.currency_one}`)}>VIEW
                    DETAIL</Button>
                </div>
                <Divider />
                <Form.Item label={'Currency'} name={'currency_one'}>
                  <Select
                    onChange={val => onCurrencyChange('currency_one', val)}
                    optionFilterProp={'alt'}
                    options={componentState.currencyList.map(item => ({
                      ...item,
                      value: item.id,
                      label: <TypographyCurrency
                        iso_alpha_3={item.iso_alpha_3}>{item.full_name} [{item.iso_alpha_3}]</TypographyCurrency>,
                      alt: item.iso_alpha_3 + item.full_name
                    }))}></Select>
                </Form.Item>
                <div style={{
                  display: 'flex',
                  justifyContent: 'center',
                  flexDirection: 'column',
                  alignItems: 'center'
                }}>
                  {
                    buildData(componentState.currency_one) && buildData(componentState.currency_one).data.length > 0
                      ? <>
                        {
                          buildData(componentState.currency_one).overCovered ? <>{buildData(componentState.currency_one).overCovered}</> :
                            <><strong>Payments (yellow) / Cover (blue).</strong><br /><br />
                              <FxPieChart inputParams={{...FxPieDefaultProps.inputParams, decimalPlaces: 0}}
                                          data={buildData(componentState.currency_one).data}
                                          isCurrency={true}></FxPieChart>
                            </>
                        }
                      </>
                      : <>
                        <strong>No Payments Entered to Display</strong><br /><br />
                        <p>
                          {renderNoData(componentState.currency_one)}
                        </p>
                      </>
                  }
                  {
                    buildData(componentState.currency_one) && buildData(componentState.currency_one).data.length > 0 && !buildData(componentState.currency_one).overCovered && <>
                      {/* Somehow use "rawData" in here */}
                      {/*<div style={{ display: 'flex', flexDirection: 'column' }}>*/}
                      {/*    <Typography.Text></Typography.Text>*/}
                      {/*</div>*/}
                      <div style={{textAlign: 'left', display: 'inline-block'}}>
                        {buildData(componentState.currency_one).descriptions.map(i => (
                          <Form.Item wrapperCol={5} labelAlign={'left'}
                                     style={{marginBottom: -12, paddingBottom: 0}}
                                     label={i.label}>
                            {i.value}
                          </Form.Item>))}
                      </div>
                    </>
                  }
                </div>
              </Card>
            </Col>
            <Col xs={24} sm={24} md={24} lg={8} xl={8} xxl={8}>
              <Card size={'small'} style={{height: '99%'}}>
                <div style={{display: 'flex', justifyContent: 'space-between'}}>
                  <Title level={4}><TypographyCurrency
                    iso_alpha_3={getCurrency(componentState.currency_two)}>{getCurrency(componentState.currency_two)} Exposure
                    Summary</TypographyCurrency> </Title>
                  <Button type={'primary'}
                          onClick={() => history.push(`/exposure-monitor/exposure-monitor-detail-actual?currency=${componentState.currency_two}`)}>VIEW
                    DETAIL</Button>
                </div>
                <Divider />
                <Form.Item label={'Currency'} name={'currency_two'}>
                  <Select
                    onChange={val => onCurrencyChange('currency_two', val)}
                    optionFilterProp={'alt'}
                    options={componentState.currencyList.map(item => ({
                      ...item,
                      value: item.id,
                      label: <TypographyCurrency
                        iso_alpha_3={item.iso_alpha_3}>{item.full_name} [{item.iso_alpha_3}]</TypographyCurrency>,
                      alt: item.iso_alpha_3 + item.full_name
                    }))}></Select>
                </Form.Item>
                <div style={{
                  display: 'flex',
                  justifyContent: 'center',
                  flexDirection: 'column',
                  alignItems: 'center'
                }}>
                  {/*{JSON.stringify(buildData(componentState.currency_two))}*/}
                  {
                    buildData(componentState.currency_two) && buildData(componentState.currency_two).data.length > 0
                      ? <>
                        {
                          buildData(componentState.currency_two).overCovered ? <>{buildData(componentState.currency_two).overCovered}</> :
                            <><strong>Payments (yellow) / Cover (blue).</strong><br /><br />
                            <FxPieChart inputParams={{...FxPieDefaultProps.inputParams, decimalPlaces: 0}}
                                        data={buildData(componentState.currency_two).data}
                                        isCurrency={true}></FxPieChart>
                              </>
                        }
                      </>
                      : <>
                        <strong>No Payments Entered to Display</strong><br /><br />
                        <p>
                          {renderNoData(componentState.currency_two)}
                        </p>
                      </>
                  }
                  {
                    buildData(componentState.currency_two) && buildData(componentState.currency_two).data.length > 0 && !buildData(componentState.currency_two).overCovered && <>
                      {buildData(componentState.currency_two).descriptions.map(i => (
                        <Form.Item wrapperCol={5} labelAlign={'left'}
                                   style={{marginBottom: -12, paddingBottom: 0}}
                                   label={i.label}>
                          {i.value}
                        </Form.Item>))}
                    </>
                  }
                </div>
              </Card>
            </Col>
            <Col xs={24} sm={24} md={24} lg={8} xl={8} xxl={8}>
              <Card size={'small'} style={{height: '99%'}}>
                <div style={{display: 'flex', justifyContent: 'space-between'}}>
                  <Title level={4}><TypographyCurrency
                    iso_alpha_3={getCurrency(componentState.currency_three)}>{getCurrency(componentState.currency_three)} Exposure
                    Summary</TypographyCurrency> </Title>

                  <Button type={'primary'}
                          onClick={() => history.push(`/exposure-monitor/exposure-monitor-detail-actual?currency=${componentState.currency_three}`)}>VIEW
                    DETAIL</Button>
                </div>

                <Divider />
                <Form.Item label={'Currency'} name={'currency_three'}>
                  <Select
                    onChange={val => onCurrencyChange('currency_three', val)}
                    optionFilterProp={'alt'}
                    options={componentState.currencyList.map(item => ({
                      ...item,
                      value: item.id,
                      label: <TypographyCurrency
                        iso_alpha_3={item.iso_alpha_3}>{item.full_name} [{item.iso_alpha_3}]</TypographyCurrency>,
                      alt: item.iso_alpha_3 + item.full_name
                    }))}></Select>
                </Form.Item>
                <div style={{
                  display: 'flex',
                  justifyContent: 'center',
                  flexDirection: 'column',
                  alignItems: 'center'
                }}>
                  {
                    buildData(componentState.currency_three) && buildData(componentState.currency_three).data.length > 0
                      ? <>
                        {
                          buildData(componentState.currency_three).overCovered ? <>{buildData(componentState.currency_three).overCovered}</> :
                            <><strong>Payments (yellow) / Cover (blue).</strong><br /><br />
                            <FxPieChart inputParams={{...FxPieDefaultProps.inputParams, decimalPlaces: 0}}
                                        data={buildData(componentState.currency_three).data}
                                        isCurrency={true}></FxPieChart>
                              </>
                        }
                      </>
                      : <>
                        <strong>No Payments Entered to Display</strong><br /><br />
                        {renderNoData(componentState.currency_three)}
                      </>
                  }
                  {
                    buildData(componentState.currency_three) && buildData(componentState.currency_three).data.length > 0 && !buildData(componentState.currency_three).overCovered && <>
                      {buildData(componentState.currency_three).descriptions.map(i => (
                        <Form.Item wrapperCol={5} labelAlign={'left'}
                                   style={{marginBottom: -12, paddingBottom: 0}}
                                   label={i.label}>
                          {i.value}
                        </Form.Item>))}
                    </>
                  }
                </div>
              </Card>
            </Col>
          </Row>
        </Form>
      }
    </Card>
    {/*<Card size={'small'} title={<h5>Combined Payments and Cover (Actual)</h5>}*/}
    {/*      bodyStyle={{backgroundColor: '#efefef', border: "1px solid #d9d9d9"}}>*/}

    {/*    <Card style={{ marginBottom: 10 }} size={'small'} title={'Scheduled Payments'}>*/}
    {/*        <Table rowKey={'id'}*/}
    {/*               key={app_state.current_client ? app_state.current_client.id : 0}*/}
    {/*               columns={buildColumns()}*/}
    {/*               size="small"*/}
    {/*               dataSource={buildPayoutData()}*/}
    {/*               loading={componentState.isPayoutListLoading}></Table>*/}
    {/*    </Card>*/}

    {/*    <Card size={'small'} title={'Coverage (Transfers & Forwards)'}>*/}
    {/*        <Table rowKey={'id'}*/}
    {/*               key={app_state.current_client ? app_state.current_client.id : 0}*/}
    {/*               columns={buildForwardColumns()}*/}
    {/*               size="small"*/}
    {/*               dataSource={buildForwardData()}*/}
    {/*               loading={componentState.isPayoutListLoading}></Table>*/}
    {/*    </Card>*/}
    {/*</Card>*/}
  </>
}

const mapStateToProps = (state) => ({
  app_state: state.app_state
});

const mapDispatchToProps = (dispatch) => ({});
export default connect(mapStateToProps, mapDispatchToProps)(withStyles(style)(ListTable(ExposureMonitorActual)));