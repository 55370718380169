import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import ChartistGraph from "react-chartist";
import Chartist from 'chartist';

/**
 *
 * @param chartData = [{
 *          label: string,
 *          value: number,
 *          percentage: number,
 *          toolTip: string,
 *          hoverColor: HEX String,
 *          backgroundColor: HEX String
 *   }]
 *
 *   @param chartOptions = {
 *       donut: boolean,
 *       donutWidth: number,
 *       donutSolid: boolean,
 *       startAngle: number,
 *       showLabel: boolean,
 *       total: number
 *   }
 * Total should be 100
 */

const data = {
    labels: ['A', 'B', 'C', 'D'],
    series: [20, 10, 30, 40]
};

const options = {
    width: 120,
    donut: true,
    donutWidth: 15,
    donutSolid: true,
    startAngle: 270,
    showLabel: true,
    total: 200,
};

const inputParams = {
    backgroundColor: '#000',
}
const defaultColors = ['#00bcd4', '#f4c63d']

const FxDonut = ({
                     app_state,
                     chartData = [],
                     chartOptions = options,
                     id = 'donut-1',
                     params = {...inputParams, params}
                 }) => {


    const [componentState, setComponentState] = useState({
        dData: {
            labels: [],
            series: []
        }
    });

    const events = () => {
        return {
            draw: (data) => {
                if (data.type === 'label') {
                    data.element['_node'].style.fontWeight = 'bold';
                    // data.element['_node'].style.fill = '#fff';
                }
                if (data.type === 'slice') {
                    data.element['_node'].style.fontWeight = 'bold';
                    data.element['_node'].style.stroke = chartData && chartData[data.index] && chartData[data.index].backgroundColor ? chartData[data.index].backgroundColor : defaultColors[data.index];
                    data.element.animate({
                        opacity: {
                            begin: data.index * 200,
                            dur: 1000,
                            from: 0,
                            to: 1,
                        },
                        transform: {
                            begin: data.index * 200,
                            dur: 1000,
                            from: "scale(0)",
                            to: "scale(1)",
                            easing: Chartist.Svg.Easing.easeOutElastic,
                        },
                    });
                }
            }
        }
    };

    const prepareChart = () => {
        if (chartData != null) {
            let labels = chartData.map(i => i.label);
            const series = chartData.map(i => i.percentage);

            const backgroundColor = chartData.map(i => i.backgroundColor);
            const hoverBackgroundColor = chartData.map(i => i.hoverColor);

            setComponentState(prev => ({
                ...prev, dData: {
                    labels: labels.map(item => ' '),
                    series: series
                }
            }));
        }
    }


    useEffect(() => {
        if (app_state.current_client && chartData.length > 0) {
            prepareChart();
        }
    }, [data, app_state.current_client, chartData, id]);

    return <ChartistGraph className={id} data={componentState.dData} options={chartOptions} type={'Pie'} listener={events()}/>

}


const mapStateToProps = state => ({
    app_state: state.app_state
});
const mapDispatchToProps = state => ({});

export default connect(mapStateToProps, mapDispatchToProps)(FxDonut);