import React, { createRef } from 'react'
import { connect } from 'react-redux'

import Datetime from 'react-datetime'
// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles'
// import InputLabel from "@material-ui/core/InputLabel";
// @material-ui/icons
import PermIdentity from '@material-ui/icons/PermIdentity'

// core components
import GridContainer from 'components/Grid/GridContainer.jsx'
import GridItem from 'components/Grid/GridItem.jsx'
import Button from 'components/CustomButtons/Button.jsx'
import CustomInput from 'components/CustomInput/CustomInput.jsx'
import Clearfix from 'components/Clearfix/Clearfix.jsx'
import Card from 'components/Card/Card.jsx'
import CardBody from 'components/Card/CardBody.jsx'
import CardHeader from 'components/Card/CardHeader.jsx'
import CardIcon from 'components/Card/CardIcon.jsx'
import CardAvatar from 'components/Card/CardAvatar.jsx'
import Table from 'components/Table/Table.jsx'
import { Checkbox, Form, Input, Modal, Switch, Table as AntTable } from 'antd'
import { Card as AntCard } from 'antd'
import { Button as AntButton } from 'antd'
import NavPills from 'components/NavPills/NavPills.jsx'

import { API, Auth } from 'aws-amplify'

// import userProfileStyles from "./UserProfileStyles";
import avatar from '../../assets/img/faces/blank-profile-picture.jpg'
// import PictureUpload from "../../components/CustomUpload/PictureUpload";
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
// import Select from "@material-ui/core/Select";
// import MenuItem from "@material-ui/core/MenuItem";
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import ChangeEmailDialog from './UserProfile/ChangeEmailDialog.jsx'
// import { Grid } from "@material-ui/core";
// import ReactTable from "react-table";
// import {matchSorter} from "match-sorter";
// import { isNull } from "util";
// For CustomReactSelect
import CustomReactSelect from 'components/Forms/CustomReactSelect/CustomReactSelect.jsx'

//core for react-intl
import {FormattedMessage, injectIntl} from 'react-intl'

// import SnackbarContent from "../../components/Snackbar/SnackbarContent";
// @material-ui/core components
// import { makeStyles } from "@material-ui/core/styles";
import Radio from '@material-ui/core/Radio'
import FormControlLabel from '@material-ui/core/FormControlLabel'
// @material-ui/icons
import FiberManualRecord from '@material-ui/icons/FiberManualRecord'
import { cardTitle } from '../../assets/jss/material-dashboard-pro-react.jsx'
import customCheckboxRadioSwitch from 'assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch.jsx'
import { onInAppLoginSuccesClientId } from '../../redux/actions'
import { message } from 'antd'
import ListTable from '../../components/ListTable/ListTable'
import { EditOutlined, PlusOutlined, ReloadOutlined } from '@ant-design/icons'
import { AppUtil } from '../../AppUtil'

var moment = require('moment')

require('react-datetime')

const userProfileStyles = {
    ...customCheckboxRadioSwitch,

    cardTitle,
    cardIconTitle: {
        ...cardTitle,
        marginTop: '15px',
        marginBottom: '0px',
        '& small': {
            fontSize: '80%',
            fontWeight: '400',
        },
    },
    // description: {
    //   color: "#999999"
    // },
    updateProfileButton: {
        float: 'right',
    },
}

class UserProfile extends React.Component {

    loginsModal = createRef();
    passwordModal = createRef();
    constructor(props) {
        super(props)
        this.state = {
            update_loading: false,
            user: null,
            aws_cognito_id: '',
            account_type: '',
            email: '',
            title: '',
            first_name: '',
            middle_name: '',
            last_name: '',
            change_password_dialog: false,
            old_password: '',
            new_password: '',
            new_password_confirm: '',
            new_password_state: '',
            new_password_confirm_state: '',
            new_password_changing: false,
            change_email_dialog: false,

            language_id_list: [],
            country_list: [],
            country_list_prio: [],
            client_todo_list: [],

            current_client_id: '',

            date_of_birth: '',
            place_of_birth_city: '',
            place_of_birth_country: '',
            nationality: '',
            marital_status: '',
            language_id: '',

            // need_dob: false,
            // need_address: false,
            // need_telephone: false

            selectedValue: 'customer_type_personal',
            selectedBusinessValue: 'customer_type_soleTrader',
            business_trading_name: '',
            business_company_name: '',
            business_company_number: '',
            business_trust_name: '',
            business_abn: '',
            account_business_type: 1,
            external_reference: '',
            userLogins: [],
            group_list: [],
            isLoginModalOpen: false,
            activeLoginsId: null
        }
    }

    async componentDidMount() {
        // console.log("componentDidMount");

        if (this.props.isAuthenticated && this.props.app_state.current_client) {
            // console.log("authenticated & current_user so getting details");
            try {
                const user = await this.getUserProfile(
                    // this.props.current_user.aws_cognito_id
                    this.props.app_state.current_client.aws_cogFnito_id
                )
                // console.log(user);
                const {
                    aws_cognito_id,
                    account_type,
                    email,
                    title,
                    first_name,
                    middle_name,
                    last_name,
                    external_reference,
                    residential_street_line_1,
                    residential_street_line_2,
                    residential_street_suburb,
                    residential_street_state,
                    residential_street_postcode,
                    residential_street_country,
                    postal_street_line_1,
                    postal_street_line_2,
                    postal_street_suburb,
                    postal_street_state,
                    postal_street_postcode,
                    postal_street_country,
                    telephone_home,
                    telephone_mobile,
                    telephone_work,
                    email_secondary,
                    account_status_notes,
                    date_of_birth,
                    place_of_birth_city,
                    place_of_birth_country,
                    nationality,
                    marital_status,
                    language_id,
                    account_business_type,
                    business_trading_name,
                    business_company_name,
                    business_company_number,
                    business_trust_name,
                    business_abn,
                } = user

                this.setState({
                    aws_cognito_id,
                    account_type,
                    email,
                    title,
                    first_name,
                    middle_name,
                    last_name,
                    external_reference,
                    residential_street_line_1,
                    residential_street_line_2,
                    residential_street_suburb,
                    residential_street_state,
                    residential_street_postcode,
                    residential_street_country,
                    postal_street_line_1,
                    postal_street_line_2,
                    postal_street_suburb,
                    postal_street_state,
                    postal_street_postcode,
                    postal_street_country,
                    telephone_home,
                    telephone_mobile,
                    telephone_work,
                    email_secondary,
                    account_status_notes,
                    date_of_birth,
                    place_of_birth_city,
                    place_of_birth_country,
                    nationality,
                    marital_status,
                    language_id,
                    account_business_type,
                    business_trading_name,
                    business_company_name,
                    business_company_number,
                    business_trust_name,
                    business_abn,
                });

                switch (account_business_type) {
                    case 1:
                        this.setState({
                            selectedBusinessValue: 'customer_type_soleTrader',
                        })
                        break
                    case 2:
                        this.setState({
                            selectedBusinessValue: 'customer_type_company',
                        })
                        break
                    case 3:
                        this.setState({
                            selectedBusinessValue: 'customer_type_trust',
                        })
                        break
                }
                //Disable console log #612 by Tom
                //console.log(account_business_type);
            } catch (e) {
                console.error(e)
            }
            API.get("usersNew", `/users/logins/${this.props.app_state.current_client.id}`, {})
                .then(res => this.setState({ userLogins: res }));
        }

        API.get('countries', `/countries/list_not_deleted`)
            .then(response => {
                // console.log(response);
                this.setState({
                    // country_list: response.fullList,
                    country_list_prio: response,
                })
            })
            .catch(error => {
                console.log(error)
            })
        API.get('admin', `/get_laguages`)
            .then(response => {
                // console.log(response);
                this.setState({
                    language_id_list: response,
                })
            })
            .catch(error => {
                console.log(error)
            })
        API.get('groups', `/get/all`)
            .then(response => {
                // console.log(response);
                let group_list = []
                for (var i in response.group_list) {
                    group_list.push(response.group_list[i].nickname)
                }
                this.setState({
                    group_list,
                })
            })
            .catch(error => {
                console.log(error)
            });
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (
            prevProps.app_state.current_client !==
            this.props.app_state.current_client
        ) {
            this.fetchUserLogins();
        }
    }

    fetchUserLogins = () => {
        if (this.props.app_state.current_client != null && this.props.app_state.current_client.id != null) {
            API.post("commons", "/fetch", {
                body: {
                    context: 'client_contacts',
                    fields: ['*'],
                    condition: {clientId: this.props.app_state.current_client.id, deleted: 0}
                }
            }).then(res => {
                this.setState({
                    userLogins: res.map(i => ({
                        ...i,
                        clientID: this.props.app_state.current_client.id,
                        userClientId: i.id,
                        aid: i.aws_cognito_id,
                        name: `${i.firstName} ${i.lastName}`,
                        email: i.email,
                    }))
                })
            });
        }
    }

    async componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps.isAuthenticated && nextProps.app_state.current_client) {
            try {
                const user = await this.getUserProfile(
                    // nextProps.current_user.aws_cognito_id
                    nextProps.app_state.current_client.aws_cognito_id
                )
                const {
                    aws_cognito_id,
                    account_type,
                    email,
                    title,
                    first_name,
                    middle_name,
                    last_name,
                    external_reference,
                    residential_street_line_1,
                    residential_street_line_2,
                    residential_street_suburb,
                    residential_street_state,
                    residential_street_postcode,
                    residential_street_country,
                    postal_street_line_1,
                    postal_street_line_2,
                    postal_street_suburb,
                    postal_street_state,
                    postal_street_postcode,
                    postal_street_country,
                    telephone_home,
                    telephone_mobile,
                    telephone_work,
                    email_secondary,
                    account_status_notes,
                    date_of_birth,
                    place_of_birth_city,
                    place_of_birth_country,
                    nationality,
                    marital_status,
                    language_id,
                    account_business_type,
                    business_trading_name,
                    business_company_name,
                    business_company_number,
                    business_trust_name,
                    business_abn,
                } = user

                if (this.is_empty(date_of_birth)) {
                    this.setState({
                        need_dob: true,
                    })
                }

                if (
                    this.is_empty(telephone_home) &&
                    this.is_empty(telephone_mobile) &&
                    this.is_empty(telephone_work)
                ) {
                    this.setState({
                        need_telephone: true,
                    })
                }

                if (this.is_empty(residential_street_line_1)) {
                    this.setState({
                        need_address: true,
                    })
                }

                this.setState({
                    aws_cognito_id,
                    account_type,
                    email,
                    title,
                    first_name,
                    middle_name,
                    last_name,
                    external_reference,
                    residential_street_line_1,
                    residential_street_line_2,
                    residential_street_suburb,
                    residential_street_state,
                    residential_street_postcode,
                    residential_street_country,
                    postal_street_line_1,
                    postal_street_line_2,
                    postal_street_suburb,
                    postal_street_state,
                    postal_street_postcode,
                    postal_street_country,
                    telephone_home,
                    telephone_mobile,
                    telephone_work,
                    email_secondary,
                    account_status_notes,
                    date_of_birth,
                    place_of_birth_city,
                    place_of_birth_country,
                    nationality,
                    marital_status,
                    language_id,
                    account_business_type,
                    business_trading_name,
                    business_company_name,
                    business_company_number,
                    business_trust_name,
                    business_abn,
                })

                switch (account_business_type) {
                    case 1:
                        this.setState({
                            selectedBusinessValue: 'customer_type_soleTrader',
                        })
                        break
                    case 2:
                        this.setState({
                            selectedBusinessValue: 'customer_type_company',
                        })
                        break
                    case 3:
                        this.setState({
                            selectedBusinessValue: 'customer_type_trust',
                        })
                        break
                }

                if (user.account_status === 1) {
                    this.setState({
                        account_status_notes: (
                            <React.Fragment>
                                <strong>
                                    You will be contacted shortly to confirm
                                    account activation.
                                    <br />
                                    <br />
                                    If you have any queries in the meanwhile,
                                    please{' '}
                                    <a href={'mailto:admin@forexworldwide.com'}>
                                        contact us
                                    </a>{' '}
                                    via email.
                                </strong>
                            </React.Fragment>
                        ),
                    })
                }

                API.get('groups', `/get/all`)
                    .then(response => {
                        // console.log(response);
                        let group_list = []
                        for (var i in response.group_list) {
                            group_list.push(response.group_list[i].nickname)
                        }
                        this.setState({
                            group_list,
                        })
                    })
                    .catch(error => {
                        console.log(error)
                    })
            } catch (e) {
                console.error(e)
            }
        }
    }

    async getUserProfile(aws_cognito_id) {
        return API.get('portal', `/users/get-by-id/${aws_cognito_id}`)
    }

    async saveUserProfile(user) {
        // console.log(user);
        return API.put('usersNew', `/users/update/${this.state.aws_cognito_id}`, {
            body: user,
        })
    }

    compare(string1, string2) {
        if (string1 === string2) {
            return true
        }
        return false
    }

    is_empty(x) {
        return (
            typeof x == 'undefined' ||
            x == null ||
            x == false || //same as: !x
            x.length == 0 ||
            x == '' ||
            x.replace(/\s/g, '') == '' ||
            !/[^\s]/.test(x) ||
            /^\s*$/.test(x)
        )
    }

    change(event, stateName, type, stateNameEqualTo) {
        switch (type) {
            case 'email':
                if (this.verifyEmail(event.target.value)) {
                    this.setState({ [stateName + '_state']: 'success' })
                } else {
                    this.setState({ [stateName + '_state']: 'error' })
                }
                break
            case 'length':
                if (this.verifyLength(event.target.value, stateNameEqualTo)) {
                    this.setState({ [stateName + '_state']: 'success' })
                } else {
                    this.setState({ [stateName + '_state']: 'error' })
                }
                break
            case 'password':
                if (this.verifyLength(event.target.value, 1)) {
                    this.setState({ [stateName + '_state']: 'success' })
                } else {
                    this.setState({ [stateName + ' _state']: 'error' })
                }
                break
            case 'equalTo':
                if (
                    this.compare(
                        event.target.value,
                        this.state[stateNameEqualTo]
                    )
                ) {
                    this.setState({ [stateName + '_state']: 'success' })
                } else {
                    this.setState({ [stateName + '_state']: 'error' })
                }
                break
            default:
                break
        }
        this.setState({ [stateName]: event.target.value })
        // this.props.updateNewClientCreation(stateName, event.target.value)
    }

    //function for date picker
    displayYearMonthDate(state) {
        // eslint-disable-next-line react/prop-types
        const { classes } = this.props
        if (state.date_of_birth === null && state.date_of_birth !== undefined) {
            return (
                <React.Fragment>
                    <InputLabel
                        className={classes.label}
                        error={this.state.date_of_birth_state === 'error'}
                    >
                        <FormattedMessage
                            id="staffProfile.cardItem2.dateOfBirth"
                            defaultMessage={`Date of birth`}
                        />
                    </InputLabel>
                    <FormControl
                        fullWidth
                        className={classes.selectFormControl}
                    >
                        <Datetime
                            timeFormat={false}
                            dateFormat={'DD/MM/YYYY'}
                            closeOnSelect={true}
                            value={''}
                            //state.date_of_birth
                            // The following method which can hide data doesn't work for the first date pick
                            // dateFormat={!this.state.document_expiry_init_for_category_change && 'DD/MM/YYYY'}
                            // dateFormat={(this.props.identification.new_identification_record_data && this.props.identification.new_identification_record_data.document_expiry === "") ? false : 'DD/MM/YYYY'}
                            onChange={event => {
                                this.handleDateOfBirthChange(event)
                            }}
                        />
                    </FormControl>
                    {this.state.date_of_birth_state === 'error' ? (
                        <span>
                            <small style={{ color: 'red' }}>
                                Format: DD/MM/YYYY
                            </small>
                        </span>
                    ) : (
                        <span>
                            <small>Format: DD/MM/YYYY</small>
                        </span>
                    )}
                </React.Fragment>
            )
        }
        if (state.date_of_birth !== null && state.date_of_birth !== undefined) {
            //let birthday = state.date_of_birth.split("T",2)[0];
            return (
                <React.Fragment>
                    <InputLabel
                        className={classes.label}
                        error={this.state.date_of_birth_state === 'error'}
                    >
                        <FormattedMessage
                            id="staffProfile.cardItem2.dateOfBirth"
                            defaultMessage={`Date of birth`}
                        />
                    </InputLabel>
                    <FormControl
                        fullWidth
                        className={classes.selectFormControl}
                    >
                        <Datetime
                            timeFormat={false}
                            dateFormat={'DD/MM/YYYY'}
                            closeOnSelect={true}
                            value={moment(state.date_of_birth || '').format(
                                'DD/MM/YYYY'
                            )}
                            //state.date_of_birth
                            // The following method which can hide data doesn't work for the first date pick
                            // dateFormat={!this.state.document_expiry_init_for_category_change && 'DD/MM/YYYY'}
                            // dateFormat={(this.props.identification.new_identification_record_data && this.props.identification.new_identification_record_data.document_expiry === "") ? false : 'DD/MM/YYYY'}
                            onChange={event => {
                                this.handleDateOfBirthChange(event)
                            }}
                        />
                    </FormControl>
                    {this.state.date_of_birth_state === 'error' ? (
                        <span>
                            <small style={{ color: 'red' }}>
                                Format: DD/MM/YYYY
                            </small>
                        </span>
                    ) : (
                        <span>
                            <small>Format: DD/MM/YYYY</small>
                        </span>
                    )}
                </React.Fragment>
            )
        }
    }

    handleDateOfBirthChange = (event, option) => {
        let selDate = ''
        if (event.isValid != undefined) {
            if (event.toDate().getMonth() >= 9) {
                if (option === 'onlyYearMonth')
                    selDate =
                        event.toDate().getFullYear() +
                        '-' +
                        (event.toDate().getMonth() + 1) +
                        '-01'
                // set it as the first day of the month
                else {
                    if (event.toDate().getDate() > 9)
                        selDate =
                            event.toDate().getFullYear() +
                            '-' +
                            (event.toDate().getMonth() + 1) +
                            '-' +
                            event.toDate().getDate()
                    // + 'T00:00.00.000Z';
                    else
                        selDate =
                            event.toDate().getFullYear() +
                            '-' +
                            (event.toDate().getMonth() + 1) +
                            '-0' +
                            event.toDate().getDate() // + 'T00:00.00.000Z';
                }
            } else if (event.toDate().getMonth() < 9) {
                if (option === 'onlyYearMonth')
                    selDate =
                        event.toDate().getFullYear() +
                        '-0' +
                        (event.toDate().getMonth() + 1) +
                        '-01'
                // set it as the first day of the month
                else {
                    if (event.toDate().getDate() > 9)
                        selDate =
                            event.toDate().getFullYear() +
                            '-0' +
                            (event.toDate().getMonth() + 1) +
                            '-' +
                            event.toDate().getDate()
                    // + 'T00:00.00.000Z';
                    else
                        selDate =
                            event.toDate().getFullYear() +
                            '-0' +
                            (event.toDate().getMonth() + 1) +
                            '-0' +
                            event.toDate().getDate() // + 'T00:00.00.000Z';
                }
            }
            this.setState({ date_of_birth_state: 'success' })
        } else {
            selDate = event
            this.setState({ date_of_birth_state: 'error' })
        }
        this.setState({ date_of_birth: selDate + 'T00:00:00.000Z' })
        //this.props.updateNewIdentificationRecordCreation("document_expiry", selDate)
        this.setState({ date_of_birth_init_for_category_change: false })
    }

    // function that returns true if value is valid password, false otherwise
    verifyPassword(event, value, stateName) {
        // == Old password rules ==
        // var passwordRex = /^(?=.*[a-z].*[a-z])(?=.*[A-Z].*[A-Z])(?=.*\d.*\d)(?=.*\W.*\W)[a-zA-Z0-9\S]{9,}$/;
        // This pattern requires at least two lowercase letters, two uppercase letters, two digits, and two special characters. There must be a minimum of 9 characters total, and no white space characters are allowed.
        // very strong password

        var passwordRex = /^(?=.*[!@#$%^&*()\-_=+`~\[\]{}?|])(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9]).{8,20}$/
        // This pattern requires at least longer than 8 characters and contain at least 1 uppercase & 1 number and 1 special character

        this.setState({ [stateName]: event.target.value })
        // console.log(event.target.value);
        if (passwordRex.test(event.target.value)) {
            // console.log("True");
            this.setState({
                new_password_state: 'success',
                new_password_helptext: '',
            })
            return true
        }
        // console.log("False");
        this.setState({
            new_password_state: 'error',
            new_password_helptext:
                'Must be longer than 8 characters and contain at least 1 uppercase & 1 number & 1 special character',
        })
        return false
    }

    handleChange = event => {
        if (
            event.target.value === 'customer_type_soleTrader' ||
            event.target.value === 'customer_type_company' ||
            event.target.value === 'customer_type_trust'
        ) {
            this.setState({
                selectedBusinessValue: event.target.value,
            })

            //if user selected radio button - Sole Trader
            if (event.target.value === 'customer_type_soleTrader') {
                this.setState({
                    business_company_name: '',
                    business_trust_name: '',
                    account_type: 2,
                    account_business_type: 1,
                })
            }

            //if user selected radio button - Company
            if (event.target.value === 'customer_type_company') {
                this.setState({
                    business_trading_name: '',
                    business_trust_name: '',
                    account_type: 2,
                    account_business_type: 2,
                })
            }

            //if user selected radio button - Trust
            if (event.target.value === 'customer_type_trust') {
                this.setState({
                    business_trading_name: '',
                    business_company_name: '',
                    account_type: 2,
                    account_business_type: 3,
                })
            }
        } else {
            this.setState({
                [event.target.id]: event.target.value,
            })
        }
    }

    handleSelectChange = event => {
        this.setState({ [event.target.name]: event.target.value })
    }

    handleSubmit = async event => {
        const loading = message.loading("Updating profile. Please wait..", 0);
        event.preventDefault()
        this.setState({ isLoading: true })

        const {
            // aws_cognito_id,
            title,
            first_name,
            middle_name,
            last_name,
            // email,
            external_reference,
            residential_street_line_1,
            residential_street_line_2,
            residential_street_suburb,
            residential_street_state,
            residential_street_postcode,
            residential_street_country,
            postal_street_line_1,
            postal_street_line_2,
            postal_street_suburb,
            postal_street_state,
            postal_street_postcode,
            postal_street_country,
            telephone_home,
            telephone_mobile,
            telephone_work,
            email_secondary,
            language_id,
            date_of_birth,
            place_of_birth_city,
            place_of_birth_country,
            nationality,
            marital_status,
            //#763 business detail save into database
            account_business_type,
            business_trading_name,
            business_company_name,
            business_company_number,
            business_trust_name,
            business_abn,
            //end of #763 business detail save into database
        } = this.state
        try {
            await this.saveUserProfile({
                // aws_cognito_id: aws_cognito_id,
                title: title,
                first_name: first_name,
                middle_name: middle_name,
                last_name: last_name,
                external_reference: external_reference,
                residential_street_line_1: residential_street_line_1,
                residential_street_line_2: residential_street_line_2,
                residential_street_suburb: residential_street_suburb,
                residential_street_state: residential_street_state,
                residential_street_postcode: residential_street_postcode,
                residential_street_country: residential_street_country,
                postal_street_line_1: postal_street_line_1,
                postal_street_line_2: postal_street_line_2,
                postal_street_suburb: postal_street_suburb,
                postal_street_state: postal_street_state,
                postal_street_postcode: postal_street_postcode,
                postal_street_country: postal_street_country,
                telephone_home: telephone_home,
                telephone_mobile: telephone_mobile,
                telephone_work: telephone_work,
                email_secondary: email_secondary,
                language_id: language_id,
                date_of_birth: date_of_birth,
                place_of_birth_city: place_of_birth_city,
                place_of_birth_country: place_of_birth_country,
                nationality: nationality,
                marital_status: marital_status,
                account_business_type: account_business_type,
                business_trading_name: business_trading_name,
                business_company_name: business_company_name,
                business_company_number: business_company_number,
                business_trust_name: business_trust_name,
                business_abn: business_abn,
            })
            // this.props.history.push("/");
            this.props.reload_user(this.state.aws_cognito_id);
            this.props.switch_user(this.props.app_state.current_client.id);
            message.success('Profile Updated');

            //await test #876
            // await window.location.reload() //this.props.history.push("/");
        } catch (e) {
            alert(e)
            // this.setState({ isLoading: false });
        } finally {
            loading();
        }
    }

    get_account_status() {
        switch (this.props.app_state.current_client.account_status) {
            case 1:
                return (
                    <span style={{ color: 'red' }}>
                        <strong>PENDING</strong>
                    </span>
                )
            case 2:
                return (
                    <span style={{ color: 'green' }}>
                        <strong>ACTIVE</strong>
                    </span>
                )
            case 3:
                return (
                    <span style={{ color: 'red' }}>
                        <strong>DECLINED</strong>
                    </span>
                )
            case 4:
                return (
                    <span style={{ color: 'red' }}>
                        <strong>SUSPENDED</strong>
                    </span>
                )
            case 5:
                return (
                    <span style={{ color: 'red' }}>
                        <strong>INCOMPLETE</strong>
                    </span>
                )
            case 6:
                return (
                    <span style={{ color: 'red' }}>
                        <strong>MIGRATION IN PROGRESS</strong>
                    </span>
                )
            default:
                return 'ERROR'
        }
    }

    handle_change_password_dialog_open = () => {
        this.setState({
            change_password_dialog: true,
        })
    }

    handle_change_password_dialog_close = () => {
        this.setState({
            change_password_dialog: false,
        })
    }

    handle_change_password_proceed = async (old_password, new_password) => {
        this.setState({
            new_password_changing: true,
        });

        try {
            const currentUser = await Auth.currentAuthenticatedUser()
            // console.log(currentUser);
            await Auth.changePassword(currentUser, old_password, new_password)
            // console.log(currentUser);
            this.setState({
                new_password_changing: false,
                change_password_dialog: false,
                new_password: '',
                new_password_confirm: '',
            })
        } catch (e) {
            alert(e.message)
            this.setState({
                new_password_changing: false,
                change_password_dialog: false,
                new_password: '',
                new_password_confirm: '',
            })
        }
        this.setState({
            new_password_changing: false,
            change_password_dialog: false,
            new_password: '',
            new_password_confirm: '',
        })
    }

    // Change Email Dialog Handlers
    handle_change_email_dialog_open = () => {
        this.setState({
            change_email_dialog: true,
        })
    }

    handle_change_email_dialog_close = () => {
        this.setState({
            change_email_dialog: false,
        })
        this.props.history.push('/pages/login')
    }

    // For CustomReactSelect
    handleCustomReactSelectChange = name => value => {
        if (value === null) {
            this.setState({
                [name]: null,
            })
        } else {
            this.setState({
                [name]: value.value,
            })
        }
    }

    // For Country CustomReactSelect
    handleCustomCountryReactSelectChange = name => value => {
        if (value === null) {
            //do nothing
        } else {
            // console.log(value.value);
            this.setState({
                [name]: value.value,
            })
        }
    }

    /*
   * Solution for CP #763 User can see and edit their business details
   * 
   * Input: id
   * Output: value
   */
    getAccountTypeValue = id => {
        let accountTypeId = id
        switch (accountTypeId) {
            case 1:
                return 'Personal'
            // break;
            case 2:
                return 'Business'
            // break;
            case 3:
                return 'Student'
            // break;
            default:
                return 'Personal'
            // break;
        }
    }

    getCountryValue = id => {
        const country_list_select_options = this.state.country_list_prio.map(
            item => ({
                value: item.id,
                label: item.full_name,
            })
        )
        //console.log(id);
        //console.log(country_list_select_options);

        if (id > 0) {
            for (var i = 0; i < country_list_select_options.length; i++) {
                if (id === country_list_select_options[i].value) {
                    //console.log(country_list_select_options[i].label);
                    return id
                }
            }
        }

        //no information found
        return ''
    }

    copy_res_to_postal_address = () => {
        this.setState({
            postal_street_line_1: this.state.residential_street_line_1,
            postal_street_line_2: this.state.residential_street_line_2,
            postal_street_suburb: this.state.residential_street_suburb,
            postal_street_state: this.state.residential_street_state,
            postal_street_postcode: this.state.residential_street_postcode,
            postal_street_country: this.state.residential_street_country,
        })
    }

    changePassword = ({aid, email, userClientId}) => {

        const handleSubmit = (values) => {
            const loading = message.loading("Changing password. Please wait..", 0);
            API.post("usersNew", "/clients/password/change", {
                body: {
                    password: values.password,
                    cognitoId: aid
                }
            }).then(res => {
                message.success(`Password changed of login ${email}`);
                API.post("email18", "/client/reset/send-email", {
                    body: {
                        clientContactId: userClientId,
                        password: values.password
                    }
                }).then(res => {
                    message.success("Email Sent.");
                })
            }).catch(err => {
                message.error("Failed to change password. please try again..");
            }).finally(() => loading())
        }

        Modal.confirm({
            title: 'Change Client Contact Password',
            onOk: () => {
                return new Promise((resolve, reject) => {
                    this.passwordModal.current
                        .validateFields()
                        .then((values) => {
                            handleSubmit(values); // Call handleSubmit on success
                            resolve();
                        })
                        .catch((errorInfo) => {
                            console.log('Validation failed:', errorInfo);
                            reject(); // Prevent modal from closing
                        });
                });
            },
            onCancel: () => this.loginsModal.current.resetFields(),
            content: <>
                <Form ref={this.passwordModal} name={'reset-form'} onFinish={handleSubmit}>
                    <Form.Item label={'Password'} name='password'
                               rules={[{required: true, message: 'Password is required'}]}>
                        <Input.Password/>
                    </Form.Item>
                    <Form.Item label={'Confirm Password'} name='confirm_password' rules={[
                        {
                            required: true,
                            message: 'Please confirm your password!',
                        },
                        ({getFieldValue}) => ({
                            validator(_, value) {
                                if (!value || getFieldValue('password') === value) {
                                    return Promise.resolve();
                                }
                                return Promise.reject(new Error('The new password that you entered do not match!'));
                            },
                        }),
                    ]}>
                        <Input.Password/>
                    </Form.Item>
                </Form>
            </>
        })
    }

    toggleEmailSend = (val, record) => {
        API.post("commons", `/update`, {
            body: {
                context: 'client_contacts',
                data: { sendEmail: val },
                condition: { id: record.id }
            }
        }).then(() => {
            message.success("Email send status updated.");
            this.fetchUserLogins();
        }).catch(err => {
            message.error("Failed to update email send status.");
        })
    }

    buildColumns = () => {
        return [
            // {
            //     title: 'sessionId',
            //     dataIndex: 'aid',
            //     ...this.props.getColumnSearchProps({
            //         dataIndex: 'aid',
            //         render: (text, record) => {
            //             return text
            //         },
            //     }),
            // },
            {
                title: 'Name',
                dataIndex: 'name',
                ...this.props.getColumnSearchProps({
                    dataIndex: 'name',
                    render: (text, record) => {
                        return text
                    }
                })
            },
            {
                title: 'Email',
                dataIndex: 'email',
                ...this.props.getColumnSearchProps({
                    dataIndex: 'email',
                    render: (text, record) => {
                        return text
                    }
                })
            },
            {
                title: 'Send Email?',
                dataIndex: 'sendEmail',
                ...this.props.getColumnSearchProps({
                    label: 'Email',
                    dataIndex: 'sendEmail',
                    render: (text, record) => <>No <Switch defaultChecked={text === 1} onChange={(val) => this.toggleEmailSend(val, record)}></Switch> Yes</>
                })
            },
            {
                title: 'Action',
                dataIndex: 'userClientId',
                ...this.props.getColumnSearchProps({
                    dataIndex: 'userClientId',
                    render: (text, record) => {
                        return (
                            <>
                                <AntButton
                                    disabled={
                                        this.props.app_state.current_client &&
                                        this.props.app_state.current_client
                                            .aws_cognito_id !==
                                            this.props.app_state.current_user_id
                                    }
                                    type={'link'}
                                    icon={<EditOutlined />}
                                    onClick={() =>
                                        this.onEditLoginRow(record.userClientId)
                                    }
                                    >
                                    Edit
                                </AntButton>
                                {/*<AntButton*/}
                                {/*    disabled={*/}
                                {/*        this.props.app_state.current_client &&*/}
                                {/*        this.props.app_state.current_client*/}
                                {/*            .aws_cognito_id !==*/}
                                {/*            this.props.app_state.current_user_id*/}
                                {/*    }*/}
                                {/*    type={'link'}*/}
                                {/*    danger={true}*/}
                                {/*    icon={<EditOutlined />}*/}
                                {/*    onClick={() => this.changePassword(record)}*/}
                                {/*    >*/}
                                {/*    Change Password*/}
                                {/*</AntButton>*/}
                            </>
                        )
                    },
                }),
            },
        ]
    }

    onEditLoginRow = async id => {
        // const loading = message.loading("Loading details. Please wait..", 0);
        // API.post("commons", "/fetch", {
        //     body: {
        //         context: 'client_contacts',
        //         fields: ['*'],
        //         condition: {id: id}
        //     }
        // }).then(res => {
        //     const [data] = res;
        //     const payload = {
        //         firstName: data.firstName,
        //         lastName: data.lastName,
        //         phoneNumber: data.telephone,
        //         balanceWithdrawal: data.balanceWithdrawal === 1,
        //         addBeneficiary: data.addBeneficiary === 1,
        //         submitTransfer: data.submitTransfer === 1,
        //         clientID: data.clientId
        //     };
        //     console.log(payload);
        //     this.setState({isLoginModalOpen: true, activeLoginsId: id}, () => {
        //         this.loginsModal.current.setFieldsValue(payload);
        //     });
        //
        // }).catch(err => console.log(err)).finally(() => loading())
        this.props.history.push(`/contacts/view/${id}`);
    }

    handleLoginSubmit = async (values) => {
        const loading = message.loading("Saving.. Please wait..", 0);
        if (this.state.activeLoginsId != null) {
            try {
                await API.post("commons", `/update`, {
                    body: {
                        context: 'client_contacts',
                        data: {
                            firstName: values.firstName,
                            lastName: values.lastName,
                            telephone: values.phoneNumber,
                            balanceWithdrawal: values.balanceWithdrawal,
                            addBeneficiary: values.addBeneficiary,
                            submitTransfer: values.submitTransfer
                        },
                        condition: {id: this.state.activeLoginsId}
                    }
                });

                this.setState({ activeLoginsId: null, isLoginModalOpen: false })
                message.success("Logins details saved..");
                this.loginsModal.current.resetFields();
                this.fetchUserLogins();
            } catch (err) {
                console.log(err);
                console.log(err);
                loading();
            }
        } else {

            const password = AppUtil.generateRandomPassword();
            try {
                const [data] = await API.post("commons", "/fetch", {
                    body: {
                        context: 'client_contacts',
                        fields: ['*'],
                        condition: {email: values.email}
                    }
                });

                if (data != null) {
                    if (data.aws_cognito_id == null) {
                        const awsResponse = await Auth.signUp({username: values.email, password: password});
                        await API.post("commons", "/update", {
                            body: {
                                context: 'client_contacts',
                                condition: {id: data.id},
                                data: {
                                    clientId: values.clientID,
                                    aws_cognito_id: awsResponse.userSub
                                }
                            }
                        })
                    }
                } else {
                    const awsResponse = await Auth.signUp({username: values.email, password: password});
                    await API.post("commons", "/insert", {
                        body: {
                            context: 'client_contacts',
                            data: {
                                firstName: values.firstName,
                                lastName: values.lastName,
                                telephone: values.phoneNumber,
                                email: values.email,
                                clientId: values.clientID || this.props.app_state.current_client.id,
                                aws_cognito_id: awsResponse.userSub,
                                submitTransfer: values.submitTransfer,
                                addBeneficiary: values.addBeneficiary,
                                balanceWithdrawal: values.balanceWithdrawal,
                                sendEmail: values.sendEmail
                            }
                        }
                    });
                }
                this.fetchUserLogins();
            } catch (e) {
                console.log("Account already on cognito");
                message.info("Error. Please contact team");
                loading();
            }

            if (values.sendEmail) {
                try {
                    await API.post("email18", "/client/send-email", {
                        body: {
                            firstName: values.firstName,
                            lastName: values.lastName,
                            email: values.email,
                            password: password,
                            clientId: this.props.app_state.current_client.id
                        }
                    })
                } catch (e) {
                    console.log(e);
                    loading();
                }
            }
            this.setState({activeLoginsId: null, isLoginModalOpen: false})
            message.success("Logins details saved..");
            this.loginsModal.current.resetFields();
        }
        loading();

    }

    render() {
        const { classes } = this.props
        // For CustomReactSelect. Generate select options for dropdown list.
        const title_select_options = [
            { label: 'Mr' },
            { label: 'Ms' },
            { label: 'Mrs' },
        ].map(item => ({
            value: item.label,
            label: item.label,
        }))
        const marital_select_options = [
            { id: 1, label: 'Single' },
            { id: 2, label: 'Married' },
            { id: 3, label: 'DeFacto' },
            { id: 4, label: 'Separated' },
            { id: 5, label: 'Divorced' },
            { id: 6, label: 'Not Disclosed' },
        ].map(item => ({
            value: item.id,
            label: item.label,
        }))
        const language_select_options = this.state.language_id_list
            .sort((a, b) => a.nickname.localeCompare(b.nickname))
            .map(item => ({
                value: item.id,
                label: item.nickname,
            }))
        const country_list_select_options = this.state.country_list_prio.map(
            item => ({
                value: item.id,
                label: item.full_name,
            })
        )
        if (
            !this.props.isAuthenticated ||
            !this.props.current_user ||
            !this.props.app_state.current_client
        ) {
            return <div>NO CURRENT USER</div>
        }

        let date_time_component = this.displayYearMonthDate(this.state)

        let client_reference_label =
            this.state.account_type === 3 ? 'Student ID' : 'Client Reference'
        let client_reference_helpertext = <span />
        if (this.props.app_state.current_team.id === 24) {
            client_reference_label =
                'Ninja Trader Account Number AND Account Name'
            client_reference_helpertext = (
                <span>
                    Please provide BOTH Ninja Trader Account Number and Account
                    Name
                </span>
            )
        }

        let using_temporary_password_warning = <span />
        if (this.props.app_state.current_client.using_temporary_password) {
            using_temporary_password_warning = (
                <span style={{ color: 'red' }}>
                    <strong>
                        <br />
                        WARNING
                        <br />
                        Using temporary password.
                        <br />
                        Please change by clicking button below.
                        <br />
                    </strong>
                </span>
            )
        }

        return (
            <React.Fragment>
                <div>
                    <GridContainer>
                        <GridItem xs={12} sm={12} md={4}>
                            <Card profile>
                                <CardAvatar profile>
                                    <a
                                        href="#"
                                        onClick={e => e.preventDefault()}
                                    >
                                        <img src={avatar} alt="..." />
                                    </a>
                                </CardAvatar>
                                <CardBody profile>
                                    <h4 className={classes.cardTitle}>
                                        {`${this.state.first_name}  ${
                                            this.state.last_name
                                        }`}
                                    </h4>
                                    <h6>Account Details</h6>

                                    <Table
                                        tableData={[
                                            [
                                                <FormattedMessage
                                                    id="staffProfile.cardItem1.username"
                                                    defaultMessage={`Username`}
                                                />,
                                                `${this.state.email}`,
                                            ],
                                            [
                                                <FormattedMessage
                                                    id="staffProfile.cardItem1.accountStatus"
                                                    defaultMessage={`Account Status`}
                                                />,
                                                this.get_account_status(),
                                            ],
                                            [
                                                'Account type',
                                                this.getAccountTypeValue(
                                                    this.props.app_state
                                                        .current_client
                                                        .account_type
                                                ),
                                            ],
                                            [
                                                'Notes',
                                                this.state.account_status_notes,
                                            ],
                                        ]}
                                    />
                                    <hr />

                                    <GridContainer>
                                        {/* <GridItem xs={12} sm={12}>
                                            <Button
                                                color="warning"
                                                onClick={() =>
                                                    this.handle_change_email_dialog_open()
                                                }
                                            >
                                                <FormattedMessage
                                                    id="staffProfile.cardItem1.button1"
                                                    defaultMessage={`Change Primary Email`}
                                                />
                                            </Button>
                                        </GridItem> */}
                                        <GridItem xs={12} sm={12}>
                                            {using_temporary_password_warning}
                                            <Button
                                                color="warning"
                                                onClick={() =>
                                                    this.handle_change_password_dialog_open()
                                                }
                                            >
                                                <FormattedMessage
                                                    id="staffProfile.cardItem1.button2"
                                                    defaultMessage={`Change Password`}
                                                />
                                            </Button>
                                        </GridItem>
                                    </GridContainer>
                                </CardBody>
                            </Card>
                        </GridItem>
                        <GridItem xs={12} sm={12} md={8}>
                            <Card>
                                <CardHeader color="rose" icon>
                                    <CardIcon color="rose">
                                        <PermIdentity />
                                    </CardIcon>
                                    <h4 className={classes.cardIconTitle}>
                                        <FormattedMessage
                                            id="staffProfile.cardItem2.title"
                                            defaultMessage={`User Profile`}
                                        />
                                    </h4>
                                </CardHeader>
                                <CardBody>
                                    <NavPills
                                        color="info"
                                        tabs={[
                                            {
                                                tabButton: this.props.intl.formatMessage(
                                                    {
                                                        id:
                                                            'staffProfile.cardItem2.tabButton1',
                                                        defaultMessage: `User & Account`,
                                                    }
                                                ),
                                                tabContent: (
                                                    <div
                                                        style={{ padding: 20 }}
                                                    >
                                                        <form
                                                            onSubmit={
                                                                this
                                                                    .handleSubmit
                                                            }
                                                        >
                                                            <GridContainer>
                                                                <GridItem
                                                                    xs={12}
                                                                    sm={12}
                                                                    md={12}
                                                                >
                                                                    <CustomInput
                                                                        labelText={
                                                                            <FormattedMessage
                                                                                id="staffProfile.cardItem2.usernameWithEmail"
                                                                                defaultMessage={`Username (primary email`}
                                                                            />
                                                                        }
                                                                        id="email"
                                                                        formControlProps={{
                                                                            fullWidth: true,
                                                                        }}
                                                                        inputProps={{
                                                                            disabled: true,
                                                                            value:
                                                                                this
                                                                                    .state
                                                                                    .email ||
                                                                                '',
                                                                            onChange: event => {
                                                                                this.handleChange(
                                                                                    event
                                                                                )
                                                                            },
                                                                        }}
                                                                    />
                                                                </GridItem>
                                                            </GridContainer>

                                                            <GridContainer>
                                                                <GridItem
                                                                    xs={12}
                                                                    sm={12}
                                                                    md={2}
                                                                >
                                                                    <CustomReactSelect
                                                                        label={
                                                                            <FormattedMessage
                                                                                id="staffProfile.cardItem2.mantitle"
                                                                                defaultMessage={`Title`}
                                                                            />
                                                                        }
                                                                        options={
                                                                            title_select_options
                                                                        }
                                                                        value={
                                                                            this
                                                                                .state
                                                                                .title
                                                                        }
                                                                        onChange={this.handleCustomReactSelectChange(
                                                                            'title'
                                                                        )}
                                                                        isClearable={
                                                                            false
                                                                        }
                                                                        // isDisabled={!this.state.edit_mode}
                                                                    />
                                                                </GridItem>
                                                                <GridItem
                                                                    xs={12}
                                                                    sm={12}
                                                                    md={5}
                                                                >
                                                                    <CustomInput
                                                                        labelText={
                                                                            <FormattedMessage
                                                                                id="staffProfile.cardItem2.firstname"
                                                                                defaultMessage={`First Name`}
                                                                            />
                                                                        }
                                                                        id="first_name"
                                                                        formControlProps={{
                                                                            fullWidth: true,
                                                                        }}
                                                                        inputProps={{
                                                                            value:
                                                                                this
                                                                                    .state
                                                                                    .first_name ||
                                                                                '',
                                                                            onChange: event => {
                                                                                this.handleChange(
                                                                                    event
                                                                                )
                                                                            },
                                                                        }}
                                                                    />
                                                                </GridItem>
                                                                <GridItem
                                                                    xs={12}
                                                                    sm={12}
                                                                    md={5}
                                                                >
                                                                    <CustomInput
                                                                        labelText={
                                                                            <FormattedMessage
                                                                                id="staffProfile.cardItem2.middlename"
                                                                                defaultMessage={`Middle Name(s)`}
                                                                            />
                                                                        }
                                                                        id="middle_name"
                                                                        formControlProps={{
                                                                            fullWidth: true,
                                                                        }}
                                                                        inputProps={{
                                                                            value:
                                                                                this
                                                                                    .state
                                                                                    .middle_name ||
                                                                                '',
                                                                            onChange: event => {
                                                                                this.handleChange(
                                                                                    event
                                                                                )
                                                                            },
                                                                        }}
                                                                    />
                                                                </GridItem>
                                                            </GridContainer>

                                                            <GridContainer>
                                                                <GridItem
                                                                    xs={12}
                                                                    sm={12}
                                                                    md={12}
                                                                >
                                                                    <CustomInput
                                                                        labelText={
                                                                            <FormattedMessage
                                                                                id="staffProfile.cardItem2.lastname"
                                                                                defaultMessage={`Last Name`}
                                                                            />
                                                                        }
                                                                        id="last_name"
                                                                        disabled
                                                                        formControlProps={{
                                                                            fullWidth: true,
                                                                        }}
                                                                        inputProps={{
                                                                            value:
                                                                                this
                                                                                    .state
                                                                                    .last_name ||
                                                                                '',
                                                                            onChange: event => {
                                                                                this.handleChange(
                                                                                    event
                                                                                )
                                                                            },
                                                                        }}
                                                                    />
                                                                </GridItem>
                                                            </GridContainer>

                                                            <GridContainer>
                                                                <GridItem
                                                                    xs={12}
                                                                    sm={12}
                                                                    md={12}
                                                                >
                                                                    <CustomInput
                                                                        labelText={
                                                                            client_reference_label
                                                                        }
                                                                        id="external_reference"
                                                                        disabled
                                                                        formControlProps={{
                                                                            fullWidth: true,
                                                                        }}
                                                                        inputProps={{
                                                                            value:
                                                                                this
                                                                                    .state
                                                                                    .external_reference ||
                                                                                '',
                                                                            onChange: event => {
                                                                                this.handleChange(
                                                                                    event
                                                                                )
                                                                            },
                                                                        }}
                                                                    />
                                                                    {
                                                                        client_reference_helpertext
                                                                    }
                                                                </GridItem>
                                                            </GridContainer>

                                                            <Button
                                                                color="primary"
                                                                type="submit"
                                                                className={
                                                                    classes.updateProfileButton
                                                                }
                                                            >
                                                                <FormattedMessage
                                                                    id="staffProfile.cardItem2.button1"
                                                                    defaultMessage={`Update User & Account`}
                                                                />
                                                            </Button>
                                                        </form>
                                                    </div>
                                                ),
                                            },
                                            {
                                                tabButton: this.props.intl.formatMessage(
                                                    {
                                                        id:
                                                            'staffProfile.cardItem2.tabButton2',
                                                        defaultMessage: `Address`,
                                                    }
                                                ),
                                                tabContent: (
                                                    <div
                                                        style={{ padding: 20 }}
                                                    >
                                                        <form
                                                            onSubmit={
                                                                this
                                                                    .handleSubmit
                                                            }
                                                        >
                                                            <GridContainer>
                                                                <GridItem
                                                                    xs={12}
                                                                >
                                                                        <GridItem
                                                                            xs={
                                                                                12
                                                                            }
                                                                        >
                                                                            <CustomInput
                                                                                labelText={
                                                                                    <FormattedMessage
                                                                                        id="staffProfile.cardItem2.addressLine1"
                                                                                        defaultMessage={`Residential Street Address (line 1)"`}
                                                                                    />
                                                                                }
                                                                                id="residential_street_line_1"
                                                                                formControlProps={{
                                                                                    fullWidth: true,
                                                                                }}
                                                                                inputProps={{
                                                                                    value:
                                                                                        this
                                                                                            .state
                                                                                            .residential_street_line_1 ||
                                                                                        '',
                                                                                    onChange: event => {
                                                                                        this.handleChange(
                                                                                            event
                                                                                        )
                                                                                    },
                                                                                }}
                                                                            />
                                                                        </GridItem>
                                                                        <GridItem
                                                                            xs={
                                                                                12
                                                                            }
                                                                            sm={
                                                                                12
                                                                            }
                                                                            md={
                                                                                12
                                                                            }
                                                                        >
                                                                            <CustomInput
                                                                                labelText={
                                                                                    <FormattedMessage
                                                                                        id="staffProfile.cardItem2.addressLine2"
                                                                                        defaultMessage={`Residential Street Address (line 2)"`}
                                                                                    />
                                                                                }
                                                                                id="residential_street_line_2"
                                                                                formControlProps={{
                                                                                    fullWidth: true,
                                                                                }}
                                                                                inputProps={{
                                                                                    value:
                                                                                        this
                                                                                            .state
                                                                                            .residential_street_line_2 ||
                                                                                        '',
                                                                                    onChange: event => {
                                                                                        this.handleChange(
                                                                                            event
                                                                                        )
                                                                                    },
                                                                                }}
                                                                            />
                                                                        </GridItem>
                                                                        <GridItem
                                                                        >
                                                                            <CustomInput
                                                                                labelText={
                                                                                    <FormattedMessage
                                                                                        id="staffProfile.cardItem2.suburb"
                                                                                        defaultMessage={`Suburb`}
                                                                                    />
                                                                                }
                                                                                id="residential_street_suburb"
                                                                                formControlProps={{
                                                                                    fullWidth: true,
                                                                                }}
                                                                                inputProps={{
                                                                                    value:
                                                                                        this
                                                                                            .state
                                                                                            .residential_street_suburb ||
                                                                                        '',
                                                                                    onChange: event => {
                                                                                        this.handleChange(
                                                                                            event
                                                                                        )
                                                                                    },
                                                                                }}
                                                                            />
                                                                        </GridItem>
                                                                        <GridItem
                                                                        >
                                                                            <GridContainer
                                                                            >
                                                                                <GridItem
                                                                                    xs={
                                                                                        12
                                                                                    }
                                                                                    sm={
                                                                                        12
                                                                                    }
                                                                                    md={
                                                                                        6
                                                                                    }
                                                                                >
                                                                                    <CustomInput
                                                                                        labelText={
                                                                                            <FormattedMessage
                                                                                                id="staffProfile.cardItem2.state"
                                                                                                defaultMessage={`State`}
                                                                                            />
                                                                                        }
                                                                                        id="residential_street_state"
                                                                                        formControlProps={{
                                                                                            fullWidth: true,
                                                                                        }}
                                                                                        inputProps={{
                                                                                            value:
                                                                                                this
                                                                                                    .state
                                                                                                    .residential_street_state ||
                                                                                                '',
                                                                                            onChange: event => {
                                                                                                this.handleChange(
                                                                                                    event
                                                                                                )
                                                                                            },
                                                                                        }}
                                                                                    />
                                                                                </GridItem>
                                                                                <GridItem
                                                                                    xs={
                                                                                        12
                                                                                    }
                                                                                    sm={
                                                                                        12
                                                                                    }
                                                                                    md={
                                                                                        6
                                                                                    }
                                                                                >
                                                                                    <CustomInput
                                                                                        labelText={
                                                                                            <FormattedMessage
                                                                                                id="staffProfile.cardItem2.postcode"
                                                                                                defaultMessage={`Postcode`}
                                                                                            />
                                                                                        }
                                                                                        id="residential_street_postcode"
                                                                                        formControlProps={{
                                                                                            fullWidth: true,
                                                                                        }}
                                                                                        inputProps={{
                                                                                            value:
                                                                                                this
                                                                                                    .state
                                                                                                    .residential_street_postcode ||
                                                                                                '',
                                                                                            onChange: event => {
                                                                                                this.handleChange(
                                                                                                    event
                                                                                                )
                                                                                            },
                                                                                        }}
                                                                                    />
                                                                                </GridItem>
                                                                            </GridContainer>
                                                                        </GridItem>

                                                                        <GridItem
                                                                        >
                                                                            <CustomReactSelect
                                                                                label={
                                                                                    <FormattedMessage
                                                                                        id="staffProfile.cardItem2.country"
                                                                                        defaultMessage={`Country`}
                                                                                    />
                                                                                }
                                                                                options={
                                                                                    country_list_select_options
                                                                                }
                                                                                value={
                                                                                    this
                                                                                        .state
                                                                                        .residential_street_country
                                                                                }
                                                                                onChange={this.handleCustomReactSelectChange(
                                                                                    'residential_street_country'
                                                                                )}
                                                                                isClearable={
                                                                                    false
                                                                                }
                                                                                // isDisabled={!this.state.edit_mode}
                                                                            />
                                                                        </GridItem>
                                                                </GridItem>

                                                                <GridItem xs={6}>
                                                                <GridContainer>
                                                                <GridItem
                                                                    xs={12}
                                                                    sm={12}
                                                                    md={6}
                                                                >
                                                                    <CustomInput
                                                                        labelText={
                                                                            <FormattedMessage
                                                                                id="staffProfile.cardItem2.homePhone"
                                                                                defaultMessage={`Home Telephone`}
                                                                            />
                                                                        }
                                                                        id="telephone_home"
                                                                        formControlProps={{
                                                                            fullWidth: true,
                                                                        }}
                                                                        inputProps={{
                                                                            value:
                                                                                this
                                                                                    .state
                                                                                    .telephone_home ||
                                                                                '',
                                                                            onChange: event => {
                                                                                this.handleChange(
                                                                                    event
                                                                                )
                                                                            },
                                                                        }}
                                                                    />
                                                                </GridItem>
                                                            </GridContainer>
                                                            <GridContainer>
                                                                <GridItem
                                                                    xs={12}
                                                                    sm={12}
                                                                    md={6}
                                                                >
                                                                    <CustomInput
                                                                        labelText={
                                                                            <FormattedMessage
                                                                                id="staffProfile.cardItem2.workPhone"
                                                                                defaultMessage={`Work Telephone`}
                                                                            />
                                                                        }
                                                                        id="telephone_work"
                                                                        formControlProps={{
                                                                            fullWidth: true,
                                                                        }}
                                                                        inputProps={{
                                                                            value:
                                                                                this
                                                                                    .state
                                                                                    .telephone_work ||
                                                                                '',
                                                                            onChange: event => {
                                                                                this.handleChange(
                                                                                    event
                                                                                )
                                                                            },
                                                                        }}
                                                                    />
                                                                </GridItem>
                                                            </GridContainer>
                                                            <GridContainer>
                                                                <GridItem
                                                                    xs={12}
                                                                    sm={12}
                                                                    md={6}
                                                                >
                                                                    <CustomInput
                                                                        labelText={
                                                                            <FormattedMessage
                                                                                id="staffProfile.cardItem2.mobilePhone"
                                                                                defaultMessage={`Mobile Telephone`}
                                                                            />
                                                                        }
                                                                        id="telephone_mobile"
                                                                        formControlProps={{
                                                                            fullWidth: true,
                                                                        }}
                                                                        inputProps={{
                                                                            value:
                                                                                this
                                                                                    .state
                                                                                    .telephone_mobile ||
                                                                                '',
                                                                            onChange: event => {
                                                                                this.handleChange(
                                                                                    event
                                                                                )
                                                                            },
                                                                        }}
                                                                    />
                                                                </GridItem>
                                                            </GridContainer>

                                                            <GridContainer>
                                                                <GridItem
                                                                    xs={12}
                                                                    sm={12}
                                                                    md={6}
                                                                >
                                                                    <CustomInput
                                                                        labelText={
                                                                            <FormattedMessage
                                                                                id="staffProfile.cardItem2.primaryEmail"
                                                                                defaultMessage={`Primary Email`}
                                                                            />
                                                                        }
                                                                        id="email"
                                                                        formControlProps={{
                                                                            fullWidth: true,
                                                                        }}
                                                                        inputProps={{
                                                                            disabled: true,
                                                                            value:
                                                                                this
                                                                                    .state
                                                                                    .email ||
                                                                                '',
                                                                            onChange: event => {
                                                                                this.handleChange(
                                                                                    event
                                                                                )
                                                                            },
                                                                        }}
                                                                    />
                                                                </GridItem>
                                                            </GridContainer>
                                                            <GridContainer>
                                                                <GridItem
                                                                    xs={12}
                                                                    sm={12}
                                                                    md={6}
                                                                >
                                                                    <CustomInput
                                                                        labelText={
                                                                            <FormattedMessage
                                                                                id="staffProfile.cardItem2.secondaryEmail"
                                                                                defaultMessage={`Secondary Email`}
                                                                            />
                                                                        }
                                                                        id="email_secondary"
                                                                        formControlProps={{
                                                                            fullWidth: true,
                                                                        }}
                                                                        inputProps={{
                                                                            value:
                                                                                this
                                                                                    .state
                                                                                    .email_secondary ||
                                                                                '',
                                                                            onChange: event => {
                                                                                this.handleChange(
                                                                                    event
                                                                                )
                                                                            },
                                                                        }}
                                                                    />
                                                                </GridItem>
                                                            </GridContainer>
                                                                </GridItem>
                                                            </GridContainer>

                                                            <Button
                                                                color="primary"
                                                                type="submit"
                                                                className={
                                                                    classes.updateProfileButton
                                                                }
                                                            >
                                                                <FormattedMessage
                                                                    id="staffProfile.cardItem2.button2"
                                                                    defaultMessage={`Update Address`}
                                                                />
                                                            </Button>
                                                        </form>
                                                    </div>
                                                ),
                                            },

                                            {
                                                tabButton: this.props.intl.formatMessage(
                                                    {
                                                        id:
                                                            'staffProfile.cardItem2.tabButton4',
                                                        defaultMessage: `Personal Details`,
                                                    }
                                                ),
                                                tabContent: (
                                                    <div
                                                        style={{ padding: 20 }}
                                                    >
                                                        <form
                                                            onSubmit={
                                                                this
                                                                    .handleSubmit
                                                            }
                                                        >
                                                            <GridContainer>
                                                                <GridItem
                                                                    xs={12}
                                                                    sm={12}
                                                                    md={6}
                                                                >
                                                                    {
                                                                        date_time_component
                                                                    }
                                                                </GridItem>
                                                                <GridItem
                                                                    xs={12}
                                                                    sm={12}
                                                                    md={6}
                                                                >
                                                                    <CustomInput
                                                                        labelText={
                                                                            <FormattedMessage
                                                                                id="staffProfile.cardItem2.placeOfBirthCity"
                                                                                defaultMessage={`Place of Birth City`}
                                                                            />
                                                                        }
                                                                        id="place_of_birth_city"
                                                                        formControlProps={{
                                                                            fullWidth: true,
                                                                        }}
                                                                        inputProps={{
                                                                            // disabled: true,
                                                                            value:
                                                                                this
                                                                                    .state
                                                                                    .place_of_birth_city ||
                                                                                '', //this.props.current_user.place_of_birth_city
                                                                            onChange: event => {
                                                                                this.handleChange(
                                                                                    event
                                                                                )
                                                                            },
                                                                        }}
                                                                    />
                                                                </GridItem>
                                                            </GridContainer>

                                                            <GridContainer>
                                                                <GridItem
                                                                    xs={12}
                                                                    sm={12}
                                                                    md={6}
                                                                >
                                                                    <CustomReactSelect
                                                                        label={
                                                                            <FormattedMessage
                                                                                id="staffProfile.cardItem2.placeOfBirthCountry"
                                                                                defaultMessage={`Place Of Birth Country`}
                                                                            />
                                                                        }
                                                                        options={
                                                                            country_list_select_options
                                                                        }
                                                                        value={this.getCountryValue(
                                                                            this
                                                                                .state
                                                                                .place_of_birth_country
                                                                        )}
                                                                        onChange={this.handleCustomCountryReactSelectChange(
                                                                            'place_of_birth_country'
                                                                        )}
                                                                        isClearable={
                                                                            false
                                                                        }
                                                                        // isDisabled={!this.state.edit_mode}
                                                                    />
                                                                </GridItem>
                                                                <GridItem
                                                                    xs={12}
                                                                    sm={12}
                                                                    md={6}
                                                                >
                                                                    <CustomReactSelect
                                                                        label={
                                                                            <FormattedMessage
                                                                                id="staffProfile.cardItem2.nationality"
                                                                                defaultMessage={`Nationality`}
                                                                            />
                                                                        }
                                                                        options={
                                                                            country_list_select_options
                                                                        }
                                                                        value={this.getCountryValue(
                                                                            this
                                                                                .state
                                                                                .nationality
                                                                        )}
                                                                        onChange={this.handleCustomCountryReactSelectChange(
                                                                            'nationality'
                                                                        )}
                                                                        isClearable={
                                                                            false
                                                                        }
                                                                        // isDisabled={!this.state.edit_mode}
                                                                    />
                                                                </GridItem>
                                                            </GridContainer>

                                                            <GridContainer>
                                                                <GridItem
                                                                    xs={12}
                                                                    sm={12}
                                                                    md={6}
                                                                >
                                                                    <CustomReactSelect
                                                                        label={
                                                                            <FormattedMessage
                                                                                id="staffProfile.cardItem2.maritalStatus"
                                                                                defaultMessage={`Marital Status`}
                                                                            />
                                                                        }
                                                                        options={
                                                                            marital_select_options
                                                                        }
                                                                        value={
                                                                            this
                                                                                .state
                                                                                .marital_status ||
                                                                            ''
                                                                        }
                                                                        onChange={this.handleCustomReactSelectChange(
                                                                            'marital_status'
                                                                        )}
                                                                        isClearable={
                                                                            false
                                                                        }
                                                                        // isDisabled={!this.state.edit_mode}
                                                                    />
                                                                </GridItem>
                                                                <GridItem
                                                                    xs={12}
                                                                    sm={12}
                                                                    md={6}
                                                                >
                                                                    <CustomReactSelect
                                                                        label={
                                                                            <FormattedMessage
                                                                                id="staffProfile.cardItem2.language"
                                                                                defaultMessage={`Language`}
                                                                            />
                                                                        }
                                                                        options={
                                                                            language_select_options
                                                                        }
                                                                        value={
                                                                            this
                                                                                .state
                                                                                .language_id ||
                                                                            ''
                                                                        }
                                                                        onChange={this.handleCustomReactSelectChange(
                                                                            'language_id'
                                                                        )}
                                                                        isClearable={
                                                                            false
                                                                        }
                                                                        // isDisabled={!this.state.edit_mode}
                                                                    />
                                                                </GridItem>
                                                            </GridContainer>

                                                            <Button
                                                                color="primary"
                                                                type="submit"
                                                                className={
                                                                    classes.updateProfileButton
                                                                }
                                                            >
                                                                <FormattedMessage
                                                                    id="staffProfile.cardItem2.button4"
                                                                    defaultMessage={`Update personal details`}
                                                                />
                                                            </Button>
                                                        </form>
                                                    </div>
                                                ),
                                            },

                                            //# 763 User can see and edit their business details
                                            //if (!!this.state.account_type === 2)
                                            {
                                                //tabButton: "Business details",
                                                tabButton: this.props.intl.formatMessage(
                                                    {
                                                        id:
                                                            'staffProfile.cardItem2.tabButton5',
                                                        defaultMessage: `Business details`,
                                                    }
                                                ),
                                                tabContent: (
                                                    <form
                                                        onSubmit={
                                                            this.handleSubmit
                                                        }
                                                    >
                                                        <React.Fragment>
                                                            <GridContainer>
                                                                <GridItem
                                                                    xs={12}
                                                                    sm={12}
                                                                    md={6}
                                                                >
                                                                    {this.state
                                                                        .account_type !==
                                                                        2 && (
                                                                        <ul>
                                                                            <li>
                                                                                This
                                                                                user
                                                                                is
                                                                                NOT
                                                                                a
                                                                                business
                                                                                type
                                                                            </li>
                                                                        </ul>
                                                                    )}
                                                                    {this.state
                                                                        .account_type ===
                                                                        2 && (
                                                                        <React.Fragment
                                                                        >
                                                                            {/* Start layout #743 */}

                                                                            {/* Radio Buttons - customer_type_soleTrader */}
                                                                            <div
                                                                                className={
                                                                                    classes.checkboxAndRadio +
                                                                                    ' ' +
                                                                                    classes.checkboxAndRadioHorizontal
                                                                                }
                                                                            >
                                                                                <FormControlLabel
                                                                                    control={
                                                                                        <Radio
                                                                                            checked={
                                                                                                this
                                                                                                    .state
                                                                                                    .selectedBusinessValue ===
                                                                                                'customer_type_soleTrader'
                                                                                            }
                                                                                            onChange={
                                                                                                this
                                                                                                    .handleChange
                                                                                            }
                                                                                            value="customer_type_soleTrader"
                                                                                            name="radio button demo"
                                                                                            aria-label="Customer Type Sole Trader"
                                                                                            icon={
                                                                                                <FiberManualRecord
                                                                                                    className={
                                                                                                        classes.radioUnchecked
                                                                                                    }
                                                                                                />
                                                                                            }
                                                                                            checkedIcon={
                                                                                                <FiberManualRecord
                                                                                                    className={
                                                                                                        classes.radioChecked
                                                                                                    }
                                                                                                />
                                                                                            }
                                                                                            classes={{
                                                                                                checked:
                                                                                                    classes.radio,
                                                                                                root:
                                                                                                    classes.radioRoot,
                                                                                            }}
                                                                                        />
                                                                                    }
                                                                                    classes={{
                                                                                        label:
                                                                                            classes.label,
                                                                                    }}
                                                                                    label={
                                                                                        <span
                                                                                            style={{
                                                                                                color:
                                                                                                    'black',
                                                                                            }}
                                                                                        >
                                                                                            Sole
                                                                                            Trader
                                                                                        </span>
                                                                                    }
                                                                                />
                                                                            </div>

                                                                            {/* Radio Buttons - Company */}
                                                                            <div
                                                                                className={
                                                                                    classes.checkboxAndRadio +
                                                                                    ' ' +
                                                                                    classes.checkboxAndRadioHorizontal
                                                                                }
                                                                            >
                                                                                <FormControlLabel
                                                                                    control={
                                                                                        <Radio
                                                                                            checked={
                                                                                                this
                                                                                                    .state
                                                                                                    .selectedBusinessValue ===
                                                                                                'customer_type_company'
                                                                                            }
                                                                                            onChange={
                                                                                                this
                                                                                                    .handleChange
                                                                                            }
                                                                                            value="customer_type_company"
                                                                                            name="radio button demo"
                                                                                            aria-label="Customer Type Sole Trader"
                                                                                            icon={
                                                                                                <FiberManualRecord
                                                                                                    className={
                                                                                                        classes.radioUnchecked
                                                                                                    }
                                                                                                />
                                                                                            }
                                                                                            checkedIcon={
                                                                                                <FiberManualRecord
                                                                                                    className={
                                                                                                        classes.radioChecked
                                                                                                    }
                                                                                                />
                                                                                            }
                                                                                            classes={{
                                                                                                checked:
                                                                                                    classes.radio,
                                                                                                root:
                                                                                                    classes.radioRoot,
                                                                                            }}
                                                                                        />
                                                                                    }
                                                                                    classes={{
                                                                                        label:
                                                                                            classes.label,
                                                                                    }}
                                                                                    label={
                                                                                        <span
                                                                                            style={{
                                                                                                color:
                                                                                                    'black',
                                                                                            }}
                                                                                        >
                                                                                            Company
                                                                                        </span>
                                                                                    }
                                                                                />
                                                                            </div>

                                                                            {/* Radio Buttons - customer_type_trust */}
                                                                            <div
                                                                                className={
                                                                                    classes.checkboxAndRadio +
                                                                                    ' ' +
                                                                                    classes.checkboxAndRadioHorizontal
                                                                                }
                                                                            >
                                                                                <FormControlLabel
                                                                                    control={
                                                                                        <Radio
                                                                                            checked={
                                                                                                this
                                                                                                    .state
                                                                                                    .selectedBusinessValue ===
                                                                                                'customer_type_trust'
                                                                                            }
                                                                                            onChange={
                                                                                                this
                                                                                                    .handleChange
                                                                                            }
                                                                                            value="customer_type_trust"
                                                                                            name="radio button demo"
                                                                                            aria-label="Customer Type Sole Trader"
                                                                                            icon={
                                                                                                <FiberManualRecord
                                                                                                    className={
                                                                                                        classes.radioUnchecked
                                                                                                    }
                                                                                                />
                                                                                            }
                                                                                            checkedIcon={
                                                                                                <FiberManualRecord
                                                                                                    className={
                                                                                                        classes.radioChecked
                                                                                                    }
                                                                                                />
                                                                                            }
                                                                                            classes={{
                                                                                                checked:
                                                                                                    classes.radio,
                                                                                                root:
                                                                                                    classes.radioRoot,
                                                                                            }}
                                                                                        />
                                                                                    }
                                                                                    classes={{
                                                                                        label:
                                                                                            classes.label,
                                                                                    }}
                                                                                    label={
                                                                                        <span
                                                                                            style={{
                                                                                                color:
                                                                                                    'black',
                                                                                            }}
                                                                                        >
                                                                                            Trust
                                                                                        </span>
                                                                                    }
                                                                                />
                                                                            </div>

                                                                            {this
                                                                                .state
                                                                                .account_type ===
                                                                                2 &&
                                                                                this
                                                                                    .state
                                                                                    .selectedBusinessValue ===
                                                                                    'customer_type_soleTrader' && (
                                                                                    <GridItem
                                                                                        xs={
                                                                                            12
                                                                                        }
                                                                                        sm={
                                                                                            12
                                                                                        }
                                                                                        md={
                                                                                            12
                                                                                        }
                                                                                    >
                                                                                        <h4
                                                                                        >
                                                                                            {' '}
                                                                                            Account
                                                                                            Holder
                                                                                            -
                                                                                            Sole
                                                                                            Trader{' '}
                                                                                        </h4>
                                                                                        Provide
                                                                                        trading
                                                                                        name
                                                                                        and
                                                                                        abn
                                                                                        (if
                                                                                        applicable)
                                                                                        <CustomInput
                                                                                            labelText={
                                                                                                <span
                                                                                                >
                                                                                                    Trading
                                                                                                    Name
                                                                                                </span>
                                                                                            }
                                                                                            id="trading_name"
                                                                                            formControlProps={{
                                                                                                fullWidth: true,
                                                                                            }}
                                                                                            inputProps={{
                                                                                                value:
                                                                                                    this
                                                                                                        .state
                                                                                                        .business_trading_name ||
                                                                                                    '',
                                                                                                onChange: event =>
                                                                                                    this.change(
                                                                                                        event,
                                                                                                        'business_trading_name'
                                                                                                    ),
                                                                                            }}
                                                                                        />
                                                                                        <CustomInput
                                                                                            labelText={
                                                                                                <span
                                                                                                >
                                                                                                    ABN
                                                                                                </span>
                                                                                            }
                                                                                            id="abn"
                                                                                            formControlProps={{
                                                                                                fullWidth: true,
                                                                                            }}
                                                                                            inputProps={{
                                                                                                value:
                                                                                                    this
                                                                                                        .state
                                                                                                        .business_abn ||
                                                                                                    '',
                                                                                                onChange: event =>
                                                                                                    this.change(
                                                                                                        event,
                                                                                                        'business_abn'
                                                                                                    ),
                                                                                            }}
                                                                                        />
                                                                                    </GridItem>
                                                                                )}

                                                                            {this
                                                                                .state
                                                                                .account_type ===
                                                                                2 &&
                                                                                this
                                                                                    .state
                                                                                    .selectedBusinessValue ===
                                                                                    'customer_type_company' && (
                                                                                    <GridItem
                                                                                        xs={
                                                                                            12
                                                                                        }
                                                                                        sm={
                                                                                            12
                                                                                        }
                                                                                        md={
                                                                                            12
                                                                                        }
                                                                                    >
                                                                                        <h4
                                                                                        >
                                                                                            {' '}
                                                                                            Account
                                                                                            Holder
                                                                                            -
                                                                                            Company{' '}
                                                                                        </h4>
                                                                                        Provide
                                                                                        company
                                                                                        name
                                                                                        and
                                                                                        company
                                                                                        number/ACN
                                                                                        or
                                                                                        ABN
                                                                                        if
                                                                                        Australian
                                                                                        company,
                                                                                        or
                                                                                        company
                                                                                        number
                                                                                        if
                                                                                        international
                                                                                        <CustomInput
                                                                                            labelText={
                                                                                                <span
                                                                                                >
                                                                                                    Company
                                                                                                    Name
                                                                                                </span>
                                                                                            }
                                                                                            id="company_name"
                                                                                            formControlProps={{
                                                                                                fullWidth: true,
                                                                                            }}
                                                                                            inputProps={{
                                                                                                value:
                                                                                                    this
                                                                                                        .state
                                                                                                        .business_company_name ||
                                                                                                    '',
                                                                                                onChange: event =>
                                                                                                    this.change(
                                                                                                        event,
                                                                                                        'business_company_name'
                                                                                                    ),
                                                                                            }}
                                                                                        />
                                                                                        <CustomInput
                                                                                            labelText={
                                                                                                <span
                                                                                                >
                                                                                                    Company
                                                                                                    number
                                                                                                </span>
                                                                                            }
                                                                                            id="company_number"
                                                                                            formControlProps={{
                                                                                                fullWidth: true,
                                                                                            }}
                                                                                            inputProps={{
                                                                                                value:
                                                                                                    this
                                                                                                        .state
                                                                                                        .business_company_number ||
                                                                                                    '',
                                                                                                onChange: event =>
                                                                                                    this.change(
                                                                                                        event,
                                                                                                        'business_company_number'
                                                                                                    ),
                                                                                            }}
                                                                                        />
                                                                                        <CustomInput
                                                                                            labelText={
                                                                                                <span
                                                                                                >
                                                                                                    ABN
                                                                                                </span>
                                                                                            }
                                                                                            id="abn"
                                                                                            formControlProps={{
                                                                                                fullWidth: true,
                                                                                            }}
                                                                                            inputProps={{
                                                                                                value:
                                                                                                    this
                                                                                                        .state
                                                                                                        .business_abn ||
                                                                                                    '',
                                                                                                onChange: event =>
                                                                                                    this.change(
                                                                                                        event,
                                                                                                        'business_abn'
                                                                                                    ),
                                                                                            }}
                                                                                        />
                                                                                    </GridItem>
                                                                                )}

                                                                            {this
                                                                                .state
                                                                                .account_type ===
                                                                                2 &&
                                                                                this
                                                                                    .state
                                                                                    .selectedBusinessValue ===
                                                                                    'customer_type_trust' && (
                                                                                    <GridItem
                                                                                        xs={
                                                                                            12
                                                                                        }
                                                                                        sm={
                                                                                            12
                                                                                        }
                                                                                        md={
                                                                                            12
                                                                                        }
                                                                                    >
                                                                                        <h4
                                                                                        >
                                                                                            {' '}
                                                                                            Account
                                                                                            Holder
                                                                                            -
                                                                                            Trust{' '}
                                                                                        </h4>
                                                                                        Please
                                                                                        enter
                                                                                        trust
                                                                                        details
                                                                                        <CustomInput
                                                                                            labelText={
                                                                                                <span
                                                                                                >
                                                                                                    Trust
                                                                                                    Name
                                                                                                </span>
                                                                                            }
                                                                                            id="trust_name"
                                                                                            formControlProps={{
                                                                                                fullWidth: true,
                                                                                            }}
                                                                                            inputProps={{
                                                                                                value:
                                                                                                    this
                                                                                                        .state
                                                                                                        .business_trust_name ||
                                                                                                    '',
                                                                                                onChange: event =>
                                                                                                    this.change(
                                                                                                        event,
                                                                                                        'business_trust_name'
                                                                                                    ),
                                                                                            }}
                                                                                        />
                                                                                        <CustomInput
                                                                                            labelText={
                                                                                                <span
                                                                                                >
                                                                                                    ABN
                                                                                                </span>
                                                                                            }
                                                                                            id="abn"
                                                                                            formControlProps={{
                                                                                                fullWidth: true,
                                                                                            }}
                                                                                            inputProps={{
                                                                                                value:
                                                                                                    this
                                                                                                        .state
                                                                                                        .business_abn ||
                                                                                                    '',
                                                                                                onChange: event =>
                                                                                                    this.change(
                                                                                                        event,
                                                                                                        'business_abn'
                                                                                                    ),
                                                                                            }}
                                                                                        />
                                                                                    </GridItem>
                                                                                )}

                                                                            <Button
                                                                                color="primary"
                                                                                type="submit"
                                                                                className={
                                                                                    classes.updateProfileButton
                                                                                }
                                                                            >
                                                                                <FormattedMessage
                                                                                    id="staffProfile.cardItem2.button4TEST"
                                                                                    defaultMessage={`Update business details`}
                                                                                />
                                                                            </Button>

                                                                            {/* end of layout #743 */}
                                                                        </React.Fragment>
                                                                    )}
                                                                </GridItem>
                                                            </GridContainer>
                                                        </React.Fragment>
                                                    </form>
                                                ),
                                            },
                                            {
                                                tabButton: 'logins',
                                                tabContent: (
                                                    <>
                                                        <AntCard title={
                                                            <div style={{ display: 'flex', justifyContent: 'space-between'}}>
                                                                <h3>Logins</h3>
                                                                <div style={{ display: 'flex', justifyContent: 'end', gap: 10 }}>
                                                                    <AntButton type={'primary'} icon={<PlusOutlined />} disabled={this.props.app_state.current_client && this.props.app_state.current_client.aws_cognito_id !== this.props.app_state.current_user_id} onClick={() => this.setState({ isLoginModalOpen: true })}>Add Contact</AntButton>
                                                                    <AntButton type={'primary'} icon={<ReloadOutlined />} danger={true} disabled={this.props.app_state.current_client && this.props.app_state.current_client.aws_cognito_id !== this.props.app_state.current_user_id} onClick={() => this.fetchUserLogins()}>Reload Page</AntButton>

                                                                </div>
                                                            </div>
                                                        }>
                                                            <AntTable dataSource={this.state.userLogins}
                                                                      columns={this.buildColumns()}></AntTable>
                                                            <Modal visible={this.state.isLoginModalOpen}
                                                                   title={this.state.activeLoginsId ? 'Edit Login' : 'Add login'} onCancel={() => {
                                                                       this.setState({ isLoginModalOpen: false, activeLoginsId: null });
                                                                       this.loginsModal.current.resetFields();
                                                            }} onOk={() => this.loginsModal.current.submit() }>
                                                                <Form labelAlign={'left'} ref={this.loginsModal} form={this.loginsModal.current} onFinish={this.handleLoginSubmit}>
                                                                    <Form.Item name={'firstName'} label={'First Name'} rules={[{required: true, message: 'First name is required'}]}>
                                                                        <Input />
                                                                    </Form.Item>
                                                                    <Form.Item name={'lastName'} label={'Last Name'} rules={[{required: true, message: 'Last name is required'}]}>
                                                                        <Input />
                                                                    </Form.Item>
                                                                    {
                                                                        this.state.activeLoginsId == null ? <><Form.Item name={'email'} label={'Email'} rules={[{required: true, message: 'Email is required'}]}>
                                                                            <Input />
                                                                        </Form.Item>
                                                                        </> : <></>
                                                                    }
                                                                    <Form.Item name={'clientID'} hidden={true}>
                                                                        <Input />
                                                                    </Form.Item>
                                                                    <Form.Item name={'phoneNumber'} label={'Phone Number'}>
                                                                            <Input />
                                                                    </Form.Item>
                                                                    <Form.Item name={'balanceWithdrawal'} label={'Holding Withdrawal'} valuePropName={'checked'}>
                                                                            <Switch />
                                                                    </Form.Item>
                                                                    <Form.Item name={'submitTransfer'} label={'Submit Transfer Access'} valuePropName={'checked'}>
                                                                            <Switch />
                                                                    </Form.Item>
                                                                    <Form.Item name={'addBeneficiary'} label={'Add Beneficiary Access'} valuePropName={'checked'}>
                                                                        <Switch />
                                                                    </Form.Item>
                                                                    {
                                                                            this.state.activeLoginsId == null
                                                                                ? <>
                                                                                    <Form.Item name={'sendEmail'} label={'Send Email'} valuePropName={'checked'}>
                                                                                        <Checkbox />
                                                                                    </Form.Item>
                                                                                  </>
                                                                                : <></>
                                                                    }
                                                                </Form>
                                                            </Modal>
                                                        </AntCard>
                                                    </>
                                                )
                                            }
                                        ]}
                                    />
                                    <Clearfix />
                                </CardBody>
                            </Card>
                        </GridItem>
                    </GridContainer>
                </div>

                <Dialog
                    open={this.state.change_password_dialog}
                    onClose={this.handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">
                        <FormattedMessage
                            id="staffProfile.dialog.title"
                            defaultMessage={`Change Password`}
                        />
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            <CustomInput
                                success={
                                    this.state.old_password_state === 'success'
                                }
                                error={
                                    this.state.old_password_state === 'error'
                                }
                                labelText="Current Password *"
                                id="old_password"
                                formControlProps={{
                                    fullWidth: true,
                                }}
                                inputProps={{
                                    value: this.state.password,
                                    onChange: event => {
                                        this.handleChange(event)
                                    },
                                }}
                            />
                            <CustomInput
                                success={
                                    this.state.new_password_state === 'success'
                                }
                                error={
                                    this.state.new_password_state === 'error'
                                }
                                labelText="New Password *"
                                id="new_password"
                                helpText={this.state.new_password_helptext}
                                formControlProps={{
                                    fullWidth: true,
                                }}
                                inputProps={{
                                    value: this.state.new_password,
                                    onChange: event =>
                                        this.verifyPassword(
                                            event,
                                            'password',
                                            'new_password'
                                        ),
                                    type: 'password',
                                }}
                            />
                            <CustomInput
                                success={
                                    this.state.new_password_confirm_state ===
                                    'success'
                                }
                                error={
                                    this.state.new_password_confirm_state ===
                                    'error'
                                }
                                labelText="Confirm New Password *"
                                id="new_password_confirm"
                                formControlProps={{
                                    fullWidth: true,
                                }}
                                inputProps={{
                                    value: this.state.new_password_confirm,
                                    onChange: event =>
                                        this.change(
                                            event,
                                            'new_password_confirm',
                                            'equalTo',
                                            'new_password'
                                        ),
                                    type: 'password',
                                }}
                            />
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={this.handle_change_password_dialog_close}
                            color="info"
                        >
                            <FormattedMessage
                                id="staffProfile.dialog.cancel"
                                defaultMessage={`Cancel`}
                            />
                        </Button>
                        <Button
                            onClick={() =>
                                this.handle_change_password_proceed(
                                    this.state.old_password,
                                    this.state.new_password
                                )
                            }
                            color="primary"
                            autoFocus
                            disabled={
                                !(
                                    this.state.old_password.length > 0 &&
                                    this.state.new_password_state ===
                                        'success' &&
                                    this.state.new_password_confirm_state ===
                                        'success'
                                )
                            }
                        >
                            <FormattedMessage
                                id="staffProfile.dialog.confirm"
                                defaultMessage={`Change Password`}
                            />
                        </Button>
                    </DialogActions>
                </Dialog>

                <ChangeEmailDialog
                    open={this.state.change_email_dialog}
                    on_close={this.handle_change_email_dialog_close}
                    client_id={this.props.app_state.current_client.id}
                />
            </React.Fragment>
        )
    }
}

// export default withStyles(userProfileStyles)(injectIntl(UserProfile));
const mapStateToProps = (state, ownProps) => {
    return {
        app_state: state.app_state,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        switch_user: (clientId) => {
            dispatch(onInAppLoginSuccesClientId(clientId));
        }
    };
}

const UserProfileContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(userProfileStyles)(ListTable(injectIntl(UserProfile))))

export default UserProfileContainer
