import FxDonut from "../../../components/Charts/FxDonut";
import Title from "antd/es/typography/Title";
import {Typography} from "antd";
import React from "react";

const ExposureMonitorDonutDetails = ({ prevComponentState }) => {
    // console.log('prevComponentState', prevComponentState)

    const data = [
        {data: prevComponentState.thirtyDays, day: '0 - 30 Days'},
        {data: prevComponentState.sixtyDays, day: '30 - 60 Days'},
        {data: prevComponentState.ninetyDays, day: '60 - 90 Days'},
        {data: prevComponentState.ninetyPlus, day: '90+ Days'},
    ]

    if (data[0].data.length === 0) { return null }
    if (data[1].data.length === 0) { return null }
    if (data[2].data.length === 0) { return null }
    if (data[3].data.length === 0) { return null }

    const uncovered = [
        (data[0].data[1].value - data[0].data[0].value) < 0 ? 0 : (data[0].data[1].value - data[0].data[0].value),
        (data[1].data[1].value - data[1].data[0].value) < 0 ? 0 : (data[1].data[1].value - data[1].data[0].value),
        (data[2].data[1].value - data[2].data[0].value) < 0 ? 0 : (data[2].data[1].value - data[2].data[0].value),
        (data[3].data[1].value - data[3].data[0].value) < 0 ? 0 : (data[3].data[1].value - data[3].data[0].value),
    ]

    return <>
        <div style={{display: 'flex', justifyContent: 'space-between'}}>

            {
                [
                    {data: prevComponentState.thirtyDays, day: '0 - 30 Days'},
                    {data: prevComponentState.sixtyDays, day: '30 - 60 Days'},
                    {data: prevComponentState.ninetyDays, day: '60 - 90 Days'},
                    {data: prevComponentState.ninetyPlus, day: '90+ Days'},
                ].map((item, index) => <div
                    style={{display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <FxDonut chartData={item.data} id={`donut-${item.day}`}></FxDonut>
                    <span style={{ marginTop: '-30px'}}>
                        <Title style={{ textAlign: 'center' }} level={4}>{item.day}</Title>

                        {
                            item.data.map(i => <div style={{display: 'flex', gap: 5, justifyContent: 'center' }}>
                                <Typography.Link href={'#coverTable'}> {i.label}: </Typography.Link>
                                <Typography.Text> {new Intl.NumberFormat('AUD', {
                                    currency: 'AUD',
                                    style: 'currency',
                                    useGrouping: true,
                                    currencyDisplay: 'narrowSymbol',
                                    maximumFractionDigits: 2,
                                    minimumFractionDigits: 2
                                }).format(i.value)}</Typography.Text>
                            </div>)
                        }
                        {uncovered[index] > 0 &&
                        <div style={{display: 'flex', gap: 5, justifyContent: 'center' }}>
                                <Typography.Link href={'#coverTable'} style={{color: 'red'}}>Uncovered: </Typography.Link>
                                <Typography.Text> {new Intl.NumberFormat('AUD', {
                                    currency: 'AUD',
                                    style: 'currency',
                                    useGrouping: true,
                                    currencyDisplay: 'narrowSymbol',
                                    maximumFractionDigits: 2,
                                    minimumFractionDigits: 2
                                }).format(uncovered[index])}</Typography.Text>
                        </div>
                        }

                    </span>
                </div>)
            }
        </div>
    </>
}
export default ExposureMonitorDonutDetails