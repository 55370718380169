import { FormattedNumber } from 'react-intl';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useForm } from 'antd/es/form/Form';
import { API } from 'aws-amplify';
import dayjs from 'dayjs';
import {
  Alert,
  Button,
  Card,
  Col,
  DatePicker,
  Descriptions,
  Divider,
  Form,
  Input,
  InputNumber,
  message,
  Modal,
  Radio,
  Row,
  Segmented,
  Select,
  Switch,
  Table,
  Tag,
  Typography
} from 'antd';
import { ArrowLeftOutlined, CloseOutlined, PlusOutlined, ReloadOutlined } from '@ant-design/icons';
import TypographyCurrency from '../../../components/CurrencySelect/TypographyCurrency';
import ChartistGraph from 'react-chartist';
import CurrencySelect from '../../../components/CurrencySelect/CurrencySelect';
import FileUploader from '../../../components/FileUploader/FileUploader';
import Draggable from 'react-draggable';
import BeneficiaryModal from '../../../components/Beneficiaries/BeneficiaryModal';
import { CurrencyLocales } from '../../../AppUtil';
import { notification } from 'antd/es';
import axios from 'axios';
import { ExposureMonitorUtils, ExposureMonitorUtils as exposureMonitorUtils } from './exposureMonitorUtils';
import { useLocation } from 'react-router-dom';
import FxHorizontalBarChart from '../../Charts/FxHorizontalBarChart';
import Chip from "@material-ui/core/Chip";
import Title from "antd/es/typography/Title";
import { RateAlertAPI } from "../RateAlerts/RateAlertsUtil";
import FxPieChart from "../../Charts/FxPieChart";
import ExposureMonitorPieDescriptions from "./ExposureMonitorPieDescriptions";
import ExposureMonitorDetailCoveragePie from "./ExposurMonitorDetailCoveragePie";
import ExposureMonitorDonutDetails from "./ExposureMonitorDonutDetails";
import { connect } from "react-redux";
import withStyles from "@material-ui/core/styles/withStyles";
import ListTable from "../../../components/ListTable/ListTable";
import { Description } from "@material-ui/icons";

import './MonthlyChartAnimation.css'
import ExposureMonitorDetailOverallDescriptions from './ExposureMonitorDetailOverallDescriptions';
import FxDonut from "../../../components/Charts/FxDonut";

const style = theme => ({
  chartistTooltip: {
    position: 'absolute',
    display: 'none',
    padding: '5px',
    background: 'rgba(0, 0, 0, 0.8)',
    color: 'white',
    borderRadius: '3px',
    pointerEvents: 'none',
    zIndex: '100'
  }, currency: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: 'fit-content',
    '& > .currency-flag': {
      marginRight: '0.5rem', width: 52, height: 26
    }
  }, currencyLabel: {
    textAlign: 'left', '& > p': {
      marginBottom: -5, fontSize: '0.6rem'
    }
  },
  coverageCenter: {
    display: 'flex', alignItems: 'center', justifyContent: 'center'
  }
});
export const CurrencyElement = ({classes, currency, value}) => (<div className={classes.currency}>
  <div
    className={`currency-flag currency-flag-${currency ? currency.toLowerCase() : ''}`}
  />
  <div className={classes.currencyLabel}>
    <p>{currency}</p>
    <FormattedNumber
      minimumFractionDigits={2}
      maximumFractionDigits={2}
      value={value}
    />
  </div>
</div>);

const Context = React.createContext({
  name: 'Default'
});

const ExposureMonitorDetailActual = ({app_state, classes, getColumnSearchProps, history}) => {

  const desiredCoverage = useRef();
  const [payoutForm] = useForm();
  const [addCoverForm] = useForm();
  const [valueForm] = useForm();
  const [draftForwardForm] = useForm();

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const [api, contextHolder] = notification.useNotification();

  const [showChart, setShowChart] = useState(true);

  const uploaderRef = useRef();
  const beneficiaryModalRef = useRef();
  const draggableRef = useRef(null);

  const [modalDrag, setModalDrag] = useState({
    disabled: true, bounds: {left: 0, top: 0, bottom: 0, right: 0}
  });

  const [pieCharts, setPieCharts] = useState({
    currencyAverage: {
      labels: [], series: [], data: [], isUncoveredZero: true, forwardGtPayout: false, descriptions: []
    }
  });


  const [barCharts, setBarCharts] = useState({
    fxExposure: {
      labels: [], series: [], chartConfig: {
        axisY: {
          offset: 60
        },
        seriesBarDistance: 15, height: '400px', showPoint: true, axisX: {
          labelInterpolationFnc: function (value, i, j) {
            return value;
          }
        }
      }
    }, currencyPlan: {
      labels: [], series: [], chartConfig: {
        axisY: {
          offset: 60
        },
        seriesBarDistance: 15, height: '300px', showPoint: true, axisX: {
          labelInterpolationFnc: function (value, i, j) {
            return value;
          }
        }
      }
    }

  });

  const [componentState, setComponentState] = useState({
    transferType: 'PAYMENTS',
    coverType: 'FORWARD',
    activePayoutId: null,
    isPayoutModalOpen: false,
    isBenModalOpen: false,
    isForwardModalOpen: false,
    isDraftForwardModelOpen: false,
    isListModalOpen: false,
    isTransferModalOpen: false,
    isPayoutListLoading: false,
    beneficiaryList: [],
    currencyBeneficiaryList: [],
    fxCurrency: queryParams.get('currency') ? Number(queryParams.get('currency')) : 5,
    data: [],
    dataList: [],
    monthly: null,
    months: [],
    currentRate: null,
    dataState: 'ACTUAL',
    desired_coverage: null,
    account_list: [],
    owedEstimate: [],
    marketRate: 0,
    monthChips: [
      {label: 'February', value: 'FEB'},
      {label: 'March', value: 'MAR'},
      {label: 'April', value: 'APR'},
      {label: 'May', value: 'MAY'},
      {label: 'June', value: 'JUN'},
      {label: 'July', value: 'JUL'},
      {label: 'August', value: 'AUG'},
      {label: 'September', value: 'SEP'},
      {label: 'October', value: 'OCT'},
      {label: 'November', value: 'NOV'},
      {label: 'December', value: 'DEC'},
      {label: 'January', value: 'JAN'}
    ],
    thirtyDays: [],
    sixtyDays: [],
    ninetyDays: [],
    ninetyPlus: [],
    donutTableData: [],
    renderAverageRate: () => renderAverageRate(),
    populateDraftForwardForm: (values, state) => {
      draftForwardForm.setFieldsValue(values);
      app_state = state;
    },
    onAddCoverFromPieChart: (cover, amount, state) => {
      app_state = state;
      if (amount < 0) {
        // Modal.error({
        //     title: 'Overcovered',
        //     content: 'Your coverage level already exceeds ',
        // })
        addCoverForm.setFieldsValue({amount: 0});
        setComponentState(prev => ({...prev, isForwardModalOpen: true}));
      } else {
        addCoverForm.setFieldsValue({amount: amount.toFixed(2)});
        setComponentState(prev => ({...prev, isForwardModalOpen: true}));
      }

    },
  });

  const initPrefs = async () => {
    if (!app_state.current_client) return true;
    const [beneficiaries, clients] = await Promise.all([
      API.post('commons', '/fetch', {
        body: {
          context: 'beneficiaries', fields: ['*'], condition: {client_id: app_state.current_client.id, deleted: 0}
        }
      }),
      API.post('commons', '/fetch', {
        body: {
          context: 'clients', fields: ['*'], condition: {id: app_state.current_client.id}
        }
      }),
    ])
    const data = beneficiaries.filter(i => i.account_currency === componentState.fxCurrency);
    desiredCoverage.current.value = clients[0].exposure_desiredcoverage;
    setComponentState(prev => ({
      ...prev,
      beneficiaryList: beneficiaries,
      currencyBeneficiaryList: data,
      desired_coverage: clients[0].exposure_desiredcoverage
    }));
  };

  const prepareMappedData = (datum) => {
    let data = [];
    if (datum.payouts && datum.payouts.length > 0) {
      const d = datum.payouts.map(item => ({
        id: item.id,
        bid: item.beneficiary_id,
        identifier: 'cashplan_records',
        type: 'PAYOUTS',
        amount_from: null,
        amount: item.payout,
        currencyID: item.currencyID,
        date: item.scheduled,
        rate: null,
        draft: item.draft || false,
        attachments: (!(item.attachments == null || item.attachments === '')) ? JSON.parse(item.attachments) : []
      }));
      data = [...data, ...d];
    }
    if (datum.transfers && datum.transfers.length > 0) {
      const d = datum.transfers.map(item => ({
        id: item.id,
        identifier: 'transfers',
        bid: item.beneficiary_id,
        type: 'TRANSFERS',
        amount: item.amount_to,
        amount_from: item.amount_from,
        currencyID: item.currency_to_id,
        currencyFromId: item.currency_from_id,
        date: item.settlement_date,
        rate: item.client_rate,
        draft: item.draft || false,
        attachments: (!(item.attachments == null || item.attachments === '')) ? JSON.parse(item.attachments) : []
      }));
      data = [...data, ...d];
    }
    if (datum.forwards && datum.forwards.length > 0) {
      const d = datum.forwards.map(item => ({
        id: item.id,
        identifier: 'forwards',
        bid: null,
        type: 'FORWARDS',
        amount: item.amount_to,
        amount_from: item.amount_from,
        currencyID: item.currency_to_id,
        currencyFromId: item.currency_from_id,
        date: item.settlement_date,
        rate: item.client_rate,
        draft: false,
        attachments: (!(item.attachments == null || item.attachments === "")) ? JSON.parse(item.attachments) : []
      }));
      data = [...data, ...d];
    }
    if (datum.draftForwards && datum.draftForwards.length > 0) {
      const d = datum.draftForwards.map(item => ({
        id: item.id,
        identifier: 'forwards',
        bid: null,
        type: 'FORWARDS',
        amount: item.amountTo,
        amount_from: null,
        currencyID: item.currencyToID,
        currencyFromId: item.currencyFromID,
        date: item.settlementDate,
        rate: item.rate,
        draft: true
      }));
      data = [...data, ...d];
    }

    const fxExposureList = data.filter(i => !!dayjs(i.date).isAfter(dayjs()));
    data = data.filter(item => item.currencyID === componentState.fxCurrency && !!dayjs(item.date).isAfter(dayjs()));

    return {
      data: data,
      fxExposureList: fxExposureList
    };
  };

  const initData = () => {
    if (!app_state.current_client) return true;
    setComponentState(prev => ({...prev, isPayoutListLoading: true}));
    Promise.all([API.post('commons', '/fetch', {
      body: {
        context: 'cashplan_records',
        fields: ['*'],
        condition: {deleted: 0, receipt: null, clientID: app_state.current_client.id}
      }
    }), API.post('commons', '/fetch', {
      body: {
        context: 'forwards', fields: ['*'], condition: {
          deleted: 0, client_id: app_state.current_client.id
        }
      }
    }), API.post('commons', '/fetch', {
      body: {
        context: 'draft_forward', fields: ['*'], condition: {
          deleted: 0, clientID: app_state.current_client.id
        }
      }
    }), API.post('commons', '/fetch', {
      body: {
        context: 'transfers', fields: ['*'], condition: {
          deleted: 0, client_id: app_state.current_client.id
        }
      }
    })]).then(([payouts, forwards, draftForwards, transfers]) => prepareMappedData({
      payouts,
      forwards,
      draftForwards,
      transfers
    })).then(res => {

      const getUniqueDates = (data) => [
        ...new Map(data.map(item => {
          const date = dayjs(item.date);
          return [date.month(), {
            label: date.format('MMMM'),
            value: date.month() + 1,
            year: date.format('YYYY')
          }];
        })).values()
      ];

      const months = getUniqueDates(res.data);


      setComponentState(prev => ({
        ...prev,
        data: res.data,
        dataList: res.data,
        isPayoutListLoading: false,
        fxExposureList: res.fxExposureList,
        months: months
      }));


      const {
        eBarLabels = [],
        eBarSeries = []
      } = prepareFXExposureChart([...res.fxExposureList], componentState.dataState);
      let {barLabels = [], barSeries = []} = {barLabels: [], barSeries: []};
      if (!componentState.monthly) {
        const result = prepareCurrencyPlanChart(res.data, componentState.dataState);
        barLabels = result.barLabels;
        barSeries = result.barSeries;
      }
      setBarCharts(prev => ({
        ...prev,
        currencyPlan: {
          ...prev.currencyPlan, labels: barLabels, series: barSeries
        },
        fxExposure: {
          ...prev.fxExposure, labels: eBarLabels, series: eBarSeries
        }
      }));

      const {
        pieLabels,
        pieSeries,
        data,
        isUncoveredZero,
        descriptions
      } = prepareCurrencyAverageChart(res.data);
      setPieCharts(prev => ({
        ...prev,
        currencyAverage: {
          ...prev.currencyAverage,
          series: pieSeries,
          labels: pieLabels,
          data: data,
          isUncoveredZero,
          descriptions
        }
      }));
    });
  };

  const prepareHorizontalBarChartData = (data) => {
    if (data.length > 0) {

      if (componentState.monthly != null) {
        const {year, month} = componentState.monthly;
        const filterMonth = `${year}-${String(month).padStart(2, '0')}`; //
        data = data.filter(item =>
          dayjs(item.date).format('YYYY-MM') === filterMonth
        );
      }

      data = data.filter(item => !item.draft);
      const summedData = data.filter(item => item.type === 'PAYOUTS').reduce((acc, {bid, amount}) => {
        acc.set(bid, (acc.get(bid) || 0) + amount);
        return acc;
      }, new Map());

      const result = Array.from(summedData, ([bid, amount]) => ({
        bid,
        amount
      })).map(item => {
        const beneficiary = componentState.beneficiaryList.find(i => i.id === item.bid);
        return {
          label: beneficiary ? beneficiary.nickname : 'Currency Balance',
          value: item.amount
        };
      });

      setComponentState(prev => ({...prev, owedEstimate: result}));
    } else {
      setComponentState(prev => ({...prev, owedEstimate: []}));
    }
  };

  const prepareFXExposureChart = (data, includeDraft) => {
    const currencyIds = [...new Set(data.map(item => item.currencyID))];
    console.log('currencyIds', currencyIds);
    const labels = currencyIds.map(id => app_state.currency_list.find(i => i.id === id).iso_alpha_3).filter(Boolean);

    const seriesTypes = ['PAYOUTS', 'TRANSFERS', 'FORWARDS'];


    let newData = data.filter(item => !item.draft);

    const eBarSeries = seriesTypes.map(type =>
      labels.map(label => {
        const currencyId = app_state.currency_list.find(i => i.iso_alpha_3 === label).id;
        return newData
          .filter(i => i.currencyID === currencyId && i.type === type)
          .reduce((sum, {amount}) => sum + amount, 0);
      })
    );

    return {eBarLabels: labels, eBarSeries};
  };

  const prepareCurrencyPlanChart = (datum, isDraft) => {
    if (!componentState.monthly) {

      let data = datum.map(i => {
        const item = {...i};
        if (i.type !== 'PAYOUTS') {
          item.type = 'COVERED';
        }
        return item;
      });

      data = data.filter(item => !item.draft);

      const expectedTypes = ['PAYOUTS', 'COVERED'];

      const groupedData = data.reduce((acc, {type, amount, date}) => {
        const formattedDate = dayjs(date).format('MMM YYYY');
        return {
          ...acc,
          [type]: {
            ...(acc[type] || {}),
            [formattedDate]: ((acc[type] && acc[type][formattedDate]) || 0) + amount
          }
        };
      }, {});

      expectedTypes.forEach(type => {
        if (!groupedData[type]) {
          groupedData[type] = {[dayjs().format('MMM YYYY')]: 0}; // Initialize empty object for missing types
        }
      });

      const labels = Array.from({length: 12}, (_, i) => dayjs().add(i, 'month').format('MMM YYYY'));
      const fillMissingMonths = (dataObject = {}) => labels.reduce((acc, month) => ({
        ...acc,
        [month]: dataObject[month] || 0
      }), {});

      const series = expectedTypes.map(type => [...Object.values(fillMissingMonths(groupedData[type]))]);
      return {
        barLabels: Array.from({length: 12}, (_, i) => dayjs().add(i, 'month').format('MMM YYYY')),
        barSeries: series
      };
    }
  };

  const prepareCurrencyAverageChart = (data) => {

    let payout = data.filter(i => i.type === 'PAYOUTS').reduce((acc, obj) => acc + obj.amount, 0);
    let covered = data.filter(i => i.type !== 'PAYOUTS').reduce((acc, obj) => acc + obj.amount, 0);

    payout = data.filter(i => i.type === 'PAYOUTS' && !i.draft).reduce((acc, obj) => acc + obj.amount, 0);
    covered = data.filter(i => i.type !== 'PAYOUTS' && !i.draft).reduce((acc, obj) => acc + obj.amount, 0);

    let uncovered = payout - covered;

    let balance = 0;
    if (componentState.account_list.length > 0) {
      const acc = componentState.account_list.find(i => i.currencies_short_name === getSelectedCurrency(componentState.fxCurrency));
      covered = acc ? (acc.balance + covered) : covered;
      balance = acc ? acc.balance : 0;
      uncovered = uncovered - (acc ? acc.balance : 0);
    }

    const descriptions = ExposureMonitorUtils.prepareDescriptions(data, componentState.dataState, null, balance, componentState, app_state, renderAverageRate());

    const series = [
      {label: 'Uncovered Amount', value: uncovered},
      {label: 'Covered Amount', value: covered}
    ];

    const seriesB = [
      {label: 'Uncovered', value: uncovered},
      {label: 'Covered', value: covered}
    ];

    const total = covered + uncovered;

    const labels = seriesB.map(i => {
      const percentage = ((i.value / total) * 100).toFixed(0);
      return `${i.label}`;
      // return `${i.label} = ${percentage}%`;
      // return ' ';
    });

    const desc = [...series];
    desc.push({label: 'Payments Loaded', value: payout});

    return {
      descriptions: descriptions,
      pieSeries: seriesB,
      pieLabels: labels.map((val, ind) => series[ind].value === 0 ? ' ' : val),
      data: desc,
      isUncoveredZero: uncovered < 0
    };
  };

  const onEditBtnClick = (payoutId) => {
    const loading = message.loading(`Loading id #${payoutId}. Please wait..`, 0);
    API.post('commons', '/fetch', {
      body: {
        context: 'cashplan_records', condition: {id: payoutId}, fields: ['*']
      }
    }).then(res => {
      const [d] = res;
      setComponentState(prev => ({
        ...prev, isPayoutModalOpen: true, activePayoutId: payoutId
      }));
      if (d.attachments == null && d.attachments === '') {
        const bottle = (JSON.parse(d.attachments) || []).map(i => ({
          name: i, uid: i, status: 'done'
        }));
        uploaderRef.current.setFileList(bottle);
      }
      payoutForm.setFieldsValue({...d, date: dayjs(d.date)});

    }).catch(err => {
      console.log(err);
      message.error('Failed to load. Please try again..');
    }).finally(() => loading());
  };

  const buildColumns = () => {
    return exposureMonitorUtils.buildPayoutColumns(getColumnSearchProps, componentState, app_state, sendForward, classes, initData, onEditBtnClick, onViewDocumentsBtnClick, history, setComponentState);
  };

  const buildForwardColumns = () => {
    return exposureMonitorUtils.buildCoveredColumns(getColumnSearchProps, componentState, app_state, sendForward, classes, initData, onEditBtnClick, onViewDocumentsBtnClick, history, setComponentState);
  };

  const onViewDocumentsBtnClick = (attachments) => {
    Modal.confirm({
      title: 'Attachments',
      width: 700,
      content: <Row gutter={[16, 16]}>
        <Divider />
        {attachments.map(i => <>
          <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
            <FileUploader previewOnly={true}
                          thumbType={'picture-card'}
                          uploaderType={'PDF'}
                          fetchFileList={() => {
                            const file = {
                              name: i,
                              uid: i,
                              status: 'done'
                            };
                            return Promise.resolve([file]);
                          }}></FileUploader>
          </Col>
        </>)}
      </Row>,
      cancelButtonProps: {hidden: true},
      maskClosable: true
    });
  };

  const setCurrentRate = async () => {
    if (componentState.fxCurrency != null && app_state.currency_list.length > 0) {
      const currency = app_state.currency_list.find(i => i.id === componentState.fxCurrency);
      await axios
        .get(`https://apilayer.net/api/live?access_key=a4eb7fd0501842eb4d4712cc459cae5f&currencies=${currency.iso_alpha_3}&source=AUD&format=1`).then(res => {
          const rate = res.data.quotes[`AUD${currency.iso_alpha_3}`];
          setComponentState(prev => ({...prev, currentRate: rate}));
        });
    } else {
      setComponentState(prev => ({...prev, currentRate: null}));
    }
  };

  const fetchHoldingAccountBalance = async () => {
    if (!app_state.current_client || !componentState.fxCurrency) return [];
    const currentClient = app_state.current_client.id;
    const response = await API.get('holding_accounts', `/get/all/v2/${currentClient}`, {});
    const account_list = response.holding_account_list.filter(item => item.currency_id === componentState.fxCurrency).map(record => ({
      currencies_short_name: record.currencies_short_name,
      balance: record.balance,
      url: `/holding-account?account=${record.id}`
    }));
    setComponentState(prev => ({...prev, account_list: account_list}));
  };

  const fetchMarketRate = () => {
    const currency = (app_state.currency_list || []).find(item => item.id === componentState.fxCurrency);
    if (currency) {
      const url = `https://apilayer.net/api/live?access_key=a4eb7fd0501842eb4d4712cc459cae5f`
      axios.get(url, {
        params: {
          currencies: currency.iso_alpha_3,
          source: 'AUD',
          format: '1'
        },
      }).then(({data}) => {
        const actual_rate = data.quotes[`AUD${currency.iso_alpha_3}`];
        let rate = Number.parseFloat(actual_rate);
        let rate_adjust = Number.parseFloat(app_state.current_client.default_rate);
        let adjusted_rate = rate - rate * rate_adjust;
        setComponentState(prev => ({...prev, marketRate: adjusted_rate}));
      })
    }
  }


  const prepareMonthlyDonuts = (days) => {
    return ExposureMonitorUtils.prepareDonutPayments(
      days,
      componentState.account_list.reduce((acc, num) => acc + num.balance, 0),
      'ACTUAL'
    );
  }

  const prepareDonutsData = ({day30, day30to60, day60to90, day90plus}) => {
    const total = componentState.account_list.reduce((acc, num) => acc + num.balance, 0);

    const day30Data = ExposureMonitorUtils.prepareDonutPayments(day30, total, 'ACTUAL').find(item => item.label === 'Payments');
    const day60Data = ExposureMonitorUtils.prepareDonutPayments(day30to60, total, 'ACTUAL').find(item => item.label === 'Payments');
    const day90Data = ExposureMonitorUtils.prepareDonutPayments(day60to90, total, 'ACTUAL').find(item => item.label === 'Payments');
    const day90pData = ExposureMonitorUtils.prepareDonutPayments(day90plus, total, 'ACTUAL').find(item => item.label === 'Payments');

    const tableData = [
      {age: '0 - 30 Days', balance: day30Data.value, count: day30Data.count},
      {age: '30 - 60 Days', balance: day60Data.value, count: day60Data.count},
      {age: '60 - 90 Days', balance: day90Data.value, count: day90Data.count},
      {age: '90+ Days', balance: day90pData.value, count: day90pData.count}
    ];
    setComponentState(prev => ({...prev, donutTableData: tableData}));

  }

  const prepareMonthlyOverallDonuts = ({day30, day30to60, day60to90, day90plus}) => {
    const ledgerBalance = componentState.account_list.reduce((acc, num) => acc + num.balance, 0)
    const donutThirtyDays = ExposureMonitorUtils.prepareDonutPayments(day30, ledgerBalance, 'ACTUAL');
    const donutSixtyDays = ExposureMonitorUtils.prepareDonutPayments(day30to60, 0, 'ACTUAL');
    const donutNinetyDays = ExposureMonitorUtils.prepareDonutPayments(day60to90, 0, 'ACTUAL');
    const donutNinetyPlus = ExposureMonitorUtils.prepareDonutPayments(day90plus, 0, 'ACTUAL');

    console.log('Donut 30 Days', donutThirtyDays);
    console.log('Donut 60 Days', donutSixtyDays);
    console.log('Donut 90 Days', donutNinetyDays);
    console.log('Donut 90 Plus', donutNinetyPlus);

    const thirtyDaysCover = donutThirtyDays.find(item => item.label === 'Cover');
    const sixtyDaysCover = donutSixtyDays.find(item => item.label === 'Cover');
    const ninetyDaysCover = donutNinetyDays.find(item => item.label === 'Cover');
    const ninetyPlusCover = donutNinetyPlus.find(item => item.label === 'Cover');
    const thirtyDaysPayments = donutThirtyDays.find(item => item.label === 'Payments');
    const sixtyDaysPayments = donutSixtyDays.find(item => item.label === 'Payments');
    const ninetyDaysPayments = donutNinetyDays.find(item => item.label === 'Payments');
    const ninetyPlusPayments = donutNinetyPlus.find(item => item.label === 'Payments');

    if (thirtyDaysCover.value > thirtyDaysPayments.value) {
      sixtyDaysCover.value = sixtyDaysCover.value + (thirtyDaysCover.value - thirtyDaysPayments.value);
      sixtyDaysCover.percentage = sixtyDaysCover.value / sixtyDaysPayments.value * 100;
      sixtyDaysPayments.percentage = 100 - sixtyDaysCover.percentage;
      if (sixtyDaysPayments.value === 0) {
        sixtyDaysCover.percentage = 100;
        sixtyDaysPayments.percentage = 0;
      }
    }
    if (sixtyDaysCover.value > sixtyDaysPayments.value) {
      ninetyDaysCover.value = ninetyDaysCover.value + (sixtyDaysCover.value - sixtyDaysPayments.value);
      ninetyDaysCover.percentage = ninetyDaysCover.value / ninetyDaysPayments.value * 100;
      ninetyDaysPayments.percentage = 100 - ninetyDaysCover.percentage;
      if (ninetyDaysPayments.value === 0) {
        ninetyDaysCover.percentage = 100;
        ninetyDaysPayments.percentage = 0;
      }
    }
    if (ninetyDaysCover.value > ninetyDaysPayments.value) {
      console.log('Ninety Days Cover', ninetyDaysCover.value);
      console.log('Ninety Days Payments', ninetyDaysPayments.value);
      console.log('Ninety Plus Cover', ninetyPlusCover.value);
      ninetyPlusCover.value = ninetyPlusCover.value + (ninetyDaysCover.value - ninetyDaysPayments.value);
      ninetyPlusCover.percentage = ninetyPlusCover.value / ninetyPlusPayments.value * 100;
      console.log('Ninety Plus Cover', ninetyPlusCover.value);
      ninetyPlusPayments.percentage = 100 - ninetyPlusCover.percentage;
      if (ninetyPlusPayments.value === 0) {
        ninetyPlusCover.percentage = 100;
        ninetyPlusPayments.percentage = 0;
      }
    }

    setComponentState(prev => ({
      ...prev,
      thirtyDays: donutThirtyDays,
      sixtyDays: donutSixtyDays,
      ninetyDays: donutNinetyDays,
      ninetyPlus: donutNinetyPlus
    }));

  }


  useEffect(() => {
    prepareHorizontalBarChartData(componentState.data);
    const {day30, day30to60, day60to90, day90plus} = ExposureMonitorUtils.getDateRange(componentState.dataList);
    prepareMonthlyOverallDonuts({day30, day30to60, day60to90, day90plus});
    prepareDonutsData({day30, day30to60, day60to90, day90plus});
  }, [componentState.data, componentState.beneficiaryList, componentState.monthly]);

  useEffect(() => {
    fetchHoldingAccountBalance();
  }, [app_state.current_client]);

  useEffect(() => {
    initPrefs();
    initData();
    if (queryParams.get('currency') != null && componentState.fxCurrency == null) {
      setComponentState(prev => ({...prev, fxCurrency: Number(queryParams.get('currency'))}));
    }
    fetchMarketRate();
  }, [componentState.account_list]);

  useEffect(() => {
    fetchHoldingAccountBalance();
    setCurrentRate();
    fetchMarketRate();
  }, [componentState.fxCurrency]);

  useEffect(() => {
    if (componentState.monthly) {
      reConstructMonthlyExposure(componentState.monthly, componentState.dataState);
    } else {
      initData();
    }
  }, [componentState.monthly]);

  useEffect(() => {
    const chart1 = document.querySelector('.ct-chart-tBar');
    const chart2 = document.querySelector('.ct-chart-mBar');

    [chart1, chart2].forEach((chart, index) => {
      const tooltip = document.createElement('div');
      tooltip.className = `${classes.chartistTooltip}`;
      document.body.appendChild(tooltip);

      if (chart) {
        chart.addEventListener('mouseover', (event) => {
          if (event.target.classList.contains('ct-bar')) {
            const value = event.target.getAttribute('ct:value');
            const meta = event.target.getAttribute('meta');
            const label = event.target.getAttribute('hoverLabel');

            let str = '';
            if (componentState.data.length > 0) {
              const currency = componentState.fxCurrency;
              const payouts = componentState.fxExposureList
                .filter(i => i.currencyID === currency && i[index] != null && dayjs(i.date).format('MM/YYYY') === meta);
              payouts.forEach(i => {
                str += dayjs(i.date).format('DD/MM/YYYY') + ':' + i.amount + `<br />`;
              });
            }
            tooltip.innerHTML = `${label}: ${value}<br />${meta}<br /> ${str}`;
            tooltip.style.display = 'block';
            tooltip.style.left = `${event.pageX}px`;
            tooltip.style.top = `${event.pageY - 40}px`;
          }
        });

        chart.addEventListener('mouseout', () => {
          tooltip.style.display = 'none';
        });

        chart.addEventListener('mousemove', (event) => {
          tooltip.style.left = `${event.pageX}px`;
          tooltip.style.top = `${event.pageY - 40}px`;
        });
      }
    });


    const pie1 = document.querySelector('.ct-pie-chart');
    const pie2 = document.querySelector('.ct-pie-chart-2');

    if (pie1 && pie2) {

      [pie1, pie2].forEach((pie, index) => {

        const legendContainer = document.getElementById(`chart-legend-${index}`);
        legendContainer.innerHTML = '';
        const series = pie.querySelectorAll('.ct-series');
        const labels = pieCharts.currencyAverage.labels;

        series.forEach((serie, index) => {
          const legendItem = document.createElement('div');
          legendItem.classList.add('legend-item');

          legendItem.style.display = 'flex';
          legendItem.style.alignItems = 'center';
          legendItem.style.marginBottom = '5px';
          legendItem.style.marginRight = '10px';

          const label = document.createElement('span');
          const labelss = labels[index];
          label.textContent = labels[index]; // Add corresponding label

          if (labelss != null) {
            const img = document.createElement('img');
            img.setAttribute('src', `https://fwwportal-branding.s3.us-east-1.amazonaws.com/currencies/${labelss.toLowerCase()}.png`);
            img.setAttribute('width', '15px');
            img.setAttribute('height', '10px');
            img.style.marginRight = '5px';
            legendItem.appendChild(img);
          }
          legendItem.appendChild(label);
          legendContainer.appendChild(legendItem);
        });
      });
    }
  }, [componentState.fxCurrency]);

  const renderTopBlock = () => {
    return <div style={{display: 'flex', alignItems: 'center', width: "100%"}}>
      <div>
        <h3 style={{textAlign: 'center', marginBottom: 0}}>
          <TypographyCurrency className={classes.coverageCenter}
                              iso_alpha_3={getSelectedCurrency()}>
            {getSelectedCurrency()}&nbsp;Coverage
          </TypographyCurrency>
        </h3>
      </div>
      <div style={{display: "flex", marginLeft: "auto", gap: "10px"}}>
        <Form.Item label={'Currency'} name={'selected_currency'} style={{marginBottom: 0}}>
          <Select allowClear={true}
                  showSearch={true}
                  defaultValue={componentState.fxCurrency}
                  style={{width: '250px'}}
                  optionFilterProp={'alt'}
                  options={app_state.currency_list.filter(i => i.id !== 1).map(i => ({
                    ...i,
                    label: <TypographyCurrency
                      iso_alpha_3={i.iso_alpha_3}>[{i.iso_alpha_3}] {i.full_name}</TypographyCurrency>,
                    value: i.id,
                    alt: `${i.full_name} ${i.iso_alpha_3}`
                  }))}
                  onChange={((val, datum) => setComponentState(prev => ({
                    ...prev,
                    fxCurrency: val,
                    monthly: null
                  })))}
                  placeholder={'-- SELECT --'}></Select>
        </Form.Item>
      </div>
      {/*<div style={{display: 'flex', alignItems: 'start', gap: 5, marginLeft: '10px'}}>*/}
      {/*    <Button type={'primary'}*/}
      {/*            size={'small'}*/}
      {/*            icon={<PlusOutlined/>}*/}
      {/*            onClick={() => {*/}
      {/*                setComponentState(prev => ({*/}
      {/*                    ...prev,*/}
      {/*                    isPayoutModalOpen: true,*/}
      {/*                    activePayoutId: null,*/}
      {/*                    currencyBeneficiaryList: componentState.currencyBeneficiaryList*/}
      {/*                }));*/}
      {/*                if (componentState.dataState === 'DRAFT') {*/}
      {/*                    payoutForm.setFieldsValue({draft: true});*/}
      {/*                }*/}
      {/*            }}>*/}
      {/*        Add Payment*/}
      {/*    </Button>*/}
      {/*    <Button type={'primary'}*/}
      {/*            size={'small'}*/}
      {/*            icon={<PlusOutlined/>}*/}
      {/*            onClick={() => {*/}
      {/*                setComponentState(prev => ({*/}
      {/*                    ...prev, isForwardModalOpen: true*/}
      {/*                }));*/}
      {/*            }}>*/}
      {/*        Request Cover*/}
      {/*    </Button>*/}
      {/*</div>*/}
    </div>;
  };

  const getSelectedCurrency = () => {
    const currency = app_state.currency_list.find(item => item.id === componentState.fxCurrency);
    if (currency != null) {
      return currency.iso_alpha_3;
    } else {
      return '-';
    }
  };

  const reConstructMonthlyExposure = ({year, month}, includeDraft) => {

    if (year && month != null) {
      const formattedMonth = `${year}-${String(month).padStart(2, '0')}`; // Format month to 'YYYY-MM'

      const types = ['PAYOUTS', 'COVERED']; // Add more types if necessary

      let data = componentState.data.map(i => {
        const item = {...i};
        if (i.type !== 'PAYOUTS') {
          item.type = 'COVERED';
        }
        return item;
      });
      data = data.filter(item => !item.draft);

      const groupedData = data.reduce((acc, {type, amount, date}) => {
        const formattedDate = dayjs(date).format('YYYY-MM-DD'); // Full date for filtering
        if (formattedDate.startsWith(formattedMonth)) { // Filter only dates from the passed month and year
          return {
            ...acc,
            [type]: {
              ...(acc[type] || {}),
              [formattedDate]: ((acc[type] && acc[type][formattedDate]) || 0) + amount // Sum data by day
            }
          };
        }
        return acc;
      }, {});

      types.forEach(type => {
        if (!groupedData[type]) {
          groupedData[type] = {[dayjs().format('YYYY-MM-DD')]: 0}; // Initialize missing type
        }
      });

      const daysInMonth = dayjs(`${year}-${month}`, 'YYYY-MM').daysInMonth(); // Number of days in the passed month

      const labels = Array.from({length: daysInMonth}, (_, i) => (i + 1).toString());

      const fillMissingDays = (dataObject = {}) => labels.reduce((acc, day) => {
        const fullDate = dayjs(`${year}-${month}-${day}`).format('YYYY-MM-DD');
        return {
          ...acc,
          [fullDate]: dataObject[fullDate] || 0 // Fill missing days with 0
        };
      }, {});

      const series = types.map(type => [...Object.values(fillMissingDays(groupedData[type]))]);

      const filteredData = componentState.data.filter(item => {
        const itemDate = dayjs(item.date);
        return itemDate.year() === parseInt(year) && itemDate.month() + 1 === parseInt(month);
      });


      setBarCharts(prev => ({
        ...prev,
        currencyPlan: {
          ...prev.currencyPlan, labels: labels, series: series
        }
      }));
      setComponentState(prev => ({...prev, dataList: filteredData}));

      // ====================== For Pie chart ==================================

      let payout = 0;
      let covered = 0;
      payout = data.filter(i => i.type === 'PAYOUTS' && !i.draft).reduce((acc, obj) => acc + obj.amount, 0);
      covered = data.filter(i => i.type !== 'PAYOUTS' && !i.draft).reduce((acc, obj) => acc + obj.amount, 0);

      let uncovered = payout - covered;

      let balance = 0;
      if (componentState.account_list.length > 0) {
        const acc = componentState.account_list.find(i => i.currencies_short_name === getSelectedCurrency(componentState.fxCurrency));
        covered = acc ? (acc.balance + covered) : covered;
        balance = acc ? acc.balance : 0;
        uncovered = uncovered - (acc ? acc.balance : 0);
      }

      const pieSeries = [
        {label: 'Uncovered', value: uncovered},
        {label: 'Covered', value: covered}
      ];

      const total = covered + uncovered;

      const pieLabels = pieSeries.map(i => {
        const percentage = i.value < total ? ((i.value / total) * 100).toFixed(0) : 100;
        return `${i.label} = ${percentage}%`;
      });

      const desc = [...pieSeries];
      desc.push({label: 'Total Payout', value: payout});

      const descriptions = ExposureMonitorUtils.prepareDescriptions(data, componentState.dataState, null, balance, componentState, app_state, renderAverageRate());

      const currencyAvg = {
        series: pieSeries,
        descriptions: descriptions,
        labels: pieLabels.map((val, ind) => pieSeries[ind].value === 0 ? ' ' : val),
        data: desc,
        isUncoveredZero: uncovered < 0
      };
      setPieCharts(prev => ({
        ...prev, currencyAverage: {...prev, ...currencyAvg}
      }));
    }
  };

  const listener = (strokeWidth = null) => {

    return {
      draw: (data) => {
        if (data.type === 'bar') {

          if (data.seriesIndex === 0) {
            data.element.attr({
              style: 'stroke: #f4c63d;' // Blue color for first series
            });
          }
          // Apply color for second series
          if (data.seriesIndex === 1) {
            data.element.attr({
              style: 'stroke: #00bcd4;' // Red color for second series
            });
          }
          const xAxisLabel = data.axisX.ticks[data.index];

          data.element._node.addEventListener('click', () => {
            const date = dayjs(xAxisLabel).format('YYYY-MM');
            const mth = date.split('-')[1];
            if (mth != null) {
              setComponentState(prev => ({
                ...prev,
                monthly: {
                  year: date.split('-')[0],
                  month: date.split('-')[1],
                  label: mth != null ? dayjs(`${date.split('-')[1]}`, 'M').format('MMMM') : null
                }
              }));
            }
          });


          data.element.attr({
            'meta': xAxisLabel,
            'series': data.seriesIndex,
            'hoverLabel': data.seriesIndex === 0 ? 'Payouts' : (data.seriesIndex === 1 ? 'Covered' : 'Forwards')
          });

        }

        // if (data.type === 'label' && data.axis.units.pos === 'x') {
        //     data.element.empty()._node.innerHTML = data.text.length === 3 ? `
        // <span class="ct-label ct-horizontal ct-end" style="width: 50px; height: 20px">${data.text}</span>
        // ` : `<span class="ct-label ct-horizontal ct-end" style="width: 50px; height: 20px">${data.text}</span>`;
        // }
      }
    };
  };


  const addCoverForm_handleRadioChange = (e) => {
    console.log(e)

    const {day30, day30to60, day60to90, day90plus} = ExposureMonitorUtils.getDateRange(componentState.dataList);

    const ledgerBalance = componentState.account_list.reduce((acc, num) => acc + num.balance, 0)
    const donutThirtyDays = ExposureMonitorUtils.prepareDonutPayments(day30, ledgerBalance, 'ACTUAL');
    const donutSixtyDays = ExposureMonitorUtils.prepareDonutPayments(day30to60, 0, 'ACTUAL');
    const donutNinetyDays = ExposureMonitorUtils.prepareDonutPayments(day60to90, 0, 'ACTUAL');
    const donutNinetyPlus = ExposureMonitorUtils.prepareDonutPayments(day90plus, 0, 'ACTUAL');

    const thirtyDaysPayments = donutThirtyDays.find(item => item.label === 'Payments').value;
    const sixtyDaysPayments = donutSixtyDays.find(item => item.label === 'Payments').value;
    const ninetyDaysPayments = donutNinetyDays.find(item => item.label === 'Payments').value;
    const ninetyPlusPayments = donutNinetyPlus.find(item => item.label === 'Payments').value;
    const thirtyDaysCover = donutThirtyDays.find(item => item.label === 'Cover').value;
    const sixtyDaysCover = donutSixtyDays.find(item => item.label === 'Cover').value;
    const ninetyDaysCover = donutNinetyDays.find(item => item.label === 'Cover').value;
    const ninetyPlusCover = donutNinetyPlus.find(item => item.label === 'Cover').value;

    const desiredCoverage = Number.parseInt(componentState.desired_coverage || 0) / 100;

    const thirtyDesired = thirtyDaysPayments * desiredCoverage;
    const sixtyDesired = sixtyDaysPayments * desiredCoverage;
    const ninetyDesired = ninetyDaysPayments * desiredCoverage;
    const ninetyPlusDesired = ninetyPlusPayments * desiredCoverage;

    const totalDesired = thirtyDesired + sixtyDesired + ninetyDesired + ninetyPlusDesired;
    const totalCover = thirtyDaysCover + sixtyDaysCover + ninetyDaysCover + ninetyPlusCover;

    const valueMap = {
      "all": (totalDesired - totalCover) < 0 ? 0 : (totalDesired - totalCover),
      "0-30": (thirtyDesired - thirtyDaysCover) < 0 ? 0 : (thirtyDesired - thirtyDaysCover),
      "30-60": (sixtyDesired - sixtyDaysCover) < 0 ? 0 : (sixtyDesired - sixtyDaysCover),
      "60-90": (ninetyDesired - ninetyDaysCover) < 0 ? 0 : (ninetyDesired - ninetyDaysCover),
      "90+": (ninetyPlusDesired - ninetyPlusCover) < 0 ? 0 : (ninetyPlusDesired - ninetyPlusCover)
    };
    addCoverForm.setFieldsValue({amount: (valueMap[e.target.value] || 0).toFixed(2)});
  };

  const handleSubmit = (payload) => {
    const loading = message.loading('Saving payout record. Please wait..', 0);
    if (!componentState.activePayoutId) {
      payload.recordCreated = dayjs(new Date()).startOf('day');
    }
    payload.clientID = app_state.current_client.id;
    payload.attachments = JSON.stringify(payload.attachments);

    const request = componentState.activePayoutId != null ? API.post('commons', '/update', {
      body: {
        context: 'cashplan_records',
        data: payload,
        condition: {id: componentState.activePayoutId}
      }
    }) : API.post('commons', '/insert', {
      body: {
        context: 'cashplan_records',
        data: payload
      }
    });
    request.then(() => {
      message.success('Payout record saved');
      setComponentState(prev => ({...prev, activePayoutId: null, isPayoutModalOpen: false}));
      initData();
    }).catch(err => {
      console.log(err);
      message.error('Unable to save payout record. Please try again..');
    }).finally(() => loading());
  };


  const onStart = (_event, uiData) => {
    const {clientWidth, clientHeight} = window.document.documentElement;
    const targetRect = draggableRef.current.getBoundingClientRect();
    if (!targetRect) {
      return;
    }
    setModalDrag(prev => ({
      ...prev,
      bounds: {
        left: -targetRect.left + uiData.x,
        right: clientWidth - (targetRect.right - uiData.x),
        top: -targetRect.top + uiData.y,
        bottom: clientHeight - (targetRect.bottom - uiData.y)
      }
    }));
  };

  const handleForwardsSubmit = async (values) => {
    if (componentState.coverType === 'SPOT') {
      const fromCurrency = app_state.currency_list.find(item => values.from_currency === item.id).iso_alpha_3;
      const toCurrency = app_state.currency_list.find(item => values.to_currency === item.id).iso_alpha_3;
      history.push(`/shortcut_transfer/${toCurrency}-${fromCurrency}--${values.amount}-----`);
    } else {
      sendForward(values);
    }
  };

  const sendForward = (values, externalCover = null) => {
    let exposure_desiredcoverage = app_state.current_client.exposure_desiredcoverage;
    Modal.confirm({
      title: 'Forward Request',
      content: <>
        <Divider />
        <p>
          Choose your desired coverage percentage for this forward request.
          <Form.Item>
            <InputNumber defaultValue={exposure_desiredcoverage}
                         onKeyUp={(event) => exposure_desiredcoverage = event.target.value} />&nbsp;&nbsp;
            % of uncovered amount.
          </Form.Item>
          Amount: {exposure_desiredcoverage * ((values.amount / 100) || 0)}
        </p>
      </>,
      onOk: () => {
        addCoverForm.setFieldsValue({amount: exposure_desiredcoverage * ((values.amount / 100) || 0)});
        setComponentState(prev => ({...prev, isForwardModalOpen: true}));
      }
    });
  }

  const renderAverageRate = () => {
    const data = componentState
      .dataList.filter(i => i.type !== 'PAYOUTS' && !i.draft);

    const total = data.reduce((acc, item) => acc + item.amount, 0);
    const total_f = data.reduce((acc, item) => acc + item.amount_from, 0);
    let amount = (total / total_f);
    if (isNaN(amount)) {
      amount = (0).toString();
    } else {
      amount = amount.toFixed(4);
    }
    return amount;
  };

  const buildPayoutData = () => {
    return componentState.dataList.filter(i => i.type === 'PAYOUTS' && !i.draft);
  };

  const buildForwardData = () => {
    return componentState.dataList.filter(i => i.type === 'FORWARDS' && !i.draft);
  };

  const handleDraftForwardSubmit = (values) => {
    const loading = message.loading('saving draft forward. Please wait..', 0);
    values.clientID = app_state.current_client.id;
    API.post('commons', '/insert', {
      body: {
        context: 'draft_forward',
        data: values
      }
    }).then(res => {
      draftForwardForm.resetFields();
      setComponentState(prev => ({...prev, isDraftForwardModelOpen: false}));
      initData();
    }).catch(() => {
      message.error('Unable to save draft forward. Please try again...');
    }).finally(() => loading());
  };

  const renderTableData = () => {
    return componentState.dataList.filter(i => i.type === 'PAYOUTS' && !i.draft);
  };

  const triggerMonthlySchedule = (item) => {
    const date = dayjs(item).format('YYYY-MM');
    const mth = date.split('-')[1];
    if (mth != null) {
      setComponentState(prev => ({
        ...prev,
        monthly: {
          year: date.split('-')[0],
          month: date.split('-')[1],
          label: mth != null ? dayjs(`${date.split('-')[1]}`, 'M').format('MMMM') : null
        }
      }));
    }
  }

  const saveDesiredClientCoverage = () => {
    if (desiredCoverage.current.value == null || desiredCoverage.current.value <= 0 || desiredCoverage.current.value === '') {
      message.info("Invalid coverage. It must be greater than 0 and less than 100");
      return true;
    }
    if (desiredCoverage.current.value > 100) {
      message.info("Invalid coverage. It must be greater than 0 and less than 100");
      return true;
    }
    if (desiredCoverage.current.value.toString().includes(".")) {
      message.info("Must not contain decimal points");
      return true;
    }
    API.post("commons", "/update", {
      body: {
        context: "clients",
        data: {
          exposure_desiredcoverage: desiredCoverage.current.value
        },
        condition: {id: app_state.current_client.id, team_id: app_state.current_client.team_id}
      }
    }).then(() => {
      message.success("Desired coverage saved successfully");
    })
  }

  const buildDonutPaymentsColumns = () => {
    return [
      {
        title: 'Age',
        dataIndex: 'age',
        key: 'age',
      },
      {
        title: 'Payments',
        dataIndex: 'count',
        key: 'count'
      },
      {
        title: 'Payments',
        dataIndex: 'balance',
        key: 'balance',
        render: (text) => text != null
          ? new Intl.NumberFormat('AUD', {
            currency: 'AUD',
            style: 'currency',
            useGrouping: true,
            currencyDisplay: 'narrowSymbol',
            maximumFractionDigits: 2,
            minimumFractionDigits: 2
          }).format(text)
          : '-'
      }
    ];
  }

  const setRateAlert = () => {

    let rate = null;
    Modal.confirm({
      title: 'Set rate alert ?',
      content: <>
        <Divider />
        <p>
          Put in a Rate Alert for this currency pair.<br />
          The current market rate is {componentState.currentRate} AUD/{getSelectedCurrency()}.
        </p>
        <Form.Item label={'Rate'}>
          <InputNumber style={{width: '100%'}} onKeyUp={(event) => rate = event.target.value} />
        </Form.Item>
      </>,
      onOk: () => {
        return new Promise((resolve, reject) => {
          if (!rate) {
            message.error("Rate alert cannot be empty");
            reject();
            return;
          }
          if (rate <= 0) {
            message.info("Value cannot be less than or equal to zero");
            reject();
            return;
          }
          let payload = {currencyFromID: 1, currencyToID: componentState.fxCurrency, rate: Number(rate)};
          if (componentState.currentRate != null) {
            if (componentState.currentRate >= Number(rate)) {
              payload = {...payload, goingDownTrigger: true, goingUpTrigger: false};
            } else {
              payload = {...payload, goingDownTrigger: false, goingUpTrigger: true};
            }
          }
          payload = {
            ...payload,
            notify_changes: true,
            recordCreated: dayjs().format('YYYY-MM-DD'),
            clientID: app_state.current_client.id,
            rate: Number(payload.rate).toFixed(4)
          };
          RateAlertAPI.saveAlert(payload)
            .then(() => {
              message.success("Rate alert is successfully created");
              resolve();
            }).catch(err => reject(err));
        });
      }
    })
  }

  const toggleAddCoverNow = () => {

    const payoutActual = componentState.dataList.filter(item => item.type === 'PAYOUTS' & !item.draft).reduce((acc, item) => acc + item.amount, 0);
    let forwardActual = componentState.dataList.filter(item => item.type !== 'PAYOUTS' && !item.draft).reduce((acc, item) => acc + item.amount, 0);

    const acc = componentState.account_list.find(i => i.currencies_short_name === getSelectedCurrency(componentState.fxCurrency));
    const haBalance = acc ? acc.balance : 0;

    const coveredActual = forwardActual + (haBalance ? haBalance : 0);
    let uncoveredActual = payoutActual - coveredActual
    const totalActual = uncoveredActual + coveredActual;

    const desiredCoverage = app_state && app_state.current_client ? (app_state.current_client.exposure_desiredcoverage / 100) : 0;
    const total = (desiredCoverage * totalActual) - coveredActual;

    componentState.onAddCoverFromPieChart((uncoveredActual / totalActual), total, app_state);
  }

  const contextValue = useMemo(() => ({name: 'Forward Notification'}), []);

  return <Context.Provider value={contextValue}>
    {contextHolder}
    <Card title={renderTopBlock()} style={{marginBottom: '10px'}}>
      <div style={{
        display: 'flex',
        justifyContent: queryParams.get('currency') != null ? 'space-between' : 'end'
      }}>
        {
          queryParams.get('currency') != null ?
            <Button type={'primary'} icon={<ArrowLeftOutlined />} onClick={() => history.goBack()}>
              Back to Summary Page
            </Button>
            : <></>
        }
        <div style={{display: 'flex', justifyContent: 'end', alignItems: 'center'}}>
          {/*<div>*/}
          {/*  <Button onClick={() => toggleAddCoverNow()} type={'primary'}*/}
          {/*          style={{width: 150, marginBottom: 10}}*/}
          {/*  ><strong>Add Cover Now</strong></Button>*/}
          {/*  <Divider vertical type={'vertical'} />*/}
          {/*  <Button onClick={() => {*/}
          {/*    setComponentState(prev => ({*/}
          {/*      ...prev,*/}
          {/*      isPayoutModalOpen: true,*/}
          {/*      activePayoutId: null,*/}
          {/*      currencyBeneficiaryList: componentState.currencyBeneficiaryList*/}
          {/*    }));*/}
          {/*    if (componentState.dataState === 'DRAFT') {*/}
          {/*      payoutForm.setFieldsValue({draft: true});*/}
          {/*    }*/}
          {/*  }} type={'primary'}*/}
          {/*          style={{width: 150}}*/}
          {/*  ><strong>Add Payments</strong></Button>*/}
          {/*</div>*/}
          {/*<Divider vertical type={'vertical'} />*/}
          <Typography.Text>{getSelectedCurrency().toUpperCase()} Ledger
            Balances:&nbsp;&nbsp;</Typography.Text>
          {componentState.account_list.map(i => {
            return <Form.Item style={{display: 'inline-block', marginRight: 5, marginBottom: 0}}>
              <Radio.Button>
                <TypographyCurrency iso_alpha_3={i.currencies_short_name}><strong>
                  {new Intl.NumberFormat(CurrencyLocales[i.currencies_short_name], {
                    currency: i.currencies_short_name,
                    style: 'currency',
                    useGrouping: true,
                    currencyDisplay: 'narrowSymbol',
                    maximumFractionDigits: 2,
                    minimumFractionDigits: 2
                  }).format(i.balance)}
                </strong></TypographyCurrency>
              </Radio.Button>
            </Form.Item>;
          })}
        </div>
      </div>

      <Row gutter={[16, 16]}>
        <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
          <div
            style={{
              border: '1px solid rgba(0, 0, 0, 0.1)', // Light grey border
              borderRadius: '10px', // Rounded edges
              padding: '16px', // Padding inside the border
            }}
          >
            <Row gutter={[16, 16]}>
              <Col
                key={1}
                xs={24} sm={24} md={24} lg={6} xl={6} xxl={6}
                style={{
                  borderRight: '1px solid rgba(0, 0, 0, 0.1)',
                  paddingRight: '16px',
                  position: 'relative', // Needed for absolute positioning of the button
                  paddingBottom: '40px', // Ensure space at bottom for button
                  backgroundColor: 'rgba(255, 240, 150, 0.1)', // Soft pastel yellow background
                  borderRadius: '6px', // Slight rounding for the columns
                  padding: '12px', // Padding inside each section
                }}
              >
                <div
                  style={{
                    fontSize: '18px',
                    fontWeight: 'bold',
                    color: '#1890ff', // Keep text dark blue for contrast
                    backgroundColor: 'rgba(24, 144, 255, 0.1)', // Light blue with 10% opacity
                    padding: '8px',
                    borderRadius: '4px',
                    textAlign: 'center',
                    marginBottom: '8px', // Space between heading and content
                  }}
                >
                  Total Coverage
                </div>
                <div style={{display: 'flex', justifyContent: 'space-between', marginTop: 10}}>
                  {
                    !pieCharts.currencyAverage.isUncoveredZero && componentState.dataList.length > 0
                      ? <ExposureMonitorDetailCoveragePie pieCharts={pieCharts} />
                      : <>{app_state.current_client &&
                        <FxDonut chartData={[{label: '', value: 0, percentage: 100}, {
                          label: '',
                          value: 0,
                          percentage: 0
                        }]}
                                 chartOptions={{
                                   width: 120,
                                   donut: true,
                                   donutWidth: 15,
                                   donutSolid: true,
                                   startAngle: 270,
                                   showLabel: true,
                                   total: 100,
                                 }}></FxDonut>
                      }</>
                  }
                  <div>
                    <div id={'chart-legend-1'} style={{display: 'flex', gap: 5}}></div>
                    <div style={{marginTop: 20}}>
                      <strong>Avg Cover
                        Rate:</strong> {(renderAverageRate() != 0) ? renderAverageRate() : 'No forward data'}
                      <br />
                      <strong>Market Rate:</strong> {componentState.marketRate.toFixed(4)}
                      <br />
                      <Typography.Link onClick={() => setRateAlert()}>Set Rate Alert Now</Typography.Link>
                    </div>
                  </div>
                </div>

                {/*<div>*/}
                {/*  <span>*/}
                {/*      <em>*/}
                {/*          Payment cover:<br/>*/}
                {/*          covered (blue), uncovered (yellow)*/}
                {/*      </em>*/}
                {/*  </span>*/}
                {/*</div>*/}
                {/*<Divider orientation={'left'}*/}
                {/*         style={{paddingRight: 100}}*/}
                {/*  {prevComponentState.fxCurrency && (
                    // <Descriptions
                    //     layout="vertical"
                    //     column={3} // Distribute items horizontally
                    //     contentStyle={{margin: 0, padding: '4px 2px'}}
                    //     labelStyle={{fontWeight: 'bold', padding: '4px 2px', textAlign: 'center'}}
                    // >
                      <Row>
                        {pieCharts.currencyAverage.descriptions.map((i, index) => (
                          <Col
                            key={1}
                            xs={24} sm={24} md={24} lg={6} xl={8} xxl={8}
                            style={{
                              borderRight: '1px solid rgba(0, 0, 0, 0.1)',
                              paddingRight: '16px',
                              position: 'relative', // Needed for absolute positioning of the button
                              paddingBottom: '40px', // Ensure space at bottom for button
                              backgroundColor: 'rgba(255, 240, 150, 0.1)', // Soft pastel yellow background
                              borderRadius: '6px', // Slight rounding for the columns
                              padding: '12px', // Padding inside each section
                            }}
                          >
                            <div
                              style={{
                                fontSize: '18px',
                                fontWeight: 'bold',
                                color: '#1890ff', // Keep text dark blue for contrast
                                backgroundColor: 'rgba(24, 144, 255, 0.1)', // Light blue with 10% opacity
                                padding: '8px',
                                borderRadius: '4px',
                                textAlign: 'center',
                                marginBottom: '8px', // Space between heading and content
                              }}
                            >
                              {i.label}
                            </div>
                            <div>
                            <Descriptions.Item key={index}
                                               style={{
                                                   marginBottom: '0px',
                                                   paddingBottom: '0px',
                                                   // borderBottom: '1px solid #ddd',
                                                   textAlign: 'center' // Center align text horizontally
                                               }}
                                               label={
                                                   <span style={{
                                                       display: 'block',
                                                       fontWeight: 'bold'
                                                   }}>
                                      {i.label}
                                    </span>
                                               }>
                                <div style={{textAlign: 'left'}}>
                                    <strong>
                                      {new Intl.NumberFormat(CurrencyLocales[getSelectedCurrency()], {
                                          currency: getSelectedCurrency(),
                                          style: 'currency',
                                          useGrouping: true,
                                          currencyDisplay: 'narrowSymbol',
                                          maximumFractionDigits: 2,
                                          minimumFractionDigits: 2
                                      }).format(i.value)}
                                    </strong>
                                    <br />
                                    {index === 1 ? <>Average rate: {renderAverageRate()}<br/></> : <></>}
                                    <span>
                                        {i.template}
                                    </span>
                                </div>
                            </Descriptions.Item>
                            </div>
                            {/*<div style={{ position: 'absolute', bottom: '8px', right: '8px' }}>*/}
                {/*  <Button type="primary" size="small">*/}
                {/*    View Details*/}
                {/*  </Button>*/}
                {/*</div>*/}
                {/*</Col>*/}
                {/*))}*/}
                {/*</Descriptions>*/}
                {/*</Row>*/}
                {/*)}       orientationMargin={0}>Coverage Planning</Divider>*!/*/}
                <Typography.Paragraph>
                  Set desired global coverage (0-100%)
                </Typography.Paragraph>
                <div style={{display: 'flex', justifyContent: 'flex-start', alignItems: 'end'}}>
                  {/*<Form.Item label={'Desired Coverage'} style={{marginBottom: 0}}*/}
                  {/*           rules={[{*/}
                  {/*               required: true,*/}
                  {/*               message: 'Desired Coverage is required'*/}
                  {/*           }]}>*/}
                  <input type={'number'} ref={desiredCoverage} style={{
                    fontSize: '14px',
                    lineHeight: 1.5715,
                    padding: '4px 11px',
                    border: '1px solid #d9d9d9',
                    transition: 'all 0.2s'
                  }} />
                  {/*</Form.Item>*/}
                  <Button type={'primary'}
                          onClick={(event) => saveDesiredClientCoverage()}>Save</Button>
                </div>

              </Col>
              {/*<Col*/}
              {/*  key={1}*/}
              {/*  xs={24} sm={24} md={24} lg={6} xl={18} xxl={18}>*/}

                {/*<ExposureMonitorDetailOverallDescriptions pieCharts={pieCharts}*/}
                {/*                                          prevComponentState={componentState}*/}
                {/*                                          getSelectedCurrency={getSelectedCurrency}*/}
                {/*                                          renderAverageRate={renderAverageRate}*/}
                {/*                                          setRateAlert={setRateAlert}></ExposureMonitorDetailOverallDescriptions>*/}
                {/* /componentState.fxCurrency && (
                  // <Descriptions
                  //     layout="vertical"
                  //     column={3} // Distribute items horizontally
                  //     contentStyle={{margin: 0, padding: '4px 2px'}}
                  //     labelStyle={{fontWeight: 'bold', padding: '4px 2px', textAlign: 'center'}}
                  // >
                  // <Row> */}
                    {/*{pieCharts.currencyAverage.descriptions.map((i, index) => (*/}
              {componentState.fxCurrency &&
                pieCharts &&
                pieCharts.currencyAverage &&
                pieCharts.currencyAverage.descriptions &&
                pieCharts.currencyAverage.descriptions[0] &&
                pieCharts.currencyAverage.descriptions[1] &&
                pieCharts.currencyAverage.descriptions[2] &&
                (<>
                      <Col
                        key={1}
                        xs={24} sm={24} md={24} lg={6} xl={6} xxl={6}
                        style={{
                          borderRight: '1px solid rgba(0, 0, 0, 0.1)',
                          paddingRight: '16px',
                          position: 'relative', // Needed for absolute positioning of the button
                          paddingBottom: '40px', // Ensure space at bottom for button
                          backgroundColor: 'rgba(255, 240, 150, 0.1)', // Soft pastel yellow background
                          borderRadius: '6px',
                          paddingLeft: '12px',
                        }}
                      >
                        <div
                          style={{
                            fontSize: '18px',
                            fontWeight: 'bold',
                            color: '#1890ff', // Keep text dark blue for contrast
                            backgroundColor: 'rgba(24, 144, 255, 0.1)', // Light blue with 10% opacity
                            padding: '8px',
                            borderRadius: '4px',
                            textAlign: 'center',
                            marginBottom: '8px', // Space between heading and content
                          }}
                        >
                          {pieCharts.currencyAverage.descriptions[0].label}
                        </div>
                        <div>
                          <Descriptions.Item key={1}
                                             style={{
                                               marginBottom: '0px',
                                               paddingBottom: '0px',
                                               // borderBottom: '1px solid #ddd',
                                               textAlign: 'center' // Center align text horizontally
                                             }}
                                             label={
                                               <span style={{
                                                 display: 'block',
                                                 fontWeight: 'bold'
                                               }}>
                                      {pieCharts.currencyAverage.descriptions[0].label}
                                    </span>
                                             }>
                            <div style={{
                              textAlign: 'left',
                              paddingLeft: 8,
                              paddingTop: 20,
                              fontSize: '1.1rem'}}>
                              <strong>
                                {new Intl.NumberFormat(CurrencyLocales[getSelectedCurrency()], {
                                  currency: getSelectedCurrency(),
                                  style: 'currency',
                                  useGrouping: true,
                                  currencyDisplay: 'narrowSymbol',
                                  maximumFractionDigits: 2,
                                  minimumFractionDigits: 2
                                }).format(pieCharts.currencyAverage.descriptions[0].value)}
                              </strong>
                              <br/>
                              <span>
                                {pieCharts.currencyAverage.descriptions[0].template}
                              </span>
                            </div>
                          </Descriptions.Item>
                        </div>
                        <div style={{ position: 'absolute', bottom: '8px', right: '8px' }}>
                          {/*<Button type="primary" style={{width: 150}}>*/}
                          {/*  <Typography.Link href={'#coverTable'}>View Payments</Typography.Link>*/}
                          {/*</Button>*/}
                          {/*<Divider type="vertical" />*/}
                          <Button type="primary" style={{width: 150}}
                                  onClick={() => {
                                    setComponentState(prev => ({
                                      ...prev,
                                      isPayoutModalOpen: true,
                                      activePayoutId: null,
                                      currencyBeneficiaryList: componentState.currencyBeneficiaryList
                                    }));
                                    if (componentState.dataState === 'DRAFT') {
                                      payoutForm.setFieldsValue({draft: true});
                                    }
                                  }}
                          >
                            Add New Payment
                          </Button>
                        </div>
                      </Col>
                      <Col
                        key={1}
                        xs={24} sm={24} md={24} lg={6} xl={6} xxl={6}
                        style={{
                          borderRight: '1px solid rgba(0, 0, 0, 0.1)',
                          paddingRight: '16px',
                          position: 'relative', // Needed for absolute positioning of the button
                          paddingBottom: '40px', // Ensure space at bottom for button
                          backgroundColor: 'rgba(255, 240, 150, 0.1)', // Soft pastel yellow background
                          borderRadius: '6px', // Slight rounding for the columns
                          paddingLeft: '12px',
                        }}
                      >
                        <div
                          style={{
                            fontSize: '18px',
                            fontWeight: 'bold',
                            color: '#1890ff', // Keep text dark blue for contrast
                            backgroundColor: 'rgba(24, 144, 255, 0.1)', // Light blue with 10% opacity
                            padding: '8px',
                            borderRadius: '4px',
                            textAlign: 'center',
                            marginBottom: '8px', // Space between heading and content
                          }}
                        >
                          {pieCharts.currencyAverage.descriptions[1].label}
                        </div>
                        <div>
                          <Descriptions.Item key={2}
                                             style={{
                                               marginBottom: '0px',
                                               paddingBottom: '0px',
                                               // borderBottom: '1px solid #ddd',
                                               textAlign: 'center' // Center align text horizontally
                                             }}
                                             label={
                                               <span style={{
                                                 display: 'block',
                                                 fontWeight: 'bold'
                                               }}>
                                      {pieCharts.currencyAverage.descriptions[1].label}
                                    </span>
                                             }>
                            <div style={{
                              textAlign: 'left',
                              paddingLeft: 8,
                              paddingTop: 20,
                              fontSize: '1.1rem'}}>
                            <strong>
                                {new Intl.NumberFormat(CurrencyLocales[getSelectedCurrency()], {
                                  currency: getSelectedCurrency(),
                                  style: 'currency',
                                  useGrouping: true,
                                  currencyDisplay: 'narrowSymbol',
                                  maximumFractionDigits: 2,
                                  minimumFractionDigits: 2
                                }).format(pieCharts.currencyAverage.descriptions[1].value)}
                              </strong>
                              <br />
                              <>Average rate: {renderAverageRate()}<br /></>
                              <span>
                                        {pieCharts.currencyAverage.descriptions[1].template}
                                    </span>
                            </div>
                          </Descriptions.Item>
                        </div>
                        <div style={{ position: 'absolute', bottom: '8px', right: '8px' }}>
                          <Button type="primary" style={{width: 150}}>
                            <Typography.Link href={'#coverTable'}>View Cover</Typography.Link>
                          </Button>
                          {/*<Divider type="vertical" />*/}
                          {/*<Button type="primary" style={{width: 150}} onClick={() => toggleAddCoverNow()}>*/}
                          {/*  Add Cover*/}
                          {/*</Button>*/}
                        </div>
                      </Col>
                      <Col
                        key={1}
                        xs={24} sm={24} md={24} lg={6} xl={6} xxl={6}
                        style={{
                          borderRight: '1px solid rgba(0, 0, 0, 0.1)',
                          paddingRight: '16px',
                          position: 'relative', // Needed for absolute positioning of the button
                          paddingBottom: '40px', // Ensure space at bottom for button
                          backgroundColor: 'rgba(255, 240, 150, 0.1)', // Soft pastel yellow background
                          borderRadius: '6px', // Slight rounding for the columns
                          paddingLeft: '12px',
                        }}
                      >
                        <div
                          style={{
                            fontSize: '18px',
                            fontWeight: 'bold',
                            color: '#1890ff', // Keep text dark blue for contrast
                            backgroundColor: 'rgba(24, 144, 255, 0.1)', // Light blue with 10% opacity
                            padding: '8px',
                            borderRadius: '4px',
                            textAlign: 'center',
                            marginBottom: '8px', // Space between heading and content
                          }}
                        >
                          {pieCharts.currencyAverage.descriptions[2].label}
                        </div>
                        <div>
                          <Descriptions.Item key={2}
                                             style={{
                                               marginBottom: '0px',
                                               paddingBottom: '0px',
                                               // borderBottom: '1px solid #ddd',
                                               textAlign: 'center' // Center align text horizontally
                                             }}
                                             label={
                                               <span style={{
                                                 display: 'block',
                                                 fontWeight: 'bold'
                                               }}>
                                      {pieCharts.currencyAverage.descriptions[2].label}
                                    </span>
                                             }>
                            <div style={{
                              textAlign: 'left',
                              paddingLeft: 8,
                              paddingTop: 20,
                              fontSize: '1.1rem'}}>
                            <strong>
                                {new Intl.NumberFormat(CurrencyLocales[getSelectedCurrency()], {
                                  currency: getSelectedCurrency(),
                                  style: 'currency',
                                  useGrouping: true,
                                  currencyDisplay: 'narrowSymbol',
                                  maximumFractionDigits: 2,
                                  minimumFractionDigits: 2
                                }).format(pieCharts.currencyAverage.descriptions[2].value)}
                              </strong>
                              <br />
                              <span>
                                {pieCharts.currencyAverage.descriptions[2].template}
                              </span>
                            </div>
                          </Descriptions.Item>
                        </div>
                        <div style={{ position: 'absolute', bottom: '8px', right: '8px' }}>
                          {/*<Button type="primary" style={{width: 150}}>*/}
                          {/*  <Typography.Link href={'#coverTable'}>View Cover</Typography.Link>*/}
                          {/*</Button>*/}
                          {/*<Divider type="vertical" />*/}
                          <Button type="primary" style={{width: 150}} onClick={() => toggleAddCoverNow()}>
                            Add Cover
                          </Button>
                        </div>
                      </Col>
              </>)}
                    {/*))}*/}
                    {/*</Descriptions>*/}
                  {/*// </Row>*/}
                {/*))}*/}
              {/*</Col>*/}
            </Row>
          </div>
        </Col>
      </Row>
      {/*<Divider />*/}
      {/*<Row gutter={[48, 16]}>*/}
      {/*<Col xs={24} sm={24} md={24} lg={6} xl={6} xxl={6} style={{borderRight: '1px solid #e6e6e6'}}>*/}
      {/*  <Card style={{height: '100%'}} title={"Coverage Planning"}>*/}
      {/*{*/}
      {/*  pieCharts.currencyAverage.forwardGtPayout*/}
      {/*    ? <>Your covered amount is greater than your payouts inputted</>*/}
      {/*    : <>*/}
      {/*      <Divider orientation={'left'}*/}
      {/*               style={{paddingRight: 100}}*/}
      {/*               orientationMargin={0}>Exchange Rates</Divider>*/}
      {/*      <Descriptions column={1}*/}
      {/*                    style={{marginTop: 20}}*/}
      {/*                    contentStyle={{margin: 0, padding: '4px 2px'}}*/}
      {/*                    labelStyle={{fontWeight: 'bold', padding: '4px 2px'}}>*/}
      {/*        <Description.Item key={'Average Rate'}*/}
      {/*                          style={{*/}
      {/*                            marginBottom: '0px',*/}
      {/*                            paddingBottom: '0px',*/}
      {/*                            // borderBottom: '1px solid #ddd',*/}
      {/*                            display: 'flex',*/}
      {/*                            justifyContent: 'space-between'*/}
      {/*                          }}*/}
      {/*                          label={*/}
      {/*                            <span style={{*/}
      {/*                              display: 'inline-block',*/}
      {/*                              minWidth: '120px',*/}
      {/*                              fontWeight: 'bold'*/}
      {/*                            }}>*/}
      {/*                              {'Avg Cover Rate'}*/}
      {/*                            </span>*/}
      {/*                          }>*/}
      {/*          {(renderAverageRate() != 0) ? renderAverageRate() : 'No forward data'}*/}
      {/*        </Description.Item>*/}
      {/*        <Description.Item key={'Current Rate'}*/}
      {/*                          style={{*/}
      {/*                            marginBottom: '0px',*/}
      {/*                            paddingBottom: '0px',*/}
      {/*                            // borderBottom: '1px solid #ddd',*/}
      {/*                            display: 'flex',*/}
      {/*                            justifyContent: 'space-between'*/}
      {/*                          }}*/}
      {/*                          label={*/}
      {/*                            <span style={{*/}
      {/*                              display: 'inline-block',*/}
      {/*                              minWidth: '120px',*/}
      {/*                              fontWeight: 'bold'*/}
      {/*                            }}>*/}
      {/*                              {'Market Rate'}*/}
      {/*                            </span>*/}
      {/*                          }>*/}
      {/*                          <span style={{*/}
      {/*                            display: 'flex',*/}
      {/*                            justifyContent: 'start',*/}
      {/*                            flexDirection: 'column'*/}
      {/*                          }}>*/}
      {/*                     <Typography.Text*/}
      {/*                       style={{marginRight: 5}}>{componentState.marketRate.toFixed(4)}</Typography.Text>*/}
      {/*                     <Typography.Link onClick={() => setRateAlert()}>Set Rate Alert Now</Typography.Link>*/}
      {/*                  </span>*/}
      {/*        </Description.Item>*/}
      {/*      </Descriptions>*/}
      {/*    </>*/}
      {/*}*/}

      {/*<>*/}
      {/*<Divider orientation={'left'}*/}
      {/*         style={{paddingRight: 100}}*/}
      {/*         orientationMargin={0}>Coverage Planning</Divider>*/}
      {/*<Typography.Paragraph>*/}
      {/*    Set desired global coverage (0-100%)*/}
      {/*</Typography.Paragraph>*/}
      {/*<div style={{display: 'flex', justifyContent: 'flex-start', alignItems: 'end'}}>*/}
      {/*    /!*<Form.Item label={'Desired Coverage'} style={{marginBottom: 0}}*!/*/}
      {/*    /!*           rules={[{*!/*/}
      {/*    /!*               required: true,*!/*/}
      {/*    /!*               message: 'Desired Coverage is required'*!/*/}
      {/*    /!*           }]}>*!/*/}
      {/*    <input type={'number'} ref={desiredCoverage} style={{ fontSize: '14px', lineHeight: 1.5715, padding: '4px 11px', border: '1px solid #d9d9d9', transition: 'all 0.2s' }}/>*/}
      {/*    /!*</Form.Item>*!/*/}
      {/*    <Button type={'primary'}*/}
      {/*            onClick={(event) => saveDesiredClientCoverage()}>Save</Button>*/}
      {/*</div>*/}

      {/*<Divider />*/}
      {/*<div>*/}
      {/*    <Button onClick={() => toggleAddCoverNow()} type={'primary'}*/}
      {/*            style={{width: '100%', marginBottom: 10 }}*/}
      {/*    ><strong>Add Cover Now</strong></Button>*/}
      {/*    <Button onClick={() => {*/}
      {/*        setComponentState(prev => ({*/}
      {/*            ...prev,*/}
      {/*            isPayoutModalOpen: true,*/}
      {/*            activePayoutId: null,*/}
      {/*            currencyBeneficiaryList: componentState.currencyBeneficiaryList*/}
      {/*        }));*/}
      {/*        if (componentState.dataState === 'DRAFT') {*/}
      {/*            payoutForm.setFieldsValue({draft: true});*/}
      {/*        }*/}
      {/*    }} type={'primary'}*/}
      {/*            style={{width: '100%'}}*/}
      {/*    ><strong>Add Payments</strong></Button>*/}
      {/*</div>*/}
      {/*</>*/}

      {/*</Col>*/}
      {/*</Row>*/}
      {/*</Card>*/}
      {/*</Col>*/}

      {/*    ***************************  */}
      {/*    ADD PAYMENT CARD             */}
      {/*    ***************************  */}

      {/*<Col xs={24} sm={24} md={24} lg={10} xl={10} xxl={10} style={{ borderRight: '1px solid #e6e6e6' }}>*/}
      {/*    <Card style={{ height: '100%', display: 'flex', flexDirection: 'column' }} title="Add Payment">*/}
      {/*<Modal visible={componentState.isPayoutModalOpen}*/}
      {/*       style={{top: 3}}*/}
      {/*       title={componentState.activePayoutId ? `Update Payout #${componentState.activePayoutId} Record` : 'Add a New Payment'}*/}
      {/*       okText={*/}
      {/*           componentState.activePayoutId*/}
      {/*             ? `Update Payout`*/}
      {/*             : `Add Payout`*/}
      {/*       }*/}
      {/*       onCancel={() => {*/}
      {/*           payoutForm.resetFields();*/}
      {/*           uploaderRef.current.clearList();*/}
      {/*           setComponentState(prev => ({*/}
      {/*               ...prev,*/}
      {/*               isPayoutModalOpen: false,*/}
      {/*               activePayoutId: null*/}
      {/*           }));*/}
      {/*       }}*/}
      {/*       onOk={() => payoutForm.submit()}>*/}
      {/*    <p>*/}
      {/*        Fill in the details below to add a new payment (future invoice) into the system.<br/>*/}
      {/*        You can also upload an invoice or supporting documentation.*/}
      {/*    </p>*/}
      {/*    <Divider/>*/}
      {/*<Divider orientation={'left'}*/}
      {/*         style={{paddingRight: 100}}*/}
      {/*         orientationMargin={0}>Add Payment</Divider>*/}


      {/*    <Form layout={'vertical'} initialValues={{currencyID: componentState.fxCurrency, attachments: []}}*/}
      {/*          style={{ display: 'flex', flexDirection: 'column', flex: 1 }}*/}
      {/*          name={'payout-form'} form={payoutForm} onFinish={handleSubmit}>*/}
      {/*        <Row gutter={6}>*/}
      {/*        <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>*/}
      {/*        <Form.Item label={'Payment Due Date'} name={'scheduled'} normalize={(dayjsValue) =>*/}
      {/*          dayjsValue && dayjsValue.startOf('day').format('YYYY-MM-DD')*/}
      {/*        }*/}
      {/*                   getValueProps={(value) =>*/}
      {/*                     value && {value: dayjs(value, 'YYYY-MM-DD').startOf('day')}*/}
      {/*                   }>*/}
      {/*            <DatePicker format={'DD/MM/YYYY'}/>*/}
      {/*        </Form.Item>*/}
      {/*        <span style={{display: 'display-block', marginBottom: '10px'}}>*/}
      {/*    <CurrencySelect state={{currencies: app_state.currency_list.filter(i => i.id !== 1) || []}}*/}
      {/*                    rightLabel={'Enter payment amount'}*/}
      {/*                    onCurrencyChange={(val, d) => {*/}
      {/*                        const benes = componentState.beneficiaryList.filter(i => i.account_currency === d);*/}
      {/*                        setComponentState(prev => ({...prev, currencyBeneficiaryList: benes}));*/}
      {/*                    }}*/}
      {/*                    validated*/}
      {/*                    currencyFieldName={'currencyID'}*/}
      {/*                    fieldName={!componentState.isPlanReceipt ? 'payout' : 'receipt'}></CurrencySelect>*/}
      {/*</span>*/}
      {/*        {*/}
      {/*          !componentState.isPlanReceipt && <><Form.Item name={'beneficiary_id'} label={'Beneficiary'}>*/}
      {/*              <Select placeholder={' -- SELECT BENEFICIARY --'}*/}
      {/*                      options={componentState.currencyBeneficiaryList.map(i => {*/}
      {/*                          const curr = app_state.currency_list.find(k => k.id === i.account_currency);*/}
      {/*                          return {*/}
      {/*                              alt: i.nickname == null ? i.ben_legal_name : i.nickname,*/}
      {/*                              label: <TypographyCurrency*/}
      {/*                                iso_alpha_3={curr.iso_alpha_3}>{i.nickname == null ? i.ben_legal_name : i.nickname}</TypographyCurrency>,*/}
      {/*                              value: i.id*/}
      {/*                          };*/}
      {/*                      })}*/}
      {/*                      optionFilterProp={'alt'}></Select>*/}
      {/*          </Form.Item>*/}
      {/*              <Button style={{marginBottom: '10px'}} type={'info'} size={'small'}*/}
      {/*                      onClick={() => setComponentState(prev => ({...prev, isBenModalOpen: true}))}>Add*/}
      {/*                  Beneficiary</Button>*/}
      {/*          </>*/}
      {/*        }*/}
      {/*        </Col>*/}
      {/*        <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>*/}

      {/*        <Form.Item name={'description'} label={'Description'}>*/}
      {/*            <Input.TextArea/>*/}
      {/*        </Form.Item>*/}
      {/*        {*/}
      {/*            componentState.dataState === 'ALL'*/}
      {/*              ? <>*/}
      {/*                  <Form.Item label='Forecast Payment' name='draft' valuePropName='checked'*/}
      {/*                             style={{marginBottom: 0}}>*/}
      {/*                      <Switch/>*/}
      {/*                  </Form.Item>*/}
      {/*                  <Typography.Text style={{marginBottom: 10}}><em>Select if this is*/}
      {/*                      a <strong>forecast</strong> payment (not*/}
      {/*                      actual)</em></Typography.Text>*/}
      {/*              </> : <></>*/}
      {/*        }*/}

      {/*        <Form.Item name='attachments' getValueFromEvent={(e) => e.map(i => i.name)}>*/}
      {/*            <FileUploader key={componentState.activePayoutId} uploaderType={'DRAGDROP'}*/}
      {/*                          ref={uploaderRef}></FileUploader>*/}
      {/*        </Form.Item>*/}

      {/*        </Col>*/}
      {/*        </Row>*/}
      {/*        <Modal visible={componentState.isBenModalOpen}*/}
      {/*               width={'900px'}*/}
      {/*               style={{top: 10}}*/}
      {/*               bodyStyle={{maxHeight: '80vh', overflowY: 'scroll', padding: '10px', background: '#E0e0e0'}}*/}
      {/*               onCancel={() => {*/}
      {/*                   beneficiaryModalRef.current.resetFields();*/}
      {/*                   setComponentState(prev => ({...prev, isBenModalOpen: false}));*/}
      {/*               }}*/}
      {/*               onOk={() => {*/}
      {/*                   beneficiaryModalRef.current.submit();*/}
      {/*               }}*/}
      {/*               modalRender={(modal) => (*/}
      {/*                 <Draggable*/}
      {/*                   disabled={modalDrag.disabled}*/}
      {/*                   bounds={modalDrag.bounds}*/}
      {/*                   nodeRef={draggableRef}*/}
      {/*                   onStart={(event, uiData) => onStart(event, uiData)}*/}
      {/*                 >*/}
      {/*                     <div ref={draggableRef}>{modal}</div>*/}
      {/*                 </Draggable>*/}
      {/*               )}*/}
      {/*               title={*/}
      {/*                   <div style={{width: '100%', cursor: 'move'}}*/}
      {/*                        onMouseOver={() => {*/}
      {/*                            if (modalDrag.disabled) {*/}
      {/*                                setModalDrag(prev => ({...prev, disabled: false}));*/}
      {/*                            }*/}
      {/*                        }}*/}
      {/*                        onMouseOut={() => {*/}
      {/*                            setModalDrag(prev => ({...prev, disabled: true}));*/}
      {/*                        }}>Add New Beneficiary</div>*/}
      {/*               }>*/}
      {/*            <BeneficiaryModal clientId={app_state.current_client ? app_state.current_client.id : null}*/}
      {/*                              initialCurrency={5}*/}
      {/*                              onSubmit={(val) => {*/}
      {/*                                  initPrefs();*/}
      {/*                                  setComponentState(prev => ({...prev, isBenModalOpen: false}));*/}
      {/*                              }}*/}
      {/*                              ref={beneficiaryModalRef}></BeneficiaryModal>*/}
      {/*        </Modal>*/}
      {/*        <div style={{ marginTop: 'auto', display: 'flex', justifyContent: 'flex-end', paddingTop: '10px' }}>*/}
      {/*            <Button onClick={() => toggleAddCoverNow()} type={'primary'}*/}
      {/*                    style={{width: '120', marginBottom: 10 }}*/}
      {/*            ><strong>Add Cover Now</strong></Button>*/}
      {/*        </div>*/}
      {/*    </Form>*/}
      {/*    </Card>*/}
      {/*</Modal>*/}
      {/*</Col>*/}


      {/*    ***************************  */}
      {/*    ADD COVER CARD               */}
      {/*    ***************************  */}


      {/*<Col xs={24} sm={24} md={24} lg={8} xl={6} xxl={8}>*/}
      {/*    <Card style={{ height: '100%', display: 'flex', flexDirection: 'column' }} title="Add Cover">*/}
      {/*<Modal name={'Forwards-Modal'}*/}
      {/*       key={2}*/}
      {/*       closable={true}*/}
      {/*       visible={componentState.isForwardModalOpen}*/}
      {/*       title={'Request Coverage'}*/}
      {/*       onCancel={() => {*/}
      {/*           addCoverForm.resetFields();*/}
      {/*           setComponentState(prev => ({...prev, isForwardModalOpen: false}));*/}
      {/*       }}*/}
      {/*       onOk={() => addCoverForm.submit()}>*/}
      {/*    <p>*/}
      {/*        Select to request either a spot transfer or forward cover for your payments.*/}
      {/*    </p>*/}
      {/*    <Divider/>*/}
      {/*<Divider orientation={'left'}*/}
      {/*         style={{paddingRight: 100}}*/}
      {/*         orientationMargin={0}>Add Cover</Divider>*/}

      {/*<Form layout={'vertical'} initialValues={{from_currency: 1, to_currency: 5}} name={'forwards-form'}*/}
      {/*      style={{ display: 'flex', flexDirection: 'column', flex: 1 }}*/}
      {/*          form={addCoverForm} onFinish={handleForwardsSubmit}>*/}
      {/*        <Segmented defaultValue={componentState.coverType}*/}
      {/*                   block={true}*/}
      {/*                   style={{marginBottom: '10px'}}*/}
      {/*                   options={[{label: 'Spot', value: 'SPOT'}, {label: 'Forward', value: 'FORWARD'}]}*/}
      {/*                   onChange={(val) => setComponentState(prev => ({...prev, coverType: val}))}></Segmented>*/}
      {/*        {componentState.coverType === 'FORWARD' &&*/}
      {/*          <Form.Item label={'Payment Due Date'} name={'payment_due_date'}*/}
      {/*                     normalize={(dayjsValue) => dayjsValue && dayjsValue.startOf('day').format('YYYY-MM-DD')}*/}
      {/*                     getValueProps={(value) => value && {value: dayjs(value, 'YYYY-MM-DD').startOf('day')}}>*/}
      {/*              <DatePicker format={'DD/MM/YYYY'}/>*/}
      {/*          </Form.Item>}*/}
      {/*    <Row>*/}
      {/*        <Col xs={12}>*/}
      {/*        <Form.Item name={'from_currency'} label={'From'}>*/}
      {/*            <Select optionFilterProp={'alt'} options={(app_state.currency_list || []).map(i => ({*/}
      {/*                ...i,*/}
      {/*                value: i.id,*/}
      {/*                alt: i.full_name + i.iso_alpha_3,*/}
      {/*                label: <TypographyCurrency iso_alpha_3={i.iso_alpha_3}>{i.iso_alpha_3}</TypographyCurrency>*/}
      {/*            }))}></Select>*/}
      {/*        </Form.Item>*/}
      {/*        </Col>*/}
      {/*        <Col xs={12}>*/}
      {/*        <Form.Item name={'to_currency'} label={'To Currency'}>*/}
      {/*            <Select optionFilterProp={'alt'} options={(app_state.currency_list || []).map(i => ({*/}
      {/*                ...i,*/}
      {/*                value: i.id,*/}
      {/*                alt: i.full_name + i.iso_alpha_3,*/}
      {/*                label: <TypographyCurrency iso_alpha_3={i.iso_alpha_3}>{i.iso_alpha_3}</TypographyCurrency>*/}
      {/*            }))}></Select>*/}
      {/*        </Form.Item>*/}
      {/*        </Col>*/}
      {/*    </Row>*/}
      {/*    <Row style={{marginBottom: 6}}>*/}
      {/*        <Col xs={24}>*/}
      {/*            <span>Cover Amount (select period or type amount:</span>*/}
      {/*        </Col>*/}
      {/*    </Row>*/}
      {/*    <Row>*/}
      {/*        <Col xs={10}>*/}
      {/*            <Radio.Group onChange={addCoverForm_handleRadioChange} buttonStyle="solid" style={{ display: 'flex', flexDirection: 'column' }}>*/}
      {/*                <Col><Radio value="0-30">0-30 days</Radio></Col>*/}
      {/*                <Col><Radio value="30-60">30-60 days</Radio></Col>*/}
      {/*                <Col><Radio value="60-90">60-90 days</Radio></Col>*/}
      {/*                <Col><Radio value="90+">90+ days</Radio></Col>*/}
      {/*            </Radio.Group>*/}
      {/*        </Col>*/}
      {/*        <Col xs={12}>*/}
      {/*            <Form.Item*/}
      {/*              name={'amount'}*/}
      {/*              // label={'Cover Amount (select period or type amount)'}*/}
      {/*              required={[*/}
      {/*                  { required: true, message: '' },*/}
      {/*                  {*/}
      {/*                      validator: (_, value) => {*/}
      {/*                          if (Number(value) === 0) {*/}
      {/*                              return Promise.reject('Enter valid amount');*/}
      {/*                          }*/}
      {/*                          if (!/^\d+(\.\d{1,2})?$/.test(value)) {*/}
      {/*                              return Promise.reject('Please enter a valid number with up to 2 decimal places');*/}
      {/*                          }*/}
      {/*                          if (value && parseFloat(value) === 0) {*/}
      {/*                              return Promise.reject('Amount cannot be zero');*/}
      {/*                          }*/}
      {/*                          return Promise.resolve();*/}
      {/*                      }*/}
      {/*                  }*/}
      {/*              ]}*/}
      {/*            >*/}
      {/*                <Input />*/}
      {/*            </Form.Item>*/}
      {/*        </Col>*/}
      {/*    </Row>*/}

      {/*{componentState.coverType === 'FORWARD' && <Form.Item name={'description'} label={'Description'}>*/}
      {/*    <Input.TextArea/>*/}
      {/*</Form.Item>}*/}

      {/*    <div style={{ position: 'absolute', bottom: '20px', right: '20px' }}>*/}
      {/*        <Button onClick={() => toggleAddCoverNow()} type={'primary'}*/}
      {/*                style={{width: '120', marginBottom: 10 }}*/}
      {/*        ><strong>Add Cover Now</strong></Button>*/}
      {/*    </div>*/}
      {/*    </Form>*/}
      {/*</Card>*/}

      {/*</Modal>*/}

      {/*</Col>*/}


      {/*<Col xs={24} sm={24} md={24} lg={14} xl={18} xxl={18}>*/}
      {/*    <Divider orientation={'left'}*/}
      {/*             style={{paddingRight: 100}}*/}
      {/*             orientationMargin={0}>Overall Coverage</Divider>*/}
      {/*    <ExposureMonitorDetailOverallDescriptions pieCharts={pieCharts}*/}
      {/*                                              prevComponentState={componentState}*/}
      {/*                                              getSelectedCurrency={getSelectedCurrency}*/}
      {/*                                              renderAverageRate={renderAverageRate}*/}
      {/*                                              setRateAlert={setRateAlert}></ExposureMonitorDetailOverallDescriptions>*/}
      {/*</Col>*/}
      {/*</Row>*/}
      {/*<Divider />*/}

      <Row gutter={[16, 16]}>
        <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
          <Divider orientation={'left'}
                   style={{paddingRight: 100}}
                   orientationMargin={0}>30/60/90 Day Coverage</Divider>
          <ExposureMonitorDonutDetails prevComponentState={componentState}></ExposureMonitorDonutDetails>
        </Col>
      </Row>
      <Divider />
      <Row gutter={[16, 16]} justify={'center'}>
        {/*<Col xs={24} sm={24} md={24} lg={6} xl={6} xxl={6} style={{ alignContent: 'space-between', flexDirection: 'column' }}>*/}
        {/*    <Divider orientation={'left'}*/}
        {/*             style={{paddingRight: 100}}*/}
        {/*             orientationMargin={0}>Rates and coverage planning</Divider>*/}
        {/*</Col>*/}
        <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
          {
            componentState.fxCurrency ?
              <>
                <div>
                  {
                    componentState.monthly == null ?
                      <Row gutte={[16, 16]} style={{marginBottom: 20}}>
                        <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                          <>
                            <Divider orientation={'left'}
                                     style={{paddingRight: 100, cursor: 'pointer'}}
                                     className="hover-divider"
                                     onClick={() => setShowChart(prev => !prev)}
                                     orientationMargin={0}>[Click to {showChart ? "hide" : "show"}] Monthly Payment and
                              Cover Planner</Divider>
                            {showChart && (
                              <div style={{
                                display: 'flex',
                                justifyItems: 'start',
                                gap: '10px'
                              }}>
                                {barCharts.currencyPlan.labels.map(item => {
                                  return <Chip label={<span style={{fontSize: 10}}>{item}</span>}
                                               onClick={() => triggerMonthlySchedule(item)}
                                               key={item}></Chip>
                                })}
                              </div>
                            )}
                          </>
                        </Col>
                      </Row> : <></>
                  }
                  {
                    componentState.monthly && componentState.monthly.label &&
                    <h3 style={{textAlign: 'center'}}>({componentState.monthly.label})</h3>
                  }
                  {componentState.monthly &&
                    <div style={{display: 'flex', justifyContent: 'end', cursor: 'pointer'}}>
                      <Tag color={'red'} icon={<CloseOutlined />} onClick={() => {
                        setComponentState(prev => ({...prev, monthly: null}));
                      }}>Back to Monthly Grouping</Tag>
                    </div>
                  }
                  <div className={`chart-container ${showChart ? 'show' : 'hide'}`}>
                    {showChart && (
                      <ChartistGraph key={componentState.fxCurrency}
                                     style={{width: '100%', fontWeight: 600}}
                                     className={'ct-chart-tBar'}
                                     data={barCharts.currencyPlan}
                                     type='Bar'
                                     listener={listener(14)}
                                     options={barCharts.currencyPlan.chartConfig}
                      />
                    )}
                  </div>
                </div>
              </> : <div>
                <h3 style={{textAlign: 'center'}}>Global FX Exposure</h3>
                <h6 style={{textAlign: 'center'}}>[12-mth Forward Coverage Forecast]</h6>
                <ChartistGraph style={{width: '100%', margin: 20, fontWeight: 600}}
                               data={barCharts.fxExposure}
                               type='Bar'
                               className={'ct-chart-mBar'}
                               listener={listener()}
                               options={barCharts.fxExposure.chartConfig}
                />
              </div>
          }

        </Col>
      </Row>

    </Card>
    <Card size={'small'} title={`${getSelectedCurrency().toUpperCase()} Payments by Beneficiary`}
          style={{marginBottom: 10}}>
      <Row gutter={[16, 16]}>
        <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
          <FxHorizontalBarChart data={componentState.owedEstimate}></FxHorizontalBarChart>
        </Col>
        <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
          <Table size={'small'} dataSource={componentState.donutTableData}
                 columns={buildDonutPaymentsColumns()}></Table>
        </Col>
      </Row>
    </Card>
    <Card title={<>{getSelectedCurrency().toUpperCase()} Payments and Cover</>}
          bodyStyle={{backgroundColor: '#efefef', border: "1px solid #d9d9d9"}}>
      {componentState.dataState === 'DRAFT' &&
        <p>Records highlighted <strong style={{color: 'green'}}>green</strong> are Forecast Data</p>}
      {
        componentState.transferType !== 'PAYMENTS' ? <>
          {
            Number(renderAverageRate()) > 0 ? <div style={{display: 'flex', justifyItems: 'end'}}>
              <Alert style={{width: 200}} type={'info'} message={
                <>
                  <Descriptions column={1}>
                    <Descriptions.Item label={'Average Rate'}
                                       style={{
                                         margin: 0,
                                         padding: 0
                                       }}>{renderAverageRate()}</Descriptions.Item>
                  </Descriptions>
                </>
              } />
            </div> : <></>
          }
        </> : <></>
      }
      <Row gutter={[16, 16]}>
        <Col xs={24} sm={24} md={24} lg={24} xxl={24}>
          <Card title={'Scheduled Payments'}>
            <Table rowKey={'id'}
                   key={app_state.current_client ? app_state.current_client.id : 0}
                   columns={buildColumns()}
                   size='small'
                   scroll={{x: 'max-content'}}
                   onRow={(record, rowIndex) => {
                     return {
                       style: {
                         backgroundColor: record.draft ? '#c5edc0' : 'inherit'
                       }
                     };
                   }}
                   summary={(data) => {
                     const total = data.reduce((acc, item) => acc + item.amount, 0);
                     const total_f = data.reduce((acc, item) => acc + item.amount_from, 0);
                     return <Table.Summary.Row>
                       <Table.Summary.Cell index={0}></Table.Summary.Cell>
                       <Table.Summary.Cell index={1}></Table.Summary.Cell>
                       <Table.Summary.Cell index={7}></Table.Summary.Cell>
                       <Table.Summary.Cell index={8}>Total: {new Intl.NumberFormat('en-Au', {
                         minimumFractionDigits: 2,
                         maximumFractionDigits: 2,
                         useGrouping: true
                       }).format(total)}</Table.Summary.Cell>
                     </Table.Summary.Row>;
                   }}
                   dataSource={buildPayoutData()}
                   loading={componentState.isPayoutListLoading}></Table>
          </Card>
        </Col>
        <Col xs={24} sm={24} md={24} lg={24} xxl={24}>
          <Card id={'coverTable'} title={'Coverage (Transfers and Forwards)'}>
            <Table rowKey={'id'}
                   key={app_state.current_client ? app_state.current_client.id : 0}
                   columns={buildForwardColumns()}
                   size='small'
                   scroll={{x: 'max-content'}}
                   onRow={(record, rowIndex) => {
                     return {
                       style: {
                         backgroundColor: record.draft ? '#c5edc0' : 'inherit'
                       }
                     };
                   }}
                   summary={(data) => {
                     const total = data.reduce((acc, item) => acc + item.amount, 0);
                     return <Table.Summary.Row>
                       <Table.Summary.Cell index={0}></Table.Summary.Cell>
                       <Table.Summary.Cell index={1}></Table.Summary.Cell>
                       <Table.Summary.Cell index={2}></Table.Summary.Cell>
                       <Table.Summary.Cell index={3}>Total: {new Intl.NumberFormat('en-Au', {
                         minimumFractionDigits: 2,
                         maximumFractionDigits: 2,
                         useGrouping: true
                       }).format(total)}</Table.Summary.Cell>
                     </Table.Summary.Row>;
                   }}
                   dataSource={buildForwardData()}
                   loading={componentState.isPayoutListLoading}></Table>
          </Card>
        </Col>
      </Row>
    </Card>
    <Modal visible={componentState.isPayoutModalOpen}
           style={{top: 3}}
           title={componentState.activePayoutId ? `Update Payout #${componentState.activePayoutId} Record` : 'Add a New Payment'}
           okText={
             componentState.activePayoutId
               ? `Update Payout`
               : `Add Payout`
           }
           onCancel={() => {
             payoutForm.resetFields();
             uploaderRef.current.clearList();
             setComponentState(prev => ({
               ...prev,
               isPayoutModalOpen: false,
               activePayoutId: null
             }));
           }}
           onOk={() => payoutForm.submit()}>
      <p>
        Fill in the details below to add a new payment (future invoice) into the system.<br />
        You can also upload an invoice or supporting documentation.
      </p>
      <Divider />
      <Form layout={'vertical'} initialValues={{currencyID: componentState.fxCurrency, attachments: []}}
            name={'payout-form'} form={payoutForm} onFinish={handleSubmit}>
        <Form.Item label={'Payment Due Date'} name={'scheduled'} normalize={(dayjsValue) =>
          dayjsValue && dayjsValue.startOf('day').format('YYYY-MM-DD')
        }
                   getValueProps={(value) =>
                     value && {value: dayjs(value, 'YYYY-MM-DD').startOf('day')}
                   }>
          <DatePicker format={'DD/MM/YYYY'} />
        </Form.Item>
        <Divider />
        <span style={{display: 'display-block', marginBottom: '10px'}}>
                        <CurrencySelect state={{currencies: app_state.currency_list.filter(i => i.id !== 1) || []}}
                                        rightLabel={'Enter payment amount'}
                                        onCurrencyChange={(val, d) => {
                                          const benes = componentState.beneficiaryList.filter(i => i.account_currency === d);
                                          setComponentState(prev => ({...prev, currencyBeneficiaryList: benes}));
                                        }}
                                        validated
                                        currencyFieldName={'currencyID'}
                                        fieldName={!componentState.isPlanReceipt ? 'payout' : 'receipt'}></CurrencySelect>
                    </span>
        {
          !componentState.isPlanReceipt && <><Form.Item name={'beneficiary_id'} label={'Beneficiary'}>
            <Select placeholder={' -- SELECT BENEFICIARY --'}
                    options={componentState.currencyBeneficiaryList.map(i => {
                      const curr = app_state.currency_list.find(k => k.id === i.account_currency);
                      return {
                        alt: i.nickname == null ? i.ben_legal_name : i.nickname,
                        label: <TypographyCurrency
                          iso_alpha_3={curr.iso_alpha_3}>{i.nickname == null ? i.ben_legal_name : i.nickname}</TypographyCurrency>,
                        value: i.id
                      };
                    })}
                    optionFilterProp={'alt'}></Select>
          </Form.Item>
            <Button style={{marginBottom: '10px'}} type={'primary'} size={'small'}
                    onClick={() => setComponentState(prev => ({...prev, isBenModalOpen: true}))}>Add
              Beneficiary</Button>
          </>
        }
        <Divider />
        <Form.Item name={'description'} label={'Description'}>
          <Input.TextArea />
        </Form.Item>
        {
          componentState.dataState === 'ALL'
            ? <>
              <Form.Item label='Forecast Payment' name='draft' valuePropName='checked'
                         style={{marginBottom: 0}}>
                <Switch />
              </Form.Item>
              <Typography.Text style={{marginBottom: 10}}><em>Select if this is
                a <strong>forecast</strong> payment (not
                actual)</em></Typography.Text>
            </> : <></>
        }

        <Form.Item name='attachments' getValueFromEvent={(e) => e.map(i => i.name)}>
          <FileUploader key={componentState.activePayoutId} uploaderType={'DRAGDROP'}
                        ref={uploaderRef}></FileUploader>
        </Form.Item>
        <Modal visible={componentState.isBenModalOpen}
               width={'900px'}
               style={{top: 10}}
               bodyStyle={{maxHeight: '80vh', overflowY: 'scroll', padding: '10px', background: '#E0e0e0'}}
               onCancel={() => {
                 beneficiaryModalRef.current.resetFields();
                 setComponentState(prev => ({...prev, isBenModalOpen: false}));
               }}
               onOk={() => {
                 beneficiaryModalRef.current.submit();
               }}
               modalRender={(modal) => (
                 <Draggable
                   disabled={modalDrag.disabled}
                   bounds={modalDrag.bounds}
                   nodeRef={draggableRef}
                   onStart={(event, uiData) => onStart(event, uiData)}
                 >
                   <div ref={draggableRef}>{modal}</div>
                 </Draggable>
               )}
               title={
                 <div style={{width: '100%', cursor: 'move'}}
                      onMouseOver={() => {
                        if (modalDrag.disabled) {
                          setModalDrag(prev => ({...prev, disabled: false}));
                        }
                      }}
                      onMouseOut={() => {
                        setModalDrag(prev => ({...prev, disabled: true}));
                      }}>Add New Beneficiary</div>
               }>
          <BeneficiaryModal clientId={app_state.current_client ? app_state.current_client.id : null}
                            initialCurrency={5}
                            onSubmit={(val) => {
                              initPrefs();
                              setComponentState(prev => ({...prev, isBenModalOpen: false}));
                            }}
                            ref={beneficiaryModalRef}></BeneficiaryModal>
        </Modal>
      </Form>
    </Modal>
    <Modal name={'Forwards-Modal'}
           key={2}
           closable={true}
           visible={componentState.isForwardModalOpen}
           title={'Request Coverage'}
           onCancel={() => {
             addCoverForm.resetFields();
             setComponentState(prev => ({...prev, isForwardModalOpen: false}));
           }}
           onOk={() => addCoverForm.submit()}>
      <p>
        Select to request either a spot transfer or forward cover for your payments.
      </p>
      <Divider />
      <Form layout={'vertical'} initialValues={{from_currency: 1, to_currency: 5}} name={'forwards-form'}
            form={addCoverForm} onFinish={handleForwardsSubmit}>
        <Segmented defaultValue={componentState.coverType}
                   block={true}
                   style={{marginBottom: '10px'}}
                   options={[{label: 'Spot', value: 'SPOT'}, {label: 'Forward', value: 'FORWARD'}]}
                   onChange={(val) => setComponentState(prev => ({...prev, coverType: val}))}></Segmented>
        {componentState.coverType === 'FORWARD' &&
          <Form.Item label={'Payment Due Date'} name={'payment_due_date'}
                     normalize={(dayjsValue) => dayjsValue && dayjsValue.startOf('day').format('YYYY-MM-DD')}
                     getValueProps={(value) => value && {value: dayjs(value, 'YYYY-MM-DD').startOf('day')}}>
            <DatePicker format={'DD/MM/YYYY'} />
          </Form.Item>}
        <Form.Item name={'from_currency'} label={'From Currency'}>
          <Select optionFilterProp={'alt'} options={(app_state.currency_list || []).map(i => ({
            ...i,
            value: i.id,
            alt: i.full_name + i.iso_alpha_3,
            label: <TypographyCurrency iso_alpha_3={i.iso_alpha_3}>{i.full_name}</TypographyCurrency>
          }))}></Select>
        </Form.Item>
        <Form.Item name={'to_currency'} label={'To Currency'}>
          <Select optionFilterProp={'alt'} options={(app_state.currency_list || []).map(i => ({
            ...i,
            value: i.id,
            alt: i.full_name + i.iso_alpha_3,
            label: <TypographyCurrency iso_alpha_3={i.iso_alpha_3}>{i.full_name}</TypographyCurrency>
          }))}></Select>
        </Form.Item>
        <Form.Item
          name={'amount'}
          label={'Cover Amount (select period or type amount)'}
          required={[
            {required: true, message: ''},
            {
              validator: (_, value) => {
                if (Number(value) === 0) {
                  return Promise.reject('Enter valid amount');
                }
                if (!/^\d+(\.\d{1,2})?$/.test(value)) {
                  return Promise.reject('Please enter a valid number with up to 2 decimal places');
                }
                if (value && parseFloat(value) === 0) {
                  return Promise.reject('Amount cannot be zero');
                }
                return Promise.resolve();
              }
            }
          ]}
          extra={(
            <>
            <em>Cover {componentState.desired_coverage}% of: </em>
            <Radio.Group onChange={addCoverForm_handleRadioChange}>
              <Radio value="all">Total Uncovered</Radio>
              <Row>Row gutter={[8, 8]}>
                <Col><Radio value="0-30">0-30 day amt</Radio></Col>
                <Col><Radio value="30-60">30-60 day amt</Radio></Col>
              </Row>
              <Row>Row gutter={[8, 8]}>
                <Col><Radio value="60-90">60-90 day amt</Radio></Col>
                <Col><Radio value="90+">90+ day amt</Radio></Col>
              </Row>
            </Radio.Group>
            </>
          )}
        >
          <Input />
        </Form.Item>
        {componentState.coverType === 'FORWARD' && <Form.Item name={'description'} label={'Description'}>
          <Input.TextArea />
        </Form.Item>}
      </Form>
    </Modal>
    <Modal visible={componentState.isListModalOpen}
           title={'Payouts List'}
           width={'700px'}
           onCancel={() => setComponentState(prev => ({...prev, isListModalOpen: false}))}
           cancelButtonProps={{type: 'primary', icon: <CloseOutlined />}}
           okButtonProps={{hidden: true}}
           cancelText={'Close'}>
      <div style={{display: 'flex', justifyContent: 'end'}}>
        <Button type={'primary'} danger={true} size={'small'} onClick={() => initData()}
                icon={<ReloadOutlined />}>Refresh List</Button>
      </div>
      <Table rowKey={'id'}
             key={app_state.current_client ? app_state.current_client.id : 0}
             columns={buildColumns()}
             size='small'
             summary={(data) => {
               const total = data.reduce((acc, item) => acc + item.amount, 0);
               return <Table.Summary.Row>
                 <Table.Summary.Cell index={0}></Table.Summary.Cell>
                 <Table.Summary.Cell index={1}></Table.Summary.Cell>
                 <Table.Summary.Cell index={2}></Table.Summary.Cell>
                 <Table.Summary.Cell index={3}>Total: {new Intl.NumberFormat('en-Au', {
                   minimumFractionDigits: 2,
                   maximumFractionDigits: 2,
                   useGrouping: true
                 }).format(total)}</Table.Summary.Cell>
               </Table.Summary.Row>;
             }}
             dataSource={renderTableData()}
             loading={componentState.isPayoutListLoading}></Table>
    </Modal>
    <Modal visible={componentState.isTransferModalOpen}
           title={'Forwards and Transfers'}
           width={'1000px'}
           onCancel={() => setComponentState(prev => ({...prev, isTransferModalOpen: false}))}
           cancelButtonProps={{type: 'primary', icon: <CloseOutlined />}}
           okButtonProps={{hidden: true}}
           cancelText={'Close'}>
      <div style={{display: 'flex', justifyContent: 'end', marginBottom: '10px'}}>
        <Button type={'primary'} danger={true} size={'small'} onClick={() => initData()}
                icon={<ReloadOutlined />}>Refresh List</Button>
      </div>
      <Segmented defaultValue={componentState.transferType}
                 block={true}
                 style={{marginBottom: '10px'}}
                 options={[{label: 'Spot', value: 'SPOT'}, {label: 'Forward', value: 'FORWARD'}]}
                 onChange={(val) => setComponentState(prev => ({...prev, transferType: val}))}></Segmented>
      <Table rowKey={'id'}
             key={app_state.current_client ? app_state.current_client.id : 0}
             columns={buildColumns()}
             size='small'
             summary={(data) => {
               const total = data.reduce((acc, item) => acc + item.amount, 0);
               return <Table.Summary.Row>
                 <Table.Summary.Cell index={0}></Table.Summary.Cell>
                 <Table.Summary.Cell index={1}></Table.Summary.Cell>
                 <Table.Summary.Cell index={2}></Table.Summary.Cell>
                 <Table.Summary.Cell index={3}></Table.Summary.Cell>
                 <Table.Summary.Cell index={4}>Average Forward Rate: .xxxx%</Table.Summary.Cell>
                 <Table.Summary.Cell index={5}>Total: {new Intl.NumberFormat('en-Au', {
                   minimumFractionDigits: 2,
                   maximumFractionDigits: 2,
                   useGrouping: true
                 }).format(total)}</Table.Summary.Cell>
               </Table.Summary.Row>;
             }}
             dataSource={componentState.dataList.filter(i => componentState.transferType === 'SPOT' ? i.type === 'TRANSFERS' : i.type === 'FORWARDS')}
             loading={componentState.isPayoutListLoading}></Table>
    </Modal>
    <Modal visible={componentState.isDraftForwardModelOpen}
           onOk={() => draftForwardForm.submit()}
           onCancel={() => {
             draftForwardForm.resetFields();
             setComponentState(prev => ({...prev, isDraftForwardModelOpen: false}));
           }}
           title={'Enter Hypothetical Coverage'}>
      <Form layout={'vertical'} initialValues={{currencyFromID: 1, currencyToID: 5}} name={'draft-forward-form'}
            form={draftForwardForm} onFinish={handleDraftForwardSubmit}>
        <Typography.Paragraph>
          Use this form to enter hypothetical coverage for your future payments.
          These will be recorded as Hypothetical forwards to help you plan your hedging requirements
        </Typography.Paragraph>
        <Form.Item normalize={(e) => e && dayjs(e).startOf('day').format('YYYY-MM-DD')}
                   rules={[{required: true, message: 'Settlement date is required'}]}
                   getValueProps={(value) =>
                     value && {value: dayjs(value, 'YYYY-MM-DD').startOf('day')}
                   }
                   name='settlementDate'
                   label={'Settlement Date'}>
          <DatePicker format={'DD/MM/YYYY'} />
        </Form.Item>
        <Form.Item name='currencyFromID'
                   rules={[{required: true, message: 'Currency is required'}]}
                   label={'Currency From'}>
          <Select showSearch={true} allowClear={true} optionFilterProp={'alt'}
                  options={app_state.currency_list.map(i => ({
                    ...i,
                    alt: `${i.full_name}${i.iso_alpha_3}`,
                    value: i.id,
                    label: <TypographyCurrency
                      iso_alpha_3={i.iso_alpha_3}>{i.full_name} ({i.iso_alpha_3})</TypographyCurrency>
                  }))} />
        </Form.Item>
        <Form.Item name='currencyToID'
                   rules={[{required: true, message: 'Currency is required'}]}
                   label={'Currency To'}>
          <Select showSearch={true} allowClear={true} optionFilterProp={'alt'}
                  options={app_state.currency_list.map(i => ({
                    ...i,
                    alt: `${i.full_name}${i.iso_alpha_3}`,
                    value: i.id,
                    label: <TypographyCurrency
                      iso_alpha_3={i.iso_alpha_3}>{i.full_name} ({i.iso_alpha_3})</TypographyCurrency>
                  }))} />
        </Form.Item>
        <Form.Item name={'rate'} label={'Rate'}>
          <InputNumber />
        </Form.Item>
        <Form.Item name='amountTo'
                   rules={[{required: true, message: 'Amount is required'}]}
                   label={'Amount'}>
          <InputNumber step={0.01} />
        </Form.Item>
      </Form>
    </Modal>
  </Context.Provider>
};
const mapStateToProps = (state) => ({
  app_state: state.app_state
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(style)(ListTable(ExposureMonitorDetailActual)));