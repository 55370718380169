import React, {useEffect, useState} from "react";
import ChartistGraph from "react-chartist";
import {connect} from "react-redux";
import dayjs from "dayjs";
import withStyles from "@material-ui/core/styles/withStyles";


const style = theme => ({
    chartistTooltip: {
        position: 'absolute',
        display: 'none',
        padding: '5px',
        background: 'rgba(0, 0, 0, 0.8)',
        color: 'white',
        borderRadius: '3px',
        pointerEvents: 'none',
        zIndex: '100',
    }
})
const FxHorizontalBarChart = ({ classes, app_state, data = [], height = 300, id = 'horizontal-chart' }) => {

    const [componentState, setComponentState] = useState({
        barData: {
            labels: [],
            series: []
        },
        chartConfig: {
            horizontalBars: true,
            axisY: {
                offset: 80,
            },
            barWidth: 50,
            axisX: {
                labelInterpolationFnc: (val) => val,
                onlyInteger: true, // Ensure only integer values are displayed
                low: 0, // Start at 0
                high: 5000, // Set the max value dynamically or statically
            }
        }
    });

    const calculateTicks = () => {
        const max = Math.max(...data.map(i => i.value));
        const min = Math.min(...data.map(i => i.value));

        const numTicks = 5; // Specify the number of ticks you want
        let step = (max - min) / (numTicks - 1); // (numTicks - 1) because you need (numTicks - 1) intervals
        step = Math.ceil(step); // E

        const ticks = Array.from({ length: numTicks }, (_, index) => min + step * index);

        // Set the component state with the dynamically calculated ticks
        setComponentState(prev => ({
            ...prev,
            chartConfig: {
                ...prev.chartConfig,
                axisX: {
                    ...prev.chartConfig.axisX,
                    low: 0,
                    high: max + 100,
                    labelInterpolationFnc: (value) => {
                       return value % 40 === 0 ? value : null
                    }
                }
            }
        }));
    }

    const prepareChart = () => {
        const labels = data.map(i => i.label);
        const series = [data.map(i => i.value)];
        setComponentState(prev => ({
            ...prev,
            barData: {
                labels: labels,
                series: series
            }
        }));
    }

    const listen = () => {
        return {
            draw: (data) => {
                if (data.type === 'bar') {
                    const xAxisLabel = data.axisX.ticks[data.index];
                    data.element.attr({
                        'meta': xAxisLabel,
                        'series': data.seriesIndex,
                        'hoverLabel': 'Value',
                    });

                }
            }
        }
    }

    const drawChart = () => {
        const chart = document.querySelector(`.${id}`);
        const tooltip = document.createElement('div');
        tooltip.className = `${classes.chartistTooltip}`;
        document.body.appendChild(tooltip);
        if (chart) {
            chart.addEventListener('mouseover', (event) => {
                if (event.target.classList.contains('ct-bar')) {
                    const value = event.target.getAttribute('ct:value');
                    const label = event.target.getAttribute('hoverLabel');

                    tooltip.innerHTML = `${label}: ${value}`;
                    tooltip.style.display = 'block';
                    tooltip.style.left = `${event.pageX}px`;
                    tooltip.style.top = `${event.pageY - 40}px`;
                }
            });

            chart.addEventListener('mouseout', () => {
                tooltip.style.display = 'none';
            });

            chart.addEventListener('mousemove', (event) => {
                tooltip.style.left = `${event.pageX}px`;
                tooltip.style.top = `${event.pageY - 40}px`;
            });
        }
    }

    useEffect(() => {
        calculateTicks();
        prepareChart();
    }, [data, app_state.current_client]);

    useEffect(() => {
        drawChart();
    }, [componentState.barData]);

    return <div style={{ width: '100%', paddingRight: 0 }}>
        <ChartistGraph data={componentState.barData}
                       className={id}
                       listener={listen()}
                       options={componentState.chartConfig} type="Bar" />
    </div>
}

const mapStateToProps = (state) => ({
    app_state: state.app_state
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(style)(FxHorizontalBarChart));