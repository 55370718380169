import {Button, Card, Col, Divider, Form, message, Modal, Row, Select, Table, Tag} from "antd";
import React, {useEffect, useState} from "react";
import {API} from "aws-amplify";
import {connect} from "react-redux";
import withStyles from "@material-ui/core/styles/withStyles";
import ListTable from "../../../components/ListTable/ListTable";
import dayjs from "dayjs";
import CurrencyElement from "../../../components/CurrencyElement/CurrencyElement";
import {CheckSquareOutlined, CloseSquareOutlined, ReloadOutlined} from "@ant-design/icons";
import FileUploader from "../../../components/FileUploader/FileUploader";
import {DataStatus} from "../../../components/ComponentInfo/Status";
import TypographyCurrency from "../../../components/CurrencySelect/TypographyCurrency";

const styles = theme => ({});
const ExposureMonitorPayments = ({app_state, getColumnSearchProps}) => {

    const [componentInfos, setComponentInfos] = useState({
        fetchPaymentsInfo: {status: DataStatus.Loaded, callBack: () => fetchPaymentsByCurrencyId()}
    })

    const [componentState, setComponentState] = useState({
        beneficiaryList: [],
        currencyBeneficiaryList: [],
        payments: [],
        currencies: [],
        activeCurrency: null,
    });

    const buildColumns = () => {
        return [
            {
                title: 'ID',
                dataIndex: 'id',
                key: 'id',
                sorter: (a, b) => a.id - b.id,
                defaultSortOrder: 'descend',
                ...getColumnSearchProps({
                    dataIndex: 'id',
                    render: (text, row) => text
                })
            },
            {
                title: 'Type',
                dataIndex: 'type',
                key: 'type',
                ...getColumnSearchProps({
                    dataIndex: 'type',
                    render: (text, row) => text
                })
            },
            {
                title: 'Beneficiary',
                dataIndex: 'bid',
                key: 'bid',
                ...getColumnSearchProps({
                    dataIndex: 'bid',
                    render: (text) => {
                        const beneficiary = componentState.beneficiaryList.find(i => i.id === text);
                        return beneficiary ? beneficiary.name : ('Pay to Currency Balance');
                    }
                })
            },
            {
                title: 'Currency From',
                dataIndex: 'currencyFromId',
                key: 'currencyFromId',
                ...getColumnSearchProps({
                    dataIndex: 'currencyFromId',
                    render: (text) => {
                        if (text) {
                            return <TypographyCurrency iso_alpha_3={'AUD'}>{'AUD'}</TypographyCurrency>
                        }
                        return <>-</>
                    }
                })
            },
            {
                title: 'Rate',
                dataIndex: 'rate',
                key: 'rate',
                ...getColumnSearchProps({
                    dataIndex: 'rate',
                    render: (text) => <>{text ? text.toFixed(4) : '-'}</>
                })
            },
            {
                title: 'Currency To',
                dataIndex: 'currencyID',
                key: 'currencyID',
                ...getColumnSearchProps({
                    dataIndex: 'currencyID',
                    render: (text) => {
                        if (text) {
                            const currency = componentState.currencies.find(item => item.id === text);
                            return <TypographyCurrency
                                iso_alpha_3={currency.iso_alpha_3}>{currency.iso_alpha_3}</TypographyCurrency>
                        }
                        return <>-</>
                    }
                })
            },
            {
                title: 'Amount',
                dataIndex: 'amount',
                key: 'amount',
                sorter: (a, b) => a.amount - b.amount,
                ...getColumnSearchProps({
                    dataIndex: 'amount',
                    render: (text, row) => {
                        const currency = componentState.currencies.find(item => item.id === row.currencyID);
                        return <CurrencyElement currency={currency.iso_alpha_3} value={text}/>
                    }
                })
            },
            {
                key: 'attachments',
                title: 'Attachments',
                dataIndex: 'attachments',
                render: (text, record) => {
                    if (record.attachments.length > 0) {
                        return <Tag style={{cursor: 'pointer'}}
                                    onClick={() => onViewDocumentsBtnClick(record.attachments)}
                                    icon={<CheckSquareOutlined/>} color={'success'}></Tag>
                    } else {
                        return <Tag style={{cursor: 'pointer'}} icon={<CloseSquareOutlined/>} color={'warning'}></Tag>
                    }
                }
            }
        ]
    }

    const onViewDocumentsBtnClick = (attachments) => {
        Modal.confirm({
            title: 'Attachments',
            width: 700,
            content: <Row gutter={[16, 16]}>
                <Divider/>
                {attachments.map(i => <>
                    <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
                        <FileUploader previewOnly={true}
                                      thumbType={'picture-card'}
                                      uploaderType={'PDF'}
                                      fetchFileList={() => {
                                          const file = {
                                              name: i,
                                              uid: i,
                                              status: 'done'
                                          };
                                          return Promise.resolve([file]);
                                      }}></FileUploader>
                    </Col>
                </>)}
            </Row>,
            cancelButtonProps: {hidden: true},
            maskClosable: true
        });
    };

    const fetchPaymentsByCurrencyId = async () => {
        if (!app_state.current_client) return true;

        const loading = message.loading("Fetching data...", 0);

        setComponentInfos(prev => ({
            ...prev,
            fetchPaymentsInfo: {
                ...prev.fetchPaymentsInfo,
                status: DataStatus.Loading
            }
        }));

        try {
            const beneficiaries = await API.post("commons", "/fetch", {
                body: {
                    context: 'beneficiaries', fields: ['*'], condition: {client_id: app_state.current_client.id, deleted: 0}
                }
            });

            const payments = await Promise.all([API.post("commons", "/fetch", {
                body: {
                    context: 'cashplan_records',
                    fields: ['*'],
                    condition: {deleted: 0, receipt: null, clientID: app_state.current_client.id}
                }
            }), API.post("commons", "/fetch", {
                body: {
                    context: 'forwards', fields: ['*'], condition: {
                        deleted: 0, client_id: app_state.current_client.id
                    }
                }
            }), API.post("commons", "/fetch", {
                body: {
                    context: 'draft_forward', fields: ['*'], condition: {
                        deleted: 0, clientID: app_state.current_client.id
                    }
                }
            }), API.post("commons", "/fetch", {
                body: {
                    context: 'transfers', fields: ['*'], condition: {
                        deleted: 0, client_id: app_state.current_client.id
                    }
                }
            })]).then(([payouts, forwards, draftForwards, transfers]) => prepareMappedData({
                payouts, forwards, draftForwards, transfers
            }));

            const currBeneficiaries = beneficiaries.filter(i => i.account_currency === componentState.activeCurrency);

            setComponentState(prev => ({
                ...prev, beneficiaryList:
                beneficiaries,
                currencyBeneficiaryList: currBeneficiaries,
                payments: payments,
                currencies: app_state.currency_list
            }));

            setComponentInfos(prev => ({
                ...prev,
                fetchPaymentsInfo: {
                    ...prev.fetchPaymentsInfo,
                    status: DataStatus.Loaded
                }
            }));

        } catch (e) {

            console.error(e);
            message.error("An error occurred while fetching data.");

            setComponentInfos(prev => ({
                ...prev,
                fetchPaymentsInfo: {
                    ...prev.fetchPaymentsInfo,
                    status: DataStatus.ErrorState
                }
            }));
        } finally {
            loading();
        }
    }

    const prepareMappedData = (datum) => {
        let data = [];
        if (datum.payouts && datum.payouts.length > 0) {
            const d = datum.payouts.map(item => ({
                id: item.id,
                bid: item.beneficiary_id,
                identifier: 'cashplan_records',
                type: 'PAYOUTS',
                amount_from: null,
                amount: item.payout,
                currencyID: item.currencyID,
                date: item.scheduled,
                rate: null,
                draft: item.draft || false,
                attachments: (!(item.attachments == null || item.attachments === "")) ? JSON.parse(item.attachments) : []
            }));
            data = [...data, ...d];
        }

        data = data.map(item => ({...item, date: item.date != null ? dayjs(item.date).format('DD/MM/YYYY') : null}));
        data = data.filter(item => !!dayjs(item.date, 'DD/MM/YYYY').isAfter(dayjs()));
        if (componentState.activeCurrency) {
            data = data.filter(item => item.currencyID === componentState.activeCurrency);
        }

        return data;
    }

    useEffect(() => {
        fetchPaymentsByCurrencyId();
    }, [app_state.current_client, componentState.activeCurrency]);

    return <>
        <Card title={
            <div style={{display: 'flex', justifyContent: 'space-between'}}>
                <h5>Exposure Monitor Payments</h5>
                <div>
                    <Button type={'primary'} danger onClick={fetchPaymentsByCurrencyId}
                            icon={<ReloadOutlined/>}>Refresh</Button>
                </div>
            </div>
        }>
            <div style={{width: '300px'}}>
                <Form.Item name="currencyId" label={'Currency'}>
                    <Select defaultValue={componentState.activeCurrency}
                            onChange={val => setComponentState(prev => ({...prev, activeCurrency: val}))}
                            placeholder={'-- SELECT --'}
                            allowClear={true}
                            showSearch={true}
                            optionFilterProp={'alt'}
                            options={app_state.currency_list.map(item => ({
                                label: <TypographyCurrency
                                    iso_alpha_3={item.iso_alpha_3}>[{item.iso_alpha_3}] {item.full_name}</TypographyCurrency>,
                                value: item.id,
                                alt: item.iso_alpha_3 + item.full_name
                            }))}></Select>
                </Form.Item>
            </div>
            <Table size={'small'}
                   columns={buildColumns()}
                   onRow={(record, rowIndex) => {
                       return {
                           style: {
                               backgroundColor: record.draft ? '#c5edc0' : 'inherit',
                           }
                       }
                   }}
                   loading={componentInfos.fetchPaymentsInfo.status === DataStatus.Loading}
                   dataSource={componentState.payments}></Table>
        </Card>
    </>
}

const mapStateToProps = state => ({app_state: state.app_state});
const mapDispatchToProps = state => ({});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ListTable(ExposureMonitorPayments)));