import {connect} from 'react-redux';
import withStyles from '@material-ui/core/styles/withStyles';
import ListTable from '../../../components/ListTable/ListTable';
import {FormattedNumber} from 'react-intl';
import React, {useEffect, useMemo, useRef, useState} from 'react';
import {useForm} from 'antd/es/form/Form';
import {API} from 'aws-amplify';
import dayjs from 'dayjs';
import {
    Alert,
    Button,
    Card,
    Col,
    DatePicker,
    Descriptions,
    Divider,
    Form,
    Input,
    InputNumber,
    message,
    Modal,
    Radio,
    Row,
    Segmented,
    Select,
    Table,
    Tag,
    Typography
} from 'antd';
import {ArrowLeftOutlined, CloseOutlined, PlusOutlined, ReloadOutlined} from '@ant-design/icons';
import TypographyCurrency from '../../../components/CurrencySelect/TypographyCurrency';
import ChartistGraph from 'react-chartist';
import CurrencySelect from '../../../components/CurrencySelect/CurrencySelect';
import FileUploader from '../../../components/FileUploader/FileUploader';
import Draggable from 'react-draggable';
import BeneficiaryModal from '../../../components/Beneficiaries/BeneficiaryModal';
import {CurrencyLocales} from '../../../AppUtil';
import {notification} from 'antd/es';
import axios from 'axios';
import {ExposureMonitorUtils, ExposureMonitorUtils as exposureMonitorUtils} from './exposureMonitorUtils';
import {useLocation} from 'react-router-dom';
import FxHorizontalBarChart from '../../Charts/FxHorizontalBarChart';
import {Description} from "@material-ui/icons";
import Chip from "@material-ui/core/Chip";
import Title from "antd/es/typography/Title";
import {RateAlertAPI} from "../RateAlerts/RateAlertsUtil";
import FxDonut from "../../../components/Charts/FxDonut";
import FxPieChart from "../../Charts/FxPieChart";

const style = theme => ({
    chartistTooltip: {
        position: 'absolute',
        display: 'none',
        padding: '5px',
        background: 'rgba(0, 0, 0, 0.8)',
        color: 'white',
        borderRadius: '3px',
        pointerEvents: 'none',
        zIndex: '100'
    }, currency: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: 'fit-content',
        '& > .currency-flag': {
            marginRight: '0.5rem', width: 52, height: 26
        }
    }, currencyLabel: {
        textAlign: 'left', '& > p': {
            marginBottom: -5, fontSize: '0.6rem'
        }
    },
    coverageCenter: {
        display: 'flex', alignItems: 'center', justifyContent: 'center'
    }
});
export const CurrencyElement = ({classes, currency, value}) => (<div className={classes.currency}>
    <div
        className={`currency-flag currency-flag-${currency ? currency.toLowerCase() : ''}`}
    />
    <div className={classes.currencyLabel}>
        <p>{currency}</p>
        <FormattedNumber
            minimumFractionDigits={2}
            maximumFractionDigits={2}
            value={value}
        />
    </div>
</div>);

const Context = React.createContext({
    name: 'Default'
});

const ExposureMonitorDetailForecast = ({app_state, classes, getColumnSearchProps, history}) => {

    const desiredCoverage = useRef(null)
    const [payoutForm] = useForm();
    const [transferForm] = useForm();
    const [valueForm] = useForm();
    const [draftForwardForm] = useForm();

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);

    const [api, contextHolder] = notification.useNotification();

    const uploaderRef = useRef();
    const beneficiaryModalRef = useRef();
    const draggableRef = useRef(null);

    const [modalDrag, setModalDrag] = useState({
        disabled: true, bounds: {left: 0, top: 0, bottom: 0, right: 0}
    });

    const [pieCharts, setPieCharts] = useState({
        currencyAverage: {
            labels: [], series: [], data: [], isUncoveredZero: true, forwardGtPayout: false, descriptions: []
        }
    });


    const [barCharts, setBarCharts] = useState({
        fxExposure: {
            labels: [], series: [], chartConfig: {
                axisY: {
                    offset: 60
                },
                seriesBarDistance: 15, height: '400px', showPoint: true, axisX: {
                    labelInterpolationFnc: function(value, i, j) {
                        return value;
                    }
                }
            }
        }, currencyPlan: {
            labels: [], series: [], chartConfig: {
                axisY: {
                    offset: 60
                },
                seriesBarDistance: 15, height: '400px', showPoint: true, axisX: {
                    labelInterpolationFnc: function(value, i, j) {
                        return value;
                    }
                }
            }
        }

    });

    const [componentState, setComponentState] = useState({
        transferType: 'PAYMENTS',
        coverType: 'FORWARD',
        activePayoutId: null,
        isPayoutModalOpen: false,
        isBenModalOpen: false,
        isForwardModalOpen: false,
        isDraftForwardModelOpen: false,
        isListModalOpen: false,
        isTransferModalOpen: false,
        isPayoutListLoading: false,
        beneficiaryList: [],
        currencyBeneficiaryList: [],
        fxCurrency: queryParams.get('currency') ? Number(queryParams.get('currency')) : 5,
        data: [],
        dataList: [],
        monthly: null,
        months: [],
        currentRate: null,
        dataState: 'DRAFT',
        desired_coverage: null,
        account_list: [],
        owedEstimate: [],
        thirtyDays: [],
        sixtyDays: [],
        ninetyDays: [],
        ninetyPlus: [],
        donutTableData: [],
        marketRate: 0,
        monthChips: [
            {label: 'February', value: 'FEB'},
            {label: 'March', value: 'MAR'},
            {label: 'April', value: 'APR'},
            {label: 'May', value: 'MAY'},
            {label: 'June', value: 'JUN'},
            {label: 'July', value: 'JUL'},
            {label: 'August', value: 'AUG'},
            {label: 'September', value: 'SEP'},
            {label: 'October', value: 'OCT'},
            {label: 'November', value: 'NOV'},
            {label: 'December', value: 'DEC'},
            {label: 'January', value: 'JAN'}
        ],
        populateDraftForwardForm: (values, state) => {
            draftForwardForm.setFieldsValue(values);
            app_state = state;
        },
        onRateChangeBtnClick: (data, state) => onRateChange(data, state)
    });

    const onRateChange = ({ row, val, isDraft = false }, state) => {
        app_state = state;
        let rate = val;
        if (isDraft) {
            Modal.confirm({
                title: 'Change Rate ?',
                content: <Form.Item label={'Enter Rate'} name="rate">
                    <InputNumber defaultValue={val} onKeyUp={(event) => rate = event.target.value }/>
                </Form.Item>,
                onOk: () => {
                    return new Promise((resolve, reject) => {
                        if (rate == null) {
                            reject();
                            message.error("Rate cannot be zero or empty");
                        }
                        API.post("commons", `/update`, {
                            body: {
                                context: 'draft_forward',
                                data: {rate: Number(rate).toFixed(4)},
                                condition: {id: row.id, rate: val}
                            }
                        }).then(res => {
                            message.success("Rate updated successfully");
                            resolve();
                        }).catch(err => {
                            reject();
                            message.error("Error updating rate, try again..");
                        })
                    }).then(() => prepareData())
                },
            })
        }
    }

    const initPrefs = async () => {
        if (!app_state.current_client) return true;
        await API.post('commons', '/fetch', {
            body: {
                context: 'beneficiaries', fields: ['*'], condition: {client_id: app_state.current_client.id, deleted: 0 }
            }
        }).then(res => {
            const data = res.filter(i => i.account_currency === componentState.fxCurrency);
            setComponentState(prev => ({...prev, beneficiaryList: res, currencyBeneficiaryList: data}));
        }).catch(err => {
            console.log('Unable to fetch beneficiaries', err);
        });
    };

    const prepareMappedData = (datum) => {
        let data = [];
        if (datum.payouts && datum.payouts.length > 0) {
            const d = datum.payouts.map(item => ({
                id: item.id,
                bid: item.beneficiary_id,
                identifier: 'cashplan_records',
                type: 'PAYOUTS',
                amount_from: null,
                amount: item.payout,
                currencyID: item.currencyID,
                date: item.scheduled,
                rate: null,
                draft: item.draft || false,
                attachments: (!(item.attachments == null || item.attachments === '')) ? JSON.parse(item.attachments) : []
            }));
            data = [...data, ...d];
        }
        if (datum.transfers && datum.transfers.length > 0) {
            const d = datum.transfers.map(item => ({
                id: item.id,
                identifier: 'transfers',
                bid: item.beneficiary_id,
                type: 'TRANSFERS',
                amount: item.amount_to,
                amount_from: item.amount_from,
                currencyID: item.currency_to_id,
                currencyFromId: item.currency_from_id,
                date: item.settlement_date,
                rate: item.client_rate,
                draft: item.draft || false,
                attachments: (!(item.attachments == null || item.attachments === '')) ? JSON.parse(item.attachments) : []
            }));
            data = [...data, ...d];
        }
        if (datum.draftForwards && datum.draftForwards.length > 0) {
            const d = datum.draftForwards.map(item => ({
                id: item.id,
                identifier: 'forwards',
                bid: null,
                type: 'FORWARDS',
                amount: item.amountTo,
                amount_from: null,
                currencyID: item.currencyToID,
                currencyFromId: item.currencyFromID,
                date: item.settlementDate,
                rate: item.rate,
                draft: true
            }));
            data = [...data, ...d];
        }

        const fxExposureList = data.filter(i => !!dayjs(i.date).isAfter(dayjs()));
        data = data.filter(item => item.currencyID === componentState.fxCurrency && !!dayjs(item.date).isAfter(dayjs()));

        return {
            data: data,
            fxExposureList: fxExposureList
        };
    };

    const initData = () => {
        if (!app_state.current_client) return true;
        prepareData();
    };

    const prepareData = () => {
        setComponentState(prev => ({...prev, isPayoutListLoading: true}));
        Promise.all([API.post('commons', '/fetch', {
            body: {
                context: 'cashplan_records',
                fields: ['*'],
                condition: {deleted: 0, receipt: null, clientID: app_state.current_client.id}
            }
        }), API.post('commons', '/fetch', {
            body: {
                context: 'draft_forward', fields: ['*'], condition: {
                    deleted: 0, clientID: app_state.current_client.id
                }
            }
        }), API.post('commons', '/fetch', {
            body: {
                context: 'transfers', fields: ['*'], condition: {
                    deleted: 0, client_id: app_state.current_client.id
                }
            }
        })]).then(([payouts, draftForwards, transfers]) => prepareMappedData({ payouts, draftForwards, transfers })).then(res => {

            const getUniqueDates = (data) => [
                ...new Map(data.map(item => {
                    const date = dayjs(item.date);
                    return [date.month(), {label: date.format('MMMM'), value: date.month() + 1, year: date.format('YYYY')}];
                })).values()
            ];

            const months = getUniqueDates(res.data);


            setComponentState(prev => ({
                ...prev,
                data: res.data,
                dataList: res.data,
                isPayoutListLoading: false,
                fxExposureList: res.fxExposureList,
                months: months
            }));


            const {
                eBarLabels = [],
                eBarSeries = []
            } = prepareFXExposureChart([...res.fxExposureList], componentState.dataState);
            let {barLabels = [], barSeries = []} = {barLabels: [], barSeries: []};
            if (!componentState.monthly) {
                const result = prepareCurrencyPlanChart(res.data, componentState.dataState);
                barLabels = result.barLabels;
                barSeries = result.barSeries;
            }
            setBarCharts(prev => ({
                ...prev,
                currencyPlan: {
                    ...prev.currencyPlan, labels: barLabels, series: barSeries
                },
                fxExposure: {
                    ...prev.fxExposure, labels: eBarLabels, series: eBarSeries
                }
            }));

            const {
                pieLabels,
                pieSeries,
                data,
                isUncoveredZero,
                descriptions
            } = prepareCurrencyAverageChart(res.data, componentState.dataState);
            setPieCharts(prev => ({
                ...prev,
                currencyAverage: {
                    ...prev.currencyAverage,
                    series: pieSeries,
                    labels: pieLabels,
                    data: data,
                    isUncoveredZero,
                    descriptions
                }
            }));
        });
    }

    const prepareHorizontalBarChartData = (data) => {
        if (data.length > 0) {

            if (componentState.monthly != null) {
                const {year, month} = componentState.monthly;
                const filterMonth = `${year}-${String(month).padStart(2, '0')}`; //
                data = data.filter(item =>
                    dayjs(item.date).format('YYYY-MM') === filterMonth
                );
            }

            data = data.filter(item => item.draft);
            const summedData = data.filter(item => item.type === 'PAYOUTS').reduce((acc, {bid, amount}) => {
                acc.set(bid, (acc.get(bid) || 0) + amount);
                return acc;
            }, new Map());

            const result = Array.from(summedData, ([bid, amount]) => ({
                bid,
                amount
            })).map(item => {
                const beneficiary = componentState.beneficiaryList.find(i => i.id === item.bid);
                return {
                    label: beneficiary ? beneficiary.nickname : 'Currency Balance',
                    value: item.amount
                };
            });

            setComponentState(prev => ({...prev, owedEstimate: result}));
        } else {
            setComponentState(prev => ({...prev, owedEstimate: []}));
        }
    };

    const prepareFXExposureChart = (data, includeDraft) => {
        const currencyIds = [...new Set(data.map(item => item.currencyID))];
        const labels = currencyIds.map(id => app_state.currency_list.find(i => i.id === id).iso_alpha_3).filter(Boolean);

        const seriesTypes = ['PAYOUTS', 'TRANSFERS', 'FORWARDS'];


        const newData = data.filter(item => item.draft);

        const eBarSeries = seriesTypes.map(type =>
            labels.map(label => {
                const currencyId = app_state.currency_list.find(i => i.iso_alpha_3 === label).id;
                return newData
                    .filter(i => i.currencyID === currencyId && i.type === type)
                    .reduce((sum, {amount}) => sum + amount, 0);
            })
        );

        return {eBarLabels: labels, eBarSeries};
    };

    const prepareCurrencyPlanChart = (datum, isDraft) => {
        if (!componentState.monthly) {

            let data = datum.map(i => {
                const item = {...i};
                if (i.type !== 'PAYOUTS') {
                    item.type = 'COVERED';
                }
                return item;
            });

            data = data.filter(item => item.draft);

            const expectedTypes = ['PAYOUTS', 'COVERED'];

            const groupedData = data.reduce((acc, {type, amount, date}) => {
                const formattedDate = dayjs(date).format('MMM YYYY');
                return {
                    ...acc,
                    [type]: {
                        ...(acc[type] || {}),
                        [formattedDate]: ((acc[type] && acc[type][formattedDate]) || 0) + amount
                    }
                };
            }, {});

            expectedTypes.forEach(type => {
                if (!groupedData[type]) {
                    groupedData[type] = {[dayjs().format('MMM YYYY')]: 0}; // Initialize empty object for missing types
                }
            });

            const labels = Array.from({length: 12}, (_, i) => dayjs().add(i, 'month').format('MMM YYYY'));
            const fillMissingMonths = (dataObject = {}) => labels.reduce((acc, month) => ({
                ...acc,
                [month]: dataObject[month] || 0
            }), {});

            const series = expectedTypes.map(type => [...Object.values(fillMissingMonths(groupedData[type]))]);
            return {
                barLabels: Array.from({length: 12}, (_, i) => dayjs().add(i, 'month').format('MMM YYYY')),
                barSeries: series
            };
        }
    };

    const prepareCurrencyAverageChart = (data, isDraft) => {

        const payout = data.filter(i => i.type === 'PAYOUTS' && i.draft).reduce((acc, obj) => acc + obj.amount, 0);
        let covered = data.filter(i => i.type !== 'PAYOUTS' && i.draft).reduce((acc, obj) => acc + obj.amount, 0);

        let uncovered = payout - covered;

        let balance = 0;
        if (componentState.account_list.length > 0) {
            const acc = componentState.account_list.find(i => i.currencies_short_name === getSelectedCurrency(componentState.fxCurrency));
            covered = acc ? (acc.balance + covered) : covered;
            balance = acc ? acc.balance : 0;
            uncovered = uncovered - (acc ? acc.balance : 0);
        }

        const descriptions = ExposureMonitorUtils.prepareDescriptions(data, componentState.dataState, null, balance);

        const series = [
            {label: 'Uncovered Amount', value: uncovered},
            {label: 'Covered Amount', value: covered}
        ];

        const seriesB = [
            {label: 'Uncovered', value: uncovered},
            {label: 'Covered', value: covered}
        ];

        const total = covered + uncovered;

        const labels = seriesB.map(i => {
            const percentage = ((i.value / total) * 100).toFixed(0);
            return `${i.label} = ${percentage}%`;
        });

        const desc = [...series];
        desc.push({label: 'Payments Loaded', value: payout});

        return {
            descriptions: descriptions,
            pieSeries: seriesB,
            pieLabels: labels.map((val, ind) => series[ind].value === 0 ? ' ' : val),
            data: desc,
            isUncoveredZero: uncovered < 0
        };
    };

    const onEditBtnClick = (payoutId) => {
        const loading = message.loading(`Loading id #${payoutId}. Please wait..`, 0);
        API.post('commons', '/fetch', {
            body: {
                context: 'cashplan_records', condition: {id: payoutId}, fields: ['*']
            }
        }).then(res => {
            const [d] = res;
            setComponentState(prev => ({
                ...prev, isPayoutModalOpen: true, activePayoutId: payoutId
            }));
            if (d.attachments == null && d.attachments === '') {
                const bottle = (JSON.parse(d.attachments) || []).map(i => ({
                    name: i, uid: i, status: 'done'
                }));
                uploaderRef.current.setFileList(bottle);
            }
            payoutForm.setFieldsValue({...d, date: dayjs(d.date)});

        }).catch(err => {
            console.log(err);
            message.error('Failed to load. Please try again..');
        }).finally(() => loading());
    };

    const buildColumns = () => {
        return exposureMonitorUtils.buildPayoutColumns(getColumnSearchProps, componentState, app_state, sendForward, classes, initData, onEditBtnClick, onViewDocumentsBtnClick, history, setComponentState);
    };

    const buildForwardColumns = () => {
        return exposureMonitorUtils.buildCoveredColumns(getColumnSearchProps, componentState, app_state, sendForward, classes, initData, onEditBtnClick, onViewDocumentsBtnClick, history, setComponentState);
    };

    const onViewDocumentsBtnClick = (attachments) => {
        Modal.confirm({
            title: 'Attachments',
            width: 700,
            content: <Row gutter={[16, 16]}>
                <Divider />
                {attachments.map(i => <>
                    <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
                        <FileUploader previewOnly={true}
                                      thumbType={'picture-card'}
                                      uploaderType={'PDF'}
                                      fetchFileList={() => {
                                          const file = {
                                              name: i,
                                              uid: i,
                                              status: 'done'
                                          };
                                          return Promise.resolve([file]);
                                      }}></FileUploader>
                    </Col>
                </>)}
            </Row>,
            cancelButtonProps: {hidden: true},
            maskClosable: true
        });
    };

    const setCurrentRate = async () => {
        if (componentState.fxCurrency != null && app_state.currency_list.length > 0) {
            const currency = app_state.currency_list.find(i => i.id === componentState.fxCurrency);
            await axios
                .get(`https://apilayer.net/api/live?access_key=a4eb7fd0501842eb4d4712cc459cae5f&currencies=${currency.iso_alpha_3}&source=AUD&format=1`).then(res => {
                    const rate = res.data.quotes[`AUD${currency.iso_alpha_3}`];
                    setComponentState(prev => ({...prev, currentRate: rate}));
                });
        } else {
            setComponentState(prev => ({...prev, currentRate: null}));
        }
    };

    const fetchHoldingAccountBalance = async () => {
        if (!app_state.current_client || !componentState.fxCurrency) return [];
        const currentClient = app_state.current_client.id;
        const response = await API.get('holding_accounts', `/get/all/v2/${currentClient}`, {});
        const account_list = response.holding_account_list.filter(item => item.currency_id === componentState.fxCurrency).map(record => ({
            currencies_short_name: record.currencies_short_name,
            balance: record.balance,
            url: `/holding-account?account=${record.id}`
        }));
        setComponentState(prev => ({...prev, account_list: account_list}));
    };

    const fetchMarketRate = () => {
        const currency = (app_state.currency_list || []).find(item => item.id === componentState.fxCurrency);
        if (currency) {
            const url = `https://apilayer.net/api/live?access_key=a4eb7fd0501842eb4d4712cc459cae5f`
            axios.get(url, {
                params: {
                    currencies: currency.iso_alpha_3,
                    source: 'AUD',
                    format: '1'
                },
            }).then(({ data }) => {
                const actual_rate = data.quotes[`AUD${currency.iso_alpha_3}`];
                let rate = Number.parseFloat(actual_rate);
                let rate_adjust = Number.parseFloat(app_state.current_client.default_rate);
                let adjusted_rate = rate - rate * rate_adjust;
                setComponentState(prev => ({...prev, marketRate: adjusted_rate }));
            })
        }
    }

    const prepareMonthlyDonuts = (days) => {
        return ExposureMonitorUtils.prepareDonutPayments(
            days,
            componentState.account_list.reduce((acc, num) => acc + num.balance, 0),
            'DRAFT'
        );
    }

    const prepareDonutsData = () => {

        const day30 = componentState.dataList.filter(i =>
            dayjs(i.date).isAfter(dayjs()) && dayjs(i.date).isBefore(dayjs().add(30, 'days'))
        );

        const day30to60 = componentState.dataList.filter(i =>
            dayjs(i.date).isAfter(dayjs().add(30, 'days')) && dayjs(i.date).isBefore(dayjs().add(60, 'days'))
        );

        const day60to90 = componentState.dataList.filter(i =>
            dayjs(i.date).isAfter(dayjs().add(60, 'days')) && dayjs(i.date).isBefore(dayjs().add(90, 'days'))
        );

        const day90plus = componentState.dataList.filter(i =>
            dayjs(i.date).isAfter(dayjs().add(90, 'days'))
        );

        const day30Data = ExposureMonitorUtils.prepareDonutPayments(
            day30,
            componentState.account_list.reduce((acc, num) => acc + num.balance, 0),
            'DRAFT'
        ).find(item => item.label === 'Payments');
        const day60Data = ExposureMonitorUtils.prepareDonutPayments(
            day30to60,
            componentState.account_list.reduce((acc, num) => acc + num.balance, 0),
            'DRAFT'
        ).find(item => item.label === 'Payments');
        const day90Data = ExposureMonitorUtils.prepareDonutPayments(
            day60to90,
            componentState.account_list.reduce((acc, num) => acc + num.balance, 0),
            'DRAFT'
        ).find(item => item.label === 'Payments');
        const day90pData = ExposureMonitorUtils.prepareDonutPayments(
            day90plus,
            componentState.account_list.reduce((acc, num) => acc + num.balance, 0),
            'DRAFT'
        ).find(item => item.label === 'Payments');

        const tableData = [
            {age: '0 - 30 Days', balance: day30Data.value, count: day30Data.count},
            {age: '30 - 60 Days', balance: day60Data.value, count: day60Data.count},
            {age: '60 - 90 Days', balance: day90Data.value, count: day90Data.count},
            {age: '90+ Days', balance: day90pData.value, count: day90pData.count}
        ];
        setComponentState(prev => ({...prev, donutTableData: tableData }));
    }

    const prepareMonthlyOverallDonuts = ({ day30, day30to60, day60to90, day90plus}) => {
        const ledgerBalance = componentState.account_list.reduce((acc, num) => acc + num.balance, 0)
        const donutThirtyDays = ExposureMonitorUtils.prepareDonutPayments(day30, ledgerBalance,'ACTUAL');
        const donutSixtyDays = ExposureMonitorUtils.prepareDonutPayments(day30to60, 0, 'ACTUAL');
        const donutNinetyDays = ExposureMonitorUtils.prepareDonutPayments(day60to90, 0, 'ACTUAL');
        const donutNinetyPlus = ExposureMonitorUtils.prepareDonutPayments(day90plus, 0, 'ACTUAL');

        const thirtyDaysCover = donutThirtyDays.find(item => item.label === 'Cover');
        const sixtyDaysCover = donutSixtyDays.find(item => item.label === 'Cover');
        const ninetyDaysCover = donutNinetyDays.find(item => item.label === 'Cover');
        const ninetyPlusCover = donutNinetyPlus.find(item => item.label === 'Cover');
        const thirtyDaysPayments = donutThirtyDays.find(item => item.label === 'Payments');
        const sixtyDaysPayments = donutSixtyDays.find(item => item.label === 'Payments');
        const ninetyDaysPayments = donutNinetyDays.find(item => item.label === 'Payments');
        const ninetyPlusPayments = donutNinetyPlus.find(item => item.label === 'Payments');

        if (thirtyDaysCover.value > thirtyDaysPayments.value) {
            sixtyDaysCover.value = sixtyDaysCover.value + (thirtyDaysCover.value - thirtyDaysPayments.value);
            sixtyDaysCover.percentage = sixtyDaysCover.value / sixtyDaysPayments.value * 100;
            sixtyDaysPayments.percentage = 100 - sixtyDaysCover.percentage;
            if (sixtyDaysPayments.value === 0) { sixtyDaysCover.percentage = 100; sixtyDaysPayments.percentage = 0; }
        }
        if (sixtyDaysCover.value > sixtyDaysPayments.value) {
            ninetyDaysCover.value = ninetyDaysCover.value + (sixtyDaysCover.value - sixtyDaysPayments.value);
            ninetyDaysCover.percentage = ninetyDaysCover.value / ninetyDaysPayments.value * 100;
            ninetyDaysPayments.percentage = 100 - ninetyDaysCover.percentage;
            if (ninetyDaysPayments.value === 0) { ninetyDaysCover.percentage = 100; ninetyDaysPayments.percentage = 0; }
        }
        if (ninetyDaysCover.value > ninetyDaysPayments.value) {
            ninetyPlusCover.value = ninetyPlusCover.value + (ninetyDaysCover.value - ninetyDaysPayments.value);
            ninetyPlusCover.percentage = ninetyPlusCover.value / ninetyPlusPayments.value * 100;
            ninetyPlusPayments.percentage = 100 - ninetyPlusCover.percentage;
            if (ninetyPlusPayments.value === 0) { ninetyPlusCover.percentage = 100; ninetyPlusPayments.percentage = 0; }
        }

        setComponentState(prev => ({
            ...prev,
            thirtyDays: donutThirtyDays,
            sixtyDays: donutSixtyDays,
            ninetyDays: donutNinetyDays,
            ninetyPlus: donutNinetyPlus
        }));

    }

    useEffect(() => {
        prepareHorizontalBarChartData(componentState.data);
        const {day30, day30to60, day60to90, day90plus} = ExposureMonitorUtils.getDateRange(componentState.dataList);
        prepareMonthlyOverallDonuts({ day30, day30to60, day60to90, day90plus});
        prepareDonutsData({ day30, day30to60, day60to90, day90plus});
    }, [componentState.data, componentState.beneficiaryList, componentState.dataState, componentState.monthly]);

    useEffect(() => {
        fetchHoldingAccountBalance();
    }, [app_state.current_client]);

    useEffect(() => {
        initPrefs();
        initData();
        if (queryParams.get('currency') != null && componentState.fxCurrency == null) {
            setComponentState(prev => ({...prev, fxCurrency: Number(queryParams.get('currency'))}));
        }
        fetchMarketRate();
    }, [componentState.account_list]);

    useEffect(() => {
        fetchHoldingAccountBalance();
        setCurrentRate();
        fetchMarketRate();
    }, [componentState.fxCurrency]);

    useEffect(() => {
        if (componentState.monthly) {
            reConstructMonthlyExposure(componentState.monthly, componentState.dataState);
        } else {
            initData();
        }
    }, [componentState.monthly, componentState.dataState]);

    useEffect(() => {
        const chart1 = document.querySelector('.ct-chart-tBar');
        const chart2 = document.querySelector('.ct-chart-mBar');

        [chart1, chart2].forEach((chart, index) => {
            const tooltip = document.createElement('div');
            tooltip.className = `${classes.chartistTooltip}`;
            document.body.appendChild(tooltip);

            if (chart) {
                chart.addEventListener('mouseover', (event) => {
                    if (event.target.classList.contains('ct-bar')) {
                        const value = event.target.getAttribute('ct:value');
                        const meta = event.target.getAttribute('meta');
                        const label = event.target.getAttribute('hoverLabel');

                        let str = '';
                        if (componentState.data.length > 0) {
                            const currency = componentState.fxCurrency;
                            const payouts = componentState.fxExposureList
                                .filter(i => i.currencyID === currency && i[index] != null && dayjs(i.date).format('MM/YYYY') === meta);
                            payouts.forEach(i => {
                                str += dayjs(i.date).format('DD/MM/YYYY') + ':' + i.amount + `<br />`;
                            });
                        }
                        tooltip.innerHTML = `${label}: ${value}<br />${meta}<br /> ${str}`;
                        tooltip.style.display = 'block';
                        tooltip.style.left = `${event.pageX}px`;
                        tooltip.style.top = `${event.pageY - 40}px`;
                    }
                });

                chart.addEventListener('mouseout', () => {
                    tooltip.style.display = 'none';
                });

                chart.addEventListener('mousemove', (event) => {
                    tooltip.style.left = `${event.pageX}px`;
                    tooltip.style.top = `${event.pageY - 40}px`;
                });
            }
        });


        const pie1 = document.querySelector('.ct-pie-chart');
        const pie2 = document.querySelector('.ct-pie-chart-2');

        if (pie1 && pie2) {

            [pie1, pie2].forEach((pie, index) => {

                const legendContainer = document.getElementById(`chart-legend-${index}`);
                legendContainer.innerHTML = '';
                const series = pie.querySelectorAll('.ct-series');
                const labels = pieCharts.currencyAverage.labels;

                series.forEach((serie, index) => {
                    const legendItem = document.createElement('div');
                    legendItem.classList.add('legend-item');

                    legendItem.style.display = 'flex';
                    legendItem.style.alignItems = 'center';
                    legendItem.style.marginBottom = '5px';
                    legendItem.style.marginRight = '10px';

                    const label = document.createElement('span');
                    const labelss = labels[index];
                    label.textContent = labels[index]; // Add corresponding label

                    if (labelss != null) {
                        const img = document.createElement('img');
                        img.setAttribute('src', `https://fwwportal-branding.s3.us-east-1.amazonaws.com/currencies/${labelss.toLowerCase()}.png`);
                        img.setAttribute('width', '15px');
                        img.setAttribute('height', '10px');
                        img.style.marginRight = '5px';
                        legendItem.appendChild(img);
                    }
                    legendItem.appendChild(label);
                    legendContainer.appendChild(legendItem);
                });
            });
        }
    }, [componentState.fxCurrency]);

    const renderTopBlock = () => {
        return <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
            <Form.Item label={'Currency'} name={'selected_currency'} style={{marginBottom: 0}}>
                <Select allowClear={true}
                        showSearch={true}
                        defaultValue={componentState.fxCurrency}
                        style={{width: '250px'}}
                        optionFilterProp={'alt'}
                        options={app_state.currency_list.filter(i => i.id !== 1).map(i => ({
                            ...i,
                            label: <TypographyCurrency
                                iso_alpha_3={i.iso_alpha_3}>[{i.iso_alpha_3}] {i.full_name}</TypographyCurrency>,
                            value: i.id,
                            alt: `${i.full_name} ${i.iso_alpha_3}`
                        }))}
                        onChange={((val, datum) => setComponentState(prev => ({...prev, fxCurrency: val, monthly: null})))}
                        placeholder={'-- SELECT --'}></Select>
            </Form.Item>
            <span>
                Display Actual and/or Forecast Data: &nbsp;
                <Radio.Group
                    onChange={(event) => setComponentState(prev => ({...prev, dataState: event.target.value || 'ACTUAL'}))}
                    block
                    options={componentState.options}
                    defaultValue='ACTUAL'
                    optionType='button'
                    buttonStyle='solid'
                />
            </span>
            <div style={{display: 'flex', alignItems: 'start', gap: 5, marginLeft: '10px' }}>
                <Button type={'primary'}
                        size={'small'}
                        icon={<PlusOutlined />}
                        onClick={() => {
                            setComponentState(prev => ({
                                ...prev,
                                isPayoutModalOpen: true,
                                activePayoutId: null,
                                currencyBeneficiaryList: componentState.currencyBeneficiaryList
                            }));
                            if (componentState.dataState === 'DRAFT') {
                                payoutForm.setFieldsValue({draft: true});
                            }
                        }}>
                    Add Payment
                </Button>
                <Button type={'primary'}
                        size={'small'}
                        icon={<PlusOutlined/>}
                        onClick={() => {
                            setComponentState(prev => ({
                                ...prev, isDraftForwardModelOpen: true
                            }));
                        }}>
                    Add Forecast Cover
                </Button>
            </div>
        </div>;
    };

    const getSelectedCurrency = () => {
        const currency = app_state.currency_list.find(item => item.id === componentState.fxCurrency);
        if (currency != null) {
            return currency.iso_alpha_3;
        } else {
            return '-';
        }
    };

    const reConstructMonthlyExposure = ({year, month}, includeDraft) => {

        if (year && month != null) {
            const formattedMonth = `${year}-${String(month).padStart(2, '0')}`; // Format month to 'YYYY-MM'

            const types = ['PAYOUTS', 'COVERED']; // Add more types if necessary

            let data = componentState.data.map(i => {
                const item = {...i};
                if (i.type !== 'PAYOUTS') {
                    item.type = 'COVERED';
                }
                return item;
            });

            data = data.filter(item => item.draft);

            const groupedData = data.reduce((acc, {type, amount, date}) => {
                const formattedDate = dayjs(date).format('YYYY-MM-DD'); // Full date for filtering
                if (formattedDate.startsWith(formattedMonth)) { // Filter only dates from the passed month and year
                    return {
                        ...acc,
                        [type]: {
                            ...(acc[type] || {}),
                            [formattedDate]: ((acc[type] && acc[type][formattedDate]) || 0) + amount // Sum data by day
                        }
                    };
                }
                return acc;
            }, {});

            types.forEach(type => {
                if (!groupedData[type]) {
                    groupedData[type] = {[dayjs().format('YYYY-MM-DD')]: 0}; // Initialize missing type
                }
            });

            const daysInMonth = dayjs(`${year}-${month}`, 'YYYY-MM').daysInMonth(); // Number of days in the passed month

            const labels = Array.from({length: daysInMonth}, (_, i) => (i + 1).toString());

            const fillMissingDays = (dataObject = {}) => labels.reduce((acc, day) => {
                const fullDate = dayjs(`${year}-${month}-${day}`).format('YYYY-MM-DD');
                return {
                    ...acc,
                    [fullDate]: dataObject[fullDate] || 0 // Fill missing days with 0
                };
            }, {});

            const series = types.map(type => [...Object.values(fillMissingDays(groupedData[type]))]);

            const filteredData = componentState.data.filter(item => {
                const itemDate = dayjs(item.date);
                return itemDate.year() === parseInt(year) && itemDate.month() + 1 === parseInt(month);
            });


            setBarCharts(prev => ({
                ...prev,
                currencyPlan: {
                    ...prev.currencyPlan, labels: labels, series: series
                }
            }));
            setComponentState(prev => ({...prev, dataList: filteredData}));

            // ====================== For Pie chart ==================================

            let payout = 0;
            let covered = 0;

            payout = data.filter(i => i.type === 'PAYOUTS' && i.draft).reduce((acc, obj) => acc + obj.amount, 0);
            covered = data.filter(i => i.type !== 'PAYOUTS' && i.draft).reduce((acc, obj) => acc + obj.amount, 0);

            let uncovered = payout - covered;

            let balance = 0;

            const pieSeries = [
                {label: 'Uncovered', value: uncovered},
                {label: 'Covered', value: covered}
            ];

            const total = covered + uncovered;

            const pieLabels = pieSeries.map(i => {
                const percentage = i.value < total ? ((i.value / total) * 100).toFixed(0) : 100;
                return `${i.label} = ${percentage}%`;
            });

            const desc = [...pieSeries];
            desc.push({label: 'Total Payout', value: payout});

            const descriptions = ExposureMonitorUtils.prepareDescriptions(data, componentState.dataState, null, balance);

            const currencyAvg = {
                series: pieSeries,
                descriptions: descriptions,
                labels: pieLabels.map((val, ind) => pieSeries[ind].value === 0 ? ' ' : val),
                data: desc,
                isUncoveredZero: uncovered < 0
            };
            setPieCharts(prev => ({
                ...prev, currencyAverage: {...prev, ...currencyAvg}
            }));
        }
    };

    const listener = (strokeWidth = null) => {

        return {
            draw: (data) => {
                if (data.type === 'bar') {

                    if (data.seriesIndex === 0) {
                        data.element.attr({
                            style: 'stroke: #f4c63d;' // Blue color for first series
                        });
                    }
                    // Apply color for second series
                    if (data.seriesIndex === 1) {
                        data.element.attr({
                            style: 'stroke: #00bcd4;' // Red color for second series
                        });
                    }
                    const xAxisLabel = data.axisX.ticks[data.index];

                    data.element._node.addEventListener('click', () => {
                        const date = dayjs(xAxisLabel).format('YYYY-MM');
                        const mth = date.split('-')[1];
                        if (mth != null) {
                            setComponentState(prev => ({
                                ...prev,
                                monthly: {
                                    year: date.split('-')[0],
                                    month: date.split('-')[1],
                                    label: mth != null ? dayjs(`${date.split('-')[1]}`, 'M').format('MMMM') : null
                                }
                            }));
                        }
                    });


                    data.element.attr({
                        'meta': xAxisLabel,
                        'series': data.seriesIndex,
                        'hoverLabel': data.seriesIndex === 0 ? 'Payouts' : (data.seriesIndex === 1 ? 'Covered' : 'Forwards')
                    });

                }

                // if (data.type === 'label' && data.axis.units.pos === 'x') {
                //     data.element.empty()._node.innerHTML = data.text.length === 3 ? `
                // <span class="ct-label ct-horizontal ct-end" style="width: 50px; height: 20px">${data.text}</span>
                // ` : `<span class="ct-label ct-horizontal ct-end" style="width: 50px; height: 20px">${data.text}</span>`;
                // }
            }
        };
    };

    const handleSubmit = (payload) => {
        const loading = message.loading('Saving payout record. Please wait..', 0);
        if (!componentState.activePayoutId) {
            payload.recordCreated = dayjs(new Date()).startOf('day');
        }
        payload.clientID = app_state.current_client.id;
        payload.attachments = JSON.stringify(payload.attachments);

        const request = componentState.activePayoutId != null ? API.post('commons', '/update', {
            body: {
                context: 'cashplan_records',
                data: payload,
                condition: {id: componentState.activePayoutId}
            }
        }) : API.post('commons', '/insert', {
            body: {
                context: 'cashplan_records',
                data: payload
            }
        });
        request.then(() => {
            message.success('Payout record saved');
            setComponentState(prev => ({...prev, activePayoutId: null, isPayoutModalOpen: false}));
            initData();
        }).catch(err => {
            console.log(err);
            message.error('Unable to save payout record. Please try again..');
        }).finally(() => loading());
    };


    const onStart = (_event, uiData) => {
        const {clientWidth, clientHeight} = window.document.documentElement;
        const targetRect = draggableRef.current.getBoundingClientRect();
        if (!targetRect) {
            return;
        }
        setModalDrag(prev => ({
            ...prev,
            bounds: {
                left: -targetRect.left + uiData.x,
                right: clientWidth - (targetRect.right - uiData.x),
                top: -targetRect.top + uiData.y,
                bottom: clientHeight - (targetRect.bottom - uiData.y)
            }
        }));
    };

    const handleForwardsSubmit = async (values) => {
        if (componentState.coverType === 'SPOT') {
            const fromCurrency = app_state.currency_list.find(item => values.from_currency === item.id).iso_alpha_3;
            const toCurrency = app_state.currency_list.find(item => values.to_currency === item.id).iso_alpha_3;
            history.push(`/shortcut_transfer/${toCurrency}-${fromCurrency}--${values.amount}-----`);
        } else {
            sendForward(values);
        }
    };

    const sendForward = (values) => {
        let cover = app_state.current_client.exposure_desiredcoverage;
        Modal.confirm({
            title: 'Forward Request',
            content: <>
                <Divider />
                <Form.Item label={'Desired Cover'}>
                    <InputNumber defaultValue={app_state.current_client && (app_state.current_client.exposure_desiredcoverage || 0)} onKeyUp={(event) => cover = event.target.value }/>
                </Form.Item>
            </>,
            onOk: () => {
                transferForm.setFieldsValue({ amount: cover * (values.amount || 0) });
                setComponentState(prev => ({...prev, isForwardModalOpen: true }));
            }
        });
    }

    const renderAverageRate = () => {
        const data = componentState
            .dataList.filter(i => i.type !== 'PAYOUTS' && i.draft);

        const total = data.reduce((acc, item) => acc + item.amount, 0);
        const total_f = data.reduce((acc, item) => acc + (item.amount / item.rate), 0);
        let amount = (total / total_f);

        console.log("amount", amount);
        console.log("total", total);
        console.log("total_f", total_f);

        if  (isNaN(amount)) {
            amount = (0).toString();
        } else {
            amount = amount.toFixed(4);
        }
        return amount;
    };

    const buildPayoutData = () => {
        return componentState.dataList.filter(i => i.type === 'PAYOUTS' && i.draft);
    };

    const buildForwardData = () => {
        return componentState.dataList.filter(i => i.type === 'FORWARDS' && i.draft);
    };

    const handleDraftForwardSubmit = (values) => {
        const loading = message.loading('saving draft forward. Please wait..', 0);
        values.clientID = app_state.current_client.id;
        API.post('commons', '/insert', {
            body: {
                context: 'draft_forward',
                data: values
            }
        }).then(res => {
            draftForwardForm.resetFields();
            setComponentState(prev => ({...prev, isDraftForwardModelOpen: false}));
            initData();
        }).catch(err => {
            message.error('Unable to save draft forward. Please try again...');
        }).finally(() => loading());
    };

    const renderTableData = () => {
        return componentState.dataList.filter(i => i.type === 'PAYOUTS' && i.draft);
    };

    const triggerMonthlySchedule = (item) => {
        const date = dayjs(item).format('YYYY-MM');
        const mth = date.split('-')[1];
        if (mth != null) {
            setComponentState(prev => ({
                ...prev,
                monthly: {
                    year: date.split('-')[0],
                    month: date.split('-')[1],
                    label: mth != null ? dayjs(`${date.split('-')[1]}`, 'M').format('MMMM') : null
                }
            }));
        }
    }

    const saveDesiredClientCoverage = () => {
        if (desiredCoverage.current.value == null || desiredCoverage.current.value <= 0 || desiredCoverage.current.value === '') {
            message.info("Invalid coverage. It must be greater than 0 and less than 100");
            return true;
        }
        if (desiredCoverage.current.value > 100) {
            message.info("Invalid coverage. It must be greater than 0 and less than 100");
            return true;
        }
        if (desiredCoverage.current.value.toString().includes(".")) {
            message.info("Must not contain decimal points");
            return true;
        }
        API.post("commons", "/update", {
            body: {
                context: "clients",
                data: {
                    exposure_desiredcoverage: desiredCoverage.current.value
                },
                condition: { id: app_state.current_client.id, team_id: app_state.current_client.team_id }
            }
        }).then(() => {
            message.success("Desired coverage saved successfully");
        })
    }

    const buildDonutPaymentsColumns = () => {
        return [
            {
                title: 'Age',
                dataIndex: 'age',
                key: 'age',
            },
            {
                title: 'Payments',
                dataIndex: 'count',
                key: 'count'
            },
            {
                title: 'Payments',
                dataIndex: 'balance',
                key: 'balance',
                render: (text ) => text != null
                    ? new Intl.NumberFormat('AUD', {
                        currency: 'AUD',
                        style: 'currency',
                        useGrouping: true,
                        currencyDisplay: 'narrowSymbol',
                        maximumFractionDigits: 2,
                        minimumFractionDigits: 2
                    }).format(text)
                    : '-'
            }
        ];
    }

    const setRateAlert = () => {

        let rate = null;
        Modal.confirm({
            title: 'Set rate alert ?',
            content: <>
                <Divider />
                <p>
                    Put in a Rate Alert for this currency pair.<br/>
                    The current market rate is {componentState.currentRate} AUD/{getSelectedCurrency()}.
                </p>
                <Form.Item label={'Rate'}>
                    <InputNumber style={{ width: '100%' }} onKeyUp={(event) => rate = event.target.value }/>
                </Form.Item>
            </>,
            onOk: () => {
                return new Promise((resolve, reject) => {
                    if (!rate) {
                        message.error("Rate alert cannot be empty");
                        reject();
                        return;
                    }
                    if (rate <= 0) {
                        message.info("Value cannot be less than or equal to zero");
                        reject();
                        return;
                    }
                    let payload = {currencyFromID: 1, currencyToID: componentState.fxCurrency, rate: Number(rate)};
                    if (componentState.currentRate != null) {
                        if (componentState.currentRate >= Number(rate)) {
                            payload = {...payload, goingDownTrigger: true, goingUpTrigger: false};
                        } else {
                            payload = {...payload, goingDownTrigger: false, goingUpTrigger: true};
                        }
                    }
                    payload = {
                        ...payload,
                        notify_changes: true,
                        recordCreated: dayjs().format('YYYY-MM-DD'),
                        clientID: app_state.current_client.id,
                        rate: Number(payload.rate).toFixed(4)
                    };
                    RateAlertAPI.saveAlert(payload)
                        .then(() => {
                            message.success("Rate alert is successfully created");
                            resolve();
                        }).catch(err => reject(err));
                });
            }
        })
    }

    const contextValue = useMemo(() => ({name: 'Forward Notification'}), []);

    return <Context.Provider value={contextValue}>
        {contextHolder}
        <Card title={renderTopBlock()} style={{marginBottom: '10px'}}>
            <div style={{display: 'flex', justifyContent: queryParams.get('currency') != null ? 'space-between' : 'end'}}>
                {
                    queryParams.get('currency') != null ?
                        <Button type={'primary'} icon={<ArrowLeftOutlined />} onClick={() => history.goBack()}>Back to Exposure
                            Monitor</Button>
                        : <></>
                }
                <div style={{display: 'flex', justifyContent: 'end', alignItems: 'center'}}>
                    <Typography.Text>{getSelectedCurrency().toUpperCase()} Ledger Balances:&nbsp;&nbsp;</Typography.Text>
                    {componentState.account_list.map(i => {
                        return <Form.Item style={{display: 'inline-block', marginRight: 5}}>
                            <Radio.Button>
                                <TypographyCurrency iso_alpha_3={i.currencies_short_name}><strong>
                                    {new Intl.NumberFormat(CurrencyLocales[i.currencies_short_name], {
                                        currency: i.currencies_short_name,
                                        style: 'currency',
                                        useGrouping: true,
                                        currencyDisplay: 'narrowSymbol',
                                        maximumFractionDigits: 2,
                                        minimumFractionDigits: 2
                                    }).format(i.balance)}
                                </strong></TypographyCurrency>
                            </Radio.Button>
                        </Form.Item>;
                    })}
                </div>
            </div>
            <Row gutter={[16, 16]} justify={'center'}>
                {
                    pieCharts.currencyAverage.data.reduce((acc, i) => acc + i.value, 0) > 0 ?
                        <Col xs={24} sm={24} md={24} lg={6} xl={6} xxl={6}>
                            {
                                pieCharts.currencyAverage.forwardGtPayout
                                    ? <>Your covered amount is greater than your payouts inputted</>
                                    : <><h3 style={{textAlign: 'center', marginBottom: 0}}><TypographyCurrency className={classes.coverageCenter} iso_alpha_3={getSelectedCurrency()}>{getSelectedCurrency()}&nbsp;Coverage</TypographyCurrency></h3>
                                        {/*<h6 style={{textAlign: 'center'}}>*/}
                                        {/*    {componentState.monthly ? '[Single Month Coverage Forecast]' : '[12-mth Forward Coverage Forecast]'}*/}
                                        {/*</h6>*/}
                                        <div style={{
                                            display: 'flex',
                                            justifyContent: 'center',
                                            gap: 50,
                                            alignItems: 'center',
                                            flexDirection: 'column'
                                        }}>
                                            <div style={{display: 'flex', justifyContent: 'space-between', paddingTop: 10 }}>
                                                {<Descriptions
                                                    column={1}
                                                    contentStyle={{margin: 0, padding: '4px 2px'}}
                                                    labelStyle={{fontWeight: 'bold', padding: '4px 2px'}}
                                                >
                                                    {componentState.fxCurrency && pieCharts.currencyAverage.descriptions.map(i => (
                                                        <Descriptions.Item key={i.label}
                                                                           style={{
                                                                               marginBottom: '0px',
                                                                               paddingBottom: '0px',
                                                                               borderBottom: '1px solid #ddd',
                                                                               display: 'flex',
                                                                               justifyContent: 'space-between'
                                                                           }}
                                                                           label={
                                                                               <span style={{
                                                                                   display: 'inline-block',
                                                                                   minWidth: '150px',
                                                                                   fontWeight: 'bold'
                                                                               }}>
                                                    {i.label}
                                                  </span>
                                                                           }>
                                                            <span style={{
                                                                display: 'flex',
                                                                flexDirection: 'column',
                                                                justifyContent: 'start'
                                                            }}>
                                                                <span>
                                                                    {new Intl.NumberFormat(CurrencyLocales[getSelectedCurrency()], {
                                                                        currency: getSelectedCurrency(),
                                                                        style: 'currency',
                                                                        useGrouping: true,
                                                                        currencyDisplay: 'narrowSymbol',
                                                                        maximumFractionDigits: 2,
                                                                        minimumFractionDigits: 2
                                                                    }).format(i.value)}
                                                                </span>
                                                                <span>{i.template}</span>
                                                            </span>
                                                        </Descriptions.Item>))}
                                                    <Description.Item key={'Average Rate'}
                                                                      style={{
                                                                          marginBottom: '0px',
                                                                          paddingBottom: '0px',
                                                                          borderBottom: '1px solid #ddd',
                                                                          display: 'flex',
                                                                          justifyContent: 'space-between'
                                                                      }}
                                                                      label={
                                                                          <span style={{
                                                                              display: 'inline-block',
                                                                              minWidth: '150px',
                                                                              fontWeight: 'bold'
                                                                          }}>
                                                    {'Average Rate'}
                                                  </span>
                                                                      }>
                                                        {renderAverageRate()}
                                                    </Description.Item>
                                                    <Description.Item key={'Current Rate'}
                                                                      style={{
                                                                          marginBottom: '0px',
                                                                          paddingBottom: '0px',
                                                                          borderBottom: '1px solid #ddd',
                                                                          display: 'flex',
                                                                          justifyContent: 'space-between'
                                                                      }}
                                                                      label={
                                                                          <span style={{
                                                                              display: 'inline-block',
                                                                              minWidth: '150px',
                                                                              fontWeight: 'bold'
                                                                          }}>
                                                    {'Market Rate'}
                                                  </span>
                                                                      }>
                                                        <span style={{
                                                            display: 'flex',
                                                            justifyContent: 'start',
                                                            flexDirection: 'column'
                                                        }}>
                                 <Typography.Text style={{marginRight: 5}}>{componentState.marketRate.toFixed(4)}</Typography.Text>
                                 <Typography.Link onClick={() => setRateAlert()}>Set Rate Alert Now</Typography.Link>
                              </span>
                                                    </Description.Item>
                                                </Descriptions>}
                                                {/*<div>*/}
                                                {/*    {*/}
                                                {/*        !pieCharts.currencyAverage.isUncoveredZero &&*/}
                                                {/*        <Alert type={'info'} message={'Uncovered is Zero'}></Alert>*/}
                                                {/*    }*/}
                                                {/*</div>*/}
                                            </div>
                                            <div>
                                                {
                                                    pieCharts.currencyAverage.isUncoveredZero && componentState.dataList.length > 0 ?
                                                        <ChartistGraph data={pieCharts.currencyAverage}
                                                                       style={{width: '200px'}}
                                                                       className={'ct-pie-chart-2'}
                                                                       listener={{
                                                                           draw: (data) => {
                                                                               if (data.type === 'slice') {
                                                                                   const sliceIndex = data.index; // Get the slice index (0, 1, 2, ...)
                                                                                   if (sliceIndex === 0) {
                                                                                       data.element.attr({
                                                                                           style: 'fill: #f4c63d;' // Blue color for first series
                                                                                       });
                                                                                   }
                                                                                   // Apply color for second series
                                                                                   if (sliceIndex === 1) {
                                                                                       data.element.attr({
                                                                                           style: 'fill: #00bcd4;' // Red color for second series
                                                                                       });
                                                                                   }
                                                                               }
                                                                           }
                                                                       }}
                                                                       options={{
                                                                           height: 250,
                                                                           labelInterpolationFnc: function (value) {
                                                                               return value;
                                                                           }
                                                                       }} type={'Pie'}/> : <>{app_state.current_client &&
                                                            <FxPieChart data={[{
                                                                label: 'Overcovered',
                                                                value: Math.abs('1000'),
                                                                percentage: 100,
                                                                color: '#00bcd4'
                                                            }]} isCurrency={true}></FxPieChart>}</>
                                                }
                                                <div id={'chart-legend-1'} style={{display: 'flex', gap: 5}}></div>
                                            </div>
                                            <div>
                                                <Title level={5}>Coverage Planning</Title>
                                                <Typography.Paragraph>
                                                    Alter your desired global coverage % (0-100)
                                                </Typography.Paragraph>
                                                <div style={{display: 'flex', justifyContent: 'center', alignItems: 'end'}}>
                                                    <Form.Item label={'Desired Coverage'} style={{marginBottom: 0}}
                                                               rules={[{
                                                                   required: true,
                                                                   message: 'Desired Coverage is required'
                                                               }]}>
                                                        <InputNumber ref={desiredCoverage}
                                                                     defaultValue={app_state.current_client.exposure_desiredcoverage}
                                                                     style={{width: '200px'}}/>
                                                    </Form.Item>
                                                    <Button type={'primary'}
                                                            onClick={(event) => saveDesiredClientCoverage()}>Save</Button>
                                                </div>
                                            </div>
                                            </div>
                                    </>
                            }
                        </Col> : <>{app_state.current_client && <FxPieChart
                            data={[{label: 'Overcovered', value: Math.abs('1000'), percentage: 100, color: '#00bcd4'}]}
                            isCurrency={true}></FxPieChart>}</>
                }
                <Col xs={24} sm={24} md={24} lg={18} xl={18} xxl={18}>
                    {
                        componentState.fxCurrency ?
                            <>
                                <div style={{display: 'flex', justifyContent: 'center'}}>

                                    {
                                        [
                                            {data: componentState.thirtyDays, day: '30 Days'},
                                            {data: componentState.sixtyDays, day: '60 Days'},
                                            {data: componentState.ninetyDays, day: '90 Days'},
                                            {data: componentState.ninetyPlus, day: '90 Plus Days'},
                                        ].map((item, index) => <div
                                            style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                                            <FxDonut chartData={item.data} id={`donut-${item.day}`}></FxDonut>
                                            <Title level={2}>{item.day}</Title>
                                            {
                                                item.data.map(i => <div style={{display: 'flex', gap: 5}}>
                                                    <Typography.Link href={'#coverTable'}> {i.label} </Typography.Link>
                                                    <Typography.Text> {new Intl.NumberFormat('AUD', {
                                                        currency: 'AUD',
                                                        style: 'currency',
                                                        useGrouping: true,
                                                        currencyDisplay: 'narrowSymbol',
                                                        maximumFractionDigits: 2,
                                                        minimumFractionDigits: 2
                                                    }).format(i.value)}</Typography.Text>
                                                </div>)
                                            }
                                        </div>)
                                    }
                                </div>
                                <Divider/>
                                <div>
                                    {/*<h3 style={{textAlign: 'center'}}>*/}
                                    {/*    Exposure Monitoring <small>{componentState.monthly ? '(daily)' : '(12-month)'}</small>*/}
                                    {/*</h3>*/}
                                    {
                                        componentState.monthly == null ?
                                            <Row gutte={[16, 16]}>
                                                <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                                                <>
                                                        <Divider orientation={'left'}
                                                                 orientationMargin={0}>Monthly</Divider>
                                                        <div style={{
                                                            display: 'flex',
                                                            justifyItems: 'start',
                                                            gap: '10px'
                                                        }}>
                                                            {barCharts.currencyPlan.labels.map(item => {
                                                                return <Chip label={item}
                                                                             onClick={() => triggerMonthlySchedule(item)}
                                                                             key={item}></Chip>
                                                            })}
                                                        </div>
                                                    </>
                                                </Col>
                                            </Row> : <></>
                                    }
                                    {
                                        componentState.monthly && componentState.monthly.label &&
                                        <h3 style={{textAlign: 'center'}}>({componentState.monthly.label})</h3>
                                    }
                                    {componentState.monthly &&
                                        <div style={{display: 'flex', justifyContent: 'end', cursor: 'pointer'}}>
                                            <Tag color={'red'} icon={<CloseOutlined/>} onClick={() => {
                                                setComponentState(prev => ({...prev, monthly: null}));
                                            }}>Back to Monthly Grouping</Tag>
                                        </div>
                                    }
                                    <ChartistGraph
                                        key={componentState.fxCurrency}
                                        style={{width: '100%', fontWeight: 600}}
                                        className={'ct-chart-tBar'}
                                        data={barCharts.currencyPlan}
                                        type='Bar'
                                        listener={listener(14)}
                                        options={barCharts.currencyPlan.chartConfig}
                                    />
                                    {/*<div>*/}
                                    {/*    <Radio.Group optionType="button"*/}
                                    {/*                 buttonStyle="solid"*/}
                                    {/*                 onChange={e => {*/}
                                    {/*                     const d = componentState.months.find(item => e.target.value === item.value);*/}
                                    {/*                     setComponentState(prev => ({*/}
                                    {/*                         ...prev,*/}
                                    {/*                         monthly: { ...prev.monthly, year: d.year, month: d.value, label: d.label }*/}
                                    {/*                     }));*/}
                                    {/*                 }}*/}
                                    {/*                 options={componentState.months}></Radio.Group>*/}
                                    {/*</div>*/}
                                </div>
                            </> : <div>
                                <h3 style={{textAlign: 'center'}}>Global FX Exposure</h3>
                                <h6 style={{textAlign: 'center'}}>[12-mth Forward Coverage Forecast]</h6>
                                <ChartistGraph
                                    style={{width: '100%', margin: 20, fontWeight: 600}}
                                    data={barCharts.fxExposure}
                                    type='Bar'
                                    className={'ct-chart-mBar'}
                                    listener={listener()}
                                    options={barCharts.fxExposure.chartConfig}
                                />
                            </div>
                    }

                </Col>
            </Row>

        </Card>
        <Card size={'small'} title={`${getSelectedCurrency().toUpperCase()} Payments by Beneficiary`}
              style={{marginBottom: 10}}>
            <Row gutter={[16, 16]}>
                <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
                    <FxHorizontalBarChart data={componentState.owedEstimate}></FxHorizontalBarChart>
                </Col>
                <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
                    <Table size={'small'} dataSource={componentState.donutTableData}
                           columns={buildDonutPaymentsColumns()}></Table>
                </Col>
            </Row>
        </Card>
        <Card id={'coverTable'} title={<h5>{getSelectedCurrency().toUpperCase()} Payments and Cover (Forecast)</h5>}
              bodyStyle={{backgroundColor: '#efefef', border: "1px solid #d9d9d9"}}>
            {componentState.dataState === 'DRAFT' &&
                <p>Records highlighted <strong style={{color: 'green'}}>green</strong> are Forecast Data</p>}
            {
                componentState.transferType !== 'PAYMENTS' ? <>
                    {
                        Number(renderAverageRate()) > 0 ? <div style={{display: 'flex', justifyItems: 'end'}}>
                        <Alert style={{width: 200}} type={'info'} message={
                                <>
                                    <Descriptions column={1}>
                                        <Descriptions.Item label={'Average Rate'}
                                                           style={{
                                                               margin: 0,
                                                               padding: 0
                                                           }}>{renderAverageRate()}</Descriptions.Item>
                                    </Descriptions>
                                </>
                            }/>
                        </div> : <></>
                    }
                </> : <></>
            }
            <Row gutter={[16, 16]}>
                <Col xs={24} sm={24} md={24} lg={24} xxl={24}>
                    <Card title={'Scheduled Payments'}>
                        <Table rowKey={'id'}
                               key={app_state.current_client ? app_state.current_client.id : 0}
                               columns={buildColumns()}
                               size='small'
                               scroll={{x: 'max-content'}}
                               summary={(data) => {
                                   const total = data.reduce((acc, item) => acc + item.amount, 0);
                                   const total_f = data.reduce((acc, item) => acc + item.amount_from, 0);
                                   return <Table.Summary.Row>
                                       <Table.Summary.Cell index={0}></Table.Summary.Cell>
                                       <Table.Summary.Cell index={1}></Table.Summary.Cell>
                                       <Table.Summary.Cell index={7}></Table.Summary.Cell>
                                       <Table.Summary.Cell index={8}>Total: {new Intl.NumberFormat('en-Au', {
                                           minimumFractionDigits: 2,
                                           maximumFractionDigits: 2,
                                           useGrouping: true
                                       }).format(total)}</Table.Summary.Cell>
                                   </Table.Summary.Row>;
                               }}
                               dataSource={buildPayoutData()}
                               loading={componentState.isPayoutListLoading}></Table>
                    </Card>
                </Col>
                <Col xs={24} sm={24} md={24} lg={24} xxl={24}>
                    <Card title={'Coverage (Transfers and Forwards)'}>
                        <Table rowKey={'id'}
                               key={app_state.current_client ? app_state.current_client.id : 0}
                               columns={buildForwardColumns()}
                               size='small'
                               scroll={{x: 'max-content'}}
                               summary={(data) => {
                                   const total = data.reduce((acc, item) => acc + item.amount, 0);
                                   return <Table.Summary.Row>
                                       <Table.Summary.Cell index={0}></Table.Summary.Cell>
                                       <Table.Summary.Cell index={1}></Table.Summary.Cell>
                                       <Table.Summary.Cell index={2}></Table.Summary.Cell>
                                       <Table.Summary.Cell index={3}>Total: {new Intl.NumberFormat('en-Au', {
                                           minimumFractionDigits: 2,
                                           maximumFractionDigits: 2,
                                           useGrouping: true
                                       }).format(total)}</Table.Summary.Cell>
                                   </Table.Summary.Row>;
                               }}
                               dataSource={buildForwardData()}
                               loading={componentState.isPayoutListLoading}></Table>
                    </Card>
                </Col>
            </Row>
        </Card>
        <Modal visible={componentState.isPayoutModalOpen}
               style={{top: 3}}
               title={componentState.activePayoutId ? `Update Payout #${componentState.activePayoutId} Record` : 'Add a New Payment'}
               okText={
                   componentState.activePayoutId
                       ? `Update Payout`
                       : `Add Payout`
               }
               onCancel={() => {
                   payoutForm.resetFields();
                   uploaderRef.current.clearList();
                   setComponentState(prev => ({
                       ...prev,
                       isPayoutModalOpen: false,
                       activePayoutId: null
                   }));
               }}
               onOk={() => payoutForm.submit()}>
            <p>
                Fill in the details below to add a new payment (future invoice) into the system.<br />
                You can enter in an <strong>actual</strong> or <strong>forecast</strong> payment and also upload supporting
                documentation or invoices.
            </p>
            <Divider />
            <Form layout={'vertical'} initialValues={{currencyID: componentState.fxCurrency, attachments: [], draft: true }}
                  name={'payout-form'} form={payoutForm} onFinish={handleSubmit}>
                <Form.Item label={'Payment Due Date'} name={'scheduled'} normalize={(dayjsValue) =>
                    dayjsValue && dayjsValue.startOf('day').format('YYYY-MM-DD')
                }
                           getValueProps={(value) =>
                               value && {value: dayjs(value, 'YYYY-MM-DD').startOf('day')}
                           }>
                    <DatePicker format={'DD/MM/YYYY'} />
                </Form.Item>
                <Divider />
                <span style={{display: 'display-block', marginBottom: '10px'}}>
                        <CurrencySelect state={{currencies: app_state.currency_list.filter(i => i.id !== 1) || []}}
                                        rightLabel={'Enter payment amount'}
                                        onCurrencyChange={(val, d) => {
                                            const benes = componentState.beneficiaryList.filter(i => i.account_currency === d);
                                            setComponentState(prev => ({...prev, currencyBeneficiaryList: benes}));
                                        }}
                                        validated
                                        currencyFieldName={'currencyID'}
                                        fieldName={!componentState.isPlanReceipt ? 'payout' : 'receipt'}></CurrencySelect>
                    </span>
                {
                    !componentState.isPlanReceipt && <><Form.Item name={'beneficiary_id'} label={'Beneficiary'}>
                        <Select placeholder={' -- SELECT BENEFICIARY --'}
                                options={componentState.currencyBeneficiaryList.map(i => {
                                    const curr = app_state.currency_list.find(k => k.id === i.account_currency);
                                    return {
                                        alt: i.nickname == null ? i.ben_legal_name : i.nickname,
                                        label: <TypographyCurrency
                                            iso_alpha_3={curr.iso_alpha_3}>{i.nickname == null ? i.ben_legal_name : i.nickname}</TypographyCurrency>,
                                        value: i.id
                                    };
                                })}
                                optionFilterProp={'alt'}></Select>
                    </Form.Item>
                        <Button style={{marginBottom: '10px'}} type={'primary'} size={'small'}
                                onClick={() => setComponentState(prev => ({...prev, isBenModalOpen: true}))}>Add Beneficiary</Button>
                    </>
                }
                <Divider />
                <Form.Item name={'description'} label={'Description'}>
                    <Input.TextArea />
                </Form.Item>

                <Form.Item name='attachments' getValueFromEvent={(e) => e.map(i => i.name)}>
                    <FileUploader key={componentState.activePayoutId} uploaderType={'DRAGDROP'} ref={uploaderRef}></FileUploader>
                </Form.Item>
                <Modal visible={componentState.isBenModalOpen}
                       width={'900px'}
                       style={{top: 10}}
                       bodyStyle={{maxHeight: '80vh', overflowY: 'scroll', padding: '10px', background: '#E0e0e0'}}
                       onCancel={() => {
                           beneficiaryModalRef.current.resetFields();
                           setComponentState(prev => ({...prev, isBenModalOpen: false}));
                       }}
                       onOk={() => {
                           beneficiaryModalRef.current.submit();
                       }}
                       modalRender={(modal) => (
                           <Draggable
                               disabled={modalDrag.disabled}
                               bounds={modalDrag.bounds}
                               nodeRef={draggableRef}
                               onStart={(event, uiData) => onStart(event, uiData)}
                           >
                               <div ref={draggableRef}>{modal}</div>
                           </Draggable>
                       )}
                       title={
                           <div style={{width: '100%', cursor: 'move'}}
                                onMouseOver={() => {
                                    if (modalDrag.disabled) {
                                        setModalDrag(prev => ({...prev, disabled: false}));
                                    }
                                }}
                                onMouseOut={() => {
                                    setModalDrag(prev => ({...prev, disabled: true}));
                                }}>Add New Beneficiary</div>
                       }>
                    <BeneficiaryModal clientId={app_state.current_client ? app_state.current_client.id : null}
                                      initialCurrency={5}
                                      onSubmit={(val) => {
                                          initPrefs();
                                          setComponentState(prev => ({...prev, isBenModalOpen: false}));
                                      }}
                                      ref={beneficiaryModalRef}></BeneficiaryModal>
                </Modal>
            </Form>
        </Modal>
        <Modal name={'Forwards-Modal'}
               key={2}
               closable={true}
               visible={componentState.isForwardModalOpen}
               title={'Request Coverage'}
               onCancel={() => {
                   transferForm.resetFields();
                   setComponentState(prev => ({...prev, isForwardModalOpen: false}));
               }}
               onOk={() => transferForm.submit()}>
            <p>
                Select to request either a spot transfer or forward cover for your payments.
            </p>
            <Divider />
            <Form layout={'vertical'} initialValues={{ from_currency: 1, to_currency: 5}} name={'forwards-form'} form={transferForm} onFinish={handleForwardsSubmit}>
                <Segmented defaultValue={componentState.coverType}
                           block={true}
                           style={{marginBottom: '10px'}}
                           options={[{label: 'Spot', value: 'SPOT'}, {label: 'Forward', value: 'FORWARD'}]}
                           onChange={(val) => setComponentState(prev => ({...prev, coverType: val}))}></Segmented>
                {componentState.coverType === 'FORWARD' && <Form.Item label={'Payment Due Date'} name={'payment_due_date'}
                                                                      normalize={(dayjsValue) => dayjsValue && dayjsValue.startOf('day').format('YYYY-MM-DD')}
                                                                      getValueProps={(value) => value && {value: dayjs(value, 'YYYY-MM-DD').startOf('day')}}>
                    <DatePicker format={'DD/MM/YYYY'}/>
                </Form.Item>}
                <Form.Item name={'from_currency'} label={'From Currency'}>
                    <Select optionFilterProp={'alt'} options={(app_state.currency_list || []).map(i => ({
                        ...i,
                        value: i.id,
                        alt: i.full_name + i.iso_alpha_3,
                        label: <TypographyCurrency iso_alpha_3={i.iso_alpha_3}>{i.full_name}</TypographyCurrency>
                    }))}></Select>
                </Form.Item>
                <Form.Item name={'to_currency'} label={'To Currency'}>
                    <Select optionFilterProp={'alt'} options={(app_state.currency_list || []).map(i => ({
                        ...i,
                        value: i.id,
                        alt: i.full_name + i.iso_alpha_3,
                        label: <TypographyCurrency iso_alpha_3={i.iso_alpha_3}>{i.full_name}</TypographyCurrency>
                    }))}></Select>
                </Form.Item>
                <Form.Item name={'amount'} label={'Amount'} required={[{required: true, message: ''}, {
                    validator: (_, value) => {
                        if (Number(value) === 0) {
                            return Promise.reject('Enter valid amount');
                        }
                        if (!/^\d+(\.\d{1,2})?$/.test(value)) {
                            return Promise.reject('Please enter a valid number with up to 2 decimal places');
                        }
                        if (value && parseFloat(value) === 0) {
                            return Promise.reject('Amount cannot be zero');
                        }
                        return Promise.resolve();
                    }
                }]}>
                    <Input/>
                </Form.Item>
                {componentState.coverType === 'FORWARD' && <Form.Item name={'description'} label={'Description'}>
                    <Input.TextArea/>
                </Form.Item>}
            </Form>
        </Modal>
        <Modal visible={componentState.isListModalOpen}
               title={'Payouts List'}
               width={'700px'}
               onCancel={() => setComponentState(prev => ({...prev, isListModalOpen: false}))}
               cancelButtonProps={{type: 'primary', icon: <CloseOutlined />}}
               okButtonProps={{hidden: true}}
               cancelText={'Close'}>
            <div style={{display: 'flex', justifyContent: 'end'}}>
                <Button type={'primary'} danger={true} size={'small'} onClick={() => initData()}
                        icon={<ReloadOutlined />}>Refresh List</Button>
            </div>
            <Table rowKey={'id'}
                   key={app_state.current_client ? app_state.current_client.id : 0}
                   columns={buildColumns()}
                   size='small'
                   summary={(data) => {
                       const total = data.reduce((acc, item) => acc + item.amount, 0);
                       return <Table.Summary.Row>
                           <Table.Summary.Cell index={0}></Table.Summary.Cell>
                           <Table.Summary.Cell index={1}></Table.Summary.Cell>
                           <Table.Summary.Cell index={2}></Table.Summary.Cell>
                           <Table.Summary.Cell index={3}>Total: {new Intl.NumberFormat('en-Au', {
                               minimumFractionDigits: 2,
                               maximumFractionDigits: 2,
                               useGrouping: true
                           }).format(total)}</Table.Summary.Cell>
                       </Table.Summary.Row>;
                   }}
                   dataSource={renderTableData()}
                   loading={componentState.isPayoutListLoading}></Table>
        </Modal>
        <Modal visible={componentState.isTransferModalOpen}
               title={'Forwards and Transfers'}
               width={'1000px'}
               onCancel={() => setComponentState(prev => ({...prev, isTransferModalOpen: false}))}
               cancelButtonProps={{type: 'primary', icon: <CloseOutlined />}}
               okButtonProps={{hidden: true}}
               cancelText={'Close'}>
            <div style={{display: 'flex', justifyContent: 'end', marginBottom: '10px'}}>
                <Button type={'primary'} danger={true} size={'small'} onClick={() => initData()}
                        icon={<ReloadOutlined />}>Refresh List</Button>
            </div>
            <Segmented defaultValue={componentState.transferType}
                       block={true}
                       style={{marginBottom: '10px'}}
                       options={[{label: 'Spot', value: 'SPOT'}, {label: 'Forward', value: 'FORWARD'}]}
                       onChange={(val) => setComponentState(prev => ({...prev, transferType: val}))}></Segmented>
            <Table rowKey={'id'}
                   key={app_state.current_client ? app_state.current_client.id : 0}
                   columns={buildColumns()}
                   size='small'
                   summary={(data) => {
                       const total = data.reduce((acc, item) => acc + item.amount, 0);
                       return <Table.Summary.Row>
                           <Table.Summary.Cell index={0}></Table.Summary.Cell>
                           <Table.Summary.Cell index={1}></Table.Summary.Cell>
                           <Table.Summary.Cell index={2}></Table.Summary.Cell>
                           <Table.Summary.Cell index={3}></Table.Summary.Cell>
                           <Table.Summary.Cell index={4}>Average Forward Rate: .xxxx%</Table.Summary.Cell>
                           <Table.Summary.Cell index={5}>Total: {new Intl.NumberFormat('en-Au', {
                               minimumFractionDigits: 2,
                               maximumFractionDigits: 2,
                               useGrouping: true
                           }).format(total)}</Table.Summary.Cell>
                       </Table.Summary.Row>;
                   }}
                   dataSource={componentState.dataList.filter(i => componentState.transferType === 'SPOT' ? i.type === 'TRANSFERS' : i.type === 'FORWARDS')}
                   loading={componentState.isPayoutListLoading}></Table>
        </Modal>
        <Modal visible={componentState.isDraftForwardModelOpen}
               onOk={() => draftForwardForm.submit()}
               onCancel={() => {
                   draftForwardForm.resetFields();
                   setComponentState(prev => ({...prev, isDraftForwardModelOpen: false}));
               }}
               title={'Enter Hypothetical Coverage'}>
            <Form layout={'vertical'} initialValues={{ currencyFromID: 1, currencyToID: 5 }} name={'draft-forward-form'} form={draftForwardForm} onFinish={handleDraftForwardSubmit}>
                <Typography.Paragraph>
                    Use this form to enter hypothetical coverage for your future payments.
                    These will be recorded as Hypothetical forwards to help you plan your hedging requirements
                </Typography.Paragraph>
                <Form.Item normalize={(e) => e && dayjs(e).startOf('day').format('YYYY-MM-DD')}
                           rules={[{required: true, message: 'Settlement date is required'}]}
                           getValueProps={(value) =>
                               value && {value: dayjs(value, 'YYYY-MM-DD').startOf('day')}
                           }
                           name='settlementDate'
                           label={'Settlement Date'}>
                    <DatePicker format={'DD/MM/YYYY'} />
                </Form.Item>
                <Form.Item name='currencyFromID'
                           rules={[{required: true, message: 'Currency is required'}]}
                           label={'Currency From'}>
                    <Select showSearch={true} allowClear={true} optionFilterProp={'alt'}
                            options={app_state.currency_list.map(i => ({
                                ...i,
                                alt: `${i.full_name}${i.iso_alpha_3}`,
                                value: i.id,
                                label: <TypographyCurrency
                                    iso_alpha_3={i.iso_alpha_3}>{i.full_name} ({i.iso_alpha_3})</TypographyCurrency>
                            }))} />
                </Form.Item>
                <Form.Item name='currencyToID'
                           rules={[{required: true, message: 'Currency is required'}]}
                           label={'Currency To'}>
                    <Select showSearch={true} allowClear={true} optionFilterProp={'alt'}
                            options={app_state.currency_list.map(i => ({
                                ...i,
                                alt: `${i.full_name}${i.iso_alpha_3}`,
                                value: i.id,
                                label: <TypographyCurrency
                                    iso_alpha_3={i.iso_alpha_3}>{i.full_name} ({i.iso_alpha_3})</TypographyCurrency>
                            }))} />
                </Form.Item>
                <Form.Item name={'rate'} label={'Rate'}>
                    <InputNumber  />
                </Form.Item>
                <Form.Item name='amountTo'
                           rules={[{required: true, message: 'Amount is required'}]}
                           label={'Amount'}>
                    <InputNumber step={0.01} />
                </Form.Item>
            </Form>
        </Modal>
    </Context.Provider>
};
const mapStateToProps = (state) => ({
    app_state: state.app_state
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(style)(ListTable(ExposureMonitorDetailForecast)));