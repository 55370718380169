import ChartistGraph from "react-chartist";
import React, {useEffect, useState} from "react";
import Chartist from "chartist";

const defaultColors = ['#00bcd4', '#f4c63d']

const ExposureMonitorDetailCoveragePie = ({ pieCharts }) => {

    const [componentState, setComponentState] = useState({
        dData: {
            labels: [],
            series: []
        }
    });

    // console.log("new donut data", pieCharts.currencyAverage.series.map(item => ({ labels: item.labels, series: item.value })))

    const total = pieCharts.currencyAverage.series.reduce((acc, item) => acc + item.value, 0);

    const prepareChart = () => {
        if (pieCharts.currencyAverage != null) {
            let labels = pieCharts.currencyAverage.series.map(i => i.label);

            const total = pieCharts.currencyAverage.series.reduce((acc, item) => acc + item.value, 0);

            const series = pieCharts.currencyAverage.series.map(i => i.value / total * 100);
            series.reverse()

            setComponentState(prev => ({
                ...prev, dData: {
                    labels: labels.map(item => ' '),
                    series: series
                }
            }));
        }
    }

    useEffect(() => {
        prepareChart();
    }, [pieCharts.currencyAverage])

    // console.log('dData', componentState.dData)
    const value1 = componentState.dData.series[0]
    const value2 = componentState.dData.series[1]
    const coverPercentage = value1/(value1+value2);

    return <>
        {/*<ChartistGraph data={pieCharts.currencyAverage}*/}
        {/*               style={{width: '150px'}}*/}
        {/*               className={'ct-pie-chart-2'}*/}
        {/*               listener={{*/}
        {/*                   draw: (data) => {*/}
        {/*                       if (data.type === 'slice') {*/}
        {/*                           const sliceIndex = data.index; // Get the slice index (0, 1, 2, ...)*/}
        {/*                           if (sliceIndex === 0) {*/}
        {/*                               data.element.attr({*/}
        {/*                                   style: 'fill: #f4c63d;' // Blue color for first series*/}
        {/*                               });*/}
        {/*                           }*/}
        {/*                           // Apply color for second series*/}
        {/*                           if (sliceIndex === 1) {*/}
        {/*                               data.element.attr({*/}
        {/*                                   style: 'fill: #00bcd4;' // Red color for second series*/}
        {/*                               });*/}
        {/*                           }*/}
        {/*                       }*/}
        {/*                   }*/}
        {/*               }}*/}
        {/*               options={{*/}
        {/*                   height: 150,*/}
        {/*                   width: 150,*/}
        {/*                   labelInterpolationFnc: function (value) {*/}
        {/*                       return value;*/}
        {/*                   }*/}
        {/*               }} type={'Pie'}/>*/}

        <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
        <ChartistGraph data={componentState.dData}
                       className={'ct-pie-chart-2'}
                       listener={{
                           draw: (data) => {
                               if (data.type === 'label') {
                                   data.element['_node'].style.fontWeight = 'bold';
                                   // data.element['_node'].style.fill = '#fff';
                               }
                               if (data.type === 'slice') {
                                   data.element['_node'].style.fontWeight = 'bold';
                                   data.element['_node'].style.stroke = defaultColors[data.index];
                                   data.element.animate({
                                       opacity: {
                                           begin: data.index * 200,
                                           dur: 1000,
                                           from: 0,
                                           to: 1,
                                       },
                                       transform: {
                                           begin: data.index * 200,
                                           dur: 1000,
                                           from: "scale(0)",
                                           to: "scale(1)",
                                           easing: Chartist.Svg.Easing.easeOutElastic,
                                       },
                                   });
                               }
                           }
                       }}
                       options={{
                           width: 120,
                           donut: true,
                           donutWidth: 15,
                           donutSolid: true,
                           startAngle: 0,
                           showLabel: true,
                           total: 100,
                       }} type={'Pie'}/>
            <span style={{ fontSize: '16px', fontWeight: 'bold', marginLeft: -100, textAlign: 'center' }}>
                Covered<br/>
                {(coverPercentage*100).toFixed(0)}%
            </span>
        </div>
    </>
}

export default ExposureMonitorDetailCoveragePie